import { InstanceSelected, STORED_INSTANCE_SELECTOR } from "../types/instance";

type ConsentCookie = {
    advertising: boolean;
    essential: boolean;
    functional: boolean;
    performance: boolean;
};

interface CookieConsent {
    checkForCookieConsent: () => void;
    getConsentCookie: () => ConsentCookie;
}

declare global {
    interface Window {
        AwsUiConsent: CookieConsent;
    }
}

export const save = (key: string, value: any) => {
        localStorage.setItem(key, JSON.stringify(value));
};

export const remove = (key: string) => localStorage.removeItem(key);

export const load = (key: string) => {
    const value = localStorage.getItem(key);
    try {
        return value && JSON.parse(value);
    } catch (e) {
        console.warn(
            `⚠️ The ${key} value that is stored in localStorage is incorrect. Try to remove the value ${key} from localStorage and reload the page`
        );
        return undefined;
    }
};

const isInstanceSelected = (instance: any):instance is InstanceSelected => {
    return !!instance && instance.instanceArn;
}

export const getSelectedInstance = (): InstanceSelected | undefined => {

    const storedInstance = load(STORED_INSTANCE_SELECTOR);

    if(isInstanceSelected(storedInstance)) {

        return storedInstance;
    }

    return undefined;
}


