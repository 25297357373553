import { Header } from '@cloudscape-design/components';
import { WidgetConfig } from '../interfaces';
import { QueueSummaryStatsProvider } from './queueSummaryStatsProvider';
import QueueSummaryStatsContent from './queueSummaryStatsContent';

export const getQueueSummaryStats: WidgetConfig = {
  definition: { defaultRowSpan: 4, defaultColumnSpan: 2, minRowSpan: 3 },
  data: {
    icon: 'table',
    title: 'Queue performance',
    description: 'Table with key metrics, grouped by queue',
    header: ChartHeader,
    content: QueueSummaryStatsContent,
    provider: QueueSummaryStatsProvider,
    staticMinHeight: 560,
    multipleAllowed: true,
  },
};

function ChartHeader() {
  return (
    <Header variant="h2" description="Table with key metrics, grouped by queue">
      Queue Performance
    </Header>
  );
}
