import { useNavigate } from 'react-router-dom';
import { useApiWithFlash } from '../../../utils/hooks/useApiWithFlashInstance';
import EditSurveyForm from '../form';
import { SurveyData } from '../types';

export default function SurveyCreate() {

  // Hooks
  const navigate = useNavigate();
  const { handleApiWithFlash } = useApiWithFlash();

  // Handle form submission
  async function onSubmitForm(survey: SurveyData) {

    const response = await handleApiWithFlash(`/surveys`, 'POST', {
      successMessage: `Successfully created dial plan`,
      errorMessage: `Error creating dial plan`,
      data: survey,
    });

    if (response) {
      navigate('/surveys');
    }
  }

  return (
    <EditSurveyForm { ...{ onSubmitForm }} />
  );
}
