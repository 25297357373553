import {
  ButtonDropdown,
  ButtonDropdownProps,
  Header,
  Pagination,
  PropertyFilter,
  SpaceBetween,
  Spinner,
  Table,
  TableProps,
} from '@cloudscape-design/components';
import { CancelableEventHandler } from '@cloudscape-design/components/internal/events';
import { User, UsersArray } from '../../user-admin/types';
import { useState } from 'react';
import { FILTERING_PROPERTIES as filteringProperties } from './filtering_poperties';
import { TableEmptyStateProps } from '../../../components/table/commons';
import { ResourceTypes } from '../../../types/rolePermissions';
import { getTextFilterCounterText } from '../../../utils/text-filter';
import { useColumnWidths } from '../../../components/table/use-column-width';
import { useLocalStorage } from '../../../components/use-local-storage';
import { useTableConfig } from './table.config';
import { useCatoCollection } from '../../../utils/hooks/useCatoCollection';

export type userDecoratedButtonDetail = ButtonDropdownProps.ItemClickDetails & {
  user: User;
};

type RoleUserProps = {
  loading: boolean;
  users: UsersArray;
  title: string;
  actions: ReadonlyArray<ButtonDropdownProps.ItemOrGroup>;
  onActionClick: CancelableEventHandler<userDecoratedButtonDetail>;
};
export function RoleUserTable(props: RoleUserProps) {
  const [selectedUser, setSelectedUser] = useState<User>();
  const { columns, defaultPreferences, Preferences } = useTableConfig();

  const [columnDefinitions, saveWidths] = useColumnWidths('Cato-Role-Users-TableEditable-Widths', columns);

  const [preferences, setPreferences] = useLocalStorage('Cato-Role-Users-TableEditable-Preferences', defaultPreferences);

  const tableEmptyStateProps: TableEmptyStateProps = {
    resourceName: 'Users',
    resourceType: ResourceTypes.Roles,
    urlPath: 'Users',
  };

  const { items, actions, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCatoCollection(props.users, {
    tableEmptyStateProps,
    pageSize: preferences.pageSize,
    filteringProperties,
  });

  const dropDownButtonMenuProps: ButtonDropdownProps = {
    disabled: !selectedUser,
    onItemClick: (e) => {
      if (selectedUser) {
        const customEvent: CustomEvent<userDecoratedButtonDetail> = {
          ...e,
          detail: {
            ...e.detail,
            user: { ...selectedUser },
          },
        };
        actions.setPropertyFiltering({ tokens: [], operation: 'and' });
        setSelectedUser(undefined);
        return props.onActionClick(customEvent);
      }
    },
    loadingText: 'loading',
    items: props.actions,
  };

  const header = (
    <Header
      variant='awsui-h1-sticky'
      actions={
        <SpaceBetween
          direction='horizontal'
          size='xs'
        >
          {!!dropDownButtonMenuProps.items.length && <ButtonDropdown {...{ ...dropDownButtonMenuProps }}>Actions</ButtonDropdown>}
        </SpaceBetween>
      }
    >
      {props.title} Users {props.users && !props.loading ? `(${props.users.length})` : <Spinner />}
    </Header>
  );
  const filter = (
    <PropertyFilter
      {...propertyFilterProps}
      countText={getTextFilterCounterText(filteredItemsCount)}
      expandToViewport={true}
      filteringAriaLabel={'Filter Users'}
      filteringPlaceholder={'Filter Users'}
    />
  );

  const tableProps: TableProps<User> = {
    ...collectionProps,
    stickyHeader: true,
    resizableColumns: true,
    onColumnWidthsChange: saveWidths,
    onSelectionChange: ({ detail }) => {
      setSelectedUser(detail.selectedItems[0]);
    },
    selectedItems: selectedUser ? [selectedUser] : [],
    columnDefinitions,
    items,
    trackBy: 'Username',
    loadingText: 'Loading Users',
    columnDisplay: preferences.contentDisplay,
    wrapLines: preferences.wrapLines,
    stripedRows: preferences.stripedRows,
    stickyColumns: preferences.stickyColumns,
    contentDensity: preferences.contentDensity as 'compact' | 'comfortable',
    selectionType: 'single',
    loading: props.loading || !props.users,
    header,
    filter,
    pagination: <Pagination {...paginationProps} />,
    preferences: <Preferences {...{ preferences, setPreferences }} />,
  };

  return (
    <>
      <Table {...{ ...tableProps }} />
    </>
  );
}
