import { ContentLayout, Header, SpaceBetween } from '@cloudscape-design/components';
import useInstanceSWR from '../../utils/hooks/useInstanceSWR';
import SurveyTable, { SurveyGroupList } from './components/table';

export default function SurveyList() {
  const { data, isLoading, error } = useInstanceSWR<SurveyGroupList>('/surveys', {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 0,
  });

  return (
    <ContentLayout
      header={
        <Header
          variant='h1'
          description='Manage post call survey configuration for VOICE and OMNI'
        >
          Manage Surveys
        </Header>
      }
    >
      <SpaceBetween size='m'>
        <SurveyTable {...{ surveys: data?.data || [], isLoading, error }} />
      </SpaceBetween>
    </ContentLayout>
  );
}
