
export enum AvailableDialPlan {
  Resource = 'RESOURCE',
  Blocked = 'BLOCKED',
  VIP = 'VIP',
  Extension = 'EXTENSION',
}
export type AvailableDialPlans = `${AvailableDialPlan}`;

export interface DialPlanTableProps {
  isLoading: boolean;
  dialPlan: DialPlanList;
  error: boolean;
}

export interface DialPlanListItem {
  feature: string;
  description: string;
  type: AvailableDialPlans;
  tag: string;
}
export interface DialPlanItem extends Omit<DialPlanListItem, 'type'> {
  data: DialPlanData;
}

export interface DialPlanData {
  type: AvailableDialPlans;
  prefix?: string;
  queue?: string;
}

export type DialPlanList = DialPlanListItem[];

export type DialPlanUpdateBody = Omit<DialPlanItem, 'feature'>
