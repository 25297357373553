import { useEffect } from "react";

/**
 * Helper hook to aid readability of code
 * @param initialise 
 * @returns 
 */
export function useInitialise(initialise: React.EffectCallback) {

    return useEffect(initialise, [])
}
