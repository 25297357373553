import React from 'react';
import { ColumnLayout, Container, SpaceBetween, SplitPanel, Spinner } from '@cloudscape-design/components';
import { ValueWithLabel } from '../../../components/valueWithLabel';
import { DialPlanItem, DialPlanListItem } from '../types';
import useInstanceSWR from '../../../utils/hooks/useInstanceSWR';

interface AuditSplitPanelDetailsProps {
  selectedItems: DialPlanListItem;
}

const DialPlanSplitPanelDetails: React.FC<AuditSplitPanelDetailsProps> = ({ selectedItems }) => {
  const { data, isLoading, error } = useInstanceSWR<DialPlanItem>(`/dial-plans/${selectedItems.feature}`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 0,
  });

  if (isLoading || !data || error) {
    return (
      <SplitPanel
        header={`Dial Plan:`}
        closeBehavior='collapse'
      >
        {error ? <>{error.info.message}</> : <Spinner />}
      </SplitPanel>
    );
  }

  return (
    <SplitPanel
      header={`Dial Plan: ${selectedItems?.feature}`}
      closeBehavior='collapse'
    >
      <Container>
        <ColumnLayout
          columns={2}
          variant='text-grid'
        >
          <SpaceBetween size='l'>
            <ValueWithLabel label='Dial Plan Type'>{data.data?.data?.type}</ValueWithLabel>
            <ValueWithLabel label='Resource Name'>{data.data?.feature}</ValueWithLabel>
            <ValueWithLabel label='Description'>{selectedItems.description}</ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size='l'>
            {data.data?.data?.prefix && <ValueWithLabel label='Prefix'>{data.data?.data.prefix}</ValueWithLabel>}
            {data.data?.data?.queue && <ValueWithLabel label='Queue'>{data.data?.data.queue}</ValueWithLabel>}
          </SpaceBetween>
        </ColumnLayout>
      </Container>
    </SplitPanel>
  );
};

export default DialPlanSplitPanelDetails;
