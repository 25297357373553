import React, { createContext, useMemo, useState } from 'react';
import { WidgetPreferences } from './preferences';

export interface InstanceQueueMetricWidgetContextType {
  openPreferences: () => void;
  widgetId: any;
}

export const InstanceQueueMetricWidgetContext = createContext<InstanceQueueMetricWidgetContextType>({
  openPreferences: () => null,
  widgetId: {},
});

export const InstanceQueueMetricProvider = ({ children, widgetId }: { children: React.ReactElement; widgetId: string }) => {
  const [preferencesVisible, setPreferencesVisible] = useState(false);

  const value = useMemo(
    () => ({
      widgetId,
      openPreferences: () => setPreferencesVisible(true),
    }),
    [],
  );

  return (
    <InstanceQueueMetricWidgetContext.Provider value={value}>
      {React.cloneElement(React.Children.only(children), {
        removeConfirmationText: 'Instance Queue Metrics',
        actions: [{ text: 'Preferences', onClick: () => setPreferencesVisible(true) }],
      })}
      {preferencesVisible && (
        <WidgetPreferences
          widgetId={widgetId}
          onDismiss={() => setPreferencesVisible(false)}
        />
      )}
    </InstanceQueueMetricWidgetContext.Provider>
  );
};
