import { useState } from 'react';
import { Pagination, Table } from '@cloudscape-design/components';
import PropertyFilter from '@cloudscape-design/components/property-filter';
import Alert from '@cloudscape-design/components/alert';
import { getTextFilterCounterText } from '../../../../../../../utils/text-filter';
import { FILTERING_PROPERTIES as filteringProperties } from './filtering_properties';

import { DEFAULT_PREFERENCES, EDITABLE_COLUMN_DEFINITIONS, Preferences } from './table.config';

import Button from '@cloudscape-design/components/button';
import { WidgetDataRepresentation, WidgetTablePreferences, WidgetTableProps } from '../interfaces';
import { useItemContext } from '../../../../dashboardContext';
import { ProtectedUtilityUpdate } from '../../../../../../../components/protectedUtility';
import { ResourceTypes } from '../../../../../../../types/rolePermissions';
import { useCatoCollection } from '../../../../../../../utils/hooks/useCatoCollection';

export default function WidgetTable({ widgetId, isLoading, data = [], error }: WidgetTableProps) {
  const { getWidget, setWidgetDetails } = useItemContext();

  const widget: WidgetDataRepresentation<WidgetTablePreferences> = getWidget<WidgetTablePreferences>(widgetId);
  const columnDefinitions = EDITABLE_COLUMN_DEFINITIONS.map((d) => ({ ...d, width: widget?.preferences?.widths?.[d.id] ?? d.width }));
  const preferences = widget?.preferences?.tablePreferences ?? DEFAULT_PREFERENCES;

  const saveWidths = (event: any) => {
    const { widths } = event.detail;

    const widgetPreferences = {
      ...widget.preferences,
      widths: columnDefinitions.reduce((response: Record<string, number>, definition, index) => ({ ...response, [definition.id]: widths[index] }), {}),
    };

    setWidgetDetails<WidgetTablePreferences>(widgetId, { preferences: widgetPreferences });
  };

  const setPreferences = (preferences: any) => {
    const updatedPreferences = { ...widget.preferences, tablePreferences: preferences };

    setWidgetDetails(widgetId, { preferences: updatedPreferences });
  };
  const [showFilter, setShowFilter] = useState(false);
  // Hooks
  const { items, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCatoCollection(data, {
    filteringProperties,
    pageSize: preferences.pageSize,
  });

  // Function to toggle the visibility of the search/filter
  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  return (
    <>
      <Button
        iconName='search'
        variant='icon'
        onClick={toggleFilter}
      />

      <Table
        {...collectionProps}
        sortingDisabled={true}
        stickyHeader={true}
        resizableColumns={true}
        onColumnWidthsChange={saveWidths}
        columnDefinitions={columnDefinitions}
        items={items}
        trackBy='feature'
        loadingText='Loading data'
        columnDisplay={preferences.contentDisplay}
        wrapLines={preferences.wrapLines}
        stripedRows={preferences.stripedRows}
        stickyColumns={preferences.stickyColumns}
        contentDensity={preferences.contentDensity as 'comfortable' | 'compact'}
        variant='embedded'
        loading={isLoading}
        filter={
          showFilter && (
            <PropertyFilter
              {...propertyFilterProps}
              countText={getTextFilterCounterText(filteredItemsCount)}
              expandToViewport={true}
              filteringAriaLabel={'Filter table'}
              filteringPlaceholder={'Filter table'}
            />
          )
        }
        pagination={<Pagination {...paginationProps} />}
        preferences={
          <ProtectedUtilityUpdate resourceType={ResourceTypes.Dashboards}>
            <Preferences
              preferences={preferences}
              setPreferences={setPreferences}
            />
          </ProtectedUtilityUpdate>
        }
      />
      {error && (
        <Alert
          statusIconAriaLabel='Error'
          type='error'
          header='Error getting data'
        >
          Refresh the page to try again.
        </Alert>
      )}
    </>
  );
}
