import { Container, Header, FormField, Input, TokenGroup, Button, Icon } from '@cloudscape-design/components';
import { useTagEditor } from './useTagEditor';

export interface TagEditorProps {
  tags: string[];
  onChange: (tags: string[]) => void;
}

export const TagEditor = (props: TagEditorProps) => {
  const { value, setValue, onAddTag, onRemoveTag } = useTagEditor(props);
  const tags = props.tags.map((tag) => ({ label: tag, dismissLabel: `Remove ${tag}` }));

  return (
    <Container header={<Header variant='h2'>Tags</Header>}>
      <FormField
        secondaryControl={
          <Button onClick={() => onAddTag(value)}>
            <Icon name='add-plus' />
            Add Tag
          </Button>
        }
      >
        <Input
          value={value}
          onChange={({ detail }) => setValue(detail.value)}
          placeholder='Enter Role tag'
        />
      </FormField>
      <TokenGroup
        items={tags}
        onDismiss={onRemoveTag}
      />
    </Container>
  );
};
