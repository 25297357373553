import React, { useEffect, useState } from 'react';
import { ColumnLayout, Container, Header, ContentLayout, SpaceBetween, Form, Button, FormField, Spinner, Select, Input } from '@cloudscape-design/components';
import { ValueWithLabel } from '../valueWithLabel';
import { useAppSession } from '../../utils/hooks/sessionContext';
import { parse } from '@aws-sdk/util-arn-parser';

export interface ConfigStructure<T = any> {
  feature: string;
  data: T;
  description: string;
  tag: string;
}

interface CopyFormLayoutProps {
  readonly item: ConfigStructure;
  readonly copyingToData?: ConfigStructure;
  readonly isLoading: boolean;
  readonly hasMigration: boolean;
  readonly validation: (feature: string, isMigrating: boolean) => string | void;
  readonly fetchSelectedInstanceData?: (value: string) => Promise<void>;
  readonly handleCancel: () => void;
  readonly handleFormSubmit: (item: ConfigStructure, selectedToInstance: string) => Promise<void>;
}

export default function CopyFormLayout({
  item,
  copyingToData,
  isLoading,
  hasMigration,
  validation,
  fetchSelectedInstanceData,
  handleCancel,
  handleFormSubmit,
}: CopyFormLayoutProps) {
  const appSession = useAppSession();
  const currentInstance = appSession?.instanceSelected;
  const [selectedToInstance, setSelectedToInstance] = useState<string>(currentInstance?.instanceArn ?? '');
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [data, setData] = useState<ConfigStructure>(item);
  const isMigrating = currentInstance?.instanceArn !== selectedToInstance;
  const [resourceErrorMessage, setResourceErrorMessage] = useState<string>('');

  const canSubmit = !isMigrating ? data.feature !== item.feature && !resourceErrorMessage : selectedToInstance;

  const instanceSelectors =
    appSession?.instances.map((instance) => {
      const parsedArn = parse(instance.instanceArn);
      const instanceId = `${parsedArn.resource.split('/').pop()}`;
      return {
        label: `${instance.instanceName}: ${parsedArn.region}-${instanceId}`,
        value: instance.instanceArn,
        description: instanceId,
        tags: [parsedArn.region],
        labelTag: instance.instanceArn === currentInstance?.instanceArn ? 'Current Instance' : '',
      };
    }) ?? [];

  const handleInstanceChange = (value: string) => {
    setSelectedToInstance(value);
  };

  useEffect(() => {
    setData(item);
  }, [item]);

  useEffect(() => {
    if (fetchSelectedInstanceData) {
      selectedToInstance && fetchSelectedInstanceData(selectedToInstance);
    }
  }, [selectedToInstance]);

  const updateFeatureValue = (feature: string) => {
    setResourceErrorMessage(validation(feature, isMigrating) as string);
    setData({ ...data, feature });
  };

  const submitForm = async () => {
    setFormSubmitted(true);
    await handleFormSubmit(data, selectedToInstance);
    setFormSubmitted(false);
  };

  return (
    <ContentLayout header={<Header variant='h1'></Header>}>
      <Form>
        <SpaceBetween
          direction='vertical'
          size='xl'
        >
          <Container header={<Header variant='h2'>{isLoading ? <Spinner /> : item?.feature}</Header>}>
            <SpaceBetween
              direction='vertical'
              size='s'
            >
              <ColumnLayout
                columns={2}
                variant='text-grid'
              >
                <div>
                  <strong>INSTANCE ARN</strong>
                  <p>{currentInstance?.instanceArn}</p>
                </div>
                <div>
                  <strong>INSTANCE NAME</strong>
                  <p>{currentInstance?.instanceAlias}</p>
                </div>
              </ColumnLayout>
              {hasMigration && (
                <FormField
                  label='To instance'
                  controlId='toInstance'
                >
                  <Select
                    options={instanceSelectors}
                    onChange={(event) => {
                      const selectedOption = event.detail.selectedOption;
                      handleInstanceChange(selectedOption?.value ?? '');
                    }}
                    placeholder='Select Instance'
                    selectedOption={
                      selectedToInstance
                        ? {
                            label: instanceSelectors?.find((option) => option.value === selectedToInstance)?.label ?? '',
                            value: selectedToInstance,
                          }
                        : null
                    }
                  />
                </FormField>
              )}
              {!isMigrating && (
                <FormField
                  constraintText={'Only alphanumeric, - _ and + supported'}
                  label={'Provide a feature name'}
                  errorText={resourceErrorMessage}
                >
                  <Input
                    onChange={({ detail }) => updateFeatureValue(detail.value)}
                    value={data.feature}
                  />
                </FormField>
              )}
            </SpaceBetween>
          </Container>
          <ColumnLayout
            columns={!isMigrating || !copyingToData ? 1 : 2}
            variant='text-grid'
          >
            <Container header={<Header variant='h3'>{!isMigrating ? 'Config to be duplicated' : 'Config to be copied'}</Header>}>
              {data ? (
                <SpaceBetween size='l'>
                  <ValueWithLabel label='Feature'>{data.feature}</ValueWithLabel>
                  <ValueWithLabel label='Description'>{data.description}</ValueWithLabel>
                  {data?.data?.type && <ValueWithLabel label='Type'>{data.data.type}</ValueWithLabel>}
                </SpaceBetween>
              ) : (
                <div>No Config available</div>
              )}
            </Container>
            {isMigrating && copyingToData && (
              <Container header={<Header variant='h3'>Config to be Replaced:</Header>}>
                <SpaceBetween size='l'>
                  <ValueWithLabel label='Feature'>{String(copyingToData.feature)}</ValueWithLabel>
                  <ValueWithLabel label='Description'>{String(copyingToData.description)}</ValueWithLabel>
                  {copyingToData?.data?.type && <ValueWithLabel label='Type'>{copyingToData.data.type}</ValueWithLabel>}
                </SpaceBetween>
              </Container>
            )}
          </ColumnLayout>
          <SpaceBetween
            direction='horizontal'
            size='xl'
          >
            <Button
              formAction='none'
              variant='link'
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
            <Button
              variant='primary'
              loading={formSubmitted}
              onClick={submitForm}
              disabled={!canSubmit}
            >
              Copy
            </Button>
          </SpaceBetween>
        </SpaceBetween>
      </Form>
    </ContentLayout>
  );
}
