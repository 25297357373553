import * as React from 'react';
import Modal from '@cloudscape-design/components/modal';
import Box from '@cloudscape-design/components/box';
import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@cloudscape-design/components/button';

export interface ConfirmDeleteModalProps {
  onConfirm: () => void;
  onCancel: () => void;
  deleteMessage: React.ReactNode;
}

export const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = ({
  onConfirm,
  onCancel,
  deleteMessage,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const handleConfirm = async () => {
    setIsLoading(true);
    await onConfirm(); // Assumes onConfirm is async, or returns a promise.
    setIsLoading(false);
  };

  return (
    <Modal
      visible={true}
      onDismiss={onCancel}
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button variant='link' onClick={onCancel}>
              Cancel
            </Button>
            <Button
              variant='primary'
              onClick={handleConfirm}
              loading={isLoading}
            >
              Confirm
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={'This action cannot be undone'}
    >
      {deleteMessage}
    </Modal>
  );
};
