import { Spinner } from '@cloudscape-design/components';
import useIntuitionSWR from '../../../../../../../utils/hooks/useIntuitionSWR';
import { EmptyState } from '../../empty-state';
import WidgetTable from './table';
import { useItemContext } from '../../../../dashboardContext';
import { queueArnsToQueueIds } from '../utils/widget.helpers';

export default function ContactTraceRecord(props: { widgetId: string }) {
  
  const { getWidget } = useItemContext();
  const widget = getWidget(props.widgetId);
  const queues: string[] | null = widget.filters?.queues ?? null;

  const params = new URLSearchParams();
       params.append('templateType', 'getAgentTraceRecord');
       params.append('queues', queueArnsToQueueIds(queues));
  const endpoint = `/queries?${params.toString()}`;
 
  const { data, isLoading, error } = useIntuitionSWR<any>(endpoint, {
    shouldRetryOnError: true,
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    refreshInterval: 5000,
  });

  if (error) {
    return (
      <EmptyState
        title='Error retrieving data'
        description='This could be due to no data being received for this connect instance'
        verticalCenter={true}
      />
    );
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <WidgetTable
      widgetId={props.widgetId}
      isLoading={isLoading}
      data={data?.data}
      error={error}
    />
  );
}
