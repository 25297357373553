import React, { useCallback, useEffect, useState } from 'react';
import { ContentLayout, Container, Header, SpaceBetween, Form, Input, Button, FormField, Textarea, Select, FileUpload } from '@cloudscape-design/components';
import { useNavigate } from 'react-router-dom';
import useChangeDetector from '../../../utils/useChangeDetector';
import { useApiWithFlash } from '../../../utils/hooks/useApiWithFlash';
import fetcher from '../../../utils/fetcher';
import { ConfirmCancelModal } from '../../../components/confirmCancelModal';
import { useBlockerLogic } from '../../../utils/hooks/useBlocker';

interface Instance {
  instanceName: string;
  instanceArn: string;
  instanceAlias: string;
}

interface SelectedInstance {
  label: string;
  value: string;
}

export default function TicketsCreate() {
  const [selectedInstance, setSelectedInstance] = useState<string | null>(null);
  const [instanceOptions, setInstanceOptions] = useState<Instance[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<readonly File[] | undefined>(undefined);

  const initialFormState = {
    subject: '',
    description: '',
    instanceArn: '',
  };
  const { item, setItem, changesDetected } = useChangeDetector(initialFormState);

  const [touchedFields, setTouchedFields] = useState<Set<string>>(new Set());

  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);

  const navigate = useNavigate();
  const { handleApiWithFlash } = useApiWithFlash();

  const { blocker, handleCancel, handleCloseCancelModal, handleConfirmCancel } = useBlockerLogic({
    changesDetected,
    path: 'tickets',
    formSubmitted,
  });

  const handleInputChange = useCallback(
    (field: string, value: string) => {
      setItem((prev: any) => ({ ...prev, [field]: value }));
      setTouchedFields((prev) => new Set(prev).add(field));
    },
    [setItem],
  );

  useEffect(() => {
    const fetchInstances = async () => {
      try {
        const response = await fetcher('/instances');
        setInstanceOptions(response.data || []);
      } catch (error) {
        console.error('Error fetching instances:', error);
      }
    };

    fetchInstances();
  }, []);

  async function handleSubmitForm() {
    setFormSubmitted(true);

    const payload = {
      subject: item.subject,
      description: item.description || '',
      instanceArn: selectedInstance || '',
      files: selectedFiles,
    };

    const response = await handleApiWithFlash(`/ticket`, 'POST', {
      data: payload,
      successMessage: `Successfully created a new Ticket "${item.subject}"`,
      errorMessage: `Error creating a Ticket "${item.subject}"`,
    });

    if (response?.success && response.data) {
      const { imageKey, uploadUrl } = response.data.data;

      if (payload.files && imageKey && uploadUrl) {
        payload.files.forEach(async (file) => {
          await fetcher(uploadUrl, 'PUT', {
            file,
            contentType: file.type,
          });
        });
      }
    }

    if (response?.success) {
      navigate(`/tickets`);
    }
  }

  return (
    <ContentLayout header={<Header variant='h1'>Create a new Ticket</Header>}>
      <Container
        header={
          <Header
            variant='h2'
            description='Use this form to create a new Ticket. On clicking submit, a new Ticket will be created.'
          >
            Create a new Ticket
          </Header>
        }
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmitForm();
          }}
        >
          <Form
            variant='embedded'
            actions={
              <SpaceBetween
                direction='horizontal'
                size='xs'
              >
                <Button
                  formAction='none'
                  variant='link'
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  variant='primary'
                  loading={formSubmitted}
                  disabled={!item.subject || !item.description}
                >
                  Submit
                </Button>
              </SpaceBetween>
            }
          >
            <SpaceBetween
              direction='vertical'
              size='xl'
            >
              <FormField
                label='Subject*'
                errorText={touchedFields.has('subject') && !item.subject ? 'Subject is required' : ''}
              >
                <Input
                  onChange={({ detail }) => handleInputChange('subject', detail.value)}
                  value={item?.subject}
                  placeholder='Enter the Subject'
                />
              </FormField>
              <FormField
                label='Instance (Optional)'
                errorText={touchedFields.has('instance') && !selectedInstance ? 'Instance is required' : ''}
              >
                <Select
                  options={instanceOptions.map((instance: Instance) => ({
                    label: `${instance.instanceAlias || instance.instanceName} - ${instance.instanceArn}`,
                    value: instance.instanceAlias || instance.instanceName + ': ' + instance.instanceArn,
                  }))}
                  onChange={(event) => {
                    const selectedOption = event.detail.selectedOption as SelectedInstance;
                    setSelectedInstance(selectedOption?.value || '');
                  }}
                  placeholder='Select Instance'
                  selectedOption={
                    selectedInstance
                      ? {
                          label: instanceOptions.find((instance) => instance.instanceArn === selectedInstance)?.instanceAlias ?? '',
                          value: selectedInstance,
                        }
                      : null
                  }
                />
              </FormField>

              <FormField
                label='Description*'
                errorText={touchedFields.has('description') && !item.description ? 'Description is required' : ''}
              >
                <Textarea
                  onChange={({ detail }) => handleInputChange('description', detail.value)}
                  value={item?.description}
                  placeholder='Enter the description'
                />
              </FormField>
              <FormField
                label='Upload File (Optional)'
                description='Attach a file or image'
              >
                <FileUpload
                  onChange={({ detail }) => {
                    setSelectedFiles(detail.value);
                  }}
                  value={selectedFiles ?? []}
                  i18nStrings={{
                    uploadButtonText: (e) => (e ? 'Choose files' : 'Choose file'),
                    dropzoneText: (e) => (e ? 'Drop files to upload' : 'Drop file to upload'),
                    removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
                    limitShowFewer: 'Show fewer files',
                    limitShowMore: 'Show more files',
                    errorIconAriaLabel: 'Error',
                  }}
                  showFileLastModified
                  showFileSize
                  showFileThumbnail
                  tokenLimit={3}
                  constraintText='jpeg, png or pdf'
                />
              </FormField>
            </SpaceBetween>
          </Form>
        </form>
        {blocker.state === 'blocked' && (
          <ConfirmCancelModal
            {...{
              onCancel: handleCloseCancelModal,
              onConfirm: handleConfirmCancel,
              cancelMessage: 'Are you sure you want to close? Any unsaved changes will be lost.',
            }}
          />
        )}
      </Container>
    </ContentLayout>
  );
}
