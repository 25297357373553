import { useState } from 'react';
import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@cloudscape-design/components/button';
import Header from '@cloudscape-design/components/header';
import { Pagination, Spinner, Table } from '@cloudscape-design/components';
import PropertyFilter from '@cloudscape-design/components/property-filter';
import Alert from '@cloudscape-design/components/alert';
import { getTextFilterCounterText } from '../../../utils/text-filter';
import { useLocalStorage } from '../../../components/use-local-storage';
import { useColumnWidths } from '../../../components/table/use-column-width';
import { FILTERING_PROPERTIES as filteringProperties } from './filtering_poperties';
import { mutate } from 'swr';
import { TicketTableProps, TicketsData } from '../types';
import { useSplitPanelControl } from '../../../utils/hooks/splitPanelContext';
import { useNavigate } from 'react-router-dom';

import { COLUMN_DEFINITIONS, DEFAULT_PREFERENCES, EDITABLE_COLUMN_DEFINITIONS, Preferences } from './table.config';

import { TableEmptyStateProps } from '../../../components/table/commons';
import ViewTicketModal from '../viewTicketModel';
import { ResourceTypes } from '../../../types/rolePermissions';
import { useCatoCollection } from '../../../utils/hooks/useCatoCollection';

export default function TicketsTable({ isLoading, ticket = [], error }: Readonly<TicketTableProps>) {
  // State
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState<TicketsData[]>([]);
  const [columnDefinitions, saveWidths] = useColumnWidths('Cato-Ticket-TableEditable-Widths', EDITABLE_COLUMN_DEFINITIONS);
  const [preferences, setPreferences] = useLocalStorage('Cato-Ticket-TableEditable-Preferences', DEFAULT_PREFERENCES);

  // Hooks
  const { setSplitPanelOpen, setSplitPanelContent } = useSplitPanelControl();
  const navigate = useNavigate();

  const tableEmptyStateProps: TableEmptyStateProps = {
    resourceName: 'Tickets',
    resourceType: ResourceTypes.Admin,
    urlPath: 'tickets',
  };

  const { items, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCatoCollection(ticket, {
    tableEmptyStateProps,
    pageSize: preferences.pageSize,
    filteringProperties,
  });

  const onRefresh = async () => {
    setRefreshLoading(true);
    const endpoint = '/ticket';
    await mutate(endpoint);
    setRefreshLoading(false);
  };

  const refreshButtonProps = { onClick: onRefresh };

  const onClose = () => {
    setSplitPanelOpen(false);
    setSplitPanelContent(null);
    setSelectedItems([]);
  };

  function onSelectionChange(data: { detail: any }) {
    setSplitPanelContent(
      <ViewTicketModal
        selectedItems={data}
        onClose={onClose}
      />,
    );
    setSplitPanelOpen(true);
    setSelectedItems(data.detail.selectedItems);
  }

  return (
    <>
      <Table
        {...collectionProps}
        stickyHeader={true}
        resizableColumns={true}
        onColumnWidthsChange={saveWidths}
        onSelectionChange={({ detail: { selectedItems } }) => {
          onSelectionChange({ detail: selectedItems });
          if (selectedItems && selectedItems.length > 0) {
            const selectedItem: TicketsData = selectedItems[0];
            setSelectedItems(() => [selectedItem]);
          }
        }}
        selectedItems={selectedItems as any}
        columnDefinitions={COLUMN_DEFINITIONS.map((column) => ({
          ...columnDefinitions,
          ...column,
          cell: typeof column.cell === 'function' ? (item: TicketsData) => column.cell(item, onSelectionChange) : column.cell,
        }))}
        items={items}
        // submitEdit={handleSubmit}
        trackBy='ticketId'
        loadingText='Loading tickets'
        columnDisplay={preferences.contentDisplay}
        wrapLines={preferences.wrapLines}
        stripedRows={preferences.stripedRows}
        stickyColumns={preferences.stickyColumns}
        contentDensity={preferences.contentDensity as 'compact' | 'comfortable'}
        selectionType={'single'}
        loading={isLoading || !ticket || refreshLoading}
        header={
          <Header
            variant='awsui-h1-sticky'
            actions={
              <SpaceBetween
                direction='horizontal'
                size='xs'
              >
                <Button
                  iconName='refresh'
                  ariaLabel='Refresh'
                  {...refreshButtonProps}
                />
                <Button
                  variant='primary'
                  onClick={() => navigate('/tickets/create')}
                >
                  Create a new ticket
                </Button>
              </SpaceBetween>
            }
          >
            Tickets {ticket && !isLoading ? `(${ticket.length})` : <Spinner />}
          </Header>
        }
        filter={
          <PropertyFilter
            {...propertyFilterProps}
            countText={getTextFilterCounterText(filteredItemsCount)}
            expandToViewport={true}
            filteringAriaLabel={'Filter tickets'}
            filteringPlaceholder={'Filter tickets'}
          />
        }
        pagination={<Pagination {...paginationProps} />}
        preferences={
          <Preferences
            preferences={preferences}
            setPreferences={setPreferences}
          />
        }
      />
      {error && (
        <Alert
          statusIconAriaLabel='Error'
          type='error'
          header='Error getting data'
        >
          Refresh the page to try again.
        </Alert>
      )}
    </>
  );
}
