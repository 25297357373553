/*
    This file sets the side menu navigation items. It pulls in the items from the navigationMapping.tx file.
    We then filter the menu options based on the tenant feature access. We always show Administration.
 */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SideNavigation, { SideNavigationProps } from '@cloudscape-design/components/side-navigation';
import { ProtectedSectionGroup, items } from './navigationMapping';
import { useAppSession } from '../../utils/hooks/sessionContext';
import { useProtectRoute } from '../protectedRoute';

export default function Navigation() {
  // Hooks
  const navigate = useNavigate();
  const location = useLocation();
  const tenantData = useAppSession();
  const protectRoute = useProtectRoute();

  // State
  const [activeHref, setActiveHref] = useState(location.pathname);
  const [filteredItems, setFilteredItems] = useState<SideNavigationProps.Item[] | undefined>(undefined);
  const memberType = tenantData?.memberType;
  const instanceSelected = tenantData?.instanceSelected;
  const instancePermissions = tenantData?.instancePermissions;

  // Here we check the tenant feature access and only show the menu items that they have signed up for.
  useEffect(() => {

    if (memberType) {
      const filteredMenuItems = items.reduce(
        (response: SideNavigationProps.Item[], item: ProtectedSectionGroup) => {
          const isAdminMenu = item.title === 'Administration';
          const isInMembership = !!instanceSelected && memberType.toLowerCase().includes(item.title.toLowerCase());
          const isAvailable = isInMembership && (!item.instanceRequiredField || instanceSelected[item.instanceRequiredField]);

          const authorisedMenuItems = item.items.filter(({resourceType}) =>  protectRoute({ resourceType }));

          if (authorisedMenuItems.length) {
            (isAdminMenu || isAvailable) && response.push({...item, items: authorisedMenuItems });
            isAvailable && response.push({ type: 'divider' }); // admin is always last so does not need a divider
          }
          return response;
        },
        [],
      );

      setFilteredItems(filteredMenuItems);
    }
  }, [memberType, instanceSelected, instancePermissions]);

  const handleClick = (event: any) => {
    event.preventDefault();
    navigate(event.detail.href);
  };

  useEffect(() => {
    setActiveHref(location.pathname);
  }, [location.pathname]);

  return (
    <>
      <SideNavigation
        activeHref={activeHref}
        onFollow={(event) => handleClick(event)}
        header={{ href: '/', text: 'Home' }}
        items={filteredItems}
      />
    </>
  );
}
