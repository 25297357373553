import * as React from 'react';
import Multiselect from '@cloudscape-design/components/multiselect';
import useIntuitionSWR from '../../utils/hooks/useIntuitionSWR';
import { useItemContext } from '../../routes/visualizations/view/dashboardContext';

type Queue = {
  Id: string;
  Arn: string;
  Name: string;
  QueueType: string;
};

interface QueuePickerResponse {
  data: Queue[];
}

type OptionDefinition = {
  label: string;
  value: string;
  description: string;
};

interface QueuePickerProps {
  widgetId: string;
}

const QueuePicker: React.FC<QueuePickerProps> = ({ widgetId }) => {
  const { item, getWidget, setItem } = useItemContext();
  const [selectedOptions, setSelectedOptions] = React.useState<OptionDefinition[]>([]);

  const { data, error } = useIntuitionSWR<QueuePickerResponse>(`/instance-config/queues`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 0,
  });

  const isLoading = !data && !error;

  const transformQueueData = (queues: Queue[]): OptionDefinition[] => {
    return [
      {
        label: 'All',
        value: '*',
        description: 'All Queues',
      },
      ...queues.map((queue) => ({
        label: queue.Name,
        value: queue.Arn,
        description: queue.QueueType,
      })),
    ];
  };
  const options = React.useMemo(() => {
    return isLoading || !data ? [] : transformQueueData(data.data);
  }, [isLoading, data]);

  React.useEffect(() => {
    const getInitialSelectedOptions = (): OptionDefinition[] => {
      const currentWidget = getWidget(widgetId);
      if (currentWidget && currentWidget.filters && currentWidget.filters.queues) {
        if (currentWidget.filters.queues.includes('*')) {
          return [{ label: 'All', value: '*', description: 'All Queues' }];
        } else {
          return currentWidget.filters.queues.map((arn: string) => {
            const queue = options.find((opt) => opt.value === arn);
            return queue || { label: arn, value: arn, description: '' };
          });
        }
      }
      return [];
    };

    setSelectedOptions(getInitialSelectedOptions);
  }, [item, options, widgetId]);
  const updateWidgetItem = (selectedArns: string[]) => {
    setItem(
      (prevItem) => {
        const updatedWidgets = prevItem.data.widgets.map((widget) => {
          if (widget.id === widgetId) {
            return {
              ...widget,
              filters: {
                ...widget.filters,
                queues: selectedArns,
              },
            };
          }
          return widget;
        });

        return {
          ...prevItem,
          data: {
            ...prevItem.data,
            widgets: updatedWidgets,
          },
        };
      },
    );
  };

  const handleSelectionChange = (selectedOpts: OptionDefinition[]) => {
    if (selectedOpts.some((opt) => opt.value === '*')) {
      setSelectedOptions([{ label: 'All', value: '*', description: 'All Queues' }]);
      updateWidgetItem(['*']);
    } else {
      setSelectedOptions(selectedOpts);
      updateWidgetItem(selectedOpts.map((opt) => opt.value));
    }
  };

  if (error) {
    return <div>Error loading data</div>;
  }

  return (
    <div>
      <Multiselect
        filteringType='auto'
        selectedOptions={selectedOptions}
        onChange={({ detail }) => handleSelectionChange(detail.selectedOptions as OptionDefinition[])}
        options={options}
        placeholder='Select queues'
        tokenLimit={2}
      />
    </div>
  );
};

export default QueuePicker;
