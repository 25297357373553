import { useState, useEffect } from 'react';
import {
  ColumnLayout,
  Container,
  Header,
  ContentLayout,
  SpaceBetween,
  Form,
  Input,
  Button,
  FormField,
  Spinner,
  Alert,
  Autosuggest,
  AutosuggestProps,
} from '@cloudscape-design/components';
import { useNavigate, useParams } from 'react-router-dom';
import useChangeDetector from '../../../utils/useChangeDetector';
import { useApiWithFlash } from '../../../utils/hooks/useApiWithFlash';
import fetcher from '../../../utils/fetcher';
import useSWR from 'swr';
import tzList from '../../../objects/tz';
import { ApiResponse } from '../../../types/api';
import { InstanceType } from '../../../types/instance';
import { useBlockerLogic } from '../../../utils/hooks/useBlocker';
import { ConfirmCancelModal } from '../../../components/confirmCancelModal';

export default function InstancesUpdate() {
  const navigate = useNavigate();
  const { instanceId } = useParams();
  const { handleApiWithFlash } = useApiWithFlash();
  const [timezone, setTimezone] = useState<string>('');

  const { item, setItem, setItemShadow, changesDetected } = useChangeDetector<InstanceType | null>(null);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);

  const { data, isLoading, error } = useSWR<ApiResponse<InstanceType>>(`/instances/${instanceId}`, fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 0,
  });

  const { blocker, handleCancel, handleCloseCancelModal, handleConfirmCancel } = useBlockerLogic({
    changesDetected,
    path: 'instances',
    formSubmitted,
  });

  useEffect(() => {
    if (data?.data) {
      setItem(data.data);
      setItemShadow(data.data);
      setTimezone(data.data.timezone || '');
    }
  }, [data]);

  if (isLoading) {
    return (
      <ContentLayout header={<Header variant='h1'></Header>}>
        <Spinner
          size='large'
          variant='inverted'
        />
      </ContentLayout>
    );
  }

  if (error || !item) {
    return (
      <Alert
        statusIconAriaLabel='Error'
        type='error'
        header='Unable to load component'
      >
        {error?.status || 'An error occurred'}
      </Alert>
    );
  }

  const timezoneSugestProps: AutosuggestProps = {
    onChange: ({ detail }) => {
      setTimezone(detail.value);
      const isTimezoneValue = !!tzList.find((timezoneItem) => timezoneItem.value === detail.value) || detail.value === '';
      isTimezoneValue && setItem((previousItem) => ({ ...(previousItem as InstanceType), timezone: detail.value }));
    },
    value: timezone,
    options: tzList,
    ariaLabel: 'Select a timezone',
    placeholder: item.timezone || 'Enter a timezone',
  };

  const handleNameChange = (value: string) => {
    setItem((prev: InstanceType | null) => ({ ...(prev as InstanceType), instanceName: value }));
  };

  async function handleSubmitForm() {
    setFormSubmitted(true);

    const payload = {
      name: item?.instanceName || '',
      timezone: item?.timezone || '',
    };
    await handleApiWithFlash(`/instances/${instanceId}`, 'PUT', {
      successMessage: `Successfully updated instance ${payload.name}`,
      errorMessage: `Error updating instance ${payload.name}`,
      mutateKey: '/instances',
      data: payload,
    });
    navigate('/instances');
  }

  return (
    <ContentLayout header={<Header variant='h1'></Header>}>
      <Container
        header={
          <Header
            variant='h2'
            description='Use this form to edit an existing Instance. Update the name or timezone and click submit to save changes.'
          >
            {isLoading ? <Spinner /> : `${item.instanceName} : ${item.instanceRegion}`}
          </Header>
        }
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmitForm();
          }}
        >
          <Form
            variant='embedded'
            actions={
              <SpaceBetween
                direction='horizontal'
                size='xs'
              >
                <Button
                  formAction='none'
                  variant='link'
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  variant='primary'
                  disabled={!changesDetected}
                  loading={formSubmitted}
                >
                  Submit
                </Button>
              </SpaceBetween>
            }
          >
            <SpaceBetween
              direction='vertical'
              size='xl'
            >
              <ColumnLayout
                columns={2}
                variant='text-grid'
              >
                <FormField label='ARN'>
                  <Input
                    value={item.instanceArn}
                    readOnly
                  />
                </FormField>

                <FormField label='Name'>
                  <Input
                    value={item.instanceName}
                    placeholder='Name for your Instance'
                    onChange={({ detail }) => handleNameChange(detail.value)}
                  />
                </FormField>
                <FormField label='Timezone'>
                  <Autosuggest {...{ ...timezoneSugestProps }} />
                </FormField>
              </ColumnLayout>
            </SpaceBetween>
          </Form>
        </form>
        {blocker.state === 'blocked' && (
          <ConfirmCancelModal
            {...{
              onCancel: handleCloseCancelModal,
              onConfirm: handleConfirmCancel,
              cancelMessage: 'Are you sure you want to close? Any unsaved changes will be lost.',
            }}
          />
        )}
      </Container>
    </ContentLayout>
  );
}
