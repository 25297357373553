import { useState, useEffect } from 'react';
import _ from 'lodash';

const deepCopy = <T,>(data:T) => JSON.parse(JSON.stringify(data));

function useChangeDetector<T>(initialValue: T) {
  const [item, setItem] = useState<T>(initialValue);
  const [itemShadow, setItemShadow] = useState<T>(deepCopy(initialValue));
  const [changesDetected, setChangesDetected] = useState(false);

  useEffect(() => {
    const changesDetected = !_.isEqual(item, itemShadow);
    setChangesDetected(changesDetected);
  }, [item, itemShadow]);

  return {
    item,
    setItem,
    itemShadow,
    setItemShadow: (item: T) => setItemShadow(deepCopy(item)),
    changesDetected,
  };
}

export default useChangeDetector;
