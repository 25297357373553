import React from 'react';
import Header from '@cloudscape-design/components/header';
import SpaceBetween from '@cloudscape-design/components/space-between';
import { ContentLayout } from '@cloudscape-design/components';
import RolesTable from './components/table';
import useSWR from 'swr';
import fetcher from '../../utils/fetcher';
import { ApiResponse } from '../../types/api';
import { RoleApiResponse } from '../../types/rolePermissions';

export default function Roles() {
  const { data, isLoading, error } = useSWR<ApiResponse<RoleApiResponse>>('/roles', fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 0,
  });

  return (
    <ContentLayout
      header={
        <Header
          variant='h1'
          description='Add/ Edit/ Remove roles within the Cato application'
        >
          Manage Application Roles
        </Header>
      }
    >
      <SpaceBetween size='m'>
        <RolesTable
          isLoading={isLoading}
          error={error}
          roles={data?.data?.Items || []}
        />
      </SpaceBetween>
    </ContentLayout>
  );
}
