import React, { useState } from "react";
import Button from "@cloudscape-design/components/button";
import Form from "@cloudscape-design/components/form";
import Header from "@cloudscape-design/components/header";
import SpaceBetween from "@cloudscape-design/components/space-between";
import DocumentUpload from "./components/documentUpload";
import { ContentLayout } from "@cloudscape-design/components";
import { useNavigate } from "react-router-dom";
import fetcher from "../../../utils/fetcher";
import { v4 as uuidv4 } from "uuid";
import { useFlash } from "../../../utils/hooks/useFlash";

export default function DocumentCreate() {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [value, setValue] = useState<File[]>([]);

  // Hooks
  const navigate = useNavigate();
  const { addFlash, removeFlash } = useFlash();

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    setIsFormSubmitted(true);
    event.preventDefault();

    const payload = {
      fileName: value[0]?.name,
    };

    // Step 1: Get signed URL from API
    // Note we are not use the hook 'handleApiWithFlash' due to the need to get the pre-signed url
    try {
      const response = await fetcher(`/documents`, "POST", payload); // Assuming value[0] is your File object

      const signedUrl = response.data.uploadUrl;
      // Step 2: Upload the file to S3 using signed URL
      if (signedUrl) {
        const uploadResponse = await fetch(signedUrl, {
          method: "PUT",
          headers: {
            "Content-Type": value[0]?.type || "text/plain", // Replace with your actual content type
          },
          body: value[0],
        });

        if (uploadResponse.ok) {
          const messageId = uuidv4();
          addFlash({
            type: "success",
            content:
              "Document has been uploaded. View the status in the table below.",
            dismissible: true,
            dismissLabel: "Dismiss",
            onDismiss: () => removeFlash(messageId),
            id: messageId,
          });
          navigate("/documents");
        } else {
          console.log(uploadResponse);
          const messageId = uuidv4();
          addFlash({
            type: "error",
            content: `There has been an error uploading the document. ${uploadResponse.statusText} `,
            dismissible: true,
            dismissLabel: "Dismiss",
            onDismiss: () => removeFlash(messageId),
            id: messageId,
          });
        }
      }
    } catch (error: any) {
      let errorMessage = "There has been an error uploading the document.";
      if (error.status === 403) {
        errorMessage += ` ${error.message}`; // Append API's error message
      }

      const messageId = uuidv4();
      addFlash({
        type: "error",
        content: errorMessage,
        dismissible: true,
        dismissLabel: "Dismiss",
        onDismiss: () => removeFlash(messageId),
        id: messageId,
      });
    } finally {
      setIsFormSubmitted(false);
    }
  };

  return (
    <ContentLayout
      header={
        <Header
          variant="h1"
          description="Add a new document to the chatbot engine. Supports .pdf or .txt files upto 5MB in size"
        >
          Upload a new document
        </Header>
      }
    >
      <SpaceBetween size="m">
        <form onSubmit={handleSubmit}>
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button onClick={() => navigate("/documents")} variant="link">
                  Cancel
                </Button>
                <Button
                  formAction="submit"
                  variant="primary"
                  loading={isFormSubmitted}
                  disabled={value.length === 0}
                >
                  Upload
                </Button>
              </SpaceBetween>
            }
          >
            <SpaceBetween size="l">
              <DocumentUpload value={value} setValue={setValue} />
            </SpaceBetween>
          </Form>
        </form>
      </SpaceBetween>
    </ContentLayout>
  );
}
