import React, { useId } from 'react';
import Box from '@cloudscape-design/components/box';
import ColumnLayout from '@cloudscape-design/components/column-layout';
import Modal from '@cloudscape-design/components/modal';
import styles from './preferences.module.scss';
import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@cloudscape-design/components/button';
import Toggle from '@cloudscape-design/components/toggle';
import { useItemContext } from '../../../../dashboardContext';
import QueuePickerSingle from '../../../../../../../components/amzn-connect-components/queue-picker-single';
import FormField from '@cloudscape-design/components/form-field';
import ThresholdPreferences, { SingleThresholdPreferences } from '../utils/thresholdPreferences';

const allContent = [
  'AGENTS_AFTER_CONTACT_WORK',
  'AGENTS_AVAILABLE',
  'AGENTS_ERROR',
  'AGENTS_NON_PRODUCTIVE',
  'AGENTS_ON_CALL',
  'AGENTS_ON_CONTACT',
  'AGENTS_ONLINE',
  'AGENTS_STAFFED',
  'CONTACTS_IN_QUEUE',
  'CONTACTS_SCHEDULED',
  'OLDEST_CONTACT_AGE',
  'SLOTS_ACTIVE',
  'SLOTS_AVAILABLE',
];

const defaultPreference: SingleThresholdPreferences = {
  max: 100,
  maxValue: 100,
  minValue: 0,
  ascending: false,
};

type Content = (typeof allContent)[number];

type AllMetrics = (typeof allContent)[number];

export type EnabledMetrics = Array<AllMetrics>;

export interface QueueMetricPreferences {
  enabledMetrics: EnabledMetrics;
  thresholds: {
    [metric in (typeof allContent)[number]]?: SingleThresholdPreferences;
  };
}

export interface WidgetPreferencesProps {
  onDismiss: () => void;
  widgetId: string;
}

export function WidgetPreferences({ onDismiss, widgetId }: Readonly<WidgetPreferencesProps>) {
  const { getWidget, setWidgetDetails } = useItemContext();
  const widget = getWidget<QueueMetricPreferences | EnabledMetrics>(widgetId);

  const initialPreferences: QueueMetricPreferences = Array.isArray(widget.preferences) ? { enabledMetrics: widget.preferences, thresholds: {} } : widget.preferences;
  
  const handlePreferenceChange = (metricName: AllMetrics, newPreferencesUpdated: SingleThresholdPreferences) => {
    const newPreferences = { ...initialPreferences };
    newPreferences.thresholds[metricName] = newPreferencesUpdated;

    setWidgetDetails(widgetId, { preferences: newPreferences });
  };

  function toggle(content: Content, checked: boolean) {
    const newPreferences = { ...initialPreferences };
    if (checked) {
      newPreferences.enabledMetrics.push(content);
    } else {
      newPreferences.enabledMetrics = newPreferences.enabledMetrics.filter((item) => item !== content);
    }
    setWidgetDetails(widgetId, { preferences: newPreferences });
  }

  return (
    <Modal
      visible={true}
      header='Preferences'
      footer={
        <Box float='right'>
          <SpaceBetween
            direction='horizontal'
            size='xs'
          >
            <Button
              variant='link'
              formAction='none'
              onClick={onDismiss}
            >
              Close
            </Button>
          </SpaceBetween>
        </Box>
      }
      onDismiss={onDismiss}
    >
      <ColumnLayout
        columns={1}
        borders='horizontal'
      >
        <FormField
          description='Select a queue from the dropdown below to view its realtime data'
          label='Queue'
        >
          <QueuePickerSingle widgetId={widgetId} />
        </FormField>
        {allContent.map((content) => {
          const label = content
            .toLowerCase()
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');

          return (
            <React.Fragment key={content}>
              <PreferencesControl
                label={label}
                toggle={(id) => (
                  <Toggle
                    controlId={id}
                    checked={initialPreferences.enabledMetrics.includes(content)}
                    onChange={(event) => toggle(content, event.detail.checked)}
                  />
                )}
              />

              {initialPreferences.enabledMetrics.includes(content) && (
                <ThresholdPreferences
                  preferences={initialPreferences.thresholds[content] || defaultPreference}
                  onChange={(preferences) => handlePreferenceChange(content, preferences)}
                />
              )}
            </React.Fragment>
          );
        })}
      </ColumnLayout>
    </Modal>
  );
}

interface PreferencesControlProps {
  label: string;
  isGroup?: boolean;
  toggle?: (id: string) => React.ReactNode;
}

function PreferencesControl({ label, toggle, isGroup }: PreferencesControlProps) {
  const id = useId();
  return (
    <div className={styles.displayPreference}>
      <label
        htmlFor={id}
        className={`${styles.displayPreferenceLabel} ${isGroup ? styles.displayPreferenceGroup : ''}`}
      >
        {label}
      </label>
      {toggle?.(id)}
    </div>
  );
}
