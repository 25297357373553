import React, { forwardRef } from 'react';
import {
  AppLayout,
  AppLayoutProps,
  Box,
  Button,
  Link,
  SpaceBetween,
} from '@cloudscape-design/components';
import { I18nProvider } from '@cloudscape-design/components/i18n';
import enMessages from '@cloudscape-design/components/i18n/messages/all.en.json';
import { useNavigate } from 'react-router-dom';
import { ProtectedUtilityCreate } from '../protectedUtility';
import { ResourceType } from '../../types/rolePermissions';

export const TableNoMatchState = ({
  onClearFilter,
}: {
  onClearFilter: () => void;
}) => (
  <Box margin={{ vertical: 'xs' }} textAlign='center' color='inherit'>
    <SpaceBetween size='xxs'>
      <div>
        <b>No matches</b>
        <Box variant='p' color='inherit'>
          We can't find a match.
        </Box>
      </div>
      <Button onClick={onClearFilter}>Clear filter</Button>
    </SpaceBetween>
  </Box>
);

export interface TableEmptyStateProps {
  resourceName: string;
  resourceType: ResourceType;
  urlPath: string;
}

export const TableEmptyState: React.FC<TableEmptyStateProps> = ({
  resourceName,
  urlPath,
  resourceType,
}) => {
  const navigate = useNavigate();

  return (
    <Box margin={{ vertical: 'xs' }} textAlign='center' color='inherit'>
      <SpaceBetween size='xxs'>
        <div>
          <b>No {resourceName.toLowerCase()}</b>
          <Box variant='p' color='inherit'>
            No {resourceName.toLowerCase()} associated with this tenant.
          </Box>
        </div>
        <ProtectedUtilityCreate resourceType={resourceType}><Button
          onClick={() => {
            // Here we're using the navigate function to redirect to the desired path
            navigate(`/${urlPath}/create`);
          }}
        >
          Create {resourceName.toLowerCase()}
        </Button></ProtectedUtilityCreate>
      </SpaceBetween>
    </Box>
  );
};

export const CustomAppLayout = forwardRef<AppLayoutProps.Ref, AppLayoutProps>(
  (props, ref) => {
    return (
      <I18nProvider locale='en' messages={[enMessages]}>
        <AppLayout ref={ref} {...props} />
      </I18nProvider>
    );
  }
);

export const CounterLink = ({ children }: { children: React.ReactNode }) => {
  return (
    <Link variant='awsui-value-large' href='#'>
      {children}
    </Link>
  );
};
