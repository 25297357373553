import { saveAs } from 'file-saver';
import { Alert, Button, Container, ContentLayout, Header, KeyValuePairs, KeyValuePairsProps, SpaceBetween, Spinner } from '@cloudscape-design/components';
import { useState } from 'react';
import ContactSurvey from '../../../objects/AmznContactFlows/contactSurvey.json';
import fetcher from '../../../utils/fetcherInstance';
import { useAppSession } from '../../../utils/hooks/sessionContext';
import { useInitialise } from '../../../utils/hooks/useInitialise';

export default function SurveySettings() {
  const [loading, setLoading] = useState<boolean>();
  const [surveyLambdaArn, setSurveyLambdaArn] = useState<string>('');
  const appSession = useAppSession();
  const { instanceArn } = appSession?.instanceSelected || {};

  const load = async () => {
    const { data: instanceResponse } = await fetcher('/instance', 'GET', null, instanceArn);

    setSurveyLambdaArn(instanceResponse.surveyLambdaArn);
    setLoading(false);
  };

  useInitialise(() => {
    setLoading(true);
    load();
  });

  function replaceLambdaARN<T>(json: T, lambdaFunctionArn: string) {
    const jsonString = JSON.stringify(json);
    const updatedJsonString = jsonString.replace(/{{LambdaARN}}/g, lambdaFunctionArn);
    return JSON.parse(updatedJsonString);
  }
  const downloadSampleContactFlow = () => {
    const updatedJson = replaceLambdaARN(ContactSurvey, surveyLambdaArn);
    const blob = new Blob([JSON.stringify(updatedJson, null, 2)], {
      type: 'application/json',
    });
    saveAs(blob, `contactSurveyModule.json`);
  };
  const keyValuePairItems: readonly KeyValuePairsProps.Item[] = [
    {
      label: 'Step 1',
      value: (
        <SpaceBetween size={'xs'}>
          <p>Press the button to download the <strong>Survey Contact Disconnect Flow</strong> to your machine.</p>
          {loading ? <Spinner /> : <Button onClick={downloadSampleContactFlow} disabled={!surveyLambdaArn}>Download Template</Button>}
        </SpaceBetween>
      ),
    },
    {
      label: 'Step 2',
      value: (
        <>
          Import the downloaded JSON file into your instance, as a <strong>Contact flow</strong> and publish
        </>
      ),
    },
    {
      label: 'Step 3',
      value: (
        <>
          For the call flow in question, set the disconnect flow to <strong>Survey Contact Disconnect Flow</strong>
        </>
      ),
    },
    {
      label: 'Step 4',
      value: (
        <>
          Create <strong>Set Contact Attributes</strong> block and copy the correct Survey Id from the list, set this as{' '}
          <strong>User defined -&gt; surveyId</strong> against the current Contact
        </>
      ),
    },
  ];

  return (
    <ContentLayout
      header={
        <Header
          variant='h1'
          description='Your survey settings and quick start guides'
        >
          Settings
        </Header>
      }
    >
      <SpaceBetween size='m'>
        {!surveyLambdaArn && !loading && <Alert
          statusIconAriaLabel='Error'
          type='error'
          header='Your survey set up is not complete'
        >
          Surveys does not appear to be set up correctly, please contact CX Studio to support you.
        </Alert>}
        <Container header={<Header headingTagOverride='h3'>Surveys Quick Start</Header>}>
          <SpaceBetween size='l'>
            <div className='divider'>Survey Quick Start for post call surveys</div>
            <KeyValuePairs
              columns={2}
              items={keyValuePairItems}
            />
          </SpaceBetween>
        </Container>
      </SpaceBetween>
    </ContentLayout>
  );
}
