import { forEach, fromPairs, map } from 'lodash';

const REST = 'REST';
const WS = 'WS';
export const parseApiVariable = () => {
  const REACT_APP_API_URL_LIST = process.env.REACT_APP_API_URL_LIST;
  if (!REACT_APP_API_URL_LIST) {
    console.error('API URLS are missing');
    throw new Error('API URLS are missing');
  }
  const regionsArray = REACT_APP_API_URL_LIST.split('[|]');

  const result: { [key: string]: { [key: string]: string } } = {};

  forEach(regionsArray, (regionString) => {
    if (regionString) {
      const [region, apiString] = regionString.split('[=]');
      const endpoints = apiString.split(',');
      result[region] = fromPairs(map(endpoints, (endpoint) => endpoint.split('[:]')));
    }
  });
  return result;
};

export const getRegionRestApi = (region: string) => {
  const endpoints = parseApiVariable();
  return endpoints[region][REST];
};

export const getRegionWebsocketApi = (region: string) => {
  const endpoints = parseApiVariable();
  return endpoints[region][WS];
};
