import { Auth } from 'aws-amplify';

export type UserProfile = {
  given_name: string;
  family_name: string;
  email: string;
  username: string;
};

export async function fetchUserProfile(): Promise<UserProfile | null> {
  try {
    const user = await Auth.currentAuthenticatedUser();
    // Using this method as SAML users did not have attribute object in user.
    const { payload } = user.signInUserSession.idToken;
    const { given_name: givenName, family_name: familyName, email } = payload;
    return {
      given_name: givenName,
      family_name: familyName,
      email,
      username: payload['cognito:username'],
    } as UserProfile;
  } catch (error) {
    console.error('Failed to fetch user profile', error);
    return null;
  }
}
