import React from 'react';
import { CollectionPreferences, CollectionPreferencesProps, TableProps } from '@cloudscape-design/components';
import { getAttributeValue } from '../../user-admin/components/helpers';
import { User } from '../../user-admin/types';
import { PreferenceProps } from '../../../types/table';

export const useTableConfig = () => {

  const columns: TableProps.ColumnDefinition<User>[] = [
    {
      id: 'Username',
      header: 'Username',
      cell: (item) => item.Username,
    },
    {
      id: 'fullname',
      header: 'Full Name',
      cell: (item) => {
        const givenName = getAttributeValue(item.Attributes, 'given_name') ?? '';
        const familyName = getAttributeValue(item.Attributes, 'family_name') ?? '';
        return givenName || familyName ? `${givenName} ${familyName}`.trim() : '-';
      },
    },
  ];

  const defaultPreferences: CollectionPreferencesProps.Preferences = {
    pageSize: 10,
    contentDisplay: [
      { id: 'Username', visible: true },
      { id: 'fullname', visible: true },
    ],
    wrapLines: false,
    stripedRows: false,
    contentDensity: 'comfortable',
    stickyColumns: { first: 1, last: 0 },
  };

  const CONTENT_DISPLAY_OPTIONS = [
    { id: 'Username', label: 'Username', alwaysVisible: true },
    { id: 'fullname', label: 'Full Name', alwaysVisible: true },
  ];

  const PAGE_SIZE_OPTIONS = [
    { value: 10, label: '10 users' },
    { value: 30, label: '30 users' },
    { value: 50, label: '50 users' },
  ];

  const Preferences = ({
    preferences,
    setPreferences,
    disabled = false,
    pageSizeOptions = PAGE_SIZE_OPTIONS,
    contentDisplayOptions = CONTENT_DISPLAY_OPTIONS,
  }: PreferenceProps) => (
    <CollectionPreferences
      title={'Preferences'}
      cancelLabel={'Cancel'}
      confirmLabel={'Confirm'}
      disabled={disabled}
      preferences={preferences}
      onConfirm={({ detail }) => setPreferences(detail)}
      pageSizePreference={{ title: 'Page size', options: pageSizeOptions }}
      wrapLinesPreference={{
        label: 'Wrap lines',
        description: 'Select to see all the text and wrap the lines',
      }}
      stripedRowsPreference={{
        label: 'Striped rows',
        description: 'Select to add alternating shaded rows',
      }}
      contentDensityPreference={{
        label: 'Compact mode',
        description: 'Select to display content in a denser, more compact mode',
      }}
      contentDisplayPreference={{
        title: 'Select visible content / order',
        options: contentDisplayOptions,
      }}
      stickyColumnsPreference={{
        firstColumns: {
          title: 'Stick first column(s)',
          description: 'Keep the first column(s) visible while horizontally scrolling the table content.',
          options: [
            { label: 'None', value: 0 },
            { label: 'First column', value: 1 },
            { label: 'First two columns', value: 2 },
          ],
        },
        lastColumns: {
          title: 'Stick last column',
          description: 'Keep the last column visible while horizontally scrolling the table content.',
          options: [
            { label: 'None', value: 0 },
            { label: 'Last column', value: 1 },
          ],
        },
      }}
    />
  );

  return { columns, defaultPreferences, Preferences };
};
