export const filteringProperties = [
  {
    propertyLabel: 'Closure Group',
    key: 'feature',
    groupValuesLabel: 'Closure Group values',
    operators: [':', '!:', '=', '!='] as const,
  },
  {
    propertyLabel: 'Description',
    key: 'description',
    groupValuesLabel: 'Description',
    operators: [':', '!:', '=', '!='] as const,
  },
  {
    propertyLabel: 'Timezone',
    key: 'timezone',
    groupValuesLabel: 'Timezone',
    operators: [':', '!:', '=', '!='] as const,
  },
  {
    propertyLabel: 'Status',
    key: 'status',
    groupValuesLabel: 'Status',
    operators: [':', '!:', '=', '!='] as const,
  },
  {
    propertyLabel: 'Tag',
    key: 'tag',
    groupValuesLabel: 'Status',
    operators: [':', '!:', '=', '!='] as const,
  },
] as const;
