import React from 'react';
import { ClickableNameLink } from '../edit/clickableLink';
import { InstanceType } from '../../../types/instance';

const rawColumns = [
  {
    id: 'name',
    header: 'Name',
    cell: (item: InstanceType) => <ClickableNameLink item={item} />,
    sortingField: 'name',
    width: 200,
  },
  {
    id: 'instanceRegion',
    header: 'Region',
    cell: (item: InstanceType) => item?.instanceRegion || '-',
    sortingField: 'instanceRegion',
    width: 150,
  },
  {
    id: 'instanceArn',
    header: 'Instance ARN',
    cell: (item: InstanceType) => item?.instanceArn || '-',
    sortingField: 'instanceArn',
    width: 200,
  },

  {
    id: 'instanceId',
    header: 'Instance ID',
    cell: (item: InstanceType) => item?.instanceId || '-',
    sortingField: 'instanceId',
    width: 200,
  },
];

export const InstancesTableConfig = rawColumns.map((column) => ({ ...column }));
