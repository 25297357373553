import { Auth } from 'aws-amplify';
import { FetcherError } from './fetcherError';

const MANAGEMENT_URL = process.env.REACT_APP_MNG_API_URL;

async function getAuthToken() {
  try {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch (error) {
    console.error('Error getting the authentication token', error);
    return null;
  }
}

async function fetcher(endpoint: string | null, method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' = 'GET', data: Record<string, any> | null = null) {
  if (!endpoint) return null;
  const token = await getAuthToken();

  if (!token) {
    throw new Error('Unable to fetch the authentication token');
  }

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  let fullUrl = endpoint;

  if (!(method === 'PUT' && data && data.file)) {
    fullUrl = `${MANAGEMENT_URL}${endpoint}`;
  }

  const requestConfig: RequestInit = {
    method,
    headers,
  };

  if (['POST', 'PUT', 'PATCH'].includes(method) && data) {
    if (method === 'PUT' && data && data.file) {
      requestConfig.method = 'PUT';
      requestConfig.headers = {
        'Content-Type': data.file.type,
      };
      requestConfig.body = data.file;
    } else {
      requestConfig.body = JSON.stringify(data);
    }
  }

  const response = await fetch(fullUrl, requestConfig);

  if (!response.ok) {
    const error: FetcherError = new FetcherError('An error occurred while fetching the data.');
    error.info = await response.json();
    error.status = response.status;
    throw error;
  }

  if (method === 'DELETE') {
    return null;
  }

  const text = await response.text();
  return text ? JSON.parse(text) : null;
}

export default fetcher;
