import _ from "lodash";
import { Dispatch, SetStateAction } from "react";

export const setNestedState = (obj: any, path: string[], value: any) => {
    if (path.length === 1) {
        obj[path[0]] = value;
    } else {
        if (!obj[path[0]]) obj[path[0]] = {};
        setNestedState(obj[path[0]], path.slice(1), value);
    }
};

export const handleFieldChange = <T extends object>(setItem: Dispatch<SetStateAction<T>>) => {
    return <K extends keyof T>(path: K, value: T[K]) => {
        setItem((prev: T) => {
            const newObj = _.cloneDeep(prev);
            _.set(newObj, path, value);
            return newObj;
        });
    };
};
