import { PropertyFilterProperty } from "@cloudscape-design/collection-hooks";

export const filteringProperties: PropertyFilterProperty[] = [
  {
    propertyLabel: 'Name',
    key: 'name',
    groupValuesLabel: 'Resource values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Description',
    key: 'description',
    groupValuesLabel: 'Description',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Instance',
    key: 'instance',
    groupValuesLabel: 'Instance',
    operators: [':', '!:', '=', '!='],
  },
];
