import { Header } from '@cloudscape-design/components';
import { useItemContext } from '../../../../dashboardContext';
import { WidgetConfig, WidgetHeaderProps } from '../interfaces';
import { content } from './content';
import { provider } from './provider';
import { SingleMetricPreferences } from './singleMetricTypes';
import styles from './singleMetric.module.scss';

export const getSingleMetric: WidgetConfig = {
  definition: { defaultRowSpan: 1, defaultColumnSpan: 1, minRowSpan: 1 },
  data: {
    icon: 'number',
    title: 'Single Metric',
    description: 'Displays a single metric',
    header: WidgetHeader,
    content: content,
    provider: provider,
    staticMinHeight: 560,
    multipleAllowed: true,
    className: styles.singleMetricWidget
  },
  filters: {
    queues: [],
  },
};

function WidgetHeader({ widgetId }: WidgetHeaderProps) {
  const { getWidget } = useItemContext();

  const widget = getWidget<SingleMetricPreferences>(widgetId);
  const { title, description } = widget.data;
  return (
    <Header
      variant='h2'
      description={title}
    >
      {description}
    </Header>
  );
}
