import React from 'react';
import { Link } from 'react-router-dom';
import { CollectionPreferencesProps, CopyToClipboard, TableProps } from '@cloudscape-design/components';
import { useProtectUtility } from '../../../components/protectedUtility';
import { Actions, ResourceTypes } from '../../../types/rolePermissions';

import { PreferenceProps } from '../../../types/table';
import { TagIcon } from '../../utilities/tagIcon';
import { TablePreferences } from '../../../utils/tablePreferences';
import { SurveyGroupListItem } from './table';

export const useTableConfig = () => {
  const protectUtility = useProtectUtility();

  const columns: TableProps.ColumnDefinition<SurveyGroupListItem>[] = [
    {
      id: 'id',
      header: 'Id',
      cell: (item) => (
        <CopyToClipboard
          copyButtonAriaLabel='Copy Survey Id'
          copyErrorText='Survey Id failed to copy'
          copySuccessText='Survey Id copied'
          textToCopy={item.feature}
          copyButtonText={item.feature}
          variant='inline'
        />
      )
    },
    {
      id: 'title',
      header: 'Title',
      cell: (item) =>
        protectUtility({ resourceType: ResourceTypes.Surveys, resourceAction: Actions.Update }) ? (
          <Link to={`/surveys/edit/${item.feature}`}>{item.title}</Link>
        ) : (
          item.title
        ),
      sortingField: 'title',
    },
    {
      id: 'description',
      header: 'Description',
      cell: (item) => item.description || '-',
      sortingField: 'description',
    },
    {
      id: 'tag',
      header: (
        <>
          Tag <TagIcon />
        </>
      ),
      cell: (item) => item.tag ?? '',
      sortingField: 'tag',
    },
  ];

  const defaultContentDisplayOptions: CollectionPreferencesProps.ContentDisplayOption[] = [
    { id: 'id', label: 'Id', alwaysVisible: true },
    { id: 'title', label: 'title', alwaysVisible: true },
    { id: 'description', label: 'Description', alwaysVisible: false },
    { id: 'tag', label: 'Tag', alwaysVisible: false },
  ];

  const defaultPageSizeOptions: CollectionPreferencesProps.PageSizeOption[] = [
    { value: 10, label: '10 survey groups' },
    { value: 30, label: '30 survey groups' },
    { value: 50, label: '50 survey groups' },
  ];

  const defaultPreferences: CollectionPreferencesProps.Preferences = {
    pageSize: 10,
    contentDisplay: [
      { id: 'id', visible: true },
      { id: 'title', visible: true },
      { id: 'description', visible: true },
      { id: 'tag', visible: true },
    ],
    wrapLines: false,
    stripedRows: false,
    contentDensity: 'comfortable',
    stickyColumns: { first: 1, last: 0 },
  };

  const Preferences = ({
    preferences,
    setPreferences,
    disabled = false,
    pageSizeOptions = defaultPageSizeOptions,
    contentDisplayOptions = defaultContentDisplayOptions,
  }: PreferenceProps) => <TablePreferences {...{ preferences, setPreferences, disabled, pageSizeOptions, contentDisplayOptions }} />;

  return { columns, defaultPreferences, Preferences };
};
