import { Spinner, Alert, Button } from '@cloudscape-design/components';
import { useContext } from 'react';
import { useItemContext } from '../../../../dashboardContext';
import { EmptyState } from '../../empty-state';
import { SingleMetricWidgetContext } from './provider';
import { WidgetContentProps } from '../interfaces';
import useIntuitionSWR from '../../../../../../../utils/hooks/useIntuitionSWR';
import styles from './singleMetric.module.scss';
import { SingleMetricPreferences } from './singleMetricTypes';
import { FormattedMetricDataPoint, InstanceMetricApiResponse } from '../interfaces/queueMetricsInterfaces';
import { queueArnsToQueueIdParams } from '../utils/widget.helpers';



export const content = ({ widgetId }: WidgetContentProps) => {
  const singleMetricWidgetContext = useContext(SingleMetricWidgetContext);
  const { getWidget } = useItemContext();
  const widget = getWidget<SingleMetricPreferences>(widgetId);

  const queueIdParams = queueArnsToQueueIdParams(widget?.filters?.queues);
  const endpoint = `/instance-queue-metrics?queueIds=${queueIdParams}`;
  const { data, isLoading, error } = useIntuitionSWR<InstanceMetricApiResponse>(endpoint, {
    shouldRetryOnError: true,
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    refreshInterval: 5000,
  });

  if (!widget.preferences?.metric || !widget.filters?.queues?.length) {
    return (
      <EmptyState
        title='No Data to display'
        description=''
        action={<Button onClick={singleMetricWidgetContext.openPreferences}>Preferences</Button>}
      />
    );
  }

  if (isLoading) {
    return <Spinner />;
  }

  if (error || !data) {
    return (
      <Alert
        statusIconAriaLabel='Error'
        type='error'
        header='Unable to load widget'
      >
        {error.status}
      </Alert>
    );
  }

  const {metric, ascending} = widget.preferences;
  const metricDataPoint = data.metricSummary.find((element) => element.name === metric) as FormattedMetricDataPoint;

  const minValue = widget.preferences.minValue ?? 10;
  const maxValue = widget.preferences.maxValue ?? 30;

  const lowClass = ascending ? 'success' : 'error';
  const highClass = ascending ? 'error' : 'success';

  const colorClassName = metricDataPoint.value < minValue ? lowClass : metricDataPoint.value < maxValue ? 'warning' : highClass;

  return (
    <section className={`${styles.singleMetric} ${styles[colorClassName]}`}>
      <span className={styles.metric}>{metricDataPoint?.value || 0}</span>
    </section>
  );
};
