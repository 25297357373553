import { useState } from 'react';
import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@cloudscape-design/components/button';
import Header from '@cloudscape-design/components/header';
import ButtonDropdown, { ButtonDropdownProps } from '@cloudscape-design/components/button-dropdown';
import { Pagination, Spinner, Table } from '@cloudscape-design/components';
import PropertyFilter from '@cloudscape-design/components/property-filter';
import { getTextFilterCounterText } from '../../../utils/text-filter';
import { useLocalStorage } from '../../../components/use-local-storage';
import { useColumnWidths } from '../../../components/table/use-column-width';
import { FILTERING_PROPERTIES as filteringProperties } from './filtering_poperties';
import { mutate } from 'swr';
import { useNavigate } from 'react-router-dom';
import { useApiWithFlash } from '../../../utils/hooks/useApiWithFlash';

import { DEFAULT_PREFERENCES, EDITABLE_COLUMN_DEFINITIONS, Preferences } from './table.config';

import { TableEmptyStateProps } from '../../../components/table/commons';
import { Document, DocumentTableProps } from '../types';
import { ResourceTypes } from '../../../types/rolePermissions';
import { useCatoCollection } from '../../../utils/hooks/useCatoCollection';

export default function DocumentTable({ isLoading, documents = [] }: DocumentTableProps) {
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState<Document[]>([]);
  const [actionLoading, setActionLoading] = useState(false);
  const [columnDefinitions, saveWidths] = useColumnWidths('Cato-Document-TableEditable-Widths', EDITABLE_COLUMN_DEFINITIONS);
  const [preferences, setPreferences] = useLocalStorage('Cato-Document-TableEditable-Preferences', DEFAULT_PREFERENCES);

  // hooks
  const navigate = useNavigate();
  const { handleApiWithFlash } = useApiWithFlash();

  const tableEmptyStateProps: TableEmptyStateProps = {
    resourceName: 'Documents',
    resourceType: ResourceTypes.Xq,
    urlPath: 'documents',
  };

  const { items, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCatoCollection(documents, {
    tableEmptyStateProps,
    pageSize: preferences.pageSize,
    filteringProperties,
  });

  const handleUtilityItemClick = async (event: CustomEvent<ButtonDropdownProps.ItemClickDetails>) => {
    setActionLoading(true);
    const details = event.detail;
    const documentId = selectedItems[0].id;
    if (details.id === 'toggle-active') {
      await handleApiWithFlash(`/documents/${documentId}`, 'PATCH', {
        successMessage: `Successfully toggled activity for document: ${documentId}`,
        errorMessage: `Error toggling activity for document: ${documentId}`,
        mutateKey: '/documents',
      });
    }
    if (details.id === 'remove') {
      await handleApiWithFlash(`/documents/${documentId}`, 'DELETE', {
        successMessage: `Successfully deleted document: ${documentId}`,
        errorMessage: `Error deleting document: ${documentId}`,
        mutateKey: '/documents',
      });
    }
    setActionLoading(false);
  };

  const onRefresh = async () => {
    setRefreshLoading(true);
    await mutate('/documents');
    setRefreshLoading(false);
  };

  const refreshButtonProps = { onClick: onRefresh };

  return (
    <>
      <Table
        {...collectionProps}
        stickyHeader={true}
        resizableColumns={true}
        onColumnWidthsChange={saveWidths}
        onSelectionChange={({
          detail: { selectedItems },
        }: {
          detail: {
            selectedItems: Document[];
          };
        }) => {
          if (selectedItems && selectedItems.length > 0) {
            const selectedItem: Document = selectedItems[0];
            setSelectedItems(() => [selectedItem]);
          }
        }}
        selectedItems={selectedItems as any}
        columnDefinitions={columnDefinitions}
        items={items}
        // submitEdit={handleSubmit}
        trackBy='id'
        loadingText='Loading documents'
        columnDisplay={preferences.contentDisplay}
        wrapLines={preferences.wrapLines}
        stripedRows={preferences.stripedRows}
        // stickyColumns={preferences.stickyColumns} // Do not use, causes react resizer error. Leaving in comment to avoid accidental issues.
        contentDensity={preferences.contentDensity as 'compact' | 'comfortable'}
        selectionType={'single'}
        loading={isLoading || !documents || refreshLoading}
        header={
          <Header
            variant='awsui-h1-sticky'
            actions={
              <SpaceBetween
                direction='horizontal'
                size='xs'
              >
                <Button
                  iconName='refresh'
                  ariaLabel='Refresh'
                  {...refreshButtonProps}
                />
                <ButtonDropdown
                  disabled={!selectedItems || selectedItems.length === 0}
                  onItemClick={handleUtilityItemClick}
                  loading={actionLoading}
                  loadingText={'loading'}
                  items={[
                    {
                      text: 'Toggle active',
                      id: 'toggle-active',
                      disabled: false,
                    },
                    {
                      text: 'Remove',
                      id: 'remove',
                      disabled: false,
                    },
                  ]}
                >
                  Actions
                </ButtonDropdown>
                <Button
                  variant='primary'
                  onClick={() => navigate('/documents/create')}
                >
                  Create document
                </Button>
              </SpaceBetween>
            }
          >
            Documents {documents && !isLoading ? `(${documents.length})` : <Spinner />}
          </Header>
        }
        filter={
          <PropertyFilter
            {...propertyFilterProps}
            countText={getTextFilterCounterText(filteredItemsCount)}
            expandToViewport={true}
            filteringAriaLabel={'Filter documents'}
            filteringPlaceholder={'Filter documents'}
          />
        }
        pagination={<Pagination {...paginationProps} />}
        preferences={
          <Preferences
            preferences={preferences}
            setPreferences={setPreferences}
          />
        }
      />
    </>
  );
}
