import React, { useState } from 'react';
import { SpaceBetween, Button, ColumnLayout, Box, Header, Table, TableProps } from '@cloudscape-design/components';
import { User } from '../types';
import { useApiWithFlash } from '../../../utils/hooks/useApiWithFlash';

interface UserRolesModalProps {
  roles: Role[];
  user: User;
}

interface Role {
  feature: string;
  name: string;
  description: string;
}

const UserRolesModal: React.FC<UserRolesModalProps> = ({ roles, user }) => {
  const [loading, setLoading] = useState(false);
  const [userRoles, setUserRoles] = useState<string[]>(() => {
    return user.Attributes.find((attr) => attr.Name === 'custom:roles')?.Value.split('|') || [];
  });
  const { handleApiWithFlash } = useApiWithFlash();

  const handleRoleChange = async (featureId: string, action: 'add' | 'remove') => {
    setLoading(true);
    try {
      const command = action === 'add' ? 'ADD' : 'REMOVE';

      await handleApiWithFlash(`/roles/${featureId}/users`, 'PUT', {
        successMessage: `Role ${action === 'add' ? 'added to' : 'removed from'} user.`,
        errorMessage: 'Failed to update role.',
        data: { username: user.Username, command },
      });
      setUserRoles((prevRoles) => {
        const newRoles = new Set(prevRoles);
        if (action === 'add') {
          newRoles.add(featureId);
        } else {
          newRoles.delete(featureId);
        }
        return Array.from(newRoles);
      });
    } catch (error) {
      console.error('Failed to update role:', error);
    } finally {
      setLoading(false);
    }
  };

  const attachedRoles = roles.filter((role) => userRoles.includes(role.feature));
  const addableRoles = roles.filter((role) => !userRoles.includes(role.feature));

  const attachedTableProps: TableProps = {
    variant: 'borderless',
    empty: (
      <Box
        margin={{ vertical: 'xs' }}
        textAlign='center'
        color='inherit'
      >
        <SpaceBetween size='m'>
          <b>No roles</b>
        </SpaceBetween>
      </Box>
    ),
    columnDefinitions: [
      {
        id: 'feature',
        header: <Header variant='h3'>Attached Roles</Header>,
        cell: (role: Role) => (
          <Button
            iconName='remove'
            onClick={() => handleRoleChange(role.feature, 'remove')}
            loading={loading}
            loadingText='Removing'
            variant='inline-link'
          >
            {role.name}
          </Button>
        ),
      },
    ],
    items: attachedRoles,
  }
  const addableTableProps: TableProps = {
    variant: 'borderless',
    empty: (
      <Box
        margin={{ vertical: 'xs' }}
        textAlign='center'
        color='inherit'
      >
        <SpaceBetween size='m'>
          <b>No roles</b>
        </SpaceBetween>
      </Box>
    ),
    columnDefinitions: [
      {
        id: 'feature',
        header: <Header variant='h3'>Available Roles</Header>,
        cell: (role: Role) => (
          <Button
            iconName='add-plus'
            onClick={() => handleRoleChange(role.feature, 'add')}
            loading={loading}
            loadingText='Adding'
            variant='inline-link'
          >
            {role.name}
          </Button>
        ),
      },
    ],
    items: addableRoles,
  }

  return (
    <SpaceBetween size='l'>
      <ColumnLayout
        columns={2}
        variant='text-grid'
      >
        <Table {...{ ...attachedTableProps }} />
        <Table {...{ ...addableTableProps }} />
      </ColumnLayout>
    </SpaceBetween>
  );
};

export default UserRolesModal;
