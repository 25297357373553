import { Auth } from 'aws-amplify';
import { getRegionRestApi } from './regionApiParse';
import { parse } from '@aws-sdk/util-arn-parser';
import { FetcherError } from './fetcherError';

const MANAGEMENT_URL = process.env.REACT_APP_MNG_API_URL;

async function getAuthToken() {
  try {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch (error) {
    console.error('Error getting the authentication token', error);
    return null;
  }
}

function appendInstanceId(endpoint: string, instanceArn?: string): string {
  if (!instanceArn) {
    return endpoint; // Return original endpoint if instanceId is not provided
  }

  // Split the ARN and get the last part, which should be the instanceId
  const arnComponents = parse(instanceArn);
  const [, instanceId] = arnComponents.resource.split('/'); // This gets the last part after splitting by ':'

  // Append the instanceId as a query parameter
  return `/${instanceId}${endpoint}`;
}

const getRegion = (instanceArn: string): string => {
  const [, , , region] = instanceArn.split(':');
  return region;
};

async function fetcher(
  endpoint: string | null,
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' = 'GET',
  data: Record<string, any> | null = null,
  instanceArn: string | undefined,
) {
  if (!endpoint) return null;
  const token = await getAuthToken();
  if (!token) {
    throw new Error('Unable to fetch the authentication token');
  }

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  const apiUrl = instanceArn ? getRegionRestApi(getRegion(instanceArn)) : MANAGEMENT_URL;
  const endpointWithInstanceId = appendInstanceId(endpoint, instanceArn);
  const fullUrl = `${apiUrl}${endpointWithInstanceId}`;

  const requestConfig: RequestInit = {
    method,
    headers,
  };

  if (['POST', 'PUT', 'PATCH'].includes(method) && data) {
    requestConfig.body = JSON.stringify(data);
  }

  const response = await fetch(fullUrl, requestConfig);

  if (!response.ok) {
    const error: FetcherError = new FetcherError('An error occurred while fetching the data.');
    // Attach extra info to the error object.
    error.info = await response.json();
    error.status = response.status;
    throw error;
  }

  if (method === 'DELETE') {
    return null;
  }

  // First, check for content and try to parse JSON only if there's data
  const text = await response.text();
  return text ? JSON.parse(text) : null;
}

export default fetcher;
