import fetcher from '../fetcherInstance';
import { useApiWithFlashBase } from './useApiWithFlashBase';
import { useAppSession } from './sessionContext';
import { FetcherFunction } from '../../types/api';

export const useApiWithFlash = () => {
  const appSession = useAppSession();
  const instanceArn = appSession?.instanceSelected?.instanceArn;

  const fetcherWithInstanceArn: FetcherFunction = (url, method, data) => fetcher(url, method, data, instanceArn);

  return useApiWithFlashBase(fetcherWithInstanceArn);
};

export const useApiWithFlashForFeatureCopy = () => {
  return useApiWithFlashBase(fetcher);
};
