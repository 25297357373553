import { useState } from 'react';
import Header from '@cloudscape-design/components/header';
import { Pagination, SpaceBetween, Spinner, Table } from '@cloudscape-design/components';
import PropertyFilter from '@cloudscape-design/components/property-filter';
import Alert from '@cloudscape-design/components/alert';
import { getTextFilterCounterText } from '../../../utils/text-filter';
import { useLocalStorage } from '../../../components/use-local-storage';
import { useColumnWidths } from '../../../components/table/use-column-width';
import { useSplitPanelControl } from '../../../utils/hooks/splitPanelContext';
import { FILTERING_PROPERTIES as filteringProperties } from './filtering_poperties';
import AuditSplitPanelDetails from './auditSplitPanelDetails';
import CxDateRangePicker from '../../../components/cxDataRangePicker';

import { DEFAULT_PREFERENCES, EDITABLE_COLUMN_DEFINITIONS, Preferences } from './table.config';


import { AuditLog, AuditTableProps } from '../types';
import { useCatoCollection } from '../../../utils/hooks/useCatoCollection';

export default function AuditTable({
  isLoading,
  auditLogs = [],
  error,

  onDateRangeChange,
  period,
}: AuditTableProps) {
  const [selectedItems, setSelectedItems] = useState<AuditLog[]>([]);
  const [columnDefinitions, saveWidths] = useColumnWidths('Cato-Audit-TableEditable-Widths', EDITABLE_COLUMN_DEFINITIONS);
  const [preferences, setPreferences] = useLocalStorage('Cato-Audit-TableEditable-Preferences', DEFAULT_PREFERENCES);

  const { setSplitPanelOpen, setSplitPanelContent } = useSplitPanelControl();

  const { items, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCatoCollection(auditLogs, {
    filteringProperties,
    pageSize: preferences.pageSize,
  });

  function onSelectionChange(data: { detail: any }) {
    setSplitPanelContent(<AuditSplitPanelDetails selectedItems={data} />);
    setSplitPanelOpen(true);
  }

  return (
    <>
      <Table
        {...collectionProps}
        stickyHeader={true}
        resizableColumns={true}
        onColumnWidthsChange={saveWidths}
        columnDefinitions={columnDefinitions}
        onSelectionChange={({ detail: { selectedItems } }) => {
          onSelectionChange({ detail: selectedItems });
          if (selectedItems && selectedItems.length > 0) {
            const selectedItem: AuditLog = selectedItems[0];
            setSelectedItems((_prevItems) => [selectedItem]);
          }
        }}
        selectedItems={selectedItems as any}
        items={items}
        selectionType={'single'}
        loadingText='Loading audit logs'
        columnDisplay={preferences.contentDisplay}
        wrapLines={preferences.wrapLines}
        stripedRows={preferences.stripedRows}
        stickyColumns={preferences.stickyColumns}
        contentDensity={preferences.contentDensity as 'compact' | 'comfortable'}
        loading={isLoading || !auditLogs}
        header={
          <SpaceBetween size='xs'>
            <Header variant='awsui-h1-sticky'>Audit logs {auditLogs && !isLoading ? `(${auditLogs.length})` : <Spinner />}</Header>
            <CxDateRangePicker
              setDateRangeValue={onDateRangeChange}
              defaults={period}
            />
          </SpaceBetween>
        }
        filter={
          <PropertyFilter
            {...propertyFilterProps}
            countText={getTextFilterCounterText(filteredItemsCount)}
            expandToViewport={true}
            filteringAriaLabel={'Filter results'}
            filteringPlaceholder={'Filter results'}
          />
        }
        pagination={<Pagination {...paginationProps} />}
        preferences={
          <Preferences
            preferences={preferences}
            setPreferences={setPreferences}
          />
        }
      />
      {error && (
        <Alert
          statusIconAriaLabel='Error'
          type='error'
          header='Error getting data'
        >
          {error?.info?.error?.message ?? 'Refresh the page to try again.'}
        </Alert>
      )}
    </>
  );
}
