import { useState } from 'react';
import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@cloudscape-design/components/button';
import Header from '@cloudscape-design/components/header';
import ButtonDropdown, { ButtonDropdownProps } from '@cloudscape-design/components/button-dropdown';
import { Pagination, Spinner, Table, TableProps } from '@cloudscape-design/components';
import PropertyFilter from '@cloudscape-design/components/property-filter';
import Alert from '@cloudscape-design/components/alert';
import { getTextFilterCounterText } from '../../../utils/text-filter';
import { useLocalStorage } from '../../../components/use-local-storage';
import { useColumnWidths } from '../../../components/table/use-column-width';
import { FILTERING_PROPERTIES as filteringProperties } from './filtering_poperties';
import { mutate } from 'swr';

import { useNavigate } from 'react-router-dom';

import { useTableConfig } from './table.config';

import { TableEmptyStateProps } from '../../../components/table/commons';
import { useAppSession } from '../../../utils/hooks/sessionContext';
import { Actions, ResourceTypes } from '../../../types/rolePermissions';

import { ProtectedUtilityCreate, useProtectUtility } from '../../../components/protectedUtility';
import { useCatoCollection } from '../../../utils/hooks/useCatoCollection';
import SurveysSplitPanelDetails from './surveysSplitPanelDetails';
import { useSplitPanelControl } from '../../../utils/hooks/splitPanelContext';

export type SurveyGroupListItem = {
  feature: string;
  title: string;
  description: string;
  type: string;
  tag?: string;
};

export type SurveyGroupList = Array<SurveyGroupListItem>;

export type SurveysTableProps = {
  isLoading: boolean;
  surveys: SurveyGroupList;
  error: boolean;
};

export default function SurveyTable({ isLoading, surveys = [], error }: Readonly<SurveysTableProps>) {
  const protectUtility = useProtectUtility();

  const { defaultPreferences, columns, Preferences } = useTableConfig();

  // State
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState<SurveyGroupList>([]);
  const [columnDefinitions, saveWidths] = useColumnWidths('Cato-Survey-TableEditable-Widths', columns);
  const [preferences, setPreferences] = useLocalStorage('Cato-Survey-TableEditable-Preferences', defaultPreferences);
  const { setSplitPanelOpen, setSplitPanelContent } = useSplitPanelControl();

  // Hooks
  const navigate = useNavigate();
  const appSession = useAppSession();
  const instanceArn = appSession?.instanceSelected?.instanceArn;

  const tableEmptyStateProps: TableEmptyStateProps = {
    resourceName: 'Surveys',
    resourceType: ResourceTypes.Surveys,
    urlPath: 'surveys',
  };

  const { items, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCatoCollection(surveys, {
    tableEmptyStateProps,
    pageSize: preferences.pageSize,
    filteringProperties,
  });

  const onRefresh = async () => {
    setRefreshLoading(true);
    const endpoint = '/surveys';
    const key = instanceArn ? [endpoint, instanceArn] : [endpoint];
    await mutate(key);
    setRefreshLoading(false);
  };

  const handleUtilityItemClick = (event: CustomEvent<ButtonDropdownProps.ItemClickDetails>) => {
    const details = event.detail;
    if (details.id === 'edit') {
      navigate(`/surveys/edit/${selectedItems[0].feature}`);
    }

    setSelectedItems([]);
    setSplitPanelOpen(false);
    setSplitPanelContent(null);
  };

  const refreshButtonProps = { onClick: onRefresh };

  function onSelectionChange(data: { detail: SurveyGroupListItem[] }) {
    setSplitPanelContent(<SurveysSplitPanelDetails selectedItem={data.detail[0]} />);
    setSplitPanelOpen(true);
  }

  const functions = [{ text: 'Edit', action: Actions.Update }];

  const dropDownButtonMenuProps: ButtonDropdownProps = {
    disabled: !selectedItems || selectedItems.length === 0,
    onItemClick: handleUtilityItemClick,
    loadingText: 'loading',
    items: functions
      .filter((f) => protectUtility({ resourceType: ResourceTypes.Surveys, resourceAction: f.action }))
      .map((f) => ({
        text: f.text,
        id: f.text.toLowerCase(),
        disabled: false,
      })),
  };

  const header = (
    <Header
      variant='awsui-h1-sticky'
      actions={
        <SpaceBetween
          direction='horizontal'
          size='xs'
        >
          <Button
            iconName='refresh'
            ariaLabel='Refresh'
            {...refreshButtonProps}
          />
          {!!dropDownButtonMenuProps.items.length && <ButtonDropdown {...{ ...dropDownButtonMenuProps }}>Actions</ButtonDropdown>}
          <ProtectedUtilityCreate resourceType={ResourceTypes.Surveys}>
            <Button
              variant='primary'
              onClick={() => navigate('/surveys/create')}
            >
              Create Survey
            </Button>
          </ProtectedUtilityCreate>
        </SpaceBetween>
      }
    >
      Surveys {surveys && !isLoading ? `(${surveys.length})` : <Spinner />}
    </Header>
  );

  const surveyTableProps: TableProps<SurveyGroupListItem> = {
    ...collectionProps,
    stickyHeader: true,
    resizableColumns: true,
    onColumnWidthsChange: saveWidths,
    onSelectionChange: ({ detail: { selectedItems } }) => {
      onSelectionChange({ detail: selectedItems });
      if (selectedItems && selectedItems.length > 0) {
        const selectedItem = selectedItems[0];
        setSelectedItems(() => [selectedItem]);
      }
    },
    selectedItems,
    columnDefinitions,
    items,
    // submitEdit: handleSubmit,
    trackBy: 'feature',
    loadingText: 'Loading survey groups',
    columnDisplay: preferences.contentDisplay,
    wrapLines: preferences.wrapLines,
    stripedRows: preferences.stripedRows,
    stickyColumns: preferences.stickyColumns,
    contentDensity: preferences.contentDensity as 'compact' | 'comfortable',
    selectionType: 'single',
    loading: isLoading || !surveys || refreshLoading,
    header,
    filter: (
      <PropertyFilter
        {...propertyFilterProps}
        countText={getTextFilterCounterText(filteredItemsCount)}
        expandToViewport={true}
        filteringAriaLabel={'Filter survey groups'}
        filteringPlaceholder={'Filter survey groups'}
      />
    ),
    pagination: <Pagination {...paginationProps} />,
    preferences: (
      <Preferences
        preferences={preferences}
        setPreferences={setPreferences}
      />
    ),
  };
  return (
    <>
      <Table {...{ ...surveyTableProps }} />
      {error && (
        <Alert
          statusIconAriaLabel='Error'
          type='error'
          header='Error getting data'
        >
          Refresh the page to try again.
        </Alert>
      )}
    </>
  );
}
