import React, { useEffect, useState } from 'react';
import Board, { BoardProps } from '@cloudscape-design/board-components/board';
import Button from '@cloudscape-design/components/button';
import ContentLayout from '@cloudscape-design/components/content-layout';
import SplitPanel from '@cloudscape-design/components/split-panel';
import SpaceBetween from '@cloudscape-design/components/space-between';
import { EmptyState } from './components/empty-state';
import { getBoardWidgets, getPaletteWidgets, exportLayout } from './widgets';
import Palette from './components/palette';
import { ConfigurableWidget } from './components/configurable-widget';
import { boardI18nStrings } from './i18n-strings';
import { useItemsLayout } from './use-items-layout';
import { useSplitPanelControl } from '../../../../utils/hooks/splitPanelContext';
import { useItemContext } from '../dashboardContext';
import { DashboardDataRepresentation, WidgetDataType } from './components/widgets/interfaces';
import Header from '@cloudscape-design/components/header';
import CxDateRangePicker from '../../../../components/cxDataRangePicker';
import { Box, Popover, ButtonProps, DateRangePickerProps } from '@cloudscape-design/components';
import { useNavigate } from 'react-router-dom';
import { ProtectedUtilityUpdate } from '../../../../components/protectedUtility';
import { ResourceTypes } from '../../../../types/rolePermissions';
import { useAppSession } from '../../../../utils/hooks/sessionContext';

/**
 * This allows us to recieve the same props as a provider without throwing errors
 * This has no impact on the running code, but is seamless to an actual provider
 * @param param0
 * @returns
 */
const MockProvider = ({ children }: { widgetId: string; children: React.ReactElement }) => <>{children}</>;

export function App() {
  const navigate = useNavigate();
  const appSession = useAppSession();
  const [resetting, setResetting] = useState<boolean>(false);
  const [ref, layout, handleLayoutChange, actualResetLayout, saveLayout] = useItemsLayout();
  const { item, setItem, changesDetected: itemChangesDetected } = useItemContext();
  const [changesDetected, setChangesDetected] = useState<boolean>(false);

  const editItem = () => navigate(`/visualizations/edit/${item.feature}`);

  const resetLayout = async () => {
    setResetting(true);
    actualResetLayout();
    setChangesDetected(false);
    await new Promise((resolve) => setTimeout(resolve, 1));
    setResetting(false);
  };
  const [isSaving, setIsSaving] = useState(false);
  const { setSplitPanelOpen, setSplitPanelContent, setSplitPanelPreferences } = useSplitPanelControl();

  async function handleSaveLayout() {
    setIsSaving(true);
    await saveLayout();
    setIsSaving(false);
    setChangesDetected(false);
  }

  function handleDateChange(value: DateRangePickerProps.Value | null) {
    if (value === null || !item?.data) {
      return;
    }

    setItem(
      (prevItem: DashboardDataRepresentation): DashboardDataRepresentation => ({
        ...prevItem,
        data: {
          ...prevItem.data,
          period: value,
        },
      }),
    );
    setChangesDetected(true);
  }

  function handleAddNewItem() {
    setSplitPanelContent(
      <SplitPanel
        header='Add widgets'
        closeBehavior='hide'
        hidePreferencesButton={true}
      >
        <Palette items={getPaletteWidgets(layout)} />
      </SplitPanel>,
    );
    setSplitPanelOpen(true);
    setSplitPanelPreferences({ position: 'side' });
    setChangesDetected(true);
  }

  function onItemsChange(items: readonly BoardProps.Item<WidgetDataType>[]) {
    handleLayoutChange(exportLayout(items));
    setChangesDetected(true);
  }

  useEffect(() => {
    setSplitPanelContent(
      <SplitPanel
        header='Add widgets'
        closeBehavior='hide'
        hidePreferencesButton={true}
      >
        <Palette items={getPaletteWidgets(layout)} />
      </SplitPanel>,
    );
  }, [layout, setSplitPanelContent]);

  const buttonProps: ButtonProps = {
    iconName: appSession?.fullScreen ? 'shrink' : 'expand',
    onClick: () => appSession?.setFullScreen(!appSession?.fullScreen),
    variant: 'icon',
  };

  return (
    <ContentLayout
      header={
        <>
          <Header
            actions={
              <SpaceBetween
                size='xs'
                direction='horizontal'
              >
                {item?.data?.period?.type === 'relative' && (
                  <Box
                    color='text-status-info'
                    display='inline'
                  >
                    <Popover
                      header='Live data'
                      size='medium'
                      triggerType='text'
                      content={<>When using relative time ranges, data is automatically updated every 30 seconds.</>}
                      renderWithPortal={true}
                    >
                      <Box
                        color='text-status-info'
                        fontSize='body-s'
                        fontWeight='bold'
                      >
                        Live data
                      </Box>
                    </Popover>
                  </Box>
                )}
                {(changesDetected || itemChangesDetected) && <Button onClick={() => resetLayout()}>Reset</Button>}
                {(changesDetected || itemChangesDetected) && (
                  <ProtectedUtilityUpdate resourceType={ResourceTypes.Dashboards}>
                    <Button
                      variant={'primary'}
                      onClick={handleSaveLayout}
                      loading={isSaving}
                    >
                      Save Changes
                    </Button>
                  </ProtectedUtilityUpdate>
                )}
                <CxDateRangePicker
                  setDateRangeValue={handleDateChange}
                  defaults={item?.data?.period}
                />
                <ProtectedUtilityUpdate resourceType={ResourceTypes.Dashboards}>
                  {!appSession?.fullScreen && (
                    <Button
                      iconName='add-plus'
                      onClick={() => handleAddNewItem()}
                    >
                      Add widget
                    </Button>
                  )}
                </ProtectedUtilityUpdate>
                <div>
                  <Button {...buttonProps} />
                </div>
              </SpaceBetween>
            }
          >
            {item?.feature} &nbsp;
            <ProtectedUtilityUpdate resourceType={ResourceTypes.Dashboards}>
              {!appSession?.fullScreen && (
                <Button
                  iconName='edit'
                  onClick={() => editItem()}
                />
              )}
            </ProtectedUtilityUpdate>
          </Header>
        </>
      }
    >
      <div ref={ref}>
        {!resetting && (
          <Board
            empty={
              <EmptyState
                title='No widgets'
                description='There are no widgets on the dashboard.'
                verticalCenter={true}
                action={
                  <SpaceBetween
                    direction='horizontal'
                    size='xs'
                  >
                    {(changesDetected || itemChangesDetected) && <Button onClick={() => resetLayout()}>Reset to last saved</Button>}
                    <ProtectedUtilityUpdate resourceType={ResourceTypes.Dashboards}>
                      <Button
                        iconName='add-plus'
                        onClick={() => handleAddNewItem()}
                      >
                        Add widget
                      </Button>
                    </ProtectedUtilityUpdate>
                  </SpaceBetween>
                }
              />
            }
            i18nStrings={boardI18nStrings}
            items={getBoardWidgets(layout, item)}
            onItemsChange={({ detail: { items } }) => {
              onItemsChange(items);
            }}
            renderItem={(item, actions) => {
              const Wrapper = item.data?.provider ?? MockProvider;
              return (
                <Wrapper widgetId={item.id}>
                  <ConfigurableWidget
                    config={item?.data}
                    id={item.id}
                    onRemove={actions?.removeItem}
                  />
                </Wrapper>
              );
            }}
          />
        )}
      </div>
    </ContentLayout>
  );
}
