import React from 'react';
import {
  Box,
  Button,
  ColumnLayout,
  Container,
  Header,
  Popover,
  SpaceBetween,
  Spinner,
  StatusIndicator,
} from '@cloudscape-design/components';

import { InstanceDataProps } from '../types';
import { ValueWithLabel } from '../../../components/valueWithLabel';

export default function InstanceData({ data, isLoading }: InstanceDataProps) {
  return (
    <Container header={<Header headingTagOverride="h3">Instance Details {isLoading && <Spinner />}</Header>}>
      <ColumnLayout columns={2} variant="text-grid">
        <SpaceBetween size="l">
          <ValueWithLabel label="Instance ARN">
            <div className="custom-wrapping">
              <Box margin={{ right: 'xxs' }} display="inline-block">
                <Popover
                  size="small"
                  position="top"
                  dismissButton={false}
                  triggerType="custom"
                  content={<StatusIndicator type="success">Instance ARN copied</StatusIndicator>}
                >
                  <Button
                    variant="inline-icon"
                    iconName="copy"
                    ariaLabel="Copy ARN"
                    onClick={() => {
                      navigator.clipboard.writeText(data?.arn);
                    }}
                  />
                </Popover>
              </Box>
              {data?.arn}
            </div>
          </ValueWithLabel>
        </SpaceBetween>
      </ColumnLayout>
    </Container>
  );
}
