import React from 'react';
import AppLayout, {
  AppLayoutProps,
} from '@cloudscape-design/components/app-layout';
import PoweredBy from '../../components/poweredBy';
import { useSplitPanelControl } from '../../utils/hooks/splitPanelContext';
import './styles.css';
import { TopBarNavigation } from './topBarNavigation/';

export interface ShellProps {
  breadcrumbs?: AppLayoutProps['breadcrumbs'];
  contentType?: Extract<
    AppLayoutProps.ContentType,
    'default' | 'table' | 'form'
  >;
  tools?: AppLayoutProps['tools'];
  children?: AppLayoutProps['content'];
  navigation?: AppLayoutProps['navigation'];
  notifications?: AppLayoutProps['notifications'];
}

export default function Shell({
  children,
  contentType,
  breadcrumbs,
  tools,
  navigation,
  notifications,
}: ShellProps) {
  const {
    splitPanelSize,
    splitPanelOpen,
    splitPanelPreferences,
    onSplitPanelResize,
    onSplitPanelToggle,
    onSplitPanelPreferences,
    splitPanelContent,
  } = useSplitPanelControl();

  return (
    <>
      <div id='top-nav'>
        <TopBarNavigation />
      </div>
      <div id='app-content'>
        <AppLayout
          contentType={contentType}
          navigation={navigation}
          breadcrumbs={breadcrumbs}
          notifications={notifications}
          stickyNotifications={true}
          tools={tools}
          toolsWidth={400}
          content={children}
          footerSelector='#footer'
          headerSelector='#top-nav'
          splitPanelOpen={splitPanelOpen}
          onSplitPanelToggle={onSplitPanelToggle}
          splitPanelSize={splitPanelSize}
          onSplitPanelResize={onSplitPanelResize}
          splitPanelPreferences={splitPanelPreferences as any}
          onSplitPanelPreferencesChange={onSplitPanelPreferences}
          splitPanel={splitPanelContent}
          maxContentWidth={Number.MAX_VALUE}
          ariaLabels={{
            navigation: 'Navigation drawer',
            navigationClose: 'Close navigation drawer',
            navigationToggle: 'Open navigation drawer',
            notifications: 'Notifications',
            tools: 'Help panel',
            toolsClose: 'Close help panel',
            toolsToggle: 'Open help panel',
          }}
        />
      </div>
      <div id='footer'>
        <PoweredBy />
      </div>
    </>
  );
}
