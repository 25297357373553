// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import React, { createContext, useContext, useState } from 'react';
import { Button, Container, Header } from '@cloudscape-design/components';
import { QuicksightEmbeddedApiResponse, WidgetConfig, WidgetDataType, WidgetHeaderProps } from '../interfaces';
import styles from './styles.module.scss';
import { WidgetPreferences } from './preferences';
import { WidgetComponentProviderProps } from '../../../../../types';
import { useItemContext } from '../../../../dashboardContext';
import useIntuitionSWR from '../../../../../../../utils/hooks/useIntuitionSWR';
import { uuidv4Regex } from '../../../../../../../utils/commonRegex';
import { EmptyState } from '../../empty-state';
import { FetcherError } from '../../../../../../../utils/fetcherError';

export const getQuicksight: WidgetConfig = {
  definition: { defaultRowSpan: 4, defaultColumnSpan: 4, minRowSpan: 3, minColumnSpan: 4 },
  data: {
    icon: 'mixedContent',
    title: 'Quicksight Dashboards',
    description: 'Embed Quicksight Dashboards',
    header: QuicksightDashboardHeader,
    content: QuicksightDashboard,
    provider: QuicksightDashboardProvider,
    staticMinHeight: 560,
    multipleAllowed: true,
  },
};

interface QuicksightDashboardContextType {
  visibleContent: ReadonlyArray<any>;
  openPreferences: () => void;
}

const QuicksightDashboardContext = createContext<QuicksightDashboardContextType>({
  visibleContent: [],
  openPreferences: () => null,
});

function QuicksightDashboardProvider(props: WidgetComponentProviderProps) {
  const { widgetId, children } = props;
  const [preferencesVisible, setPreferencesVisible] = useState(false);
  const [visibleContent, setVisibleContent] = useState<ReadonlyArray<any>>([]);
  return (
    <QuicksightDashboardContext.Provider
      value={{
        visibleContent,
        openPreferences: () => setPreferencesVisible(true),
      }}
    >
      {React.cloneElement(React.Children.only(children), {
        removeConfirmationText: 'Operational metrics',
        actions: [{ text: 'Preferences', onClick: () => setPreferencesVisible(true) }],
      })}
      {preferencesVisible && (
        <WidgetPreferences
          widgetId={widgetId}
          preferences={visibleContent}
          onConfirm={(visibleContent) => {
            setVisibleContent(visibleContent);
            setPreferencesVisible(false);
          }}
          onDismiss={() => setPreferencesVisible(false)}
        />
      )}
    </QuicksightDashboardContext.Provider>
  );
}

function QuicksightDashboardHeader({ widgetId }: WidgetHeaderProps) {
  const { item } = useItemContext();
  const widget = item.data.widgets.find((widget: { id: string }) => widget.id === widgetId);
  const defaultHeader = 'Quicksight Dashboard';
  const header = widget?.preferences?.header || defaultHeader;
  return (
    <Header
      variant='h2'
      description={header === defaultHeader ? '' : defaultHeader}
    >
      {header}
    </Header>
  );
}

export function GetQuicksight({ config, id }: { config: WidgetDataType; id: any }) {
  const Wrapper = config.provider ?? React.Fragment;
  const Content = config.content;
  return (
    <div
      className={styles.staticWidget}
      style={{ minHeight: config.staticMinHeight }}
    >
      <Wrapper widgetId={id}>
        <Container
          header={<config.header widgetId={id}/>}
          fitHeight={true}
          footer={config.footer && <config.footer />}
          disableContentPaddings={config.disableContentPaddings}
        >
          <Content widgetId={id} />
        </Container>
      </Wrapper>
    </div>
  );
}

function renderErrorPage(header: string, message: string) {
  const { openPreferences } = useContext(QuicksightDashboardContext);
  return (
    <EmptyState
      title={header}
      description={message}
      verticalCenter={true}
      action={<Button onClick={openPreferences}>Open preferences</Button>}
    />
  );
}

export default function QuicksightDashboard({ widgetId }: { widgetId: string }) {
  const { item } = useItemContext();
  const widget = item.data.widgets.find((widget: { id: string }) => widget.id === widgetId);
  const dashboardId = widget?.preferences?.dashboardId || '';

  if (!dashboardId) {
    return renderErrorPage('No dashboard to display', 'Open widget preferences to set which Quicksight Dashboard to be display.');
  }

  const uuidv4Test = uuidv4Regex.test(dashboardId);
  if (!uuidv4Test) {
    return renderErrorPage('Incorrect Dashboard ID entered', 'Please Enter a Valid Quicksight Dashboard ID');
  }

  const { data, isLoading, error } = useIntuitionSWR<QuicksightEmbeddedApiResponse>(`/quicksight/get-url/${dashboardId}`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 180000,
  });

  const url = data?.dashboard.Status === 200 ? data.dashboard.EmbedUrl : '';

  if (error) {
    const { status, info } = error as FetcherError;
    const { message } = info;
    console.error('error', { status, info });
    return renderErrorPage('Loading Dashboard', `${message}`);
  }
  const message = isLoading ? 'Fetching Quicksight Dashboard' : '';

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {url ? (
        <iframe
          src={url}
          title='Embedded URL'
          className={styles.quicksightFrame}
        />
      ) : (
        renderErrorPage('Loading Dashboard', message)
      )}
    </div>
  );
}
