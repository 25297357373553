import React from 'react';
import {
  Table,
  Input,
  Header,
  SpaceBetween,
  Container,
} from '@cloudscape-design/components';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faWandSparkles } from '@fortawesome/free-solid-svg-icons';
import ButtonDropdown from '@cloudscape-design/components/button-dropdown';
import pollyLanguages from '../../../../lib/pollyLanguages';
import fetcher from '../../../../utils/fetcherInstance';
import { getSelectedInstance } from '../../../../components/localStorage';

type Paragraph = {
  type: 'paragraph';
  children: {
    text: string;
  }[];
};

type SingleMessageData = {
  lang: string;
  message: Paragraph[];
};

type MessageEntry = {
  message: SingleMessageData[];
  active: null | boolean;
  key: string;
};

type Attribute = {
  value: string;
  key: string;
};

type ItemObject = {
  description: string;
  data: {
    messages: MessageEntry[];
    attributes: Attribute[];
  };
  feature: string;
};

// Updated props for TranslationsTable component
type TranslationsTableProps = {
  messageKey: string;
  item: ItemObject;
  setItem: (newItem: ItemObject) => void;
  messagesPath?: string;
};

const TranslationsTable: React.FC<TranslationsTableProps> = ({
  messageKey,
  item,
  setItem,
  messagesPath = 'data.messages',
}) => {
  if (!messageKey) {
    return null;
  }

  // Use lodash's `_.get` to fetch the messages array
  const messagesArray = _.get(item, messagesPath, []);

  // Use the key to find the target message within that array
  const targetMessage = _.find(messagesArray, { key: messageKey }) as
    | MessageEntry
    | undefined;

  const availableLanguages = pollyLanguages.filter(
    (lang) =>
      !targetMessage?.message.some(
        (existingLang) => existingLang.lang === lang.id
      )
  );

  const translations =
    targetMessage?.message.map((langMessage: SingleMessageData) => ({
      lang: langMessage?.lang,
      text: langMessage?.message[0]?.children[0]?.text || '',
    })) || [];

  function updateMessagesArray(
    messagesArray: MessageEntry[],
    messagesPath: string
  ) {
    const updatedItem = _.cloneDeep(item);
    _.set(updatedItem, messagesPath, messagesArray);
    setItem(updatedItem);
  }

  function addLang(detail: { id: string }) {
    const langToAdd = availableLanguages.find((lang) => lang.id === detail.id);
    if (!langToAdd) return;

    const updatedMessagesArray: MessageEntry[] = _.cloneDeep(messagesArray);

    const targetIndex = updatedMessagesArray.findIndex(
      (msg) => msg.key === messageKey
    );
    if (targetIndex > -1) {
      const newMessageData: SingleMessageData = {
        lang: langToAdd.id,
        message: [{ type: 'paragraph', children: [{ text: '' }] }],
      };
      updatedMessagesArray[targetIndex].message.push(newMessageData);
      updateMessagesArray(updatedMessagesArray, messagesPath);
    }
  }

  function removeLang(event: any, itemToRemove: any) {
    const updatedMessagesArray: MessageEntry[] = _.cloneDeep(messagesArray);
    const targetIndex = updatedMessagesArray.findIndex(
      (msg) => msg.key === messageKey
    );
    if (targetIndex === -1) return;

    const langIndex = updatedMessagesArray[targetIndex].message.findIndex(
      (langMessage) => langMessage.lang === itemToRemove.lang
    );
    if (langIndex > -1) {
      updatedMessagesArray[targetIndex].message.splice(langIndex, 1);
      updateMessagesArray(updatedMessagesArray, messagesPath);
    }
  }

  function handleEdit(
    translation: {
      lang: string;
      text: string;
    },
    newValue: string
  ) {
    const updatedMessagesArray: MessageEntry[] = _.cloneDeep(messagesArray);
    const targetIndex = updatedMessagesArray.findIndex(
      (msg) => msg.key === messageKey
    );
    if (targetIndex > -1) {
      const langIndex = updatedMessagesArray[targetIndex].message.findIndex(
        (langMessage) => langMessage.lang === translation.lang
      );
      if (langIndex > -1) {
        updatedMessagesArray[targetIndex].message[
          langIndex
        ].message[0].children[0].text = newValue;
        updateMessagesArray(updatedMessagesArray, messagesPath);
      }
    }
  }

  async function handleTranslate(event: any, itemToTranslate: any) {
    const defaultMessage = translations.find(
      (translation) => translation.lang === 'default'
    )?.text;
    if (!defaultMessage) return;

    try {
      const payload = {
        message: defaultMessage,
        lang: itemToTranslate.lang,
      };

      const storedInstanceSelected = getSelectedInstance();

      if (storedInstanceSelected) {
        const instanceArn = storedInstanceSelected.instanceArn;
        const translatedText = await fetcher(
          `/translateMessage`,
          'POST',
          payload,
          instanceArn
        );
        const updatedMessagesArray: MessageEntry[] = _.cloneDeep(messagesArray);
        const targetIndex = updatedMessagesArray.findIndex(
          (msg) => msg.key === messageKey
        );
        if (targetIndex > -1) {
          const langIndex = updatedMessagesArray[targetIndex].message.findIndex(
            (langMessage) => langMessage.lang === itemToTranslate.lang
          );
          if (langIndex > -1) {
            updatedMessagesArray[targetIndex].message[
              langIndex
            ].message[0].children[0].text = translatedText.data;
            updateMessagesArray(updatedMessagesArray, messagesPath);
          }
        }
      }
    } catch (error) {
      console.error('Translation failed:', error);
    }
  }

  return (
    <Container
      header={
        <Header
          variant='h2'
          actions={
            <SpaceBetween direction='horizontal' size='xs'>
              <ButtonDropdown
                items={availableLanguages}
                onItemClick={(event) => addLang({ id: event.detail.id })}
              >
                Add new language
              </ButtonDropdown>
            </SpaceBetween>
          }
        >
          Messages
        </Header>
      }
    >
      <Table
        wrapLines
        columnDefinitions={[
          {
            id: 'lang',
            header: 'Language',
            cell: (translation) => translation.lang || '-',
            width: 50,
            minWidth: 50,
          },
          {
            id: 'text',
            header: 'Text',
            cell: (translation) => translation.text || '-',
            editConfig: {
              ariaLabel: 'message',
              editIconAriaLabel: 'editable',
              errorIconAriaLabel: 'Name Error',
              editingCell: (translation, cellContext) => {
                const currentMessagePath = targetMessage?.message.find(
                  (m) => m.lang === translation.lang
                )?.message[0]?.children[0]?.text;
                return (
                  <Input
                    autoFocus={true}
                    value={
                      cellContext.currentValue !== undefined
                        ? cellContext.currentValue
                        : currentMessagePath
                    }
                    onChange={(event) => {
                      cellContext.setValue(event.detail.value);
                    }}
                  />
                );
              },
            },
          },
          {
            id: 'translate',
            header: 'Translate',
            cell: (translation) => {
              if (translation.lang !== 'default') {
                return (
                  <FontAwesomeIcon
                    icon={faWandSparkles}
                    style={{ color: '#008cff', cursor: 'pointer' }}
                    onClick={(event) => handleTranslate(event, translation)}
                  />
                );
              } else {
                return '';
              }
            },
            width: 50,
            minWidth: 50,
          },
          {
            id: 'remove',
            header: 'Remove',
            cell: (translation) => {
              if (translation.lang !== 'default') {
                return (
                  <FontAwesomeIcon
                    icon={faTrash}
                    style={{ color: '#ff0000', cursor: 'pointer' }}
                    onClick={(event) => removeLang(event, translation)}
                  />
                );
              } else {
                return '';
              }
            },
            width: 50,
            minWidth: 50,
          },
        ]}
        items={translations}
        submitEdit={(item, column, newValue) => {
          if (column.id === 'text' && typeof newValue === 'string') {
            handleEdit(item, newValue as string);
          }
        }}
      />
    </Container>
  );
};

export default TranslationsTable;
