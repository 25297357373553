import React from 'react';
import { Table, Spinner, Header } from '@cloudscape-design/components';

import { InstancesTableConfig } from './table.config';
import Button from '@cloudscape-design/components/button';
import { useNavigate } from 'react-router-dom';
import SpaceBetween from '@cloudscape-design/components/space-between';
import { InstanceType } from '../../../types/instance';

export interface InstancesTableProps {
  isLoading: boolean;
  instances: InstanceType[];
  error: any;
  onRefresh: () => void;
}


export const InstancesTable: React.FC<InstancesTableProps> = ({
  isLoading,
  instances = [],
  onRefresh,
}) => {
  const navigate = useNavigate();
  const refreshButtonProps = { onClick: onRefresh };


  const handleCreateInstanceClick = () => {
    navigate('/instances/create', { state: { instances } });
  };

  return (
    <Table
      items={instances}
      loading={isLoading}
      loadingText="Loading instances"
      header={
        <Header
          variant="awsui-h1-sticky"
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button iconName="refresh" ariaLabel="Refresh" {...refreshButtonProps} />
              <Button variant="primary" onClick={handleCreateInstanceClick}>
                Create Instance
              </Button>
            </SpaceBetween>
          }
        >
          Instances {instances && !isLoading ? `(${instances.length})` : <Spinner />}
        </Header>
      }
      columnDefinitions={InstancesTableConfig}
      trackBy="instanceId"
    />
  );
};
