import React, { createContext, useState } from 'react';
import { WidgetPreferences } from './preferences';

interface SingleMetricWidgetContextType {
  openPreferences: () => void;
}

export const SingleMetricWidgetContext = createContext<SingleMetricWidgetContextType>({
  openPreferences: () => null,
});

export const provider = ({ children, widgetId }: { children: React.ReactElement; widgetId: string }) => {
  const [preferencesVisible, setPreferencesVisible] = useState(false);
  return (
    <SingleMetricWidgetContext.Provider
      value={{
        openPreferences: () => setPreferencesVisible(true),
      }}
    >
      {React.cloneElement(React.Children.only(children), {
        removeConfirmationText: 'Operational metrics',
        actions: [{ text: 'Preferences', onClick: () => setPreferencesVisible(true) }],
      })}
      {preferencesVisible && (
        <WidgetPreferences
          widgetId={widgetId}
          onDismiss={() => setPreferencesVisible(false)}
        />
      )}
    </SingleMetricWidgetContext.Provider>
  );
};
