import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useInstanceSWR from '../../../utils/hooks/useInstanceSWR';
import { useApiWithFlash } from '../../../utils/hooks/useApiWithFlashInstance';
import CopyFormLayout, { ConfigStructure } from '../../../components/copyForm/copyFormLayout';
import { Alert, ContentLayout, Header } from '@cloudscape-design/components';
import { DashboardDataRepresentation } from '../view/configurable-dashboard/components/widgets/interfaces';

export default function VisualizationsCopy() {
  const [item, setItem] = useState<DashboardDataRepresentation>({} as DashboardDataRepresentation);
  const hasMigration = false;
  const navigate = useNavigate();
  const { featureId } = useParams();
  const { handleApiWithFlash } = useApiWithFlash();

  const { data, isLoading, error } = useInstanceSWR<DashboardDataRepresentation>(`/intuition/dashboards/${featureId}`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 0,
  });

  useEffect(() => {
    if (data?.data) {
      setItem(data.data);
    }
  }, [data, setItem]);

  const validation = (feature: string, isMigrating: boolean) => {
    const generalPattern = /^[a-zA-Z0-9+_-]+$/;
    if (!isMigrating && feature === data?.data?.feature) {
      return 'Try a different dashboard group name';
    } else if (!generalPattern.test(feature)) {
      return 'Only alphanumeric, - _ and + supported';
    }
  };

  const handleCancel = () => {
    navigate('/visualizations');
  };

  async function handleFormSubmit(dashboardItem: ConfigStructure<DashboardDataRepresentation["data"]>) {
    try {

      const {feature, data, ...payload} = dashboardItem;

      await handleApiWithFlash(`/intuition/dashboards/${feature}`, 'POST', {
        errorMessage: `Error copying Dashboard ${feature}`,
        data: payload,
        successMessage: ``,
      });

      await handleApiWithFlash(`/intuition/dashboards/${feature}`, 'PUT', {
        successMessage: `Successfully Copied Dashboard ${feature}`,
        errorMessage: `Error copying Dashboard ${feature}`,
        data: { ...payload, data },
      });

      navigate('/visualizations');
    } catch (error) {
      console.error('Error handling form submit:', error);
    }
  }

  if (error) {
    return (
      <ContentLayout header={<Header variant='h1'>Error</Header>}>
        <Alert
          statusIconAriaLabel='Error'
          type='error'
          header='Unable to load component'
        >
          {error.status}
        </Alert>
      </ContentLayout>
    );
  }

  console.log({ item });

  return (
    <CopyFormLayout
      item={item}
      isLoading={isLoading}
      hasMigration={hasMigration}
      validation={validation}
      handleFormSubmit={handleFormSubmit}
      handleCancel={handleCancel}
    />
  );
}
