import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import fetcher from '../../utils/fetcher';
import Header from '@cloudscape-design/components/header';
import SpaceBetween from '@cloudscape-design/components/space-between';
import { Box, Button, ContentLayout, Popover, Spinner, StatusIndicator } from '@cloudscape-design/components';
import Alert from '@cloudscape-design/components/alert';
import TenantData from './components/tenantData';
import SimplifySteps from './components/simplifySteps';
import InstanceData from './components/instanceData';
import useInstanceSWR from '../../utils/hooks/useInstanceSWR';

export default function Settings() {
  const [instanceData, setInstanceData] = useState<any | undefined>(undefined);
  const {
    data: instanceResponse,
    isLoading: instanceLoading,
    error: instanceError,
  } = useInstanceSWR(`/instance`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 0,
  });

  useEffect(() => {
    if (instanceResponse && instanceResponse.data) {
      setInstanceData(instanceResponse.data);
    }
  }, [instanceResponse]);

  const { data, isLoading, error } = useSWR('/tenantProfile', fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 0,
  });
  const lambdaArn = instanceData?.lambdaArn;

  if (data && data.data) {
    data.data = data.data || {};
    data.data.lambdaArn = lambdaArn;
  }
  const { memberType } = data?.data || {};


  return (
    <ContentLayout
      header={
        <Header variant="h1" description="Your tenant settings and quick start guides">
          Settings
        </Header>
      }
    >
      <SpaceBetween size="m">
        <TenantData data={data?.data} isLoading={isLoading && instanceLoading} error={error} />
        {data?.externalId && (
          <Alert statusIconAriaLabel="Info" header="Cross Account Role ExternalId">
            <div className="custom-wrapping">
              <Box margin={{ right: 'xxs' }} display="inline-block">
                <Popover
                  size="small"
                  position="top"
                  dismissButton={false}
                  triggerType="custom"
                  content={<StatusIndicator type="success">ARN ExternalId</StatusIndicator>}
                >
                  <Button
                    variant="inline-icon"
                    iconName="copy"
                    ariaLabel="Copy ExternalId"
                    onClick={() => {
                      navigator.clipboard.writeText(data?.ExternalId);
                    }}
                  />
                </Popover>
              </Box>
            </div>
            Keep this secure it adds an extra layer of protection for AWS Cross Account roles.
          </Alert>
        )}
        {memberType?.includes('simplify') ? (
          lambdaArn?.includes('arn:aws:lambda') ? (
            <SimplifySteps apiArn={lambdaArn} />
          ) : (
            <Alert statusIconAriaLabel="Info" header={`Simplify access`}>
              <Spinner />
              Your tenant services are being provisioned, please check back in a few minutes.
            </Alert>
          )
        ) : null}

        <InstanceData data={instanceData} isLoading={instanceLoading} error={instanceError} />
      </SpaceBetween>
    </ContentLayout>
  );
}
