import { ProtectedRouteProps } from './types';
import { ProtectedRoute } from './protectedRoute';
import { Actions } from '../../types/rolePermissions';

/**
 * React component to protect a route component
 * @param props
 * @returns JSX.Element
 */
export const ProtectedRouteUpdate = (props: Omit<ProtectedRouteProps, 'resourceAction'>) => {
  return (
    <ProtectedRoute resourceType={props.resourceType} resourceAction={Actions.Update}>
      {props.children}
    </ProtectedRoute>
  );
};
