import { Spinner } from '@cloudscape-design/components';
import { useNavigate, useParams } from 'react-router-dom';
import { useApiWithFlash } from '../../../utils/hooks/useApiWithFlashInstance';
import Alert from '@cloudscape-design/components/alert';
import { DialPlanItem } from '../types';
import useInstanceSWR from '../../../utils/hooks/useInstanceSWR';
import EditDialPlanForm from '../form';

export default function DialPlanEdit() {

  const navigate = useNavigate();
  const { featureId } = useParams();
  const { handleApiWithFlash } = useApiWithFlash();

  const { data, isLoading, error } = useInstanceSWR<DialPlanItem>(`/dial-plans/${featureId}`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 0,
  });

  if (error) {
    return (
      <Alert
        statusIconAriaLabel='Error'
        type='error'
        header='Unable to load component'
      >
        {error.status}
      </Alert>
    );
  }

  if (isLoading || !data?.data) {
    return (<Spinner />)
  }

  const dialPlan: DialPlanItem = {
    feature: data.data.feature,
    description: data.data.description,
    tag: data.data.tag,
    data: data.data.data,
  } 

  async function onSubmitForm(updatedDialPlan: DialPlanItem) {

    const response = await handleApiWithFlash(`/dial-plans/${updatedDialPlan.feature}`, 'PUT', {
      successMessage: `Successfully updated dial plan ${updatedDialPlan.feature}`,
      errorMessage: `Error updating dial plan ${updatedDialPlan.feature}`,
      data: updatedDialPlan,
      mutateKey: `/dial-plans/${updatedDialPlan.feature}`,
    });

    if (response?.success) {
      navigate('/dial-plan');
    }
  }

  return (
    <EditDialPlanForm { ...{ onSubmitForm, dialPlan } } />
  );
}
