/* eslint-disable react/prop-types */
import React from 'react';
import dayjs from 'dayjs';
import { Box, ColumnLayout, Container, Header, SpaceBetween } from '@cloudscape-design/components';
import SentimentLineChart from './sentimentLineChart';
import { Link } from 'react-router-dom';
import { ContactRecord } from '../../../../types/contactRecord';

export interface ValueWithLabelProps {
  label: string;
  children: React.ReactNode;
}

function ValueWithLabel(props: ValueWithLabelProps) {
  const { label, children } = props;
  return (
    <div>
      <Box variant='awsui-key-label'>{label}</Box>
      <div>{children}</div>
    </div>
  );
}

export interface ContactSummaryProps {
  contactObject: ContactRecord;
}

export default function ContactSummary(props: ContactSummaryProps) {
  const { contactObject } = props;
  return (
    <Container header={<Header headingTagOverride='h3'>Contact Summary</Header>}>
      <ColumnLayout
        columns={3}
        variant='text-grid'
      >
        <SpaceBetween size='xs'>
          <ValueWithLabel label='Contact id'>{contactObject.contactId}</ValueWithLabel>
          <ValueWithLabel label='Channel'>{contactObject.channel}</ValueWithLabel>
          <ValueWithLabel label='Initiation method'>{contactObject.initiationMethod}</ValueWithLabel>
          <ValueWithLabel label='Disconnected Reason'>{contactObject.disconnectReason}</ValueWithLabel>
          {contactObject.previousContactId && (
            <ValueWithLabel label='Previous contact id'>
              <span className='text-info underline'>
                <a
                  href={`/contactSearch/${contactObject.previousContactId}`}
                  target='_blank'
                >
                  {contactObject.previousContactId}
                </a>
              </span>
            </ValueWithLabel>
          )}
          {contactObject.nextContactId && (
            <ValueWithLabel label='Next contact id'>
              <span className='text-info underline'>
                {/* <Link target="_blank" rel="noopener noreferrer" to={`/contact-search/view/${contactObject.nextContactId}`}> */}
                <Link
                  rel='noopener noreferrer'
                  to={`/contact-search/view/${contactObject.nextContactId}`}
                >
                  {contactObject.nextContactId}
                </Link>
              </span>
            </ValueWithLabel>
          )}
          {contactObject.relatedContactId && (
            <ValueWithLabel label='Previous contact id'>
              <span className='text-info underline'>
                <a
                  href={`/contactSearch/${contactObject.relatedContactId}`}
                  target='_blank'
                >
                  {contactObject.relatedContactId}
                </a>
              </span>
            </ValueWithLabel>
          )}
        </SpaceBetween>
        <SpaceBetween size='xs'>
          <ValueWithLabel label='Connected to system'>{dayjs(contactObject.connectedToSystemTimestamp).format('YYYY-MM-DD HH:mm')}</ValueWithLabel>
          <ValueWithLabel label='Disconnected'>{dayjs(contactObject.disconnectTimestamp).format('YYYY-MM-DD HH:mm')}</ValueWithLabel>
        </SpaceBetween>
        <SpaceBetween size='xs'>
          {contactObject?.conversationCharacteristics?.Sentiment?.SentimentByPeriod && (
            <SentimentLineChart SentimentByPeriod={contactObject.conversationCharacteristics.Sentiment.SentimentByPeriod} />
          )}
        </SpaceBetween>
      </ColumnLayout>
    </Container>
  );
}
