import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  ColumnLayout,
  Container,
  Header,
  Popover,
  SpaceBetween,
  Spinner,
  StatusIndicator,
} from '@cloudscape-design/components';
import dayjs from 'dayjs';
import { TenantDataProps } from '../types';
import { ValueWithLabel } from '../../../components/valueWithLabel';
import { getSelectedInstance } from '../../../components/localStorage';


export default function TenantData({ data, isLoading }: TenantDataProps) {
  const [instanceId, setInstanceId] = useState<string | undefined>(undefined);
  useEffect(() => {

    const storedInstance = getSelectedInstance();

    if (storedInstance) {
      const instanceArn = storedInstance.instanceArn;
      const id = instanceArn.split('/').pop();
          setInstanceId(id);
    }else {
      console.error('Instance object does not have expected properties:', storedInstance);
    }
  }, []);


  return (
    <Container header={<Header headingTagOverride="h3">Configuration Details {isLoading && <Spinner />}</Header>}>
      <ColumnLayout columns={3} variant="text-grid">
        <SpaceBetween size="l">
          <ValueWithLabel label="Account Id">{data?.accountId}</ValueWithLabel>
          <ValueWithLabel label="Member Since">
            {data?.createdAt && <StatusIndicator>{dayjs(data?.createdAt).format('DD MMM YYYY')}</StatusIndicator>}
          </ValueWithLabel>
          <ValueWithLabel label="Member Type">{data?.memberType}</ValueWithLabel>
        </SpaceBetween>
        <SpaceBetween size="l">
          <ValueWithLabel label="Cross Account Role">{data?.crossAccountRoleName}</ValueWithLabel>
          <ValueWithLabel label="Connect Instance Id">{instanceId}</ValueWithLabel>
        </SpaceBetween>
        <SpaceBetween size="l">
          {data?.lambdaArn && (
            <ValueWithLabel label="Lambda ARN">
              <div className="custom-wrapping">
                <Box margin={{ right: 'xxs' }} display="inline-block">
                  <Popover
                    size="small"
                    position="top"
                    dismissButton={false}
                    triggerType="custom"
                    content={<StatusIndicator type="success">ARN copied</StatusIndicator>}
                  >
                    <Button
                      variant="inline-icon"
                      iconName="copy"
                      ariaLabel="Copy ARN"
                      onClick={() => {
                        navigator.clipboard.writeText(data?.lambdaArn);
                      }}
                    />
                  </Popover>
                </Box>
                {data?.lambdaArn}
              </div>
            </ValueWithLabel>
          )}
          {data?.s3AccessPointAlias && (
            <ValueWithLabel label="S3 Alias">
              <div className="custom-wrapping">
                <Box margin={{ right: 'xxs' }} display="inline-block">
                  <Popover
                    size="small"
                    position="top"
                    dismissButton={false}
                    triggerType="custom"
                    content={<StatusIndicator type="success">ARN copied</StatusIndicator>}
                  >
                    <Button
                      variant="inline-icon"
                      iconName="copy"
                      ariaLabel="Copy ARN"
                      onClick={() => {
                        navigator.clipboard.writeText(data?.s3AccessPointAlias);
                      }}
                    />
                  </Popover>
                </Box>
                {data?.s3AccessPointAlias}
              </div>
            </ValueWithLabel>
          )}
        </SpaceBetween>
      </ColumnLayout>
    </Container>
  );
}
