export const TagIcon = () => (
  <i
    style={{
      position: 'relative',
      margin: 'auto 0.5em',
      height: '1em',
      width: '1em',
      lineHeight: '20px',
      display: 'inline-block',
      transform: 'translateY(0.2rem)',
      background:
        'url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNDAiIGhlaWdodD0iMjQwIiB2aWV3Qm94PSIwIDAgMjQgMjQiIGZpbGw9Im5vbmUiPgogICAgPHBhdGggIGlkPSJTVkdJRF8xXyIgZD0iTTIxLjQxIDExLjU4TDEyLjQxIDIuNThDMTIuMDUgMi4yMiAxMS41NSAyIDExIDJINEMyLjkgMiAyIDIuOSAyIDRWMTFDMiAxMS41NSAyLjIyIDEyLjA1IDIuNTkgMTIuNDJMMTEuNTkgMjEuNDJDMTEuOTUgMjEuNzggMTIuNDUgMjIgMTMgMjJDMTMuNTUgMjIgMTQuMDUgMjEuNzggMTQuNDEgMjEuNDFMMjEuNDEgMTQuNDFDMjEuNzggMTQuMDUgMjIgMTMuNTUgMjIgMTNDMjIgMTIuNDUgMjEuNzcgMTEuOTQgMjEuNDEgMTEuNThaTTUuNSA3QzQuNjcgNyA0IDYuMzMgNCA1LjVDNCA0LjY3IDQuNjcgNCA1LjUgNEM2LjMzIDQgNyA0LjY3IDcgNS41QzcgNi4zMyA2LjMzIDcgNS41IDdaIiBmaWxsPSIjMDAwIi8+Cjwvc3ZnPg==") center center / contain no-repeat',
    }}
  />
);
