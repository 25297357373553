import React, { useState } from 'react';
import useInstanceSWR from '../../../utils/hooks/useInstanceSWR';
import { Box, SpaceBetween, SplitPanel, Tabs, Table, StatusIndicator, Modal, Button, Spinner } from '@cloudscape-design/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLanguage } from '@fortawesome/free-solid-svg-icons';
import { Message, AuditSplitPanelDetailsProps, Attribute } from '../types';

const TranslationsTable: React.FC<{
  message: Message | null;
}> = ({ message }) => {
  if (!message) return <p>No translations available.</p>;

  return (
    <Table
      wrapLines
      columnDefinitions={[
        {
          id: 'lang',
          header: 'Language',
          cell: (translation) => translation.lang || '-',
          width: 100,
          minWidth: 90,
          isRowHeader: true,
        },
        {
          id: 'text',
          header: 'Translation',
          cell: (translation) => translation.message[0]?.children[0]?.text || '-',
          width: 300,
          minWidth: 290,
        },
      ]}
      items={message.message}
      loadingText='Loading translations...'
    />
  );
};

const MessagesSplitPanelDetails: React.FC<AuditSplitPanelDetailsProps> = ({ selectedItems }) => {
  const [visible, setVisible] = useState(false);
  const [currentMessage, setCurrentMessage] = useState<Message | null>(null);

  const { data, isLoading, error } = useInstanceSWR(`/messages/${selectedItems.detail[0].feature}`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 0,
  });

  if (isLoading || !data || error) {
    return (
      <SplitPanel
        header={`Message Group:`}
        closeBehavior='collapse'
      >
        {error ? <>{error.info.message}</> : <Spinner />}
      </SplitPanel>
    );
  }

  const messages = (data?.data?.data?.messages as Message[]) || [];
  const attributes = (data?.data?.data?.attributes as Attribute[]) || [];

  const openModalWithMessage = (message: Message | null) => {
    setCurrentMessage(message);
    setVisible(true);
  };

  const tab1 = (
    <Table
      columnDefinitions={[
        {
          id: 'key',
          header: 'Message Key',
          cell: (message) => message.key || '-',
          width: 200,
          minWidth: 165,
          isRowHeader: true,
        },
        {
          id: 'defaultMessage',
          header: 'Default Message',
          cell: (message) => message.message.find((m) => m.lang === 'default')?.message[0]?.children[0]?.text || '-',
          width: 200,
          minWidth: 165,
          isRowHeader: true,
        },
        {
          id: 'status',
          header: 'Active Status',
          cell: (message) => {
            if (message.active === null) {
              return <StatusIndicator type='success'>ALWAYS ACTIVE</StatusIndicator>;
            } else if (message.active === false) {
              return <StatusIndicator type='error'>NOT ACTIVE</StatusIndicator>;
            } else if (message.active === true) {
              return <StatusIndicator type='info'>ACTIVE</StatusIndicator>;
            } else {
              return '-';
            }
          },
          width: 150,
          minWidth: 150,
          isRowHeader: true,
        },
        {
          id: 'translations',
          header: 'Translations',
          cell: (message) => (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <FontAwesomeIcon
                icon={faLanguage}
                style={{ cursor: 'pointer' }}
                onClick={() => openModalWithMessage(message)}
                title='View Translations'
              />
            </div>
          ),
          width: 50,
          minWidth: 50,
        },
      ]}
      items={messages}
      loadingText='Loading messages...'
    />
  );

  const tab2 = (
    <Table
      columnDefinitions={[
        {
          id: 'key',
          header: 'Key',
          cell: (attribute) => attribute.key || '-',
          width: 200,
          minWidth: 165,
          isRowHeader: true,
        },
        {
          id: 'value',
          header: 'Value',
          cell: (attribute) => attribute.value || '-',
          width: 200,
          minWidth: 165,
          isRowHeader: true,
        },
      ]}
      items={attributes}
      loadingText='Loading attributes...'
    />
  );

  return (
    <SplitPanel
      header={`Message Group: ${selectedItems.detail[0].feature}`}
      closeBehavior='collapse'
    >
      <SpaceBetween size='l'>
        {selectedItems.detail[0].description}
        <Tabs
          tabs={[
            {
              label: 'Messages',
              id: 'first',
              content: tab1,
            },
            {
              label: 'Attributes',
              id: 'second',
              content: tab2,
            },
          ]}
        />
      </SpaceBetween>
      <Modal
        onDismiss={() => setVisible(false)}
        visible={visible}
        size='max'
        footer={
          <Box float='right'>
            <SpaceBetween
              direction='horizontal'
              size='xs'
            >
              <Button
                variant='link'
                onClick={() => setVisible(false)}
              >
                Close
              </Button>
            </SpaceBetween>
          </Box>
        }
        header='Translations'
      >
        <TranslationsTable message={currentMessage} />
      </Modal>
    </SplitPanel>
  );
};

export default MessagesSplitPanelDetails;
