import { Auth } from 'aws-amplify';
import { Buffer } from 'buffer';
import { FetcherError } from './fetcherError';

async function getAuthToken() {
  try {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch (error) {
    console.error('Error getting the authentication token', error);
    return null;
  }
}

function addInstanceArnQueryString(endpoint: string, instanceArn: string) {
  const [pathParams, queryString] = endpoint.split('?') || [];
  const queryStringParams = queryString ? queryString.split('&') : [];
  queryStringParams.push(`instanceArn=${encodeURIComponent(instanceArn || '')}`);
  const apiPath = `${pathParams}?${queryStringParams.join('&')}`;

  return apiPath;
}

async function fetcher(
  endpoint: string | null,
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' = 'GET',
  data: Record<string, any> | null = null,
  instanceArn: string,
  instanceIntuitionUrl: string,
) {
  if (!endpoint) return null;
  const token = await getAuthToken();
  if (!token) {
    throw new Error('Unable to fetch the authentication token');
  }

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  const endpointWithInstanceId = addInstanceArnQueryString(endpoint, instanceArn);
  const fullUrl = `${instanceIntuitionUrl}${endpointWithInstanceId}`;

  const requestConfig: RequestInit = {
    method,
    headers,
  };

  if (['POST', 'PUT', 'PATCH'].includes(method) && data) {
    if (method === 'PUT' && data && data.file) {
      requestConfig.method = 'PUT';
      requestConfig.headers = {
        'Content-Type': data.file.type,
      };
      requestConfig.body = data.file;
    } else {
      requestConfig.body = JSON.stringify(data);
    }
  }

  const response = await fetch(fullUrl, requestConfig);

  if (!response.ok) {
    const error: FetcherError = new FetcherError('An error occurred while fetching the data.');
    error.info = await response.json();
    error.status = response.status;
    throw error;
  }

  if (method === 'DELETE') {
    return null;
  }

  if (response.headers.get('Content-Type') === 'audio/wav') {
    try {
      const arrayBuffer: ArrayBuffer = await response.arrayBuffer();
      const buffer = Buffer.from(arrayBuffer);
      const base64 = buffer.toString('base64');
      return { body: base64 };
    } catch (err) {
      console.error('error', { err });
      return {};
    }
  }

  const text = await response.text();
  return text ? JSON.parse(text) : null;
}

export default fetcher;
