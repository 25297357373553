import { NonCancelableCustomEvent, TokenGroupProps } from "@cloudscape-design/components";
import { useState } from "react";
import { TagEditorProps } from "./tagEditor";

export const useTagEditor = (props: TagEditorProps) => {
  const [value, setValue] = useState<string>('');
  const onAddTag = (tag: string) => {
    props.onChange([...props.tags, tag]);
    setValue('');
  };
  const onRemoveTag = ({ detail: { itemIndex } }: NonCancelableCustomEvent<TokenGroupProps.DismissDetail>) => {
    props.onChange([...props.tags.slice(0, itemIndex), ...props.tags.slice(itemIndex + 1)]);
  };

  return { value, setValue, onAddTag, onRemoveTag };
};
