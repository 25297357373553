import React from 'react';
import { mutate } from 'swr';
import Header from '@cloudscape-design/components/header';
import { ContentLayout } from '@cloudscape-design/components';
import { InstancesTable, InstancesTableProps } from '../instances/components/table';
import { useAppSession } from '../../utils/hooks/sessionContext';

export default function Instances() {
  const appSession = useAppSession();

  const instanceTableProps: InstancesTableProps = {
    isLoading: !!appSession?.instancesLoading,
    error: appSession?.instancesError,
    instances: appSession?.instances ?? [],
    onRefresh: () => mutate('/instances'),
  };

  return (
    <ContentLayout
      header={
        <Header variant="h1" description="Manage Instances configuration">
          Manage Instances
        </Header>
      }
    >
      <InstancesTable {...{ ...instanceTableProps }} />
    </ContentLayout>
  );
}
