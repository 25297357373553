import { StatusIndicator, Select, SelectProps, TableProps, CollectionPreferencesProps } from '@cloudscape-design/components';
import { getAttributeValue, getStatusIndicatorType, getAccountStatusIndicatorType } from './helpers';
import { useAppSession } from '../../../utils/hooks/sessionContext';
import { User, UserStatuses } from '../types';
import { PreferenceProps } from '../../../types/table';
import { TablePreferences } from '../../../utils/tablePreferences';

export const useTableConfig = () => {
  const appSession = useAppSession();

  const columns: TableProps.ColumnDefinition<User>[] = [
    {
      id: 'username',
      header: 'Username',
      cell: (item) => item.Username || '-',
      sortingField: 'Username',
    },
    {
      id: 'name',
      header: 'Full Name',
      cell: (item) => {
        const givenName = getAttributeValue(item.Attributes, 'given_name') || '';
        const familyName = getAttributeValue(item.Attributes, 'family_name') || '';
        return givenName || familyName ? `${givenName} ${familyName}`.trim() : '-';
      },
    },
    {
      id: 'status',
      header: 'Account Status',
      cell: (item) => {
        const statusType = getStatusIndicatorType(item.UserStatus);
        return <StatusIndicator type={statusType}>{item.UserStatus}</StatusIndicator>;
      },
      sortingField: 'UserStatus',
    },
    {
      id: 'status2',
      header: 'User Status',
      cell: (item) => {
        const statusType = getAccountStatusIndicatorType(item.Enabled);
        return <StatusIndicator type={statusType}>{item.Enabled ? 'Active' : 'Disabled'}</StatusIndicator>;
      },
      sortingField: 'Enabled',
    },
    {
      id: 'securityProfile',
      header: 'Security Profile',
      minWidth: 176,
      cell: (item) => {
        return getAttributeValue(item.Attributes, 'custom:securityProfile');
      },
      editConfig: {
        ariaLabel: 'Security Profile',
        errorIconAriaLabel: 'Security Profile Validation Error',
        editIconAriaLabel: 'editable',
        editingCell: (item, { setValue, currentValue }) => {
          const value = currentValue ?? getAttributeValue(item.Attributes, 'custom:securityProfile');

          const selectProps: SelectProps = {
            autoFocus: true,
            expandToViewport: true,
            ariaLabel: 'Select desired security profile',
            selectedOption: [
              { label: 'Administrator', value: 'Administrator' },
              { label: 'User', value: 'User' },
            ].find((option) => option.value === value) ?? null,
            onChange: (event) => {
              setValue(event.detail.selectedOption.value);
            },
            options: [
              { label: 'Administrator', value: 'Administrator' },
              { label: 'User', value: 'User' },
            ]
          }

          return (
            <Select { ...{ ...selectProps }} />
          );
        },
        disabledReason: (item) => {
          if (item.Username === appSession?.userProfile?.email) {
            return 'Editing not allowed on self';
          }
          if (item.UserStatus === UserStatuses.ExternalProvider) {
            return 'Editing not allowed on external providers';
          }
          return undefined;
        },
      }
    },
  ];

  const defaultPreferences: CollectionPreferencesProps.Preferences = {
    pageSize: 10,
    contentDisplay: [
      { id: 'username', visible: true },
      { id: 'name', visible: true },
      { id: 'status', visible: true },
      { id: 'status2', visible: true },
      { id: 'securityProfile', visible: true },
    ],
    wrapLines: false,
    stripedRows: false,
    contentDensity: 'comfortable',
    stickyColumns: { first: 1, last: 0 },
  };

  return {
    columns,
    defaultPreferences,
  };
};

const CONTENT_DISPLAY_OPTIONS = [
  { id: 'username', label: 'Username', alwaysVisible: true },
  { id: 'name', label: 'Full Name' },
  { id: 'status', label: 'Users Status' },
  { id: 'status2', label: 'Account Status' },
  { id: 'securityProfile', label: 'Security Profile' },
];

const PAGE_SIZE_OPTIONS = [
  { value: 10, label: '10 Users' },
  { value: 30, label: '30 Users' },
  { value: 50, label: '50 Users' },
];


export const Preferences = ({
  preferences,
  setPreferences,
  disabled = false,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  contentDisplayOptions = CONTENT_DISPLAY_OPTIONS,
}: PreferenceProps) => <TablePreferences {...{ preferences, setPreferences, disabled, pageSizeOptions, contentDisplayOptions }} />;
