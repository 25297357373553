export class FetcherError extends Error {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public info: any;
  public status: number | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(errorMessage: string, info?: any, status?: number) {
    super(errorMessage);
    this.info = info;
    this.status = status;
  }
}
