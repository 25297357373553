import React from 'react';
import useChangeDetector from '../../../utils/useChangeDetector';
import { DashboardDataRepresentation, WidgetDataRepresentation, WidgetConfig } from './configurable-dashboard/components/widgets/interfaces';
import { cloneDeep } from 'lodash';

const defaultContext = {
  item: {} as DashboardDataRepresentation,
  itemShadow: {} as DashboardDataRepresentation,
  changesDetected: false as boolean,
  getWidget: (() => ({}) as WidgetDataRepresentation) as <DefaultPreferences = any>(widgetId: string) => WidgetDataRepresentation<DefaultPreferences>,
  setItem: (() => null) as React.Dispatch<React.SetStateAction<DashboardDataRepresentation>>,
  setWidgetDetails: (() => null) as <DefaultPreferences = any>(widgetId: string, update: WidgetUpdate<DefaultPreferences>) => void,
  setItemShadow: (() => null) as React.Dispatch<DashboardDataRepresentation>,
};

const ItemContext = React.createContext<typeof defaultContext>(defaultContext);

export const useItemContext = () => {
  const context = React.useContext(ItemContext);
  if (!context) {
    throw new Error('useItemContext must be used within a ItemProvider');
  }
  return context;
};

interface ItemProviderProps {
  children: React.ReactNode;
}

export interface WidgetUpdate<DefaultPreferences> {
  preferences?: DefaultPreferences;
  description?: string;
  title?: string;
}

export const DashboardProvider: React.FC<ItemProviderProps> = ({ children }) => {
  const { item, setItem, setItemShadow, changesDetected, itemShadow } = useChangeDetector<DashboardDataRepresentation>({} as DashboardDataRepresentation);

  const setWidgetDetails = <DefaultPreferences=WidgetConfig['preferences'],>(
    widgetId: string,
    { preferences, description, title }: WidgetUpdate<DefaultPreferences>,
  ) => {
    setItem((previousItem) => {
      const updatedWidgets = previousItem.data.widgets.map((widget) => {
        const isCurrentWidget = widget.id === widgetId;

        if (!isCurrentWidget) {
          return widget;
        }

        const currentWidget = { ...widget };

        currentWidget.preferences = preferences ?? currentWidget.preferences;
        currentWidget.data.description = description ?? widget.data.description;
        currentWidget.data.title = title ?? widget.data.title;

        return currentWidget;
      });

      return {
        ...previousItem,
        data: {
          ...previousItem.data,
          widgets: updatedWidgets,
        },
      };
    });
  };

  const getWidget = (widgetId: string) => {
    return item.data.widgets.find((w: { id: string }) => w.id === widgetId) as WidgetDataRepresentation;
  };

  const setItemShadowValue = (item: DashboardDataRepresentation) => {
    return setItemShadow(cloneDeep(item));
  };

  return (
    <ItemContext.Provider value={{ item, getWidget, setItem, setItemShadow: setItemShadowValue, setWidgetDetails, changesDetected, itemShadow }}>
      {children}
    </ItemContext.Provider>
  );
};
