import React, { createContext, useState } from 'react';
import { Header, Spinner } from '@cloudscape-design/components';
import { WidgetConfig } from '../interfaces';
import GetWidgetData from '../../../../../../../utils/getWidgetData';
import { useItemContext } from '../../../../dashboardContext';
import { allContent, Content, WidgetPreferences } from '../preferences';
import { TrendInfo } from '../../sharedFunctions';
import Alert from '@cloudscape-design/components/alert';

export const getAbandonStats: WidgetConfig = {
  definition: { defaultRowSpan: 1, defaultColumnSpan: 1, minRowSpan: 1 },
  data: {
    icon: 'number',
    title: 'Abandon Rate',
    description: 'Abandon rate, plus 7 day average',
    header: ChartHeader,
    content: AbandonStatsContent,
    provider: AbandonStatsProvider,
    staticMinHeight: 560,
    multipleAllowed: true,
  },
};

interface AbandonStatsWidgetContextType {
  visibleContent: ReadonlyArray<Content>;
  openPreferences: () => void;
}

const AbandonStatsWidgetContext = createContext<AbandonStatsWidgetContextType>({
  visibleContent: [],
  openPreferences: () => null,
});

function AbandonStatsProvider({ children, widgetId }: { children: React.ReactElement; widgetId: string }) {
  const [preferencesVisible, setPreferencesVisible] = useState(false);
  const [visibleContent, setVisibleContent] = useState<ReadonlyArray<Content>>(allContent);
  return (
    <AbandonStatsWidgetContext.Provider
      value={{
        visibleContent,
        openPreferences: () => setPreferencesVisible(true),
      }}
    >
      {React.cloneElement(React.Children.only(children), {
        removeConfirmationText: 'Operational metrics',
        actions: [{ text: 'Preferences', onClick: () => setPreferencesVisible(true) }],
      })}
      {preferencesVisible && (
        <WidgetPreferences
          widgetId={widgetId}
          preferences={visibleContent}
          onConfirm={(visibleContent) => {
            setVisibleContent(visibleContent);
            setPreferencesVisible(false);
          }}
          onDismiss={() => setPreferencesVisible(false)}
        />
      )}
    </AbandonStatsWidgetContext.Provider>
  );
}

function ChartHeader() {
  return (
    <Header variant="h2" description="% with 7 day average">
      Abandon Rate
    </Header>
  );
}

export default function AbandonStatsContent({ widgetId }: { widgetId: string }) {
  const { item } = useItemContext();
  const widget = item.data.widgets.find((widget: { id: string }) => widget.id === widgetId);
  const period = item.data.period;
  const { data, isLoading, error } = GetWidgetData({ widget, period });

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return (
      <Alert statusIconAriaLabel="Error" type="error" header="Unable to load widget">
        {error.status}
      </Alert>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        paddingTop: '40px',
        justifyContent: 'center',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ fontSize: '6em', fontWeight: '800', textAlign: 'center' }}>
          {data?.data?.value !== null ? data?.data?.value : 'N/A'}
        </div>
        {data?.data?.trend !== undefined && <TrendInfo trend={data?.data?.trend} value={data?.data?.value} />}
      </div>
    </div>
  );
}
