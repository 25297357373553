import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useInstanceSWR from '../../../utils/hooks/useInstanceSWR';
import fetcherInstance from '../../../utils/fetcherInstance';
import { useApiWithFlashForFeatureCopy } from '../../../utils/hooks/useApiWithFlashInstance';
import CopyFormLayout, { ConfigStructure } from '../../../components/copyForm/copyFormLayout';
import { DialPlanUpdateBody, DialPlanData, DialPlanItem } from '../types';
import { Alert, ContentLayout, Header } from '@cloudscape-design/components';


export default function DialPlanCopy() {
  const navigate = useNavigate();
  const { featureId } = useParams();
  const { handleApiWithFlash } = useApiWithFlashForFeatureCopy();

  const [item, setItem] = useState<DialPlanItem>({} as DialPlanItem);
  const [copyingToData, setCopyingToData] = useState<DialPlanItem | null>(null);
  const { data, isLoading, error } = useInstanceSWR(`/dial-plans/${featureId}`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 0,
  });

  const hasMigration = true;

  useEffect(() => {
    if (data?.data) {
      setItem(data.data);
    }
  }, [data, setItem]);

  const validation = (feature: string, isMigrating: boolean) => {
    const generalPattern = /^[a-zA-Z0-9+_-]+$/;
    if (!isMigrating && feature === data?.data?.feature) {
      return 'Try a different Dial plan name';
    }
    if (!generalPattern.test(feature)) {
      return 'Only alphanumeric, - _ and + supported';
    }
  };
  const fetchSelectedInstanceData = async (selectedToInstance: string) => {
    try {
      const response = await fetcherInstance(`/dial-plans/${featureId}`, 'GET', null, selectedToInstance);

      if (response?.data) {
        setCopyingToData(response.data);
      }
    } catch (error) {
      console.error('Error fetching copying to data:', error);
      setCopyingToData(null);
    }
  };

  const handleCancel = () => {
    navigate('/dial-plan');
  };

  async function handleFormSubmit(dialPlanItem: ConfigStructure<DialPlanData>, selectedToInstance: string) {
    try {
      const payload = {
        data: { ...dialPlanItem.data },
        description: dialPlanItem.description,
        tag: dialPlanItem.tag
      };
      const isCreatingNew = !copyingToData;

      if (isCreatingNew) { 
        await create(dialPlanItem.feature, selectedToInstance, payload)
      } else {
        await update(dialPlanItem.feature, selectedToInstance, payload)
      }

      navigate('/dial-plan');
    } catch (error) {
      console.error('Error handling form submit:', error);
    }
  }

  async function create(feature: string, selectedToInstance: string, payload: DialPlanUpdateBody) {
    return handleApiWithFlash(`/dial-plans/${feature}`, 'POST', {
      successMessage: `Successfully Copied Dial Plan ${feature}`,
      errorMessage: `Error copying Dial plan ${feature}`,
      data: payload,
      instanceArn: selectedToInstance,
    });
  }

  async function update(feature: string, selectedToInstance: string, payload: DialPlanUpdateBody) {
    return handleApiWithFlash(`/dial-plans/${feature}`, 'PUT', {
      successMessage: `Successfully Copied Dial Plan ${feature}`,
      errorMessage: `Error copying Dial plan ${feature}`,
      data: payload,
      instanceArn: selectedToInstance,
    });
  }

  if (error) {
    return (
      <ContentLayout header={<Header variant='h1'>Error</Header>}>
        <Alert
          statusIconAriaLabel='Error'
          type='error'
          header='Unable to load component'
        >
          {error.status}
        </Alert>
      </ContentLayout>
    );
  }

  return (
    <CopyFormLayout
      item={item}
      copyingToData={copyingToData ?? undefined}
      isLoading={isLoading}
      hasMigration={hasMigration}
      fetchSelectedInstanceData={fetchSelectedInstanceData}
      validation={validation}
      handleFormSubmit={handleFormSubmit}
      handleCancel={handleCancel}
    />
  );
}
