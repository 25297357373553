import { useParams } from 'react-router-dom';
import { useInitialise } from '../../../utils/hooks/useInitialise';
import fetcher from '../../../utils/fetcher';
import { useState } from 'react';
import { RoleRecord } from '../../../types/rolePermissions';
import { ApiResponse } from '../../../types/api';
import { User } from '../../user-admin/types';
import { ContentLayout, Header, SpaceBetween } from '@cloudscape-design/components';
import { RoleUserTable, userDecoratedButtonDetail } from './roleUsers';

export default function EditRoleUsers() {
  const { featureId } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [role, setRole] = useState<RoleRecord>();
  const [users, setUsers] = useState<Array<User>>([]);
  const [usersInRole, setUsersInRole] = useState<Array<User>>([]);

  const load = async () => {
    setLoading(true);
    const roleResponse = await (fetcher(`/roles/${featureId}`) as Promise<ApiResponse<RoleRecord>>);
    const userResponse = await (fetcher(`/users`) as Promise<ApiResponse<Array<User>>>);

    const usersInRole: Array<User> = [];
    const usersNotInRole: Array<User> = [];

    userResponse.data?.forEach((user) => {
      const userRoleString = user.Attributes.find(({ Name }) => Name === 'custom:roles')?.Value || '';

      const userRoles = userRoleString.split('|');

      const inRole = userRoles.includes(roleResponse.data!.feature);

      inRole ? usersInRole.push(user) : usersNotInRole.push(user);
    });
    setUsersInRole(usersInRole);
    setUsers(usersNotInRole);
    setRole(roleResponse.data);
    setLoading(false);
  };

  const refreshUsers = async () => {
    setLoading(true);
    setUsersInRole([]);
    setUsers([]);

    const userResponse = await (fetcher(`/users`) as Promise<ApiResponse<Array<User>>>);

    const usersInRole: Array<User> = [];
    const usersNotInRole: Array<User> = [];

    userResponse.data?.forEach((user) => {
      const userRoleString = user.Attributes.find(({ Name }) => Name === 'custom:roles')?.Value || '';

      const userRoles = userRoleString.split('|');

      const inRole = userRoles.includes(role!.feature);

      inRole ? usersInRole.push(user) : usersNotInRole.push(user);
    });
    setUsersInRole(usersInRole);
    setUsers(usersNotInRole);

    setLoading(false);
  };

  useInitialise(() => {
    load();
  });

  const onActionClick = async (event: CustomEvent<userDecoratedButtonDetail>) => {
    const { id, user } = event.detail;

    const command = id === 'add' ? 'ADD' : 'REMOVE';
    await fetcher(`/roles/${featureId}/users`, 'PUT', { username: user.Username, command });
    refreshUsers();
  };

  const roleUserProps = {
    loading,
    users: usersInRole,
    actions: [
      {
        text: 'Remove from role',
        id: 'remove',
        disabled: false,
      },
    ],
    onActionClick,
    title: 'Current',
  };

  const roleNonMemberProps = {
    loading,
    users,
    actions: [
      {
        text: 'Add to role',
        id: 'add',
        disabled: false,
      },
    ],
    onActionClick,
    title: 'Other',
  };

  return (
    <ContentLayout
      header={
        <Header
          variant='h1'
          description='Add & Remove users from roles within the Cato application'
        >
          Manage "{role?.name}" Role Users
        </Header>
      }
    >
      <SpaceBetween
        direction='vertical'
        size='xl'
      >
        <RoleUserTable {...{ ...roleUserProps }} />
        <RoleUserTable {...{ ...roleNonMemberProps }} />
      </SpaceBetween>
    </ContentLayout>
  );
}
