import React, { createContext, useState, useMemo } from 'react';
import { WidgetPreferences, Content, allContent } from './preferences';

interface ContactTraceRecordWidgetContextType {
  visibleContent: ReadonlyArray<Content>;
  openPreferences: () => void;
}

export const ContactTraceRecordWidgetContext = createContext<ContactTraceRecordWidgetContextType>({
  visibleContent: [],
  openPreferences: () => null,
});

export const Provider = ({ children, widgetId }: { children: React.ReactElement; widgetId: string }) => {
  const [preferencesVisible, setPreferencesVisible] = useState(false);
  const [visibleContent, setVisibleContent] = useState<ReadonlyArray<Content>>(allContent);

  const contextValue = useMemo(() => ({
    visibleContent,
    openPreferences: () => setPreferencesVisible(true),
  }), [visibleContent]);
  
  return (
    <ContactTraceRecordWidgetContext.Provider
      value={contextValue}
    >
      {React.cloneElement(React.Children.only(children), {
        removeConfirmationText: 'Operational metrics',
        actions: [{ text: 'Preferences', onClick: () => setPreferencesVisible(true) }],
      })}
      
      {preferencesVisible && (
        <WidgetPreferences
          widgetId={widgetId}
          preferences={visibleContent}
          onConfirm={(visibleContent) => {
            setVisibleContent(visibleContent);
            setPreferencesVisible(false);
          }}
          onDismiss={() => setPreferencesVisible(false)}
        />
      )}  
    </ContactTraceRecordWidgetContext.Provider>
  );
};



