import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useChangeDetector from '../../../utils/useChangeDetector';
import useInstanceSWR from '../../../utils/hooks/useInstanceSWR';
import fetcherInstance from '../../../utils/fetcherInstance';
import { useApiWithFlashForFeatureCopy } from '../../../utils/hooks/useApiWithFlashInstance';
import CopyFormLayout, { ConfigStructure } from '../../../components/copyForm/copyFormLayout';
import { Alert, ContentLayout, Header } from '@cloudscape-design/components';
import { MessageGroupCreateBody, MessageGroupUpdateBody } from '../types';

export default function MessagesCopy() {
  const { item, setItem, setItemShadow } = useChangeDetector<any>({});
  const hasMigration = true;
  const [copyingToData, setCopyingToData] = useState<ConfigStructure | null>(null);
  const navigate = useNavigate();
  const { featureId } = useParams();
  const { handleApiWithFlash } = useApiWithFlashForFeatureCopy();

  const { data, isLoading, error } = useInstanceSWR(`/messages/${featureId}`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 0,
  });

  useEffect(() => {
    if (data?.data) {
      setItem(data.data);
      setItemShadow(data.data);
    }
  }, [data, setItem]);

  const validation = (feature: string, isMigrating: boolean) => {
    const generalPattern = /^[a-zA-Z0-9+_-]+$/;
    if (!isMigrating && feature === data?.data?.feature) {
      return 'Try a different message group name';
    } else if (!generalPattern.test(feature)) {
      return 'Only alphanumeric, - _ and + supported';
    }
  };

  const fetchSelectedInstanceData = async (selectedToInstance: string) => {
    try {
      const response = await fetcherInstance(`/messages/${featureId}`, 'GET', null, selectedToInstance);
      if (response?.data) {
        setCopyingToData(response.data);
      }
    } catch (error) {
      console.error('Error fetching copying to data:', error);
      setCopyingToData(null);
    }
  };

  const handleCancel = () => {
    navigate('/messages');
  };

  async function handleFormSubmit(item: ConfigStructure<MessageGroupUpdateBody["data"]>, selectedToInstance: string) {
    try {

      const { feature, description, data, tag } = item;

      const isCreatingNew = !copyingToData;

      if (isCreatingNew) {
        await create(feature, selectedToInstance, {description, tag});
      }

      await update(feature, selectedToInstance, {description, tag, data});

      navigate('/messages');
    } catch (error) {
      console.error('Error handling form submit:', error);
    }
  }

  async function create(feature: string, selectedToInstance: string, payload: MessageGroupCreateBody) {
    return handleApiWithFlash(`/messages/${feature}`, 'POST', {
      errorMessage: `Error copying message ${feature}`,
      data: payload,
      successMessage: '',
      instanceArn: selectedToInstance,
    });
  }

  async function update(feature: string, selectedToInstance: string, payload: MessageGroupUpdateBody) {
    return handleApiWithFlash(`/messages/${feature}`, 'PUT', {
      successMessage: `Successfully Copied Message ${feature}`,
      errorMessage: `Error copying Message ${feature}`,
      data: payload,
      instanceArn: selectedToInstance,
    });
  }

  if (error) {
    return (
      <ContentLayout header={<Header variant='h1'>Error</Header>}>
        <Alert
          statusIconAriaLabel='Error'
          type='error'
          header='Unable to load component'
        >
          {error.status}
        </Alert>
      </ContentLayout>
    );
  }

  return (
    <CopyFormLayout
      item={item}
      copyingToData={copyingToData ?? undefined}
      isLoading={isLoading}
      hasMigration={hasMigration}
      fetchSelectedInstanceData={fetchSelectedInstanceData}
      validation={validation}
      handleFormSubmit={handleFormSubmit}
      handleCancel={handleCancel}
    />
  );
}
