import React, { ReactNode } from 'react';
import { Box } from '@cloudscape-design/components';

export interface ValueWithLabelProps {
    label: string;
    children: ReactNode;
  }
  
  export const ValueWithLabel: React.FC<ValueWithLabelProps> = ({ label, children }) => (
    <div>
      <Box variant="awsui-key-label">{label}</Box>
      <div>{children}</div>
    </div>
  );
  
