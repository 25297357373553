import React from 'react';
import {
    CollectionPreferences,
} from '@cloudscape-design/components';



const rawColumns = [
    {
        id: 'ticketId',
        header: 'Ticket Id',
        cell: (item, onSelectionChange) => (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              onSelectionChange({ detail: { selectedItems: [{ ticketId: item.ticketId }] } });
            }}
          >
            {item?.ticketId || "-"}
          </a>
        ),
        sortingField: "ticketId"
      },
    {
        id: "subject",
        header: "Subject",
        cell: item => item?.subject || "-",
        sortingField: "subject",
    },
    {
        id: "status",
        header: "Status",
        cell: item => item?.status || "-",
        sortingField: "status",
    },
    {
        id: "createdAt",
        header: "Opened At",
        cell: item => item?.createdAt ? new Date(item.createdAt).toLocaleString('en-GB', { hour12: false, minute: 'numeric', hour: 'numeric', day: '2-digit', month: '2-digit', year: 'numeric' }) : "-",
        sortingField: "createdAt",
      },
      {
        id: "updatedAt",
        header: "Updated At",
        cell: item => item?.updatedAt ? new Date(parseInt(item.updatedAt)).toLocaleString('en-GB', { hour12: false, minute: 'numeric', hour: 'numeric', day: '2-digit', month: '2-digit', year: 'numeric' }) : "-",
        sortingField: "updatedAt",
      },
      
      

];

// const openModal = (ticketId) => {
//     onSelectionChange({ detail: { selectedItems: [{ ticketId }] } });
//   };

export const COLUMN_DEFINITIONS = rawColumns.map(column => ({ ...column }));

const editableColumns = {}; // Assuming you don't have specific editable columns for tickets

export const EDITABLE_COLUMN_DEFINITIONS = COLUMN_DEFINITIONS.map(column => {
    if (editableColumns[column.id]) {
        return {
            ...column,
            minWidth: Math.max(column.minWidth || 0, 176),
            ...editableColumns[column.id],
        };
    }
    return column;
});

const CONTENT_DISPLAY_OPTIONS = [
    { id: 'ticketId', label: 'Ticket Id', alwaysVisible: true },
    { id: 'subject', label: 'Subject', alwaysVisible: false },
    { id: 'status', label: 'Status', alwaysVisible: false },
    { id: 'createdAt', label: 'Opened On', alwaysVisible: false },
    { id: 'updatedAt', label: 'Updated On', alwaysVisible: false },
];

// The rest of your code remains unchanged.

export const PAGE_SIZE_OPTIONS = [
    {value: 10, label: '10 tickets'},
    {value: 30, label: '30 tickets'},
    {value: 50, label: '50 tickets'},
];

export const DEFAULT_PREFERENCES = {
    pageSize: 10,
    contentDisplay: [
        {id: 'ticketId', visible: true},
        {id: 'subject', visible: true},
        {id: 'status', visible: true},
        {id: 'createdAt', visible: true},
        {id: 'updatedAt', visible: true},
    ],
    wrapLines: false,
    stripedRows: false,
    contentDensity: "comfortable",
    stickyColumns: {first: 1, last: 0},
};

export const Preferences = ({
                                preferences,
                                setPreferences,
                                disabled = false,
                                pageSizeOptions = PAGE_SIZE_OPTIONS,
                                contentDisplayOptions = CONTENT_DISPLAY_OPTIONS,
                            }) => (
    <CollectionPreferences
        title={"Preferences"}
        cancelLabel={"Cancel"}
        confirmLabel={"Confirm"}
        disabled={disabled}
        preferences={preferences}
        onConfirm={({detail}) => setPreferences(detail)}
        pageSizePreference={{title: "Page size", options: pageSizeOptions}}
        wrapLinesPreference={{label: "Wrap lines", description: "Select to see all the text and wrap the lines"}}
        stripedRowsPreference={{label: "Striped rows", description: "Select to add alternating shaded rows"}}
        contentDensityPreference={{
            label: "Compact mode",
            description: "Select to display content in a denser, more compact mode"
        }}
        contentDisplayPreference={{title: "Select visible content / order", options: contentDisplayOptions}}
        stickyColumnsPreference={{
            firstColumns: {
                title: 'Stick first column(s)',
                description: 'Keep the first column(s) visible while horizontally scrolling the table content.',
                options: [
                    {label: 'None', value: 0},
                    {label: 'First column', value: 1},
                    {label: 'First two columns', value: 2},
                ],
            },
            lastColumns: {
                title: 'Stick last column',
                description: 'Keep the last column visible while horizontally scrolling the table content.',
                options: [
                    {label: 'None', value: 0},
                    {label: 'Last column', value: 1},
                ],
            },
        }}
    />
);


  