import * as React from 'react';
import Modal from '@cloudscape-design/components/modal';
import Box from '@cloudscape-design/components/box';
import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@cloudscape-design/components/button';

export interface ConfirmCancelModalProps {
  onConfirm: () => void;
  onCancel: () => void;
  cancelMessage: React.ReactNode;
}

export const ConfirmCancelModal: React.FC<ConfirmCancelModalProps> = ({ onConfirm, onCancel, cancelMessage }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const handleConfirm = async () => {
    setIsLoading(true);
    await onConfirm();
    setIsLoading(false);
  };

  return (
    <Modal
      visible={true}
      onDismiss={onCancel}
      footer={
        <Box float='right'>
          <SpaceBetween
            direction='horizontal'
            size='xs'
          >
            <Button
              variant='link'
              onClick={onCancel}
            >
              Stay
            </Button>
            <Button
              variant='primary'
              onClick={handleConfirm}
              loading={isLoading}
            >
              Confirm
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={'Confirm Close'}
    >
      {cancelMessage}
    </Modal>
  );
};
