// SentimentBar.jsx
import React from 'react';

export interface SentimentBarProps {
  transcript: any;
  totalDurationMillis: number;
}

type sentimentTypes = 'NEUTRAL' | 'POSITIVE' | 'NEGATIVE';

// eslint-disable-next-line react/prop-types
function SentimentBar({ transcript, totalDurationMillis }: SentimentBarProps) {
  const getSentimentColor = (sentiment: sentimentTypes) => {
    switch (sentiment) {
      case 'NEUTRAL':
        return 'bg-gray-200';
      case 'POSITIVE':
        return 'bg-green-500';
      case 'NEGATIVE':
        return 'bg-red-500';
      default:
        return 'bg-gray-200';
    }
  };

  return (
    <div className="flex w-full flex-col items-start">
      <div className="">Sentiment</div>
      <div className="relative mb-4 h-2 w-full bg-gray-200">
        {/* eslint-disable-next-line react/prop-types */}
        {transcript.map((entry: any) => {
          const color = getSentimentColor(entry.Sentiment);
          const start = (entry.BeginOffsetMillis / totalDurationMillis) * 100;
          const duration = (entry.EndOffsetMillis - entry.BeginOffsetMillis) / totalDurationMillis;
          const widthPercentage = duration * 100;

          return (
            <div key={entry.Id} className="absolute left-0 top-0 h-full  bg-transparent" style={{ left: `${start}%`, width: `${widthPercentage}%` }}>
              <div className={`h-full  ${color}`} />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SentimentBar;
