import { Link } from 'react-router-dom';
import { CollectionPreferencesProps, TableProps } from '@cloudscape-design/components';
import { useProtectUtility } from '../../../components/protectedUtility';
import { Actions, ResourceTypes } from '../../../types/rolePermissions';
import { DialPlanListItem } from '../types';
import { PreferenceProps } from '../../../types/table';
import { TagIcon } from '../../utilities/tagIcon';
import { TablePreferences } from '../../../utils/tablePreferences';

export const useTableConfig = () => {
  const protectUtility = useProtectUtility();
  const columns: TableProps.ColumnDefinition<DialPlanListItem>[] = [
    {
      id: 'feature',
      header: 'Resource Name',
      cell: (item) =>
        protectUtility({ resourceType: ResourceTypes.DialPlan, resourceAction: Actions.Create }) ? (
          <Link to={`/dial-plan/edit/${item?.feature}`}>{item?.feature}</Link>
        ) : (
          item.feature
        ),
      sortingField: 'feature',
    },
    {
      id: 'description',
      header: 'Description',
      cell: (item) => item?.description || '-',
      sortingField: 'description',
    },
    {
      id: 'type',
      header: 'Type',
      cell: (item) => item?.type || '-',
      sortingField: 'type',
    },
    {
      id: 'tag',
      header: (
        <>
          Tag <TagIcon />
        </>
      ),
      cell: (item) => item?.tag,
      sortingField: 'type',
    },
  ];

  const defaultContentDisplayOptions: CollectionPreferencesProps.ContentDisplayOption[] = [
    { id: 'feature', label: 'Resource Name', alwaysVisible: true },
    { id: 'description', label: 'Description', alwaysVisible: false },
    { id: 'type', label: 'Type', alwaysVisible: false },
    { id: 'tag', label: 'Tag', alwaysVisible: false },
  ];

  const defaultPageSizeOptions: CollectionPreferencesProps.PageSizeOption[] = [
    { value: 10, label: '10 dial plans' },
    { value: 30, label: '30 dial plans' },
    { value: 50, label: '50 dial plans' },
  ];

  const defaultPreferences: CollectionPreferencesProps.Preferences = {
    pageSize: 10,
    contentDisplay: [
      { id: 'feature', visible: true },
      { id: 'description', visible: true },
      { id: 'type', visible: true },
      { id: 'tag', visible: true },
    ],
    wrapLines: false,
    stripedRows: false,
    contentDensity: 'comfortable',
    stickyColumns: { first: 1, last: 0 },
  };

  const Preferences = ({
    preferences,
    setPreferences,
    disabled = false,
    pageSizeOptions = defaultPageSizeOptions,
    contentDisplayOptions = defaultContentDisplayOptions,
  }: PreferenceProps) => <TablePreferences {...{ preferences, setPreferences, disabled, pageSizeOptions, contentDisplayOptions }} />;

  return { columns, defaultPreferences, Preferences };
};
