import React from 'react';
import useSWR from 'swr';
import fetcher from '../../utils/fetcher';
import Header from '@cloudscape-design/components/header';
import { ContentLayout } from '@cloudscape-design/components';
import TicketsTable from './components/table';

export default function Tickets() {
  const { data, isLoading, error } = useSWR('/ticket', fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 0,
  });

  return (
    <ContentLayout
      header={
        <Header
          variant='h1'
          description='Manage all your open and closed tickets'
        >
          Manage Tickets
        </Header>
      }
    >
      <TicketsTable
        isLoading={isLoading}
        error={error}
        ticket={data?.responseBody?.data}
      />
    </ContentLayout>
  );
}
