import React from 'react';
import ReactDiffViewer from 'react-diff-viewer-continued';
import useSWR from 'swr';
import {
  ColumnLayout,
  Container,
  Header,
  Icon,
  SpaceBetween,
  SplitPanel,
  Spinner,
} from '@cloudscape-design/components';
import fetcher from '../../../utils/fetcher';
import { epochToUTCString } from '../../../utils/timeUtils';
import { ValueWithLabel } from '../../../components/valueWithLabel';

interface AuditSplitPanelDetailsProps {
  selectedItems: any;
}

const AuditSplitPanelDetails: React.FC<AuditSplitPanelDetailsProps> = ({ selectedItems }) => {
  const encodedDateTime = encodeURIComponent(selectedItems.detail[0].dateTime);
  const { data, error, isLoading } = useSWR(`/audit/${encodedDateTime}`, fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 0,
  });

  const event = selectedItems?.detail[0];
  const Payload = data?.data[0]?.data;

  return (
    <SplitPanel header="Audit Log" closeBehavior="collapse">
      <Container header={<Header headingTagOverride="h3">Event</Header>}>
        <ColumnLayout columns={2} variant="text-grid">
          <SpaceBetween size="l">
            <ValueWithLabel label="Datetime (UTC)">{epochToUTCString(event.dateTime)}</ValueWithLabel>
            <ValueWithLabel label="Requester">{event.requester}</ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label="Resource Type">{event.type}</ValueWithLabel>
            <ValueWithLabel label="Resource Name">{event.resource}</ValueWithLabel>
            <ValueWithLabel label="Action Performed">{event.action}</ValueWithLabel>
          </SpaceBetween>
        </ColumnLayout>
        <ColumnLayout columns={1} variant="text-grid">
          <SpaceBetween size="l">
            <ValueWithLabel label="Payload">
              {isLoading ? (
                <Spinner />
              ) : (
                <ReactDiffViewer
                  oldValue={JSON.stringify(Payload?.before)}
                  newValue={JSON.stringify(Payload?.after)}
                  splitView={true}
                />
              )}
              {error && (
                <>
                  <Icon name="status-negative" variant="error" /> Error retrieving data
                </>
              )}
            </ValueWithLabel>
          </SpaceBetween>
        </ColumnLayout>
      </Container>
    </SplitPanel>
  );
};

export default AuditSplitPanelDetails;
