export type InstanceType = {
  dateTime: number;
  feature: string;
  instanceAccountId: string;
  instanceArn: string;
  instanceId: string;
  instanceName: string;
  timezone?: string;
  intuitionUrl?: string;
  name: string;
  instanceRegion: string;
  requester: string;
  tenantId: string;
  type: string;
  updatedAt: number;
  updatedBy: string;
};
export interface InstanceSelected {
  instanceArn: string;
  instanceAlias: string;
  instanceRegion: string;
  instanceIntuitionUrl?: string;
  instanceTimezone?: string;
}
export const STORED_INSTANCE_SELECTOR = 'Cato-Instance-Selected';
