import React, { useCallback, useState } from 'react';
import { ContentLayout, Container, SpaceBetween, Form, Input, Button, FormField, Select, Header, ColumnLayout } from '@cloudscape-design/components';
import { useNavigate, useParams } from 'react-router-dom';
import useChangeDetector from '../../../utils/useChangeDetector';
import { useApiWithFlash } from '../../../utils/hooks/useApiWithFlash';
import fetcher from '../../../utils/fetcher';
import Checkbox from '@cloudscape-design/components/checkbox';
import { useAppSession } from '../../../utils/hooks/sessionContext';
import { Actions, InstanceEditorPermissions, ResourceTypes, RoleRecord } from '../../../types/rolePermissions';
import { InstanceType } from '../../../types/instance';
import { useInitialise } from '../../../utils/hooks/useInitialise';
import { ConfirmCancelModal } from '../../../components/confirmCancelModal';
import { TagEditor } from './tagEditor';
import { useBlockerLogic } from '../../../utils/hooks/useBlocker';
import { camelCaseToWords } from '../../../utils/strings';

interface SelectedInstance {
  label: string;
  value: string;
}

type PickRoleRecord = Omit<RoleRecord, 'tenant' | 'feature'>;
type PartialPickRoleRecord = Partial<PickRoleRecord>;

const availableResources = [ResourceTypes.Messages, ResourceTypes.Closures, ResourceTypes.DialPlan, ResourceTypes.Dashboards, ResourceTypes.ContactSearch];

const blankRoleRecord: PartialPickRoleRecord = {
  policies: availableResources.map((resourceType) => ({ resourceType, instance: '', actions: [] })),
};

export default function CreateOrEditRole() {
  const [touchedFields, setTouchedFields] = useState<Set<string>>(new Set());
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const navigate = useNavigate();
  const appSession = useAppSession();
  const memberType = appSession?.memberType;
  const { featureId } = useParams();
  const { handleApiWithFlash } = useApiWithFlash();
  const { item, setItem, setItemShadow, changesDetected } = useChangeDetector<PartialPickRoleRecord>({ ...blankRoleRecord });

  const { blocker, handleCancel, handleCloseCancelModal, handleConfirmCancel } = useBlockerLogic({
    changesDetected,
    path: 'roles',
    formSubmitted,
  });

  const load = async () => {
    const data = await fetcher(`/roles/${featureId}`);

    const { name, description, policies, tags: initialTags } = data.data as RoleRecord;

    const incomingItem = {
      name,
      description,
      tags: initialTags,
      policies,
      instance: policies.find((policy) => policy.instance)?.instance as string,
    };

    setItem(incomingItem);
    setItemShadow(incomingItem);
  };

  useInitialise(() => {
    if (featureId) {
      load();
    } else {
      setItem({
        ...blankRoleRecord,
        name: '',
        description: '',
        tags: [],
        instance: '',
      });
      setItemShadow({
        ...blankRoleRecord,
        name: '',
        description: '',
        tags: [],
        instance: '',
      });
    }
  });

  const handleInputChange = useCallback(
    (field: string, value: string) => {
      setItem((prev) => ({ ...prev, [field]: value }));
      setTouchedFields((prev) => new Set(prev).add(field));
    },
    [setItem],
  );

  async function handleSubmitForm() {
    setFormSubmitted(true);

    const payload = {
      name: item.name,
      description: item.description ?? '',
      instanceArn: item.instance ?? '',
      policies: item.policies ?? [],
      tags: item.tags,
    };

    let response;
    if (featureId) {
      response = await handleApiWithFlash(`/roles/${featureId}`, 'PUT', {
        data: payload,
        successMessage: `Successfully edited role "${item.name}"`,
        errorMessage: `Error editing role "${item.name}"`,
      });
    } else {
      response = await handleApiWithFlash(`/roles`, 'POST', {
        data: payload,
        successMessage: `Successfully created a new role "${item.name}"`,
        errorMessage: `Error creating a role "${item.name}"`,
      });
    }

    if (response?.success) {
      navigate(`/roles`);
    }
  }

  const updatePermissions = (resource: ResourceTypes, permissions: InstanceEditorPermissions) => {
    setItem((previousItem) => {
      const updatedPolicies = (previousItem.policies ?? []).map((policy) => {
        if (policy.resourceType === resource) {
          return {
            ...policy,
            actions: Object.entries(permissions)
              .filter(([_, value]) => value)
              .map(([action]) => action) as Array<Actions>,
          };
        }
        return policy;
      });

      return { ...previousItem, policies: updatedPolicies };
    });
  };

  const updateInstance = (instanceId: string) => {
    setItem((previousItem) => {
      const updatedPolicies = (previousItem.policies ?? []).map((policy) => ({
        ...policy,
        instance: instanceId,
      }));

      return { ...previousItem, instance: instanceId, policies: updatedPolicies };
    });
  };

  const getResourceTypePermissions = (resource: ResourceTypes) => {
    const policy = (item.policies ?? []).find(({ resourceType }) => resourceType === resource) || { actions: [] };

    const permissions: InstanceEditorPermissions = {
      create: false,
      read: false,
      update: false,
      delete: false,
    };
    policy.actions.forEach((action) => (permissions[action] = true));

    return permissions;
  };

  const hasSimplify = memberType?.includes('simplify');
  const hasIntuition = memberType?.includes('intuition');

  const isCreatePage = !featureId;
  const isSubmitDisabled = isCreatePage ? !item.name || !item.description : !changesDetected;

  const simplifyActionData = [
    {
      title: camelCaseToWords(ResourceTypes.Messages),
      resource: ResourceTypes.Messages,
      permissions: getResourceTypePermissions(ResourceTypes.Messages),
    },
    {
      title: camelCaseToWords(ResourceTypes.DialPlan),
      resource: ResourceTypes.DialPlan,
      permissions: getResourceTypePermissions(ResourceTypes.DialPlan),
    },
    {
      title: camelCaseToWords(ResourceTypes.Closures),
      resource: ResourceTypes.Closures,
      permissions: getResourceTypePermissions(ResourceTypes.Closures),
    },
  ];

  const intuitionActionData = [
    {
      title: camelCaseToWords(ResourceTypes.Dashboards),
      resource: ResourceTypes.Dashboards,
      permissions: getResourceTypePermissions(ResourceTypes.Dashboards),
    },
    {
      title: camelCaseToWords(ResourceTypes.ContactSearch),
      resource: ResourceTypes.ContactSearch,
      permissions: getResourceTypePermissions(ResourceTypes.ContactSearch),
    },
  ];

  return (
    <ContentLayout>
      <SpaceBetween size='l'>
        <Form
          variant='embedded'
          actions={
            <SpaceBetween
              direction='horizontal'
              size='xs'
            >
              <Button
                formAction='none'
                variant='link'
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                variant='primary'
                loading={formSubmitted}
                disabled={isSubmitDisabled}
                onClick={handleSubmitForm}
              >
                Submit
              </Button>
            </SpaceBetween>
          }
        >
          <SpaceBetween size='l'>
            <Container>
              <SpaceBetween size='l'>
                <FormField
                  label='Name*'
                  errorText={touchedFields.has('name') && !item.name ? 'Name is required' : ''}
                >
                  <Input
                    onChange={({ detail }) => handleInputChange('name', detail.value)}
                    value={item?.name ?? ''}
                    placeholder='Enter the Name'
                  />
                </FormField>
                <FormField
                  label='Description*'
                  errorText={touchedFields.has('description') && !item.description ? 'Description is required' : ''}
                >
                  <Input
                    onChange={({ detail }) => handleInputChange('description', detail.value)}
                    value={item?.description ?? ''}
                    placeholder='Enter the description'
                  />
                </FormField>
                <FormField
                  label='Instance'
                  errorText={touchedFields.has('instance') && !item.instance ? 'Instance is required' : ''}
                >
                  <Select
                    options={appSession?.instances.map((instance: InstanceType) => ({
                      label: `${instance.instanceName} - ${instance.instanceArn}`,
                      value: instance.instanceId,
                    }))}
                    onChange={(event) => {
                      const selectedOption = event.detail.selectedOption as SelectedInstance;
                      updateInstance(selectedOption.value);
                    }}
                    placeholder='Select Instance'
                    selectedOption={
                      item.instance
                        ? {
                            label: appSession?.instances.find((instance) => instance.instanceId === item.instance)?.instanceName ?? '',
                            value: item.instance,
                          }
                        : null
                    }
                  />
                </FormField>
              </SpaceBetween>
            </Container>
            {hasSimplify && (
              <Container header={<Header variant='h2'>Simplify</Header>}>
                <ColumnLayout
                  columns={5}
                  minColumnWidth={100}
                  disableGutters={true}
                >
                  <div>&nbsp;</div>
                  {Object.values(Actions).map((action) => (
                    <div
                      style={{ display: 'flex', justifyContent: 'center', fontWeight: 'bold' }}
                      key={'Simplify' + action}
                    >
                      {action}
                    </div>
                  ))}
                  {simplifyActionData.map((row) => (
                    <React.Fragment key={row.resource}>
                      <div>{row.title}</div>
                      {Object.values(Actions).map((action) => (
                        <div
                          style={{ display: 'flex', justifyContent: 'center' }}
                          key={row.resource + action}
                        >
                          <Checkbox
                            onChange={({ detail }) =>
                              updatePermissions(row.resource, {
                                ...row.permissions,
                                [action]: detail.checked,
                              })
                            }
                            checked={row.permissions[action]}
                          />
                        </div>
                      ))}
                    </React.Fragment>
                  ))}
                </ColumnLayout>
              </Container>
            )}
            {hasIntuition && (
              <Container header={<Header variant='h2'>Intuition </Header>}>
                <ColumnLayout
                  columns={5}
                  minColumnWidth={100}
                  disableGutters={true}
                >
                  <div>&nbsp;</div>
                  {Object.values(Actions).map((action) => (
                    <div
                      style={{ display: 'flex', justifyContent: 'center', fontWeight: 'bold' }}
                      key={'Intuition' + action}
                    >
                      {action}
                    </div>
                  ))}
                  {intuitionActionData.map((row) => (
                    <React.Fragment key={row.resource}>
                      <div>{row.title}</div>
                      {Object.values(Actions).map((action) => (
                        <div
                          style={{ display: 'flex', justifyContent: 'center' }}
                          key={row.resource + action}
                        >
                          <Checkbox
                            onChange={({ detail }) =>
                              updatePermissions(row.resource, {
                                ...row.permissions,
                                [action]: detail.checked,
                              })
                            }
                            checked={row.permissions[action]}
                          />
                        </div>
                      ))}
                    </React.Fragment>
                  ))}
                </ColumnLayout>
              </Container>
            )}

            <TagEditor
              tags={item?.tags ?? []}
              onChange={(tags: string[]) => setItem({ ...item, tags })}
            />
          </SpaceBetween>
        </Form>
        {blocker.state === 'blocked' && (
          <ConfirmCancelModal
            {...{
              onCancel: handleCloseCancelModal,
              onConfirm: handleConfirmCancel,
              cancelMessage: 'Are you sure you want to close? Any unsaved changes will be lost.',
            }}
          />
        )}
      </SpaceBetween>
    </ContentLayout>
  );
}
