import { Actions } from '../../types/rolePermissions';
import { ProtectedUtility } from './protectedUtility';
import { ProtectedUtilityProps } from './types';

/**
 * React component to protect a utility component
 * @param props
 * @returns JSX.Element
 */
export const ProtectedUtilityUpdate = (props: Omit<ProtectedUtilityProps, 'resourceAction'>) => {

  return (
    <ProtectedUtility resourceType={props.resourceType} resourceAction={Actions.Update}>
      {props.children}
    </ProtectedUtility>
  );
};
