import Header from '@cloudscape-design/components/header';
import { Pagination, SpaceBetween, Spinner, Table, Box, Popover, StatusIndicator } from '@cloudscape-design/components';
import Alert from '@cloudscape-design/components/alert';
import { useLocalStorage } from '../../../components/use-local-storage';
import { useColumnWidths } from '../../../components/table/use-column-width';
import { FILTERING_PROPERTIES as filteringProperties } from './filteringProperties';
import SearchBar from './searchBar';


import { ContactSearchTableProps } from '../types';
import { useTableConfig } from './table.config';
import { useCatoCollection } from '../../../utils/hooks/useCatoCollection';

export default function ContactSearchTable({
  changesMade,
  setChangesMade,
  setSubmitSearch,
  setDateRange,
  setSearchFilters,
  isLoading,
  contactSearchResults = [],
  error,
}: ContactSearchTableProps) {
  const { DEFAULT_PREFERENCES, EDITABLE_COLUMN_DEFINITIONS, Preferences } = useTableConfig();

  const [columnDefinitions, saveWidths] = useColumnWidths('Cato-Search-TableEditable-Widths', EDITABLE_COLUMN_DEFINITIONS);
  const [preferences, setPreferences] = useLocalStorage('Cato-Search-TableEditable-Preferences', DEFAULT_PREFERENCES);

  const { items, collectionProps, paginationProps } = useCatoCollection(contactSearchResults, { filteringProperties, pageSize: preferences.pageSize });

  return (
    <>
      <Table
        {...collectionProps}
        stickyHeader={true}
        resizableColumns={true}
        onColumnWidthsChange={saveWidths}
        columnDefinitions={columnDefinitions}
        items={items}
        loadingText='Loading contacts'
        columnDisplay={preferences.contentDisplay}
        wrapLines={preferences.wrapLines}
        stripedRows={preferences.stripedRows}
        stickyColumns={preferences.stickyColumns}
        contentDensity={preferences.contentDensity as 'compact' | 'comfortable'}
        loading={isLoading || !contactSearchResults}
        header={
          <SpaceBetween size='xs'>
            <Header variant='awsui-h1-sticky'>
              <SpaceBetween
                direction='horizontal'
                size='xs'
              >
                <span key='contactLabel'>Contact search</span>
                {!isLoading ? <span key='count'>({contactSearchResults.length})</span> : <Spinner key='spinner' />}
                {contactSearchResults.length > 499 && (
                  <Box
                    color='text-status-error'
                    key='maxResultsBox'
                  >
                    <Popover
                      header='Max Results'
                      content='Your search returns more than 500 records. Please reduce your time frame or add search filters.'
                      key='popover'
                    >
                      <StatusIndicator
                        type='warning'
                        key='statusIndicator'
                      >
                        Max Results
                      </StatusIndicator>
                    </Popover>
                  </Box>
                )}
              </SpaceBetween>
            </Header>
            <SearchBar
              setDateRange={setDateRange}
              setSearchFilters={setSearchFilters}
              setSubmitSearch={setSubmitSearch}
              setChangesMade={setChangesMade}
              changesMade={changesMade}
              isLoading={isLoading}
            />
          </SpaceBetween>
        }
        pagination={<Pagination {...paginationProps} />}
        preferences={
          <Preferences
            preferences={preferences}
            setPreferences={setPreferences}
          />
        }
      />
      {error && (
        <Alert
          statusIconAriaLabel='Error'
          type='error'
          header='Error getting data'
        >
          Refresh the page to try again.
        </Alert>
      )}
    </>
  );
}
