import * as React from 'react';
import { mutate } from 'swr';
import { v4 as uuidv4 } from 'uuid';
import { useFlash } from './useFlash';
import { useModal } from './modalContext';
import { UseDocumentAction, HandleApiWithFlashOptions, ResponsePayload, FetcherFunction } from '../../types/api';
import { ConfirmDeleteModal } from '../../components/confirmDeleteModal';

export const useApiWithFlashBase = (fetcherFunction: FetcherFunction): UseDocumentAction => {
  const { addFlash, removeFlash } = useFlash();
  const { showModal, hideModal } = useModal();

  const actualApiCall = async (url: string, method: 'PATCH' | 'DELETE' | 'POST' | 'PUT', options: HandleApiWithFlashOptions): Promise<ResponsePayload> => {
    try {
      await fetcherFunction(url, method, options.data, options.instanceArn);
      const messageId = uuidv4();
      if (options.successMessage && options.successMessage.trim() !== '') {
        addFlash({
          type: 'success',
          content: options.successMessage,
          dismissible: true,
          dismissLabel: 'Dismiss',
          onDismiss: () => removeFlash(messageId),
          id: messageId,
        });
      }

      if (options.mutateKey) {
        await mutate(options.mutateKey);
      }
      return { success: true };
    } catch (error: any) {
      const messageId = uuidv4();
      const errorCode = error.status || 500;
      const errorMessage = error.message || 'An error occurred!';
      addFlash({
        type: 'error',
        content: options.errorMessage,
        dismissible: true,
        dismissLabel: 'Dismiss',
        onDismiss: () => removeFlash(messageId),
        id: messageId,
      });
      return { success: false, errorCode, errorMessage };
    }
  };

  const handleApiWithFlash = async (
    url: string,
    method: 'PATCH' | 'DELETE' | 'POST' | 'PUT',
    options: HandleApiWithFlashOptions,
  ): Promise<ResponsePayload | undefined> => {
    if (method === 'DELETE') {
      return new Promise((resolve) => {
        showModal(
          <ConfirmDeleteModal
            deleteMessage={options.deleteMessage || 'Are you sure you want to delete?'}
            onConfirm={() =>
              actualApiCall(url, method, options).then((response) => {
                hideModal();
                resolve(response);
              })
            }
            onCancel={() => {
              hideModal();
              resolve(undefined);
            }}
          />,
        );
      });
    } else {
      return await actualApiCall(url, method, options);
    }
  };

  return { handleApiWithFlash };
};
