import { SideNavigationProps } from '@cloudscape-design/components/side-navigation';
import { BreadcrumbGroupProps } from '@cloudscape-design/components/breadcrumb-group';
import { ResourceType, ResourceTypes } from '../../types/rolePermissions';

export type ProtectedLink = SideNavigationProps.Link & {
  resourceType: ResourceType;
};

export type ProtectedSectionGroup = {
  type: 'section-group';
  title: string;
  instanceRequiredField?: 'instanceArn' | 'instanceAlias' | 'instanceRegion' | 'instanceIntuitionUrl';
  items: ReadonlyArray<ProtectedLink>;
};

export const items: ProtectedSectionGroup[] = [
  {
    type: 'section-group',
    title: 'Simplify',
    items: [
      {
        type: 'link',
        text: 'Messages',
        href: '/messages',
        resourceType: ResourceTypes.Messages,
      },
      {
        type: 'link',
        text: 'Closures',
        href: '/closures',
        resourceType: ResourceTypes.Closures,
      },
      {
        type: 'link',
        text: 'Dial Plan',
        href: '/dial-plan',
        resourceType: ResourceTypes.DialPlan,
      },
      {
        type: 'link',
        text: 'Settings',
        href: '/settings',
        resourceType: ResourceTypes.Instance,
      },
      // {
      //   type: 'link',
      //   text: 'Utilities',
      //   href: '/utilities',
      //   resourceType: ResourceTypes.Instance,
      // },
    ],
  },
  {
    type: 'section-group',
    title: 'Surveys',
    items: [
      {
        type: 'link',
        text: 'Surveys',
        href: '/surveys',
        resourceType: ResourceTypes.Surveys,
      },
      {
        type: 'link',
        text: 'Settings',
        href: '/surveys/settings',
        resourceType: ResourceTypes.Surveys,
      },
    ],
  },
  {
    type: 'section-group',
    title: 'Intuition',
    instanceRequiredField: 'instanceIntuitionUrl',
    items: [
      {
        type: 'link',
        text: 'Visualizations',
        href: '/visualizations',
        resourceType: ResourceTypes.Dashboards,
      },
      {
        type: 'link',
        text: 'Contact Search',
        href: '/contact-search',
        resourceType: ResourceTypes.ContactSearch,
      },
    ],
  },
  {
    type: 'section-group',
    title: 'xQ',
    items: [
      {
        type: 'link',
        text: 'Gen AI Chatbot',
        href: '/chatbot-testing',
        resourceType: ResourceTypes.Xq,
      },
      {
        type: 'link',
        text: 'Documents',
        href: '/documents',
        resourceType: ResourceTypes.Xq,
      },
      {
        type: 'link',
        text: 'Prompt Engineering',
        href: '/prompt-engineering',
        resourceType: ResourceTypes.Xq,
      },
    ],
  },
  {
    type: 'section-group',
    title: 'Administration',
    items: [
      {
        type: 'link',
        text: 'Instances',
        href: '/instances',
        resourceType: ResourceTypes.Admin,
      },
      {
        type: 'link',
        text: 'User Admin',
        href: '/user-admin',
        resourceType: ResourceTypes.Admin,
      },
      {
        type: 'link',
        text: 'Roles',
        href: '/roles',
        resourceType: ResourceTypes.Admin,
      },
      {
        type: 'link',
        text: 'Tickets',
        href: '/tickets',
        resourceType: ResourceTypes.Admin,
      },
      {
        type: 'link',
        text: 'Audit Log',
        href: '/audit-log',
        resourceType: ResourceTypes.Admin,
      },
    ],
  },
];

type BreadcrumbItem = BreadcrumbGroupProps['items'][0];
type BreadcrumbMapping = {
  [key: string]: BreadcrumbItem[];
};

const breadcrumbMapping: BreadcrumbMapping = {
  '/': [{ text: 'Home', href: '/' }],
  '/audit-log': [{ text: 'Audit Log', href: '/audit-log' }],
  '/user-admin': [{ text: 'User Admin', href: '/user-admin' }],
  '/user-admin/create': [{ text: 'Create', href: '/user-admin/create' }],
  '/roles': [{ text: 'Role Admin', href: '/roles' }],
  '/roles/create': [{ text: 'Create', href: '/roles/create' }],
  '/roles/edit': [{ text: 'Edit', href: '/roles/edit' }],

  '/tickets': [{ text: 'Tickets', href: '/tickets' }],
  '/tickets/create': [{ text: 'Create', href: '/tickets/create' }],

  '/prompt-engineering': [{ text: 'Prompt Engineering', href: '/prompt-engineering' }],
  '/prompt-engineering/create': [{ text: 'Create', href: '/prompt-engineering/create' }],
  '/prompt-engineering/edit': [{ text: 'Edit', href: '/prompt-engineering/edit' }],
  '/dial-plan': [{ text: 'Dial Plan', href: '/dial-plan' }],
  '/dial-plan/create': [{ text: 'Create', href: '/dial-plan/create' }],
  '/dial-plan/edit': [{ text: 'Edit', href: '/dial-plan/edit' }],
  '/dial-plan/copy': [{ text: 'Copy', href: '/dial-plan/copy' }],
  '/messages': [{ text: 'Message Groups', href: '/messages' }],
  '/messages/create': [{ text: 'Create', href: '/messages/create' }],
  '/messages/edit': [{ text: 'Edit', href: '/messages/edit' }],
  '/messages/copy': [{ text: 'Copy', href: '/messages/copy' }],
  '/closures': [{ text: 'Closures', href: '/closures' }],
  '/closures/create': [{ text: 'Create', href: '/closures/create' }],
  '/closures/edit': [{ text: 'Edit', href: '/closures/edit' }],
  '/closures/copy': [{ text: 'Copy', href: '/closures/copy' }],
  '/contact-search': [{ text: 'Contact Search', href: '/contact-search' }],
  '/contact-search/view': [{ text: 'View', href: '/contact-search/view' }],
  '/settings': [{ text: 'View', href: '/settings' }],
  '/surveys': [{ text: 'Surveys', href: '/surveys' }],
  '/surveys/edit': [{ text: 'Edit', href: '/surveys/edit' }],
  '/surveys/create': [{ text: 'Create', href: '/surveys/create' }],
  '/surveys/settings': [{ text: 'Settings', href: '/surveys/settings' }],
  '/visualizations': [{ text: 'Visualizations', href: '/visualizations' }],
  '/visualizations/create': [{ text: 'Create', href: '/visualizations/create' }],
  '/visualizations/view': [{ text: 'View', href: '/visualizations/view' }],
  '/visualizations/edit': [{ text: 'Edit', href: '/visualizations/edit' }],
  '/visualizations/copy': [{ text: 'Copy', href: '/visualizations/copy' }],
  '/instances': [{ text: 'Instances', href: '/instances' }],
  '/instances/create': [{ text: 'Create', href: '/instances/create' }],
  '/instances/edit': [{ text: 'Edit', href: '/instances/edit' }],
};

export function getBreadcrumbsForPath(path: string): BreadcrumbItem[] {
  const pathParts = path.split('/').filter((p) => p);

  const breadcrumbItems = [];
  let currentPath = '';

  for (const part of pathParts) {
    currentPath += `/${part}`;

    if (breadcrumbMapping[currentPath]) {
      breadcrumbItems.push(...breadcrumbMapping[currentPath]);
    }
  }

  return breadcrumbItems.length ? breadcrumbItems : [{ text: 'Home', href: '/' }];
}
