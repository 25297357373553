import React, { useCallback, useState } from 'react';
import {
  ColumnLayout,
  Container,
  Header,
  ContentLayout,
  SpaceBetween,
  Form,
  Input,
  Button,
  FormField,
  Autosuggest,
  AutosuggestProps,
} from '@cloudscape-design/components';
import { useLocation, useNavigate } from 'react-router-dom';
import useChangeDetector from '../../../utils/useChangeDetector';
import { useApiWithFlash } from '../../../utils/hooks/useApiWithFlash';
import tzList from '../../../objects/tz';
import { mutate } from 'swr';
import { useBlockerLogic } from '../../../utils/hooks/useBlocker';
import { ConfirmCancelModal } from '../../../components/confirmCancelModal';

export default function InstancesCreate() {
  const location = useLocation();
  const { instances } = location.state || { instances: [] };

  const initialFormState = {
    arn: '',
    name: '',
    data: {
      timezone: '',
    },
  };
  // state to control changes made
  const { item, setItem, changesDetected } = useChangeDetector(initialFormState);

  const [arnError, setArnError] = useState<boolean>(false);

  // state
  const [resourceError, setResourceError] = useState<boolean>(true);
  const [resourceErrorClosure, setResourceErrorClosure] = useState<string>('');
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [timezone, setTimezone] = useState<string>('');

  const { blocker, handleCancel, handleCloseCancelModal, handleConfirmCancel } = useBlockerLogic({
    changesDetected,
    path: 'instances',
    formSubmitted,
  });

  // Hooks
  const navigate = useNavigate();
  const { handleApiWithFlash } = useApiWithFlash();

  // Functions to handle form changes
  const handleDropdownChange = useCallback(
    (field: any, value: any) => {
      setItem((prev: any) => ({ ...prev, [field]: value }));
    },
    [setItem],
  );

  const timezoneSugestProps: AutosuggestProps = {
    onChange: ({ detail }) => {
      setTimezone(detail.value);
      const isInList = !!tzList.find((timezoneItem) => timezoneItem.value === detail.value);
      isInList && setItem({ ...item, data: { ...item.data, timezone: detail.value } });
    },
    value: timezone,
    options: tzList,
    ariaLabel: 'Select a timezone',
    placeholder: 'Enter a timezone',
  };
  const validateResource = (value: string, field: string) => {
    if (value.length >= 1) {
      if (field === 'arn') {
        const arnPattern = /^arn:aws:connect:[a-z0-9-]+:[0-9]+:instance\/[a-f0-9-]+$/;
        if (!arnPattern.test(value)) {
          setResourceError(true);
          setResourceErrorClosure('Invalid ARN format');
        } else {
          setResourceError(false);
          setResourceErrorClosure('');
        }
      }
    }
  };

  const handleResourceChange = (value: string) => {
    validateResource(value, 'arn');

    const arnExists = instances.some((instance: { instanceArn: string }) => instance.instanceArn === value);
    setArnError(arnExists);

    handleDropdownChange('arn', value);
  };

  const handleDescriptionChange = (value: string) => {
    validateResource(value, 'name');
    handleDropdownChange('name', value);
  };
  const onRefresh = async () => {
    await mutate('/instances');
  };

  // Handle form submission
  async function handleSubmitForm() {
    setFormSubmitted(true);

    const payload = {
      arn: item.arn,
      name: item.name || '',
      timezone: timezone || '',
    };

    await handleApiWithFlash(`/instances`, 'POST', {
      successMessage: `Successfully created an Instance ${payload.name}`,
      errorMessage: `Error creating Instance ${payload.name}`,
      data: payload,
    });
    await onRefresh();
    navigate(`/instances`);
  }

  return (
    <ContentLayout header={<Header variant='h1'></Header>}>
      <Container
        header={
          <Header
            variant='h2'
            description='Use this form to create a new Instance. On clicking submit a new Instance will be created '
          >
            Create a new Instance
          </Header>
        }
      >
        <form
          onSubmit={(e) => {
            console.log(e);
            e.preventDefault();
            handleSubmitForm();
          }}
        >
          <Form
            variant='embedded'
            actions={
              <SpaceBetween
                direction='horizontal'
                size='xs'
              >
                <Button
                  formAction='none'
                  variant='link'
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  variant='primary'
                  loading={formSubmitted}
                  disabled={resourceError}
                >
                  Submit
                </Button>
              </SpaceBetween>
            }
          >
            <SpaceBetween
              direction='vertical'
              size='xl'
            >
              <ColumnLayout
                columns={2}
                variant='text-grid'
              >
                <FormField
                  label='ARN'
                  errorText={arnError ? 'ARN already exists' : resourceError ? resourceErrorClosure : ''}
                >
                  <Input
                    onChange={({ detail }) => handleResourceChange(detail.value)}
                    value={item?.arn}
                    placeholder='arn:aws:connect:eu-east-2:336473918870:instance/fdb5v772-1659-468b-8003-aec5025538eb'
                  />
                </FormField>
                <FormField
                  label='Name'
                  errorText={resourceError ? resourceErrorClosure : ''}
                >
                  <Input
                    onChange={({ detail }) => handleDescriptionChange(detail.value)}
                    value={item?.name}
                    placeholder='Name for your Instance'
                  />
                </FormField>
                <FormField label='Timezone'>
                  <Autosuggest {...{ ...timezoneSugestProps }} />
                </FormField>
              </ColumnLayout>
            </SpaceBetween>
          </Form>
        </form>
        {blocker.state === 'blocked' && (
          <ConfirmCancelModal
            {...{
              onCancel: handleCloseCancelModal,
              onConfirm: handleConfirmCancel,
              cancelMessage: 'Are you sure you want to close? Any unsaved changes will be lost.',
            }}
          />
        )}
      </Container>
    </ContentLayout>
  );
}
