import React, { createContext } from 'react';
import { Header, Spinner, Table, TableProps, Badge } from '@cloudscape-design/components';
import { WidgetConfig } from '../interfaces';
import { EmptyState } from '../../empty-state';
import _ from 'lodash';
import Link from '@cloudscape-design/components/link';
import useInstanceSWR from '../../../../../../../utils/hooks/useInstanceSWR';

export const getClosures: WidgetConfig = {
  definition: { defaultRowSpan: 1, defaultColumnSpan: 1, minRowSpan: 1 },
  data: {
    icon: 'list',
    title: 'Unplanned Closures',
    description: `Shows only queues that are currently in the status of "unplanned closure"`,
    header: ChartHeader,
    content: Closures,
    provider: ClosuresProvider,
    staticMinHeight: 560,
    multipleAllowed: true,
  },
};

interface ClosuresProviderProps {
  children: React.ReactElement;
  widgetId: string;
}
interface ClosuresWidgetContextType {
  widgetId: string;
}

interface Item {
  feature: string;
  description: string;
  timezone: string;
  status: string;
}

const ClosuresWidgetContext = createContext<ClosuresWidgetContextType>({
  widgetId: '',
});

function ClosuresProvider(props: ClosuresProviderProps) {
  const { widgetId, children } = props;
  return (
    <ClosuresWidgetContext.Provider
      value={{
        widgetId,
      }}
    >
      {React.cloneElement(React.Children.only(children), {
        removeConfirmationText: 'Instance Queue Metrics',
      })}
    </ClosuresWidgetContext.Provider>
  );
}

function ChartHeader() {
  return <Header variant="h2">Unplanned Closures</Header>;
}

export default function Closures() {
  // Conditionally set the endpoint to null if queueId doesn't exist
  const endpoint = `/closures`;

  const { data, isLoading, error } = useInstanceSWR(endpoint, {
    shouldRetryOnError: true,
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    refreshInterval: 5000,
  });

  if (error || !data) {
    return <EmptyState title="Error retrieving data" description="Please check your connection and try again" verticalCenter={true} />;
  }

  if (isLoading) {
    return <Spinner />;
  }

  const tableDefinition: TableProps<Item>['columnDefinitions'] = [
    {
      id: 'feature',
      header: 'Group',
      cell: (item) => (
        <Link target="_blank" rel="noopener noreferrer" href={`/closures/edit/${item.feature}`}>
          {item.feature}
        </Link>
      ),
      width: 75,
      isRowHeader: true,
    },
    {
      id: 'status',
      header: 'Status',
      cell: (item) => {
        if (item?.status === 'unplannedClosure') {
          return <Badge color="red">Unplanned Closure</Badge>;
        } else if (item?.status === 'closed') {
          return <Badge color="grey">Closed</Badge>;
        } else if (item?.status === 'open') {
          return <Badge color="green">Open</Badge>;
        } else if (item?.status === 'plannedClosure') {
          return <Badge color="blue">Planned Closure</Badge>;
        } else {
          return '-';
        }
      },
    },
  ];

  const filteredData = _.filter(data.data, { status: 'unplannedClosure' });

  return <Table variant="borderless" items={filteredData} columnDefinitions={tableDefinition} loading={isLoading} />;
}
