import React, { useEffect } from 'react';
import { App } from './configurable-dashboard/app';
import { AppLoadingError } from './configurable-dashboard/appLoadingError';
import { DashboardProvider, useItemContext } from './dashboardContext';
import { useParams } from 'react-router-dom';
import { AppLoading } from './configurable-dashboard/appLoading';
import useInstanceSWR from '../../../utils/hooks/useInstanceSWR';
import { DashboardDataRepresentation } from './configurable-dashboard/components/widgets/interfaces';

export default function VisualizationsView() {
  return (
    <DashboardProvider>
      <VisualizationContent />
    </DashboardProvider>
  );
}

function VisualizationContent() {
  const { setItem, setItemShadow } = useItemContext();
  const { featureId } = useParams();

  const { data, isLoading, error } = useInstanceSWR<DashboardDataRepresentation>(`/intuition/dashboards/${featureId}`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 0,
  });

  useEffect(() => {
    if (data?.data) {
      setItem(data.data);
      setItemShadow(data.data);
    }
  }, [data]);

  if (isLoading) {
    return <AppLoading />;
  } else if (error) {
    return <AppLoadingError />;
  } else {
    return <App />;
  }
}
