import { CollectionPreferences } from "@cloudscape-design/components";
import { PreferenceProps } from "../types/table";

export const TablePreferences = ({
    preferences,
    setPreferences,
    disabled = false,
    pageSizeOptions = [],
    contentDisplayOptions = [],
  }: PreferenceProps) => (
    <CollectionPreferences
      title={'Preferences'}
      cancelLabel={'Cancel'}
      confirmLabel={'Confirm'}
      disabled={disabled}
      preferences={preferences}
      onConfirm={({ detail }) => setPreferences(detail)}
      pageSizePreference={{ title: 'Page size', options: pageSizeOptions }}
      wrapLinesPreference={{ label: 'Wrap lines', description: 'Select to see all the text and wrap the lines' }}
      stripedRowsPreference={{ label: 'Striped rows', description: 'Select to add alternating shaded rows' }}
      contentDensityPreference={{
        label: 'Compact mode',
        description: 'Select to display content in a denser, more compact mode',
      }}
      contentDisplayPreference={{ title: 'Select visible content / order', options: contentDisplayOptions }}
      stickyColumnsPreference={{
        firstColumns: {
          title: 'Stick first column(s)',
          description: 'Keep the first column(s) visible while horizontally scrolling the table content.',
          options: [
            { label: 'None', value: 0 },
            { label: 'First column', value: 1 },
            { label: 'First two columns', value: 2 },
          ],
        },
        lastColumns: {
          title: 'Stick last column',
          description: 'Keep the last column visible while horizontally scrolling the table content.',
          options: [
            { label: 'None', value: 0 },
            { label: 'Last column', value: 1 },
          ],
        },
      }}
    />
  );
