import { ProtectedUtilityProps } from './types';
import { useProtectUtility } from './useProtectedUtility';

/**
 * React component to protect a utility component
 * @param props
 * @returns JSX.Element
 */
export const ProtectedUtility = (props: ProtectedUtilityProps) => {
  const protectUtility = useProtectUtility();

  const canShowUtility = protectUtility(props);

  return <>{canShowUtility && props.children}</>;
};
