import React, { useState } from 'react';
import useInstanceSWR from '../../../utils/hooks/useInstanceSWR';
import {
  Box,
  SpaceBetween,
  SplitPanel,
  Tabs,
  Table,
  Modal,
  Button,
  ColumnLayout,
  Badge,
  FormField,
  StatusIndicator,
  Spinner,
} from '@cloudscape-design/components';
import {
  ClosureSplitPanelDetailsProps,
  LanguageMessage,
  PlannedClosure,
} from '../../../types/closures';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLanguage } from '@fortawesome/free-solid-svg-icons';
import Container from '@cloudscape-design/components/container';
import sortEvents from '../../../utils/sortevents';
import { isDateExpired } from '../../../utils/isDateExpired';

const TranslationsTable: React.FC<{
  message: LanguageMessage[] | null;
}> = ({ message }) => {
  if (!message) return <p>No translations available.</p>;

  return (
    <Table
      wrapLines
      columnDefinitions={[
        {
          id: 'lang',
          header: 'Language',
          cell: (translation) => translation.lang || '-',
          width: 100,
          minWidth: 90,
          isRowHeader: true,
        },
        {
          id: 'text',
          header: 'Translation',
          cell: (translation) =>
            translation.message[0]?.children[0]?.text || '-',
          width: 300,
          minWidth: 290,
        },
      ]}
      items={message}
      loadingText='Loading translations...'
    />
  );
};

const ClosuresSplitPanelDetails: React.FC<ClosureSplitPanelDetailsProps> = ({
  selectedItems,
}) => {
  const [visible, setVisible] = useState(false);
  const [currentMessage, setCurrentMessage] = useState<
    LanguageMessage[] | null
  >(null);

  const selectedFeature = selectedItems.feature ?? 'N/A';
  const selectedDescription = selectedItems.description ?? 'N/A';
  const selectedTimezone = selectedItems.timezone ?? 'N/A';

  const { data, isLoading, error } = useInstanceSWR(
    `/closures/${selectedFeature}`,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshInterval: 0,
    }
  );

  if (isLoading || !data || error) {
    return (
      <SplitPanel header={`Closure Group:`} closeBehavior='collapse'>
        {error ? <>{error.info.message}</> : <Spinner />}
      </SplitPanel>
    );
  }

  const hoops = data?.data?.data?.hoops || [];
  const plannedClosures = data?.data?.data?.plannedClosures || [];
  const unplannedClosure = data?.data?.data?.unplannedClosure || [];

  const sortedPlannedClosures: PlannedClosure[] = sortEvents(plannedClosures);

  const openModalWithMessage = (message: LanguageMessage[]) => {
    setCurrentMessage(message);
    setVisible(true);
  };

  const tab1 = (
    <SpaceBetween size='l'>
      <Table
        columnDefinitions={[
          {
            id: 'day',
            header: 'Day',
            cell: (item) => (item as { day?: string }).day || '-',
            width: 200,
            minWidth: 165,
            isRowHeader: true,
          },
          {
            id: 'openTime',
            header: 'Open Time',
            cell: (item) => (item as { openTime?: string }).openTime || '-',
            width: 200,
            minWidth: 165,
            isRowHeader: true,
          },
          {
            id: 'closeTime',
            header: 'Closing Time',
            cell: (item) => (item as { closeTime?: string }).closeTime || '-',
            width: 200,
            minWidth: 165,
            isRowHeader: true,
          },
        ]}
        items={hoops.days}
        loadingText='Loading opening hours...'
      />
      <Container>
        <ColumnLayout columns={1}>
          <FormField label='Message'>
            {hoops.message[0]?.message[0]?.children[0]?.text}
          </FormField>
          <FormField label='Translations'>
            <FontAwesomeIcon
              icon={faLanguage}
              style={{ cursor: 'pointer' }}
              onClick={() => openModalWithMessage(hoops.message)}
              title='View Translations'
            />
          </FormField>
        </ColumnLayout>
      </Container>
    </SpaceBetween>
  );

  const tab2 = (
    <Table
      stickyColumns={{ first: 1, last: 0 }}
      columnDefinitions={[
        {
          id: 'description',
          header: 'Description',
          cell: (item) => {
            return (
              <>
                {item.description}
                {isDateExpired(item) && (
                  <StatusIndicator type='warning'>Expired</StatusIndicator>
                )}
              </>
            );
          },
          width: 150,
          minWidth: 150,
          isRowHeader: true,
        },
        {
          id: 'date',
          header: 'Date',
          cell: (item) => item.date || '-',
          width: 50,
          minWidth: 50,
          isRowHeader: true,
        },
        {
          id: 'startTime',
          header: 'Start',
          cell: (item) => item.startTime || '-',
          width: 50,
          minWidth: 50,
          isRowHeader: true,
        },
        {
          id: 'endTime',
          header: 'End',
          cell: (item) => item.endTime || '-',
          width: 50,
          minWidth: 50,
          isRowHeader: true,
        },
        {
          id: 'recurrence',
          header: 'Recurrence',
          cell: (item) => item.recurrence || '-',
          width: 50,
          minWidth: 50,
          isRowHeader: true,
        },
        {
          id: 'recurrenceEnd',
          header: 'Recurrence End',
          cell: (item) => item.recurrenceEnd || '-',
          width: 50,
          minWidth: 50,
          isRowHeader: true,
        },
        {
          id: 'defaultMessage',
          header: 'Default Message',
          cell: (item) => {
            const defaultMessage = item.message.find(
              (m) => m.lang === 'default'
            );
            return defaultMessage?.message[0]?.children[0]?.text || '-';
          },
          width: 200,
          minWidth: 200,
          isRowHeader: true,
        },
        {
          id: 'translations',
          header: 'Translations',
          cell: (message) => (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <FontAwesomeIcon
                icon={faLanguage}
                style={{ cursor: 'pointer' }}
                onClick={() => openModalWithMessage(message.message)}
                title='View Translations'
              />
            </div>
          ),
          width: 50,
          minWidth: 50,
        },
      ]}
      items={sortedPlannedClosures}
      loadingText='Loading planned closures...'
    />
  );

  const tab3 = (
    <Container>
      <ColumnLayout columns={1}>
        <FormField label='Status'>
          {unplannedClosure.active === true && (
            <Badge color='red'>Unplanned closure active</Badge>
          )}{' '}
          {unplannedClosure.active !== true && (
            <Badge>Unplanned closure not active</Badge>
          )}
        </FormField>
        <FormField label='Message'>
          {unplannedClosure.message[0]?.message[0]?.children[0]?.text}
        </FormField>
        <FormField label='Translations'>
          <FontAwesomeIcon
            icon={faLanguage}
            style={{ cursor: 'pointer' }}
            onClick={() => openModalWithMessage(unplannedClosure.message)}
            title='View Translations'
          />
        </FormField>
      </ColumnLayout>
    </Container>
  );

  return (
    <SplitPanel
      header={`Closure Group: ${selectedFeature}`}
      closeBehavior='collapse'
    >
      <SpaceBetween size='l'>
        <Box>
          {selectedDescription} - ({selectedTimezone})
        </Box>
        <Tabs
          tabs={[
            {
              label: 'Opening Hours',
              id: 'first',
              content: tab1,
            },
            {
              label: 'Planned Closures',
              id: 'second',
              content: tab2,
            },
            {
              label: 'Unplanned Closure',
              id: 'third',
              content: tab3,
            },
          ]}
        />
      </SpaceBetween>
      <Modal
        onDismiss={() => setVisible(false)}
        visible={visible}
        size='max'
        footer={
          <Box float='right'>
            <SpaceBetween direction='horizontal' size='xs'>
              <Button variant='link' onClick={() => setVisible(false)}>
                Close
              </Button>
            </SpaceBetween>
          </Box>
        }
        header='Translations'
      >
        <TranslationsTable message={currentMessage} />
      </Modal>
    </SplitPanel>
  );
};

export default ClosuresSplitPanelDetails;
