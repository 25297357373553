import React from 'react';
import GetAudioFromS3, { GetAudioFromS3ChildProps } from './getAudioFileFromS3';
import Waveform from './WaveForm';
import { Spinner } from '@cloudscape-design/components';
import Alert from '@cloudscape-design/components/alert';
import { ContactRecord } from '../../../../../types/contactRecord';

export interface AudioPlaybackWidgetProps {
  contactObject: ContactRecord;
  setAudioPosition: React.Dispatch<React.SetStateAction<number>>;
  audioURL: string;
}

// eslint-disable-next-line react/prop-types
export default function AudioPlaybackWidget(props: AudioPlaybackWidgetProps) {
  const { contactObject, setAudioPosition, audioURL } = props;
  return (
    <GetAudioFromS3 url={audioURL}>
      {({ blobURL, isLoading, error }: GetAudioFromS3ChildProps) =>
        error ? (
          <Alert statusIconAriaLabel="Warning" type="warning">
            Unable to access the audio file
          </Alert>
        ) : isLoading ? (
          <Spinner />
        ) : blobURL ? (
          <main style={{ padding: '16px' }}>
            <Waveform audio={blobURL} contactObject={contactObject} setAudioPosition={setAudioPosition} />
          </main>
        ) : (
          <div>Waiting for audio...</div>
        )
      }
    </GetAudioFromS3>
  );
}
