import React, { useEffect, useState } from 'react';
import { ColumnLayout, Container, Header, ContentLayout, SpaceBetween, Textarea, Form, Input, Button, FormField } from '@cloudscape-design/components';
import RadioGroup, { RadioGroupProps } from '@cloudscape-design/components/radio-group';
import useChangeDetector from '../../../utils/useChangeDetector';
import { handleFieldChange } from '../../../utils/fieldChangeUtils';
import { isValidTag } from '../../../utils/validations';
import { TagIcon } from '../../utilities/tagIcon';
import { AvailableDialPlan, AvailableDialPlans, DialPlanItem } from '../types';
import { ConfirmCancelModal } from '../../../components/confirmCancelModal';
import { useBlockerLogic } from '../../../utils/hooks/useBlocker';

const constraintTextMessages = {
  BLOCKED: 'Must start with a +',
  RESOURCE: 'Only alphanumeric, - _ and + supported',
  VIP: 'Must start with a +',
  EXTENSION: 'Only numeric characters allowed',
};

const constraintTextLabels = {
  RESOURCE: 'Provide a resource group name',
  EXTENSION: 'Provide an extension number',
  BLOCKED: 'Provide the e164 number',
  VIP: 'Provide the e164 number',
};

export interface EditDialPlanFormProps {
  onSubmitForm: (dialPlan: DialPlanItem) => Promise<void>;
  dialPlan?: DialPlanItem;
}

export default function EditDialPlanForm({ onSubmitForm, dialPlan: initialDialPlan }: Readonly<EditDialPlanFormProps>) {
  const { feature, description, tag, data } = initialDialPlan || { feature: '', description: '', tag: '', data: { type: AvailableDialPlan.Resource } };

  const { item, setItem, changesDetected } = useChangeDetector<DialPlanItem>({ feature, description, tag, data });
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const handleDropdownChange = handleFieldChange(setItem);

  // state
  const [resourceError, setResourceError] = useState<boolean>(true);
  const [resourceErrorMessage, setResourceErrorMessage] = useState<string>('');
  const [tagError, setTagError] = useState<boolean>(false);
  const [tagErrorMessage, setTagErrorMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { blocker, handleCancel, handleCloseCancelModal, handleConfirmCancel } = useBlockerLogic({
    changesDetected,
    path: 'dial-plan',
    formSubmitted,
  });

  // Hooks

  const validateTag = (value: string) => {
    // Define patterns
    const validTag = isValidTag(value);

    if (!validTag) {
      setTagError(true);
      setTagErrorMessage('Only lowercase alphanumeric, - _ and + supported');
    } else {
      setTagError(false);
      setTagErrorMessage('');
    }
  };

  const validateResource = (value: string) => {
    // Define patterns
    const generalPattern = /^[a-zA-Z0-9+_-]+$/;
    const e164Pattern = /^\+\d{1,15}$/;

    const e164Error = !e164Pattern.test(value);
    const extensionError = value?.length >= 6;
    const resourceHasError = !generalPattern.test(value);

    switch (item.data.type) {
      case 'VIP':
      case 'BLOCKED':
        setResourceError(e164Error);
        setResourceErrorMessage((e164Error && 'Please provide a valid E.164 phone number format') || '');
        break;
      case 'EXTENSION':
        setResourceError(extensionError);
        setResourceErrorMessage((extensionError && 'Extension number must be less than 6 digits') || '');
        break;
      case 'RESOURCE':
        setResourceError(resourceHasError);
        setResourceErrorMessage((resourceHasError && 'Only alphanumeric, - _ and + supported. Min 6 characters') || '');
        break;

      default:
        setResourceError(false);
        setResourceErrorMessage('');
        break;
    }
  };

  useEffect(() => {
    validateResource(item.feature);
  }, [item.data.type, item.feature]);

  useEffect(() => {
    item.tag && validateTag(item.tag);
  }, [item.tag]);

  const handleResourceChange = (value: string) => {
    handleDropdownChange('feature', value);
  };

  const handleDescriptionChange = (value: string) => {
    handleDropdownChange('description', value);
  };

  const handlePrefixChange = (value: string) => {
    handleDropdownChange('data', {
      ...item.data,
      prefix: value,
    });
  };

  const handleQueueChange = (value: string) => {
    handleDropdownChange('data', {
      ...item.data,
      queue: value,
    });
  };

  const handleTypeChange = (value: string) => {
    handleDropdownChange('data', {
      ...item.data,
      type: value as AvailableDialPlans,
    });
  };

  const handleTagChange = (value: string) => {
    handleDropdownChange('tag', value);
  };

  // Handle form submission
  async function handleSubmitForm() {
    setIsLoading(true);
    setFormSubmitted(true);
    await onSubmitForm(item);
  }

  const DialPlanSelectorProps: RadioGroupProps = {
    onChange: ({ detail }) => handleTypeChange(detail.value),
    value: item.data.type ?? '',
    items: [
      {
        value: 'RESOURCE',
        label: 'Resource Group',
        description: 'Create a dial plan group, used for attribute related call handling',
        disabled: !!initialDialPlan?.feature,
      },
      {
        value: 'VIP',
        label: 'VIP Caller',
        description: 'Add a phone number to the VIP list',
        disabled: !!initialDialPlan?.feature,
      },
      {
        value: 'BLOCKED',
        label: 'Blocked Caller',
        description: 'Add a phone number to the blocked list',
        disabled: !!initialDialPlan?.feature,
      },
      {
        value: 'EXTENSION',
        label: 'Extension',
        description: 'Add an extension code for an agent/queue',
        disabled: !!initialDialPlan?.feature,
      },
    ],
  };

  const canSubmit = item.feature && !resourceError && !tagError && changesDetected;
  const createEditLabel = (!!initialDialPlan?.feature && `Edit ${initialDialPlan.feature}`) || 'Create a new';

  return (
    <ContentLayout header={<Header variant='h1'></Header>}>
      <Container
        header={
          <Header
            variant='h2'
            description={`Use this form to ${createEditLabel} dial plan`}
          >
            {createEditLabel} dial plan
          </Header>
        }
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmitForm();
          }}
        >
          <Form
            variant='embedded'
            actions={
              <SpaceBetween
                direction='horizontal'
                size='xs'
              >
                <Button
                  formAction='none'
                  variant='link'
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  variant='primary'
                  loading={isLoading}
                  disabled={!canSubmit}
                >
                  Submit
                </Button>
              </SpaceBetween>
            }
          >
            <SpaceBetween
              direction='vertical'
              size='xl'
            >
              <ColumnLayout
                columns={2}
                variant='text-grid'
              >
                <FormField label='Dial Plan selector'>
                  <RadioGroup {...{ ...DialPlanSelectorProps }} />
                </FormField>

                {item.data.type === 'RESOURCE' && (
                  <ColumnLayout
                    columns={1}
                    variant='text-grid'
                  >
                    <FormField
                      constraintText='Provide a comma separated list of prefixes using e164'
                      label='Prefix'
                    >
                      <Input
                        onChange={({ detail }) => handlePrefixChange(detail.value)}
                        value={item.data.prefix ?? ''}
                        placeholder={'+449, +44871, +44872'}
                      />
                    </FormField>
                  </ColumnLayout>
                )}
                {item.data.type === 'EXTENSION' && (
                  <ColumnLayout
                    columns={1}
                    variant='text-grid'
                  >
                    <FormField label='Agent/ Queue'>
                      <Input
                        onChange={({ detail }) => handleQueueChange(detail.value)}
                        value={item.data.queue ?? ''}
                      />
                    </FormField>
                  </ColumnLayout>
                )}
              </ColumnLayout>
              {item.data.type && (
                <>
                  <ColumnLayout
                    columns={2}
                    variant='text-grid'
                  >
                    <FormField
                      constraintText={constraintTextMessages[item.data.type]}
                      label={constraintTextLabels[item.data.type]}
                      errorText={resourceErrorMessage}
                    >
                      <Input
                        onChange={({ detail }) => handleResourceChange(detail.value)}
                        value={item.feature}
                        type={item.data.type === 'EXTENSION' ? 'number' : 'text'}
                        disabled={!!initialDialPlan?.feature}
                      />
                    </FormField>
                    <FormField
                      constraintText={'Only alphanumeric, - _ and + supported'}
                      errorText={tagErrorMessage}
                      label={
                        <>
                          Tag your message group <TagIcon />
                        </>
                      }
                    >
                      <Input
                        onChange={({ detail }) => handleTagChange(detail.value)}
                        value={item?.tag ?? ''}
                      />
                    </FormField>
                  </ColumnLayout>
                  <FormField
                    label='Description'
                    stretch
                  >
                    <Textarea
                      onChange={({ detail }) => handleDescriptionChange(detail.value)}
                      value={item.description}
                    />
                  </FormField>
                </>
              )}
            </SpaceBetween>
          </Form>
        </form>
        {blocker.state === 'blocked' && (
          <ConfirmCancelModal
            {...{
              onCancel: handleCloseCancelModal,
              onConfirm: handleConfirmCancel,
              cancelMessage: 'Are you sure you want to close? Any unsaved changes will be lost.',
            }}
          />
        )}
      </Container>
    </ContentLayout>
  );
}
