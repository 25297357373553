import React from 'react';
import Header from '@cloudscape-design/components/header';
import SpaceBetween from '@cloudscape-design/components/space-between';
import { ContentLayout } from '@cloudscape-design/components';
import ClosuresTable from './components/table';
import useInstanceSWR from '../../utils/hooks/useInstanceSWR';

export default function Closures() {
  const { data, isLoading, error } = useInstanceSWR('/closures', {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 0,
  });
  return (
    <ContentLayout
      header={
        <Header
          variant='h1'
          description='Manage closure group configuration for VOICE and OMNI'
        >
          Manage Groups
        </Header>
      }
    >
      <SpaceBetween size='m'>
        <ClosuresTable
          isLoading={isLoading}
          error={error}
          closures={data?.data}
        />
      </SpaceBetween>
    </ContentLayout>
  );
}
