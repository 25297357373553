import * as React from "react";
import { RolePolicyRecord } from "../../types/rolePermissions";

export type UserTableProps = {
    isLoading: boolean;
    users: UsersArray;
    roles?: RolesArray;
    error: boolean;
    currentUser: string;
}

export type Items = {
    tenantId: string; 
    feature: string;
    name: string;
    description: string;
    policies: RolePolicyRecord[];
  }

export type AddUserProps = {
    visibleAddUser: boolean;
    setVisibleAddUser: React.Dispatch<React.SetStateAction<boolean>>;
}

export type RemoveUserProps = {
    visibleRemoveUser: boolean;
    selectedItems: User[] | undefined;
    setVisibleRemoveUser: React.Dispatch<React.SetStateAction<boolean>>;
}

export type Attribute = {
    Name:
        | "custom:tenantId"
        | "sub"
        | "custom:securityProfile"
        | "custom:roles"
        | "given_name"
        | "family_name"
        | "email";
    Value: string;
};

export enum UserStatuses {
    Uncomfirmed = "UNCONFIRMED",
    Confirmed = "CONFIRMED",
    Archived = "ARCHIVED",
    Compromised = "COMPROMISED",
    Unknown = "UNKNOWN",
    ResetRequired = "RESET_REQUIRED",
    ForceChangePassword = "FORCE_CHANGE_PASSWORD",
    ExternalProvider = "EXTERNAL_PROVIDER"
}

export type UserStatus = `${UserStatuses}`;

export type User = {
    Attributes: Attribute[];
    Enabled: boolean;
    UserCreateDate: string; // Can also be a Date
    UserLastModifiedDate: string; // Can also be a Date
    UserStatus: UserStatus;
    Username: string;
};


export type UsersArray = User[];
export type RolesArray = Items[];
