import React, { createContext, useState } from "react";
import { AgentStatusPreferences } from "./agentStatusPreferences";

interface AgentSummaryStatsWidgetContextType {
  openPreferences: () => void;
}
export const AgentStatusWidgetContext = createContext<AgentSummaryStatsWidgetContextType>({
  openPreferences: () => null,
});

export const AgentStatusProvider = ({ children, widgetId }: { children: React.ReactElement; widgetId: string }) => {
  const [preferencesVisible, setPreferencesVisible] = useState(false);

  return (
    <AgentStatusWidgetContext.Provider
      value={{
        openPreferences: () => setPreferencesVisible(true),
      }}
    >
      {React.cloneElement(React.Children.only(children), {
        removeConfirmationText: 'Operational metrics',
        actions: [{ text: 'Preferences', onClick: () => setPreferencesVisible(true) }],
      })}
      {preferencesVisible && (
        <AgentStatusPreferences
        widgetId={widgetId}
          onDismiss={() => setPreferencesVisible(false)}
        />
      )}
    </AgentStatusWidgetContext.Provider>
  );
}
