import React, { useEffect, useState } from 'react';
import Modal from '@cloudscape-design/components/modal';
import Spinner from '@cloudscape-design/components/spinner';
import {
  Box,
  SpaceBetween,
  Button,
  ColumnLayout,
  Container,
  FormField,
  Textarea,
} from '@cloudscape-design/components';
import Tabs from '@cloudscape-design/components/tabs';
import fetcher from '../../../utils/fetcher';
import { useApiWithFlash } from '../../../utils/hooks/useApiWithFlash';

interface ViewTicketModalProps {
  selectedItems: any;
  onClose: () => void;
}

const ViewTicketModal: React.FC<ViewTicketModalProps> = ({
  selectedItems,
  onClose,
}) => {
  const [ticketData, setTicketData] = React.useState<any>(null);
  const [comments, setComments] = useState<any[]>([]);
  const [commentValue, setCommentValue] = useState('');
  const { handleApiWithFlash } = useApiWithFlash();
  const [loading, setLoading] = useState(true);
  const [isSubmittingComment, setSubmittingComment] = useState(false);

  const extractComments = (jiraResponse: any) => {
    if (
      jiraResponse &&
      jiraResponse.fields &&
      jiraResponse.fields.comment &&
      jiraResponse.fields.comment.comments
    ) {
      return jiraResponse.fields.comment.comments;
    }
    return [];
  };

  const formatCommentTimestamp = (timestamp: string | Date) => {
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };

    const formattedDate =
      timestamp instanceof Date
        ? timestamp.toLocaleString('en-GB', options)
        : new Date(timestamp).toLocaleString('en-GB', options);

    return formattedDate;
  };

  const fetchTicketData = async () => {
    try {
      setLoading(true);
      const payloadTicketId = selectedItems?.detail[0]?.ticketId;
      const selectedItemTicketId =
        selectedItems?.detail?.selectedItems?.[0]?.ticketId;

      const ticketId = payloadTicketId || selectedItemTicketId;

      const response = await fetcher(`/ticket/${ticketId}`);

      if (response && response.data && response.jiraResponse) {
        const { data, jiraResponse } = response;

        setTicketData({
          ticketId: data.ticketId,
          instanceArn: data.instanceArn,
          subject: jiraResponse.fields.summary,
          status: jiraResponse.fields.status.name,
          createdAt: new Date(jiraResponse.fields.created),
          updatedAt: new Date(jiraResponse.fields.updated),
          description:
            jiraResponse.fields.description.content[0].content[0].text,
          updatedBy: data.updatedBy,
          jiraResponse,
        });

        setComments(extractComments(jiraResponse));
      } else {
        console.error('Incomplete or unexpected response structure:', response);
      }
    } catch (error) {
      console.error('Error fetching ticket data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTicketData();
  }, [selectedItems]);

  const extractInstanceNameFromDescription = (description: string) => {
    const instanceArnMatch = description.match(/Instance Arn: ([^\n]+)/);

    if (instanceArnMatch) {
      const arnParts = instanceArnMatch[1].split(':');
      if (arnParts.length > 1) {
        return arnParts[0].trim();
      }
    }

    return '';
  };

  const removeExtractedDetails = (originalDescription: string) => {
    const instanceArnMatch = originalDescription.match(
      /Instance Arn:\s*([^\n]+)/
    );
    const updatedByMatch = originalDescription.match(
      /Updated\s*By:\s*([^\n]+)/i
    );

    let modifiedDescription = originalDescription;

    if (instanceArnMatch) {
      modifiedDescription = modifiedDescription.replace(
        instanceArnMatch[0],
        ''
      );
    }

    if (updatedByMatch) {
      modifiedDescription = modifiedDescription.replace('Updated By:', '');
      modifiedDescription = modifiedDescription.replace(
        ticketData.updatedBy,
        ''
      );
    }

    return modifiedDescription;
  };

  return (
    <Modal
      visible={true}
      onDismiss={onClose}
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button variant='link' onClick={onClose}>
              Close
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={
        <SpaceBetween direction='horizontal' size='xs'>
          <div
            style={{
              fontSize: '1.0em',
              fontWeight: 'bold',
              margin: '10px',
            }}
          >
            {`Ticket`}
          </div>
        </SpaceBetween>
      }
      size='large'
    >
      {loading ? (
        <Spinner size='large' />
      ) : (
        <Container>
          {ticketData && (
            <Tabs
              tabs={[
                {
                  label: 'Ticket Details',
                  id: 'details',
                  content: (
                    <ColumnLayout columns={2} variant='text-grid'>
                      <SpaceBetween size='s'>
                        <FormField label='Ticket Id'>
                          {ticketData?.ticketId}
                        </FormField>
                        <FormField label='Subject'>
                          {ticketData?.subject}
                        </FormField>
                        <FormField label='Status'>
                          {ticketData?.status}
                        </FormField>
                        <FormField label='Raised By'>
                          {ticketData.updatedBy}
                        </FormField>
                      </SpaceBetween>

                      <SpaceBetween size='s'>
                        <FormField label='Created At'>
                          {formatCommentTimestamp(
                            new Date(ticketData?.createdAt)
                          )}
                        </FormField>
                        <FormField label='Updated At'>
                          {formatCommentTimestamp(
                            new Date(ticketData?.updatedAt)
                          )}
                        </FormField>

                        <FormField label='Instance Name'>
                          {extractInstanceNameFromDescription(
                            ticketData.description
                          )}
                        </FormField>
                      </SpaceBetween>
                    </ColumnLayout>
                  ),
                },
                {
                  label: 'Description',
                  id: 'description',
                  content: (
                    <ColumnLayout columns={1} variant='text-grid'>
                      <SpaceBetween size='s'>
                        <FormField />
                        <div
                          style={{
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          {ticketData?.description && (
                            <>
                              {removeExtractedDetails(ticketData.description)
                                .split('\n')
                                .map((line: string, index: number) => (
                                  <React.Fragment key={index}>
                                    {line}
                                    <br />
                                  </React.Fragment>
                                ))}
                              <br />
                            </>
                          )}
                        </div>
                      </SpaceBetween>
                    </ColumnLayout>
                  ),
                },
                {
                  label: 'Comments',
                  id: 'comments',
                  content: (
                    <ColumnLayout columns={1} variant='text-grid'>
                      <SpaceBetween size='s'>
                        <FormField>
                          <SpaceBetween size='s'>
                            <Textarea
                              value={commentValue}
                              onChange={({ detail }) =>
                                setCommentValue(detail.value)
                              }
                              placeholder='Type your comment...'
                            />
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <Button
                                loading={isSubmittingComment}
                                onClick={async () => {
                                  setSubmittingComment(true);

                                  try {
                                    if (commentValue.trim() === '') {
                                      return;
                                    }

                                    const payload = { comment: commentValue };
                                    const response = await handleApiWithFlash(
                                      `/ticket/${ticketData?.ticketId}`,
                                      'PUT',
                                      {
                                        successMessage: `Successfully added comment to the ticket "${ticketData?.ticketId}"`,
                                        errorMessage: `Error adding comment to ticket "${ticketData?.ticketId}"`,
                                        data: payload,
                                        mutateKey: `/tickets`,
                                      }
                                    );

                                    if (response && response.data) {
                                      setComments(
                                        extractComments(
                                          response.data.jiraResponse
                                        )
                                      );
                                      setCommentValue('');
                                      fetchTicketData();
                                    } else {
                                      console.error(
                                        'Unexpected response structure:',
                                        response
                                      );
                                    }
                                  } catch (error) {
                                    console.error(
                                      'Error adding comment:',
                                      error
                                    );
                                  } finally {
                                    setSubmittingComment(false);
                                  }
                                }}
                              >
                                Submit
                              </Button>
                            </div>
                          </SpaceBetween>
                        </FormField>
                      </SpaceBetween>
                      <SpaceBetween size='s'>
                        <div
                          style={{
                            maxHeight: '150px',
                            overflowY: 'auto',
                            border: '1px solid #ccc',
                            padding: '10px',
                            borderRadius: '5px',
                          }}
                        >
                          {comments
                            .filter(
                              (comment) =>
                                !!comment.body.content[0]?.content[0]?.text
                            )
                            .map((comment: any) => (
                              <div
                                key={comment.id}
                                style={{
                                  marginBottom: '10px',
                                  borderBottom: '1px solid #ddd',
                                  paddingBottom: '5px',
                                  whiteSpace: 'pre-wrap',
                                }}
                              >
                                <strong>{comment.author.displayName}:</strong>{' '}
                                {comment.body.content.map(
                                  (content: any, index: number) => (
                                    <React.Fragment key={index}>
                                      <div
                                        style={{
                                          textAlign: 'justify',
                                          marginBottom: '5px', // Adjust this margin
                                        }}
                                      >
                                        {content.content[0]?.text}
                                      </div>
                                    </React.Fragment>
                                  )
                                )}
                                <small
                                  style={{
                                    display: 'block',
                                    textAlign: 'right',
                                  }}
                                >
                                  {formatCommentTimestamp(comment.created)}
                                </small>
                              </div>
                            ))}
                        </div>
                      </SpaceBetween>
                    </ColumnLayout>
                  ),
                },

                // {
                //   label: 'Attachments',
                //   id: 'attachments',
                //   content: (
                //     <ColumnLayout columns={4} variant='text-grid'>
                //       {ticketData.jiraResponse.fields.attachment.map(
                //         (attachment: any, index: number) => (
                //           <div
                //             key={index}
                //             style={{ textAlign: 'center', margin: '10px' }}
                //           >
                //             <a href={attachment.content} download>
                //               <img
                //                 src={attachment.content}
                //                 alt={`Attachment ${index + 1}`}
                //                 style={{
                //                   width: '100%',
                //                   height: 'auto',
                //                   cursor: 'pointer',
                //                 }}
                //               />
                //             </a>
                //             <div style={{ marginTop: '5px' }}>
                //               {attachment.filename}
                //             </div>
                //           </div>
                //         )
                //       )}
                //     </ColumnLayout>
                //   ),
                // },
              ]}
            />
          )}
        </Container>
      )}
    </Modal>
  );
};

export default ViewTicketModal;
