import { useState } from 'react';
import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@cloudscape-design/components/button';
import Header from '@cloudscape-design/components/header';
import ButtonDropdown, { ButtonDropdownProps } from '@cloudscape-design/components/button-dropdown';
import { Pagination, Spinner, Table, TableProps } from '@cloudscape-design/components';
import PropertyFilter from '@cloudscape-design/components/property-filter';
import Alert from '@cloudscape-design/components/alert';
import { getTextFilterCounterText } from '../../../utils/text-filter';
import { useLocalStorage } from '../../../components/use-local-storage';
import { useColumnWidths } from '../../../components/table/use-column-width';
import { FILTERING_PROPERTIES as filteringProperties } from './filtering_poperties';
import { mutate } from 'swr';
import { MessageGroupList, MessageGroupListItem, MessagesTableProps } from '../types';
import { useSplitPanelControl } from '../../../utils/hooks/splitPanelContext';
import { useNavigate } from 'react-router-dom';

import { useTableConfig } from './table.config';

import { TableEmptyStateProps } from '../../../components/table/commons';
import MessagesSplitPanelDetails from './messageSplitPanelDetails';
import { useApiWithFlash } from '../../../utils/hooks/useApiWithFlashInstance';
import { useAppSession } from '../../../utils/hooks/sessionContext';
import { Actions, ResourceTypes } from '../../../types/rolePermissions';

import { ProtectedUtilityCreate, useProtectUtility } from '../../../components/protectedUtility';
import { useCatoCollection } from '../../../utils/hooks/useCatoCollection';

export default function MessageTable({ isLoading, messages = [], error }: MessagesTableProps) {
  const protectUtility = useProtectUtility();

  const { defaultPreferences, columns, Preferences } = useTableConfig();

  // State
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState<MessageGroupList>([]);
  const [columnDefinitions, saveWidths] = useColumnWidths('Cato-Messages-TableEditable-Widths', columns);
  const [preferences, setPreferences] = useLocalStorage('Cato-Messages-TableEditable-Preferences', defaultPreferences);

  // Hooks
  const { setSplitPanelOpen, setSplitPanelContent } = useSplitPanelControl();
  const navigate = useNavigate();
  const { handleApiWithFlash } = useApiWithFlash();
  const appSession = useAppSession();
  const instanceArn = appSession?.instanceSelected?.instanceArn;

  const tableEmptyStateProps: TableEmptyStateProps = {
    resourceName: 'Messages',
    resourceType: ResourceTypes.Messages,
    urlPath: 'messages',
  };

  const { items, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCatoCollection(messages, {
    tableEmptyStateProps,
    pageSize: preferences.pageSize,
    filteringProperties,
  });

  const onRefresh = async () => {
    setRefreshLoading(true);
    const endpoint = '/messages';
    const key = instanceArn ? [endpoint, instanceArn] : [endpoint];
    await mutate(key);
    setRefreshLoading(false);
  };

  const handleUtilityItemClick = async (event: CustomEvent<ButtonDropdownProps.ItemClickDetails>) => {
    const details = event.detail;
    if (details.id === 'copy') {
      navigate(`/messages/copy/${selectedItems[0].feature}`);
    }
    if (details.id === 'edit') {
      navigate(`/messages/edit/${selectedItems[0].feature}`);
    }
    if (details.id === 'delete') {
      await handleApiWithFlash(`/messages/${selectedItems[0].feature}`, 'DELETE', {
        successMessage: `Successfully deleted message group  ${selectedItems[0].feature}`,
        errorMessage: `Error deleting message group ${selectedItems[0].feature}`,
        deleteMessage: `Are you sure you want to delete message group ${selectedItems[0].feature}`,
      });
      setSelectedItems([]);
      setSplitPanelOpen(false);
      setSplitPanelContent(null);
      onRefresh();
    }
  };

  const refreshButtonProps = { onClick: onRefresh };

  function onSelectionChange(data: { detail: any }) {
    setSplitPanelContent(<MessagesSplitPanelDetails selectedItems={data} />);
    setSplitPanelOpen(true);
  }

  const functions = [
    { text: 'Copy', action: Actions.Create },
    { text: 'Edit', action: Actions.Update },
    { text: 'Delete', action: Actions.Delete },
  ];

  const dropDownButtonMenuProps: ButtonDropdownProps = {
    disabled: !selectedItems || selectedItems.length === 0,
    onItemClick: handleUtilityItemClick,
    loadingText: 'loading',
    items: functions
      .filter((f) => protectUtility({ resourceType: ResourceTypes.Messages, resourceAction: f.action }))
      .map((f) => ({
        text: f.text,
        id: f.text.toLowerCase(),
        disabled: false,
      })),
  };

  const header = (
    <Header
      variant='awsui-h1-sticky'
      actions={
        <SpaceBetween
          direction='horizontal'
          size='xs'
        >
          <Button
            iconName='refresh'
            ariaLabel='Refresh'
            {...refreshButtonProps}
          />
          {!!dropDownButtonMenuProps.items.length && <ButtonDropdown {...{ ...dropDownButtonMenuProps }}>Actions</ButtonDropdown>}
          <ProtectedUtilityCreate resourceType={ResourceTypes.Messages}>
            <Button
              variant='primary'
              onClick={() => navigate('/messages/create')}
            >
              Create message group
            </Button>
          </ProtectedUtilityCreate>
        </SpaceBetween>
      }
    >
      Message Groups {messages && !isLoading ? `(${messages.length})` : <Spinner />}
    </Header>
  );

  const messageTableProps: TableProps<MessageGroupListItem> = {
    ...collectionProps,
    stickyHeader: true,
    resizableColumns: true,
    onColumnWidthsChange: saveWidths,
    onSelectionChange: ({ detail: { selectedItems } }) => {
      onSelectionChange({ detail: selectedItems });
      if (selectedItems && selectedItems.length > 0) {
        const selectedItem = selectedItems[0];
        setSelectedItems(() => [selectedItem]);
      }
    },
    selectedItems,
    columnDefinitions,
    items,
    // submitEdit: handleSubmit,
    trackBy: 'feature',
    loadingText: 'Loading message groups',
    columnDisplay: preferences.contentDisplay,
    wrapLines: preferences.wrapLines,
    stripedRows: preferences.stripedRows,
    stickyColumns: preferences.stickyColumns,
    contentDensity: preferences.contentDensity as 'compact' | 'comfortable',
    selectionType: 'single',
    loading: isLoading || !messages || refreshLoading,
    header,
    filter: (
      <PropertyFilter
        {...propertyFilterProps}
        countText={getTextFilterCounterText(filteredItemsCount)}
        expandToViewport={true}
        filteringAriaLabel={'Filter message groups'}
        filteringPlaceholder={'Filter message groups'}
      />
    ),
    pagination: <Pagination {...paginationProps} />,
    preferences: (
      <Preferences
        preferences={preferences}
        setPreferences={setPreferences}
      />
    ),
  };
  return (
    <>
      <Table {...{ ...messageTableProps }} />
      {error && (
        <Alert
          statusIconAriaLabel='Error'
          type='error'
          header='Error getting data'
        >
          Refresh the page to try again.
        </Alert>
      )}
    </>
  );
}
