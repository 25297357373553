import {useRouteError} from "react-router-dom";
import React from "react";
import Alert from "@cloudscape-design/components/alert";

export default function ErrorPage() {
    const error = useRouteError();
    console.error(error);

    return (
        <div id="error-page">
            <h1>Oops! 404</h1>
            <Alert
                statusIconAriaLabel="Error"
                type="error"
                header="The page you requested is not available"
            >
                Please choose from one of the options from the menu
            </Alert>
        </div>
    );
}
