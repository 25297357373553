import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useInstanceSWR from '../../../utils/hooks/useInstanceSWR';
import fetcherInstance from '../../../utils/fetcherInstance';
import { ClosureCreateBody, ClosureData, ClosureUpdateBody, SingleClosureAPIResponse } from '../../../types/closures';
import { useApiWithFlashForFeatureCopy } from '../../../utils/hooks/useApiWithFlashInstance';
import CopyFormLayout, { ConfigStructure } from '../../../components/copyForm/copyFormLayout';
import Alert from '@cloudscape-design/components/alert';
import ContentLayout from '@cloudscape-design/components/content-layout';
import { Header } from '@cloudscape-design/components';

export default function ClosuresCopy() {
  const navigate = useNavigate();
  const { featureId } = useParams();
  const { handleApiWithFlash } = useApiWithFlashForFeatureCopy();

  const [item, setItem] = useState<SingleClosureAPIResponse>({} as SingleClosureAPIResponse);
  const [copyingToData, setCopyingToData] = useState<ConfigStructure | null>(null);

  const { data, isLoading, error } = useInstanceSWR<SingleClosureAPIResponse>(`/closures/${featureId}`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 0,
  });

  const hasMigration = true;
  const validation = (feature: string, isMigrating: boolean) => {
    const generalPattern = /^[a-zA-Z0-9+_-]+$/;
    if (!isMigrating && feature === data?.data?.feature) {
      return 'Try a different Closure group name';
    }
    if (!generalPattern.test(feature)) {
      return 'Only alphanumeric, - _ and + supported';
    }
  };

  useEffect(() => {
    if (data?.data) {
      setItem(data.data);
    }
  }, [data, setItem]);

  const fetchSelectedInstanceData = async (selectedToInstance: string) => {
    try {
      const response = await fetcherInstance(`/closures/${featureId}`, 'GET', null, selectedToInstance);
      if (response?.data) {
        setCopyingToData(response.data);
      }
    } catch (error) {
      console.error('Error fetching copying to data:', error);
      setCopyingToData(null);
    }
  };

  const handleCancel = () => {
    navigate('/closures');
  };

  async function handleFormSubmit(closureItem: ConfigStructure<ClosureData>, selectedToInstance: string) {
    try {

      const { feature, description, tag, data} = closureItem;
      const isCreatingNew = !copyingToData;

      if (isCreatingNew) {
        await create(feature, selectedToInstance, { description, tag })
      }

      await update(feature, selectedToInstance, {description, data, tag})

      navigate('/closures');
    } catch (error) {
      console.error('Error handling form submit:', error);
    }
  }

  async function create(feature: string, selectedToInstance: string, payload: ClosureCreateBody) {
    return handleApiWithFlash(`/closures/${feature}`, 'POST', {
      errorMessage: `Error copying Closure ${feature}`,
      data: payload,
      successMessage: '',
      instanceArn: selectedToInstance,
    });
  }

  async function update(feature: string, selectedToInstance: string, payload: ClosureUpdateBody) {
    return handleApiWithFlash(`/closures/${feature}`, 'PUT', {
      data: payload,
      successMessage: `Successfully Copied Closure ${feature}`,
      errorMessage: `Error copying Closure ${feature}`,
      instanceArn: selectedToInstance,
    });
  }

  if (error) {
    return (
      <ContentLayout header={<Header variant='h1'>Error</Header>}>
        <Alert
          statusIconAriaLabel='Error'
          type='error'
          header='Unable to load component'
        >
          {error.status}
        </Alert>
      </ContentLayout>
    );
  }

  return (
    <CopyFormLayout
      item={item}
      copyingToData={copyingToData ?? undefined}
      isLoading={isLoading}
      hasMigration={hasMigration}
      fetchSelectedInstanceData={fetchSelectedInstanceData}
      validation={validation}
      handleFormSubmit={handleFormSubmit}
      handleCancel={handleCancel}
    />
  );
}
