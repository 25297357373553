import { Navigate } from 'react-router-dom';
import { useProtectRoute } from './useProtectedRoute';
import { ProtectedRouteProps } from './types';
import { useAppSession } from '../../utils/hooks/sessionContext';

/**
 * React component to protect a route component
 * @param props
 * @returns JSX.Element
 */
export const ProtectedRoute = (props: ProtectedRouteProps) => {
  const protectRoute = useProtectRoute();
  const canShowRoute = protectRoute(props);

  const appSession = useAppSession();
  if (appSession?.instances && !appSession?.instanceSelected?.instanceArn) {
    return <></>;
  }

  if (!canShowRoute) {
    return (
      <Navigate
        to='/home'
        replace
      />
    );
  }

  return <>{props.children}</>;
};
