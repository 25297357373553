import React, { useState, useEffect } from 'react';
import { ColumnLayout, Container, Header, ContentLayout, SpaceBetween, Form, Input, Button, FormField } from '@cloudscape-design/components';
import { useNavigate } from 'react-router-dom';
import Select from '@cloudscape-design/components/select';
import { useApiWithFlash } from '../../../utils/hooks/useApiWithFlash';
import { isValidEmail } from '../../../utils/validations';
import { useBlockerLogic } from '../../../utils/hooks/useBlocker';
import useChangeDetector from '../../../utils/useChangeDetector';
import { handleFieldChange } from '../../../utils/fieldChangeUtils';
import { ConfirmCancelModal } from '../../../components/confirmCancelModal';

export default function UserAdminCreate() {
  const [emailErrorText, setEmailErrorText] = useState('');
  const [givenNameErrorText, setGivenNameErrorText] = useState('');
  const [familyNameErrorText, setFamilyNameErrorText] = useState('');

  const initialFormState = {
    email: '',
    givenName: '',
    familyName: '',
    securityProfile: 'Administrator',
  };
  const { item, setItem, changesDetected } = useChangeDetector(initialFormState);
  const handleInputChange = handleFieldChange(setItem);
  const [canSubmit, setCanSubmit] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);

  const { blocker, handleCancel, handleCloseCancelModal, handleConfirmCancel } = useBlockerLogic({
    changesDetected,
    path: 'user-admin',
    formSubmitted,
  });

  // Hooks
  const navigate = useNavigate();
  const { handleApiWithFlash } = useApiWithFlash();

  function isValidName(name: string): boolean {
    const regex = /^[a-zA-Z ]{2,}$/; // Ensure at least 2 characters
    return regex.test(name);
  }

  function handleEmailChange(value: string) {
    handleInputChange('email', value);

    if (!isValidEmail(value)) {
      setEmailErrorText('Please enter a valid email address.');
    } else {
      setEmailErrorText('');
    }
  }

  useEffect(() => {
    const isFormValid = isValidEmail(item.email) && isValidName(item.givenName) && isValidName(item.familyName);
    setCanSubmit(isFormValid);
  }, [item, changesDetected]);

  // Handle form submission
  async function handleSubmitForm() {
    setFormSubmitted(true);

    const payload = {
      email: item.email,
      firstName: item.givenName,
      lastName: item.familyName,
      securityProfile: item.securityProfile,
    };

    await handleApiWithFlash(`/users`, 'POST', {
      successMessage: `Successfully created user ${item.email}`,
      errorMessage: `Error creating user ${item.email}`,
      mutateKey: '/user-admin',
      data: payload,
    });

    navigate('/user-admin');
  }

  return (
    <ContentLayout header={<Header variant='h1'></Header>}>
      <Container
        header={
          <Header
            variant='h2'
            description='Use this form to create a new user. These users are onboarded to this application only.'
          >
            Create admin user
          </Header>
        }
      >
        <form
          onSubmit={(e) => {
            console.log(e);
            e.preventDefault();
            handleSubmitForm();
          }}
        >
          <Form
            variant='embedded'
            actions={
              <SpaceBetween
                direction='horizontal'
                size='xs'
              >
                <Button
                  formAction='none'
                  variant='link'
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  variant='primary'
                  loading={formSubmitted}
                  disabled={!canSubmit}
                >
                  Submit
                </Button>
              </SpaceBetween>
            }
          >
            <ColumnLayout
              columns={2}
              variant='text-grid'
            >
              <FormField
                label='Provide users email'
                description='Provide the email exactly as created in the identity store'
                errorText={emailErrorText}
              >
                <Input
                  inputMode='email'
                  onChange={({ detail }) => handleEmailChange(detail.value)}
                  value={item.email}
                />
              </FormField>
              <FormField
                label='Select permission type'
                description='Please see the help pages for details on permission sets'
              >
                <Select
                  selectedOption={{ label: item.securityProfile, value: item.securityProfile }}
                  onChange={({ detail }) => handleInputChange('securityProfile', detail.selectedOption.label ?? 'Administrator')}
                  options={[
                    { label: 'Administrator', value: 'admin' },
                    { label: 'User', value: 'User' },
                  ]}
                />
              </FormField>
              <FormField
                label='First name'
                errorText={givenNameErrorText}
              >
                <Input
                  onChange={({ detail }) => {
                    handleInputChange('givenName', detail.value);
                    if (!isValidName(detail.value)) {
                      setGivenNameErrorText('Please enter a valid first name.');
                    } else {
                      setGivenNameErrorText('');
                    }
                  }}
                  value={item.givenName}
                />
              </FormField>
              <FormField
                label='Last name'
                errorText={familyNameErrorText}
              >
                <Input
                  onChange={({ detail }) => {
                    handleInputChange('familyName', detail.value);
                    if (!isValidName(detail.value)) {
                      setFamilyNameErrorText('Please enter a valid last name.');
                    } else {
                      setFamilyNameErrorText('');
                    }
                  }}
                  value={item.familyName}
                />
              </FormField>
            </ColumnLayout>
          </Form>
        </form>
        {blocker.state === 'blocked' && (
          <ConfirmCancelModal
            {...{
              onCancel: handleCloseCancelModal,
              onConfirm: handleConfirmCancel,
              cancelMessage: 'Are you sure you want to close? Any unsaved changes will be lost.',
            }}
          />
        )}
      </Container>
    </ContentLayout>
  );
}
