// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import ButtonDropdown from '@cloudscape-design/components/button-dropdown';
import BoardItem from '@cloudscape-design/board-components/board-item';
import { WidgetDataType } from '../widgets/interfaces';
import { boardItemI18nStrings } from '../../i18n-strings';
import { ProtectedUtilityUpdate } from '../../../../../../components/protectedUtility';
import { ResourceTypes } from '../../../../../../types/rolePermissions';

interface BaseWidgetProps {
  config: WidgetDataType;
  onRemove: () => void;
  removeConfirmationText?: string;
  actions?: ReadonlyArray<{
    text: string;
    onClick: () => void;
  }>;
  id: string;
}

export function ConfigurableWidget({ config, id, onRemove, actions = [] }: BaseWidgetProps) {
  if (!config) {
    return null;
  }

  const Content = config.content;

  const customClassName = config?.className ?? '';

  return (
    <section
      style={{ display: 'contents' }}
      className={`${id} ${customClassName}`}
      key={id}
    >
      <BoardItem
        header={<config.header widgetId={id} />}
        disableContentPaddings={config.disableContentPaddings}
        i18nStrings={boardItemI18nStrings}
        settings={
          <ProtectedUtilityUpdate resourceType={ResourceTypes.Dashboards}><ButtonDropdown
            items={[...actions.map((action) => ({ id: action.text, text: action.text })), { id: 'remove', text: 'Remove' }]}
            ariaLabel='Widget settings'
            variant='icon'
            onItemClick={(event) => {
              if (event.detail.id === 'remove') {
                onRemove();
              } else {
                const actionMatch = actions.find((action) => action.text === event.detail.id)!;
                actionMatch.onClick();
              }
            }}
          /></ProtectedUtilityUpdate>
        }
        footer={config.footer && <config.footer />}
      >
        <Content
          widgetId={id}
          className={id}
        />
      </BoardItem>
    </section>
  );
}
