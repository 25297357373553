import React from 'react';
import { Table, Input, Header, SpaceBetween, Container, Box, Button } from '@cloudscape-design/components'; // Import necessary components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faWandSparkles } from '@fortawesome/free-solid-svg-icons';
import ButtonDropdown from '@cloudscape-design/components/button-dropdown';
import pollyLanguages from '../../lib/pollyLanguages';
import { MessageTranslation } from './types';
import Modal from '@cloudscape-design/components/modal';
import fetcher from '../../utils/fetcherInstance';
import { getSelectedInstance } from '../localStorage';

type TranslationsTableProps = {
  messageKey: string;
  messages: any;
  setItem: React.Dispatch<any>;
  open: boolean;
  setOpen: React.Dispatch<any>;
};

type Translation = {
  lang: string;
  text: string;
};

const TranslationModal: React.FC<TranslationsTableProps> = ({ messageKey, messages, setItem, open, setOpen }) => {
  if (!messages) return null;

  const translations = messages.map((message: MessageTranslation) => ({
    lang: message.lang,
    text: message.message[0]?.children[0]?.text || '',
  }));

  const availableLanguages = pollyLanguages.filter(
    (lang) => !messages.some((existingMessage: MessageTranslation) => existingMessage.lang === lang.id),
  );

  function removeLang(event: any, itemToRemove: any) {
    setItem((prevState: any) => {
      if (!prevState.data || !prevState.data[messageKey] || !Array.isArray(prevState.data[messageKey].message)) {
        console.error('Invalid state shape; expected an array at prevState.data[messageKey].messages');
        return prevState;
      }

      // Deep copy the messages array
      const updatedMessages = [...prevState.data[messageKey].message];

      // Filter out the language message
      const filteredMessages = updatedMessages.filter((msg: MessageTranslation) => {
        const isMatch = msg.lang !== itemToRemove.lang;
        if (!isMatch) {
          console.log(`Removing message with lang ${msg.lang}`);
        }
        return isMatch;
      });

      if (updatedMessages.length === filteredMessages.length) {
        console.warn(`Did not find any message to remove with lang ${itemToRemove}`);
      }

      // Update the state
      const updatedData = {
        ...prevState.data,
        [messageKey]: {
          ...prevState.data[messageKey],
          message: filteredMessages,
        },
      };

      return {
        ...prevState,
        data: updatedData,
      };
    });
  }

  function addLang(detail: { id: string }) {
    // Assuming messageKey is passed as a prop or argument
    setItem((prevState: any) => {
      // Make a deep copy of the existing data
      const updatedData = JSON.parse(JSON.stringify(prevState.data));

      // Safely access the messages array based on the messageKey
      const targetMessages = updatedData[messageKey]?.message;
      if (!targetMessages) {
        // Handle error: No such messageKey exists in data
        console.error('Invalid messageKey:', messageKey);
        return prevState;
      }

      // Find the language to add
      const langToAdd = availableLanguages.find((lang) => lang.id === detail.id);
      if (!langToAdd) return prevState;

      // Create a new message object
      const newMessage: MessageTranslation = {
        lang: langToAdd.id,
        message: [{ type: 'paragraph', children: [{ text: '' }] }],
      };

      // Append the new message object to the target messages array
      targetMessages.push(newMessage);

      // Update the target messages in the new data object
      updatedData[messageKey].message = targetMessages;

      // Return the updated state
      return {
        ...prevState,
        data: updatedData,
      };
    });
  }

  function handleEdit(lang: string, newValue: string) {
    setItem((prevState: any) => {
      const updatedData = JSON.parse(JSON.stringify(prevState.data));

      const targetMessages = updatedData[messageKey]?.message;
      if (!targetMessages) {
        // Handle error: No such messageKey exists in data
        console.error('Invalid messageKey:', messageKey);
        return prevState;
      }

      const messageToUpdate = updatedData[messageKey].message.find((msg: MessageTranslation) => msg.lang === lang);
      if (messageToUpdate) {
        messageToUpdate.message[0].children[0].text = newValue;
      }

      return {
        ...prevState,
        data: updatedData,
      };
    });
  }

  async function handleTranslate(event: any, itemToTranslate: any) {
    const defaultMessage = messages[0].message[0].children[0].text;

    const storedInstanceSelected = getSelectedInstance();

    if (storedInstanceSelected) {
      const instanceArn = storedInstanceSelected.instanceArn;

      try {
        const payload = {
          message: defaultMessage,
          lang: itemToTranslate.lang,
        };
        const translatedText = await fetcher(`/translateMessage`, 'POST', payload, instanceArn);

        handleEdit(itemToTranslate.lang, translatedText.data);
      } catch (error) {
        console.error('Translation failed:', error);
      }
    }
  }

  return (
    <Modal
      onDismiss={() => setOpen(false)}
      visible={open}
      size="max"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={() => setOpen(false)}>
              Close
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <Container
        header={
          <Header
            variant="h2"
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <ButtonDropdown items={availableLanguages} onItemClick={(event) => addLang({ id: event.detail.id })}>
                  Add new language
                </ButtonDropdown>
              </SpaceBetween>
            }
          >
            Closures
          </Header>
        }
      >
        <Table
          wrapLines
          columnDefinitions={[
            {
              id: 'lang',
              header: 'Language',
              cell: (translation: Translation) => translation.lang || '-',
              width: 50,
              minWidth: 50,
            },
            {
              id: 'text',
              header: 'Text',
              maxWidth: 200,
              cell: (translation) => translation.text || '-',
              editConfig: {
                ariaLabel: 'message',
                editIconAriaLabel: 'editable',
                errorIconAriaLabel: 'Name Error',
                editingCell: (translation, cellContext) => {
                  return (
                    <Input
                      autoFocus={true}
                      value={cellContext.currentValue ?? translation.text}
                      onChange={(event) => {
                        cellContext.setValue(event.detail.value);
                      }}
                    />
                  );
                },
              },
            },
            {
              id: 'translate',
              header: 'Translate',
              cell: (translation) => {
                if (translation.lang !== 'default') {
                  return (
                    <FontAwesomeIcon
                      icon={faWandSparkles}
                      style={{ color: '#008cff', cursor: 'pointer' }}
                      onClick={(event) => handleTranslate(event, translation)}
                    />
                  );
                } else {
                  return '';
                }
              },
              width: 50,
              minWidth: 50,
            },
            {
              id: 'remove',
              header: 'Remove',
              cell: (translation: Translation) => {
                if (translation.lang !== 'default') {
                  return (
                    <FontAwesomeIcon
                      icon={faTrash}
                      style={{ color: '#ff0000', cursor: 'pointer' }}
                      onClick={(event) => removeLang(event, translation)}
                    />
                  );
                } else {
                  return '';
                }
              },
              width: 50,
              minWidth: 50,
            },
          ]}
          items={translations}
          submitEdit={(item, column, newValue) => {
            if (column.id === 'text' && typeof newValue === 'string') {
              handleEdit(item.lang, newValue as string);
            }
          }}
        />
      </Container>
    </Modal>
  );
};

export default TranslationModal;
