import useSWR, { SWRResponse } from 'swr';
import { useAppSession } from './sessionContext';

type FetcherFunction<T> = (...args: any[]) => Promise<T>;

type FetchType = 'instance' | 'intuition';

export const useBaseSWR = <T = any,>(
  endpoint: string | null,
  fetcher: FetcherFunction<any>,
  fetchType: FetchType,
  swrOptions: any = {},
): SWRResponse<T> => {
  const appSession = useAppSession();
  const instanceArn = appSession?.instanceSelected?.instanceArn;
  const intuitionUrl = appSession?.instanceSelected?.instanceIntuitionUrl;

  const getSWRKey = (): Array<any> => {
    if (fetchType === 'instance') {
      return [endpoint, instanceArn];
    } else if (fetchType === 'intuition') {
      return [endpoint, instanceArn, intuitionUrl];
    }
    return [endpoint];
  };

  const swrKey = getSWRKey();

  const { data, error, isValidating, isLoading, mutate } = useSWR<T>(
    swrKey,
    (key) => {
      if (!Array.isArray(key)) {
        throw new Error('The SWR key is not an array');
      }
      const [endpoint, ...arns] = key;
      return fetcher(endpoint, 'GET', null, ...arns);
    },
    swrOptions,
  );

  return {
    data,
    isLoading: isLoading || !error && !data,
    error,
    isValidating,
    mutate,
  };
};
