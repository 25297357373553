import React, { createContext, useState } from 'react';
import { Header, Spinner } from '@cloudscape-design/components';
import { WidgetConfig } from '../interfaces';
import GetWidgetData from '../../../../../../../utils/getWidgetData';
import { useItemContext } from '../../../../dashboardContext';
import { allContent, Content, WidgetPreferences } from '../preferences';
import { TrendInfo } from '../../sharedFunctions';
import Alert from '@cloudscape-design/components/alert';

export const getAverageSpeedOfAnswerStats: WidgetConfig = {
  definition: { defaultRowSpan: 1, defaultColumnSpan: 1, minRowSpan: 1 },
  data: {
    icon: 'number',
    title: 'Avg Speed of Answer',
    description: 'ASA, plus 7 day average',
    header: ChartHeader,
    content: AverageSpeedOfAnswerStatsContent,
    provider: AverageSpeedOfAnswerStatsProvider,
    staticMinHeight: 560,
    multipleAllowed: true,
  },
};

interface AverageSpeedOfAnswerStatsWidgetContextType {
  visibleContent: ReadonlyArray<Content>;
  openPreferences: () => void;
}

const AverageSpeedOfAnswerStatsWidgetContext = createContext<AverageSpeedOfAnswerStatsWidgetContextType>({
  visibleContent: [],
  openPreferences: () => null,
});

function AverageSpeedOfAnswerStatsProvider({ children, widgetId }: { children: React.ReactElement; widgetId: string }) {
  const [preferencesVisible, setPreferencesVisible] = useState(false);
  const [visibleContent, setVisibleContent] = useState<ReadonlyArray<Content>>(allContent);
  return (
    <AverageSpeedOfAnswerStatsWidgetContext.Provider
      value={{
        visibleContent,
        openPreferences: () => setPreferencesVisible(true),
      }}
    >
      {React.cloneElement(React.Children.only(children), {
        removeConfirmationText: 'Operational metrics',
        actions: [{ text: 'Preferences', onClick: () => setPreferencesVisible(true) }],
      })}
      {preferencesVisible && (
        <WidgetPreferences
          widgetId={widgetId}
          preferences={visibleContent}
          onConfirm={(visibleContent) => {
            setVisibleContent(visibleContent);
            setPreferencesVisible(false);
          }}
          onDismiss={() => setPreferencesVisible(false)}
        />
      )}
    </AverageSpeedOfAnswerStatsWidgetContext.Provider>
  );
}

function ChartHeader() {
  return (
    <Header variant="h2" description="ASA with 7 day average">
      Avg Speed of Answer
    </Header>
  );
}

export default function AverageSpeedOfAnswerStatsContent({ widgetId }: { widgetId: string }) {
  const { item } = useItemContext();
  const widget = item.data.widgets.find((widget: { id: string }) => widget.id === widgetId);
  const period = item.data.period;
  const { data, isLoading, error } = GetWidgetData({ widget, period });

  if (error) {
    return (
      <Alert statusIconAriaLabel="Error" type="error" header="Unable to load widget">
        {error.status}
      </Alert>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        paddingTop: '40px',
        justifyContent: 'center',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ fontSize: '6em', fontWeight: '800', textAlign: 'center' }}>{data?.data?.value !== null ? data?.data?.value : 'N/A'}</div>
        {data?.data?.trend !== undefined && <TrendInfo trend={data?.data?.trend} value={data?.data?.value} />}
      </div>
      {isLoading && <Spinner />}
    </div>
  );
}
