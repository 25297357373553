import { useState } from 'react';
import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@cloudscape-design/components/button';
import Header from '@cloudscape-design/components/header';
import ButtonDropdown, { ButtonDropdownProps } from '@cloudscape-design/components/button-dropdown';
import { Pagination, Spinner, Table } from '@cloudscape-design/components';
import PropertyFilter from '@cloudscape-design/components/property-filter';
import Alert from '@cloudscape-design/components/alert';
import { getTextFilterCounterText } from '../../../utils/text-filter';
import { useLocalStorage } from '../../../components/use-local-storage';
import { useColumnWidths } from '../../../components/table/use-column-width';
import { FILTERING_PROPERTIES as filteringProperties } from './filteringProperties';
import { mutate } from 'swr';
import { Visualizations, VisualizationsTableProps } from '../types';
import { useNavigate } from 'react-router-dom';

import { DEFAULT_PREFERENCES, COLUMN_DEFINITIONS, Preferences } from './table.config';

import { useApiWithFlash } from '../../../utils/hooks/useApiWithFlashInstance';
import { useAppSession } from '../../../utils/hooks/sessionContext';
import { ProtectedUtilityCreate, useProtectUtility } from '../../../components/protectedUtility';
import { Actions, ResourceTypes } from '../../../types/rolePermissions';
import { useCatoCollection } from '../../../utils/hooks/useCatoCollection';

export default function VisualizationsTable({ isLoading, visualizations = [], error }: VisualizationsTableProps) {
  // State
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState<Visualizations[]>([]);
  const [columnDefinitions, saveWidths] = useColumnWidths('Cato-Visualizations-TableEditable-Widths', COLUMN_DEFINITIONS);
  const [preferences, setPreferences] = useLocalStorage('Cato-Visualizations-TableEditable-Preferences', DEFAULT_PREFERENCES);

  // Hooks
  const navigate = useNavigate();
  const protectUtility = useProtectUtility();

  const { handleApiWithFlash } = useApiWithFlash();
  const appSession = useAppSession();
  const instanceArn = appSession?.instanceSelected?.instanceArn;
  const { items, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCatoCollection(visualizations, {
    filteringProperties,
    pageSize: preferences.pageSize,
  });

  const onRefresh = async () => {
    setRefreshLoading(true);
    const endpoint = '/intuition/dashboards';
    const key = instanceArn ? [endpoint, instanceArn] : [endpoint];
    await mutate(key);
    setRefreshLoading(false);
  };

  const handleUtilityItemClick = async (event: CustomEvent<ButtonDropdownProps.ItemClickDetails>) => {
    const details = event.detail;
    if (details.id === 'edit') {
      navigate(`/visualizations/view/${selectedItems[0].feature}`);
    }
    if (details.id === 'copy') {
      navigate(`/visualizations/copy/${selectedItems[0].feature}`);
    }
    if (details.id === 'delete') {
      await handleApiWithFlash(`/intuition/dashboards/${selectedItems[0].feature}`, 'DELETE', {
        successMessage: `Successfully deleted dashboard  ${selectedItems[0].feature}`,
        deleteMessage: `Are you sure you want to delete dashboard  ${selectedItems[0].feature}`,
        errorMessage: `Error deleting dashboard ${selectedItems[0].feature}`,
      });
      onRefresh();
    }
  };

  const refreshButtonProps = { onClick: onRefresh };

  const functions = [
    { text: 'Copy', action: Actions.Create },
    { text: 'Edit', action: Actions.Update },
    { text: 'Delete', action: Actions.Delete },
  ];

  const dropDownButtonMenuProps: ButtonDropdownProps = {
    disabled: !selectedItems || selectedItems.length === 0,
    onItemClick: (event: CustomEvent<ButtonDropdownProps.ItemClickDetails>) => {
      handleUtilityItemClick(event);
    },
    loadingText: 'loading',
    items: functions
      .filter((f) => protectUtility({ resourceType: ResourceTypes.Dashboards, resourceAction: f.action }))
      .map((f) => ({
        text: f.text,
        id: f.text.toLowerCase(),
        disabled: false,
      })),
  };

  const hasMenuItems = Boolean(dropDownButtonMenuProps.items.length);

  return (
    <>
      <Table
        {...collectionProps}
        stickyHeader={true}
        resizableColumns={true}
        onColumnWidthsChange={saveWidths}
        onSelectionChange={({ detail: { selectedItems } }) => {
          if (selectedItems && selectedItems.length > 0) {
            const selectedItem: Visualizations = selectedItems[0];
            setSelectedItems(() => [selectedItem]);
          }
        }}
        selectedItems={selectedItems as any}
        columnDefinitions={columnDefinitions}
        items={items}
        trackBy='feature'
        loadingText='Loading visualizations'
        columnDisplay={preferences.contentDisplay}
        wrapLines={preferences.wrapLines}
        stripedRows={preferences.stripedRows}
        stickyColumns={preferences.stickyColumns}
        contentDensity={preferences.contentDensity as 'compact' | 'comfortable'}
        selectionType={'single'}
        loading={isLoading || !visualizations || refreshLoading}
        header={
          <Header
            variant='awsui-h1-sticky'
            actions={
              <SpaceBetween
                direction='horizontal'
                size='xs'
              >
                <Button
                  iconName='refresh'
                  ariaLabel='Refresh'
                  {...refreshButtonProps}
                />
                {hasMenuItems && <ButtonDropdown {...{ ...dropDownButtonMenuProps }}>Actions</ButtonDropdown>}

                <ProtectedUtilityCreate resourceType={ResourceTypes.Dashboards}>
                  <Button
                    variant='primary'
                    onClick={() => navigate('/visualizations/create')}
                  >
                    Create visualization
                  </Button>
                </ProtectedUtilityCreate>
              </SpaceBetween>
            }
          >
            Visualizations {visualizations && !isLoading ? `(${visualizations.length})` : <Spinner />}
          </Header>
        }
        filter={
          <PropertyFilter
            {...propertyFilterProps}
            countText={getTextFilterCounterText(filteredItemsCount)}
            expandToViewport={true}
            filteringAriaLabel={'Filter visualizations'}
            filteringPlaceholder={'Filter visualizations'}
          />
        }
        pagination={<Pagination {...paginationProps} />}
        preferences={
          <Preferences
            preferences={preferences}
            setPreferences={setPreferences}
          />
        }
      />
      {error && (
        <Alert
          statusIconAriaLabel='Error'
          type='error'
          header='Error getting data'
        >
          Refresh the page to try again.
        </Alert>
      )}
    </>
  );
}
