import React from 'react';
// import useSWR from 'swr';
// import fetcher from '../../utils/fetcher';
import Header from '@cloudscape-design/components/header';
import SpaceBetween from '@cloudscape-design/components/space-between';
import { ContentLayout } from '@cloudscape-design/components';
import VisualizationsTable from './components/table';
import useInstanceSWR from '../../utils/hooks/useInstanceSWR';

export default function Visualizations() {
  const { data, isLoading, error } = useInstanceSWR('/intuition/dashboards', {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 0,
  });

  return (
    <ContentLayout
      header={
        <Header variant="h1" description="Create and manage your Visualizations">
          Visualizations
        </Header>
      }
    >
      <SpaceBetween size="m">
        <VisualizationsTable isLoading={isLoading} visualizations={data?.data} error={error} />
      </SpaceBetween>
    </ContentLayout>
  );
}
