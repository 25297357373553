import { useState } from 'react';
import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@cloudscape-design/components/button';
import Header from '@cloudscape-design/components/header';
import ButtonDropdown, { ButtonDropdownProps } from '@cloudscape-design/components/button-dropdown';
import { Pagination, Spinner, Table, TableProps } from '@cloudscape-design/components';
import PropertyFilter from '@cloudscape-design/components/property-filter';
import Alert from '@cloudscape-design/components/alert';
import { getTextFilterCounterText } from '../../../utils/text-filter';
import { useLocalStorage } from '../../../components/use-local-storage';
import { useColumnWidths } from '../../../components/table/use-column-width';
import { FILTERING_PROPERTIES as filteringProperties } from './filtering_poperties';
import { mutate } from 'swr';
import { DialPlanTableProps, DialPlanListItem } from '../types';
import { useSplitPanelControl } from '../../../utils/hooks/splitPanelContext';
import { useNavigate } from 'react-router-dom';

import { useTableConfig } from './table.config';

import { TableEmptyStateProps } from '../../../components/table/commons';
import DialPlanSplitPanelDetails from './dialPlanSplitPanelDetails';
import { useApiWithFlash } from '../../../utils/hooks/useApiWithFlashInstance';
import { useAppSession } from '../../../utils/hooks/sessionContext';
import { Actions, ResourceTypes } from '../../../types/rolePermissions';
import { ProtectedUtilityCreate, useProtectUtility } from '../../../components/protectedUtility';
import { useCatoCollection } from '../../../utils/hooks/useCatoCollection';

export default function DialPlanTable({ isLoading, dialPlan = [], error }: DialPlanTableProps) {
  // State

  const { columns, defaultPreferences, Preferences } = useTableConfig();
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState<DialPlanListItem[]>([]);
  const [columnDefinitions, saveWidths] = useColumnWidths('Cato-DialPlans-TableEditable-Widths', columns);
  const [preferences, setPreferences] = useLocalStorage('Cato-DialPlans-TableEditable-Preferences', defaultPreferences);

  // Hooks
  const protectUtility = useProtectUtility();
  const { setSplitPanelOpen, setSplitPanelContent } = useSplitPanelControl();
  const navigate = useNavigate();
  const { handleApiWithFlash } = useApiWithFlash();
  const appSession = useAppSession();
  const instanceArn = appSession?.instanceSelected?.instanceArn;

  const tableEmptyStateProps: TableEmptyStateProps = {
    resourceName: 'Dial Plan',
    resourceType: ResourceTypes.DialPlan,
    urlPath: 'dial-plan',
  };

  const { items, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCatoCollection(dialPlan, {tableEmptyStateProps, pageSize: preferences.pageSize, filteringProperties });

  const onRefresh = async () => {
    setRefreshLoading(true);
    const endpoint = '/dial-plans';
    const key = instanceArn ? [endpoint, instanceArn] : [endpoint];
    await mutate(key);
    setRefreshLoading(false);
  };

  const handleUtilityItemClick = async (event: CustomEvent<ButtonDropdownProps.ItemClickDetails>) => {
    const details = event.detail;
    if (details.id === 'copy') {
      navigate(`/dial-plan/copy/${selectedItems[0].feature}`);
    }
    if (details.id === 'edit') {
      navigate(`/dial-plan/edit/${selectedItems[0].feature}`);
    }
    if (details.id === 'delete') {
      await handleApiWithFlash(`/dial-plans/${selectedItems[0].feature}`, 'DELETE', {
        successMessage: `Successfully deleted dial plan ${selectedItems[0].feature}`,
        errorMessage: `Error deleting dial plan ${selectedItems[0].feature}`,
        deleteMessage: `Are you sure you want to delete dial plan ${selectedItems[0].feature}`,
      });
      setSelectedItems([]);
      setSplitPanelOpen(false);
      setSplitPanelContent(null);
      onRefresh();
    }
  };

  const refreshButtonProps = { onClick: onRefresh };

  function onSelectionChange(data: { detail: DialPlanListItem }) {
    setSplitPanelContent(<DialPlanSplitPanelDetails selectedItems={data.detail} />);
    setSplitPanelOpen(true);
  }

  const functions = [
    { text: 'Copy', action: Actions.Create },
    { text: 'Edit', action: Actions.Update },
    { text: 'Delete', action: Actions.Delete },
  ];

  const dropDownButtonMenuProps: ButtonDropdownProps = {
    disabled: !selectedItems || selectedItems.length === 0,
    onItemClick: handleUtilityItemClick,
    loadingText: 'loading',
    items: functions
      .filter((f) =>
        protectUtility({
          resourceType: ResourceTypes.DialPlan,
          resourceAction: f.action,
        }),
      )
      .map((f) => ({
        text: f.text,
        id: f.text.toLowerCase(),
        disabled: false,
      })),
  };

  const header = (
    <Header
      variant='awsui-h1-sticky'
      actions={
        <SpaceBetween
          direction='horizontal'
          size='xs'
        >
          <Button
            iconName='refresh'
            ariaLabel='Refresh'
            {...refreshButtonProps}
          />
          {!!dropDownButtonMenuProps.items.length && <ButtonDropdown {...{ ...dropDownButtonMenuProps }}>Actions</ButtonDropdown>}
          <ProtectedUtilityCreate resourceType={ResourceTypes.DialPlan}>
            <Button
              variant='primary'
              onClick={() => navigate('/dial-plan/create')}
            >
              Create dial plan
            </Button>
          </ProtectedUtilityCreate>
        </SpaceBetween>
      }
    >
      Dial Plans {dialPlan && !isLoading ? `(${dialPlan.length})` : <Spinner />}
    </Header>
  );

  const filter = (
    <PropertyFilter
      {...propertyFilterProps}
      countText={getTextFilterCounterText(filteredItemsCount)}
      expandToViewport={true}
      filteringAriaLabel={'Filter DialPlan'}
      filteringPlaceholder={'Filter DialPlan'}
    />
  );

  const closureTableProps: TableProps = {
    ...collectionProps,
    stickyHeader: true,
    resizableColumns: true,
    onColumnWidthsChange: saveWidths,
    onSelectionChange: ({ detail }) => {
      if (detail && detail.selectedItems && detail.selectedItems.length > 0) {
        const selectedItem: DialPlanListItem = detail.selectedItems[0];
        setSelectedItems([selectedItem]);
        onSelectionChange({ detail: selectedItem });
      }
    },
    selectedItems,
    columnDefinitions,
    items,
    // submitEdit:handleSubmit,
    trackBy: 'feature',
    loadingText: 'Loading closure groups',
    columnDisplay: preferences.contentDisplay,
    wrapLines: preferences.wrapLines,
    stripedRows: preferences.stripedRows,
    stickyColumns: preferences.stickyColumns,
    contentDensity: preferences.contentDensity as 'compact' | 'comfortable',
    selectionType: 'single',
    loading: isLoading || !dialPlan || refreshLoading,
    header,
    filter,
    pagination: <Pagination {...paginationProps} />,
    preferences: <Preferences {...{ preferences, setPreferences }} />,
  };

  return (
    <>
      <Table {...{ ...closureTableProps }} />
      {error && (
        <Alert
          statusIconAriaLabel='Error'
          type='error'
          header='Error getting data'
        >
          Refresh the page to try again.
        </Alert>
      )}
    </>
  );
}
