import React, { useState } from 'react';
import useSWR from 'swr';
import fetcher from '../../utils/fetcher';
import Header from '@cloudscape-design/components/header';
import SpaceBetween from '@cloudscape-design/components/space-between';
import { ContentLayout, DateRangePickerProps } from '@cloudscape-design/components';
import AuditTable from './components/table';
import { dateRangeToEpoch, EpochDateRange } from '../../utils/dateRangeToEpoch';

export default function AuditLog() {
  const defaultRange: DateRangePickerProps.Value = {
    type: 'relative',
    amount: 30,
    unit: 'day',
    key: 'last-30-days',
  };

  const [period, setPeriod] = useState<DateRangePickerProps.Value>(defaultRange);
  const [epochRange, setEpochRange] = useState<EpochDateRange>(dateRangeToEpoch(defaultRange).epochRange);

  const handleDateRangeChange = (rangeValue: DateRangePickerProps.Value | null) => {
    if (rangeValue) {
      setPeriod(rangeValue);
      const { epochRange: newEpochRange } = dateRangeToEpoch(rangeValue);
      setEpochRange(newEpochRange);
    }
  };

  const fetchUrl = epochRange ? `/audit?start=${epochRange.startEpoch}&end=${epochRange.endEpoch}` : null;

  const { data, isLoading, error } = useSWR(fetchUrl, fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 0,
    shouldRetryOnError: false,
  });

  return (
    <ContentLayout
      header={
        <Header
          variant='h1'
          description='Search and view changes that have been made within the Cato application'
        >
          System audit logs
        </Header>
      }
    >
      <SpaceBetween size='m'>
        <AuditTable
          isLoading={isLoading}
          error={error}
          auditLogs={data?.data}
          onDateRangeChange={handleDateRangeChange}
          period={period}
        />
      </SpaceBetween>
    </ContentLayout>
  );
}
