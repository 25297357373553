import React from 'react';
import { Container, Header, ContentLayout, Spinner, ColumnLayout, SpaceBetween, Grid } from '@cloudscape-design/components';

import { useParams } from 'react-router-dom';
import ContactSummary from './components/contactSummary';
import ContactAnalysisVoice from './components/voice/contactAnalysisVoice';
import { ContactSearchViewResponse } from '../../../types/contactRecord';
import useIntuitionSWR from '../../../utils/hooks/useIntuitionSWR';

export default function ContactSearchView() {
  const { contactId } = useParams();
  const { data, isLoading, error } = useIntuitionSWR<ContactSearchViewResponse>(`/queries?templateType=getContactRecord&contactId=${contactId}`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 0,
  });

  if (isLoading || !data || !data.data) {
    return (
      <Container>
        <Spinner size='large' />
      </Container>
    );
  }

  if (error || !Array.isArray(data.data) || data.data.length === 0) {
    return (
      <Container header={<Header headingTagOverride='h3'>Error</Header>}>
        <ColumnLayout
          columns={2}
          variant='text-grid'
        >
          <SpaceBetween size='xs'>Unable to find a record matching {JSON.stringify(contactId)}</SpaceBetween>
        </ColumnLayout>
      </Container>
    );
  }

  const contactData = data.data[0];

  return (
    <ContentLayout header={<Header variant='h1'>{isLoading ? <Spinner /> : contactData.contactId}</Header>}>
      <Grid
        gridDefinition={[
          { colspan: { default: 12, xxs: 12 } },
          { colspan: { default: 12, xxs: 12 } },
          { colspan: { default: 12, xxs: 12 } },
          { colspan: { default: 12, xxs: 12 } },
        ]}
      >
        <ContactSummary contactObject={contactData} />
        {contactData.channel === 'VOICE' && <ContactAnalysisVoice contactObject={contactData} />}
      </Grid>
    </ContentLayout>
  );
}
