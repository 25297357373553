import React from 'react';
import { useItemContext } from '../../../../dashboardContext';
import { Box, Button, ColumnLayout, FormField, Modal, SpaceBetween, Input, InputProps } from '@cloudscape-design/components';
import { NonCancelableCustomEvent } from '@cloudscape-design/components/internal/events';
import { DashboardDataRepresentation } from '../interfaces';

interface WidgetPreferencesProps {
  preferences: ReadonlyArray<any>;
  onDismiss: () => void;
  onConfirm: (visibleContent: ReadonlyArray<any>) => void;
  widgetId: string;
}

export function WidgetPreferences(props: WidgetPreferencesProps) {
  const { onDismiss, widgetId } = props;
  const { item, setItem } = useItemContext();
  const widget = item?.data?.widgets?.find((w: { id: string }) => w.id === widgetId);

  const initialPreferences = widget?.preferences || {};
  const dashboardId = initialPreferences?.dashboardId || '';
  const header = initialPreferences?.header || '';

  function setItemContext(preferenceName: string, value: string, widgetId: string) {
    setItem((prevItem: DashboardDataRepresentation) => {
      const updatedWidgets = prevItem.data.widgets.map((widget: any) => {
        if (widget.id === widgetId) {
          const newPreferences = { ...(widget.preferences || {}) };
          newPreferences[preferenceName] = value;
          return { ...widget, preferences: newPreferences };
        }
        return widget;
      });
      return {
        ...prevItem,
        data: {
          ...prevItem.data,
          widgets: updatedWidgets,
        },
      };
    });
  }

  function onDashboardIdInputChange(event: NonCancelableCustomEvent<InputProps.ChangeDetail>) {
    const value = event.detail.value;
    setItemContext('dashboardId', value, widgetId);
  }

  function onHeadingInputChange(event: NonCancelableCustomEvent<InputProps.ChangeDetail>) {
    const value = event.detail.value;
    setItemContext('header', value, widgetId);
  }

  return (
    <Modal
      visible={true}
      header='Preferences'
      onDismiss={onDismiss}
      footer={
        <Box float='right'>
          <SpaceBetween
            direction='horizontal'
            size='xs'
          >
            <Button
              variant='link'
              formAction='none'
              onClick={onDismiss}
            >
              Close
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <ColumnLayout
        columns={1}
        borders='horizontal'
      >
        <FormField
          description='Enter the Quicksight Dashboard ID'
          label='Dashboard ID'
        >
          <Input
            name='dashboardId'
            onChange={onDashboardIdInputChange}
            value={dashboardId}
          />
        </FormField>
        <FormField
          description="Enter the Header for the tile.  (Defaults to 'Quicksight Dashboard')"
          label='Widget Header'
        >
          <Input
            name='header'
            onChange={onHeadingInputChange}
            value={header}
          />
        </FormField>
      </ColumnLayout>
    </Modal>
  );
}
