import { useEffect } from 'react';
import DateRangePicker, { DateRangePickerProps } from '@cloudscape-design/components/date-range-picker';

export type DateRange = DateRangePickerProps.Value;
export type RelativeDateRange = DateRangePickerProps.RelativeValue;
export type AbsoluteDateRange = DateRangePickerProps.AbsoluteValue;

interface CxDateRangePickerProps {
  readonly setDateRangeValue: (value: DateRange | null) => void;
  readonly defaults?: DateRange;
}

export default function CxDateRangePicker({ setDateRangeValue, defaults }: CxDateRangePickerProps) {
  const defaultValue: DateRangePickerProps.RelativeValue = {
    amount: 30,
    unit: 'day',
    type: 'relative',
  };

  const value = defaults || defaultValue;

  useEffect(() => {
    setDateRangeValue(value);
  }, []);

  const handleDateRangeChange: DateRangePickerProps['onChange'] = ({ detail }) => {
    setDateRangeValue(detail.value);
  };

  const relativeRangeLabels: { [key: string]: string } = {
    'minute-5': 'Last 5 minutes',
    'minute-30': 'Last 30 minutes',
    'hour-1': 'Last 1 hour',
    'hour-6': 'Last 6 hours',
    'day-3': 'Last 3 days',
    'day-7': 'Last 7 days',
    'day-14': 'Last 14 days',
    'day-30': 'Last 30 days',
    'day-1': 'Today',
    'week-1': 'Current week',
    'month-1': 'Current month',
    'year-1': 'Current year',
  };

  const formatRelativeRange = (value: DateRangePickerProps.RelativeValue): string => {
    const { unit, amount, type } = value;

    if (type !== 'relative') {
      return 'Custom range';
    }

    const key = `${unit}-${amount}`;
    return relativeRangeLabels[key] || `Last ${amount} ${unit}${amount !== 1 ? 's' : ''}`;
  };

  const isValidRange: DateRangePickerProps['isValidRange'] = (range) => {
    if (!range) {
      return { valid: true };
    }

    if (range.type === 'absolute') {
      const startDate = new Date(range.startDate);
      const endDate = new Date(range.endDate);

      if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
        return {
          valid: false,
          errorMessage: 'Invalid date format',
        };
      }

      if (startDate > endDate) {
        return {
          valid: false,
          errorMessage: 'Start date must be before end date',
        };
      }
    }

    return { valid: true };
  };

  const relativeOptions: DateRangePickerProps['relativeOptions'] = [
    { key: 'previous-5-minutes', amount: 5, unit: 'minute', type: 'relative' },
    { key: 'previous-30-minutes', amount: 30, unit: 'minute', type: 'relative' },
    { key: 'previous-1-hour', amount: 1, unit: 'hour', type: 'relative' },
    { key: 'previous-6-hours', amount: 6, unit: 'hour', type: 'relative' },
    { key: 'last-3-days', amount: 3, unit: 'day', type: 'relative' },
    { key: 'last-7-days', amount: 7, unit: 'day', type: 'relative' },
    { key: 'last-14-days', amount: 14, unit: 'day', type: 'relative' },
    { key: 'last-30-days', amount: 30, unit: 'day', type: 'relative' },
    { key: 'today', amount: 1, unit: 'day', type: 'relative' },
    { key: 'this-week', amount: 1, unit: 'week', type: 'relative' },
    { key: 'this-month', amount: 1, unit: 'month', type: 'relative' },
    { key: 'this-year', amount: 1, unit: 'year', type: 'relative' },
  ];

  return (
    <DateRangePicker
      onChange={handleDateRangeChange}
      value={value}
      absoluteFormat='long-localized'
      hideTimeOffset
      relativeOptions={relativeOptions}
      isValidRange={isValidRange}
      i18nStrings={{
        relativeModeTitle: 'Relative range',
        absoluteModeTitle: 'Absolute range',
        relativeRangeSelectionHeading: 'Choose a range',
        startDateLabel: 'Start date',
        endDateLabel: 'End date',
        startTimeLabel: 'Start time',
        endTimeLabel: 'End time',
        clearButtonLabel: 'Clear and dismiss',
        cancelButtonLabel: 'Cancel',
        applyButtonLabel: 'Apply',
        formatRelativeRange,
      }}
      placeholder={'Select data time ranges'}
    />
  );
}
