import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { I18nProvider } from '@cloudscape-design/components/i18n';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Theme, applyTheme } from '@cloudscape-design/components/theming';
import customTheme from './themes/customTheme';

// Import all locales
import messages from '@cloudscape-design/components/i18n/messages/all.all';

// Auth
import { Amplify, Auth } from 'aws-amplify';
import { Authenticator, Heading, Text, useTheme, View, useAuthenticator, Button, Image } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

// Import Routes here
import Root from './routes/root';
import Home from './routes/home';
import Visualizations from './routes/visualizations';
import PromptEngineering from './routes/prompt-engineering';
import UserAdmin from './routes/user-admin';
import ErrorPage from './error-page';
import Documents from './routes/documents';
import ChatbotTesting from './routes/chatbot-testing';
import AuditLog from './routes/audit-log';
import PromptEngineeringEdit from './routes/prompt-engineering/edit';
import PromptEngineeringCreate from './routes/prompt-engineering/create';
import ContactSearch from './routes/contact-search';
import ContactSearchView from './routes/contact-search/view';
import Settings from './routes/settings';
import Utilities from './routes/utilities';
import DialPlan from './routes/dial-plan';
import DialPlanCreate from './routes/dial-plan/create';
import DialPlanEdit from './routes/dial-plan/edit';
import Messages from './routes/messages';
import MessagesCreate from './routes/messages/create';
import MessagesEdit from './routes/messages/edit';
import UserAdminCreate from './routes/user-admin/create';
import VisualizationsView from './routes/visualizations/view';
import DocumentsCreate from './routes/documents/create';
import Closures from './routes/closures';
import ClosuresCreate from './routes/closures/create';
import ClosuresEdit from './routes/closures/edit';
import VisualizationsCreate from './routes/visualizations/create';
import Instances from './routes/instances';
import InstancesCreate from './routes/instances/create';
import InstancesUpdate from './routes/instances/edit';
import ClosuresCopy from './routes/closures/copy';
import MessagesCopy from './routes/messages/copy';
import DialPlanCopy from './routes/dial-plan/copy';
import Tickets from './routes/tickets';
import TicketsCreate from './routes/tickets/create';
import ProtectedRoute, { ProtectedRouteCreate, ProtectedRouteRead, ProtectedRouteUpdate } from './components/protectedRoute';
import { ResourceTypes } from './types/rolePermissions';
import Roles from './routes/roles';
// import CreateRole from './routes/roles/create';
import CreateOrEditRole from './routes/roles/createOrEdit';
import EditRoleUsers from './routes/roles/users';
import { VisualizationsEdit } from './routes/visualizations/edit';
import SurveyCreate from './routes/surveys/create';
import SurveyEdit from './routes/surveys/edit';
import SurveyList from './routes/surveys';
import VisualizationsCopy from './routes/visualizations/copy';
import SurveySettings from './routes/surveys/settings';

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
    oauth: {
      domain: `${process.env.REACT_APP_COGNITO_DOMAIN}.auth.us-east-1.amazoncognito.com`,
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: process.env.REACT_APP_PORTAL_URL,
      redirectSignOut: process.env.REACT_APP_PORTAL_URL,
      responseType: 'code',
    },
  },
});

const components = {
  Header() {
    return (
      <View textAlign='center'>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Image
            alt='logo'
            src={'/dark.svg'}
            width={250}
            height={75}
          />
        </div>
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View
        textAlign='center'
        padding={tokens.space.large}
      >
        <Text color={tokens.colors.neutral[80]}>&copy; All rights reserved CX Studios Ltd</Text>
      </View>
    );
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme();

      Auth.currentAuthenticatedUser()
        .then(() => location.reload())
        .catch((noop) => noop);

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <span>Cato</span>
        </Heading>
      );
    },
    Footer() {
      const { toResetPassword } = useAuthenticator();

      return (
        <View textAlign='center'>
          <Button
            fontWeight='normal'
            onClick={toResetPassword}
            size='small'
            variation='link'
          >
            Reset Password
          </Button>
        </View>
      );
    },
  },
  SetupTOTP: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.large} 0 0 ${tokens.space.large}`}
          level={3}
        >
          Register OTP
        </Heading>
      );
    },
    Footer() {
      return <Text>TIP: Scan QR using Google / Microsoft Authenticator mobile app</Text>;
    },
  },
  ConfirmSignIn: {
    Header() {
      const { tokens } = useTheme();

      useEffect(() => {
        const element = document.querySelector('[name="confirmation_code"]');
        if (element && element instanceof HTMLElement) {
          element.focus();
        }
      });
      return (
        <Heading
          padding={`${tokens.space.large} 0 0 ${tokens.space.large}`}
          level={3}
        >
          One time passcode:
        </Heading>
      );
    },
    Footer() {
      return <Text>TIP: Google / Microsoft Authenticator</Text>;
    },
  },
  ResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.large} 0 0 ${tokens.space.large}`}
          level={3}
        >
          Password reset:
        </Heading>
      );
    },
    Footer() {
      return <Text />;
    },
  },
  ConfirmResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.large} 0 0 ${tokens.space.large}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text></Text>;
    },
  },
};
const formFields = {
  signIn: {
    username: {
      placeholder: 'Enter your email:',
      label: '',
    },
    password: {
      label: '',
      placeholder: 'Enter your Password:',
    },
  },

  forceNewPassword: {
    password: {
      placeholder: 'Enter your Password:',
    },
  },
  resetPassword: {
    username: {
      placeholder: 'Enter your email:',
      label: '',
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      placeholder: 'Enter your email Code:',
      // label: 'New Label',
      isRequired: true,
    },
    confirm_password: {
      placeholder: 'Enter your Password Please:',
    },
  },
  setupTOTP: {
    QR: {
      totpIssuer: process.env.REACT_APP_ENV === 'prod' ? 'cato.cx.studio' : `Cato-${process.env.REACT_APP_ENV}`,
    },
    confirmation_code: {
      label: '',
      placeholder: 'Enter your code:',
      isRequired: true,
    },
  },
  confirmSignIn: {
    confirmation_code: {
      label: '',
      placeholder: 'Enter your authenticator Code:',
      isRequired: true,
    },
  },
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'home',
        element: <Home />,
      },
      {
        path: 'dial-plan',
        element: (
          <ProtectedRoute resourceType={ResourceTypes.DialPlan}>
            <DialPlan />
          </ProtectedRoute>
        ),
      },
      {
        path: 'dial-plan/create',
        element: (
          <ProtectedRouteCreate resourceType={ResourceTypes.DialPlan}>
            <DialPlanCreate />
          </ProtectedRouteCreate>
        ),
      },
      {
        path: 'dial-plan/edit/:featureId',
        element: (
          <ProtectedRouteUpdate resourceType={ResourceTypes.DialPlan}>
            <DialPlanEdit />
          </ProtectedRouteUpdate>
        ),
      },
      {
        path: 'dial-plan/copy/:featureId',
        element: (
          <ProtectedRouteCreate resourceType={ResourceTypes.DialPlan}>
            <DialPlanCopy />
          </ProtectedRouteCreate>
        ),
      },
      {
        path: 'messages',
        element: (
          <ProtectedRoute resourceType={ResourceTypes.Messages}>
            <Messages />
          </ProtectedRoute>
        ),
      },
      {
        path: 'messages/create',
        element: (
          <ProtectedRouteCreate resourceType={ResourceTypes.Messages}>
            <MessagesCreate />
          </ProtectedRouteCreate>
        ),
      },
      {
        path: 'messages/edit/:featureId',
        element: (
          <ProtectedRouteUpdate resourceType={ResourceTypes.Messages}>
            <MessagesEdit />
          </ProtectedRouteUpdate>
        ),
      },
      {
        path: 'messages/copy/:featureId',
        element: (
          <ProtectedRouteCreate resourceType={ResourceTypes.Messages}>
            <MessagesCopy />
          </ProtectedRouteCreate>
        ),
      },
      {
        path: 'closures',
        element: (
          <ProtectedRoute resourceType={ResourceTypes.Closures}>
            <Closures />
          </ProtectedRoute>
        ),
      },
      {
        path: 'closures/create',
        element: (
          <ProtectedRouteCreate resourceType={ResourceTypes.Closures}>
            <ClosuresCreate />
          </ProtectedRouteCreate>
        ),
      },
      {
        path: 'closures/edit/:featureId',
        element: (
          <ProtectedRouteUpdate resourceType={ResourceTypes.Closures}>
            <ClosuresEdit />
          </ProtectedRouteUpdate>
        ),
      },
      {
        path: 'closures/copy/:featureId',
        element: (
          <ProtectedRouteCreate resourceType={ResourceTypes.Closures}>
            <ClosuresCopy />
          </ProtectedRouteCreate>
        ),
      },
      {
        path: 'visualizations',
        element: (
          <ProtectedRouteRead resourceType={ResourceTypes.Dashboards}>
            <Visualizations />
          </ProtectedRouteRead>
        ),
      },
      {
        path: 'visualizations/create',
        element: (
          <ProtectedRouteCreate resourceType={ResourceTypes.Dashboards}>
            <VisualizationsCreate />
          </ProtectedRouteCreate>
        ),
      },
      {
        path: 'visualizations/edit/:featureId',
        element: (
          <ProtectedRouteCreate resourceType={ResourceTypes.Dashboards}>
            <VisualizationsEdit />
          </ProtectedRouteCreate>
        ),
      },
      {
        path: 'visualizations/copy/:featureId',
        element: (
          <ProtectedRouteCreate resourceType={ResourceTypes.Closures}>
            <VisualizationsCopy />
          </ProtectedRouteCreate>
        ),
      },
      {
        path: 'visualizations/view/:featureId',
        element: <VisualizationsView />,
      },
      {
        path: 'documents',
        element: <Documents />,
      },
      {
        path: 'documents/create',
        element: <DocumentsCreate />,
      },
      {
        path: 'prompt-engineering',
        element: <PromptEngineering />,
      },
      {
        path: 'prompt-engineering/edit/:featureId',
        element: <PromptEngineeringEdit />,
      },
      {
        path: 'prompt-engineering/create',
        element: <PromptEngineeringCreate />,
      },
      {
        path: 'chatbot-testing',
        element: <ChatbotTesting />,
      },
      {
        path: 'user-admin',
        element: <UserAdmin />,
      },
      {
        path: 'user-admin/create',
        element: <UserAdminCreate />,
      },
      {
        path: 'audit-log',
        element: <AuditLog />,
      },
      {
        path: 'contact-search',
        element: (
          <ProtectedRouteRead resourceType={ResourceTypes.ContactSearch}>
            <ContactSearch />
          </ProtectedRouteRead>
        ),
      },
      {
        path: 'contact-search/view/:contactId',
        element: (
          <ProtectedRouteRead resourceType={ResourceTypes.ContactSearch}>
            <ContactSearchView />
          </ProtectedRouteRead>
        ),
      },
      {
        path: 'settings',
        element: <Settings />,
      },
      {
        path: 'utilities',
        element: <Utilities />,
      },
      {
        path: '*',
        element: <ErrorPage />,
      },
      {
        path: 'instances',
        element: <Instances />,
      },
      {
        path: 'instances/create',
        element: <InstancesCreate />,
      },
      {
        path: 'instances/edit/:instanceId',
        element: <InstancesUpdate />,
      },
      {
        path: 'tickets',
        element: <Tickets />,
      },
      {
        path: 'tickets/create',
        element: <TicketsCreate />,
      },
      {
        path: 'roles',
        element: <Roles />,
      },
      {
        path: 'roles/create',
        element: <CreateOrEditRole />,
      },
      {
        path: 'roles/edit/:featureId',
        element: <CreateOrEditRole />,
      },
      {
        path: 'roles/edit/:featureId/users',
        element: <EditRoleUsers />,
      },
      {
        path: 'surveys',
        element: (
          <ProtectedRoute resourceType={ResourceTypes.Surveys}>
            <SurveyList />
          </ProtectedRoute>
        ),
      },
      {
        path: 'surveys/settings',
        element: (
          <ProtectedRoute resourceType={ResourceTypes.Surveys}>
            <SurveySettings />
          </ProtectedRoute>
        ),
      },
      {
        path: 'surveys/edit/:featureId',
        element: (
          <ProtectedRouteCreate resourceType={ResourceTypes.Surveys}>
            <SurveyEdit />
          </ProtectedRouteCreate>
        ),
      },
      {
        path: 'surveys/create',
        element: (
          <ProtectedRouteCreate resourceType={ResourceTypes.Surveys}>
            <SurveyCreate />
          </ProtectedRouteCreate>
        ),
      },
    ],
  },
]);

const theme: Theme = customTheme;
// Apply custom theme
applyTheme({ theme });

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <I18nProvider
      locale='en'
      messages={[messages]}
    >
      <Authenticator
        hideSignUp
        formFields={formFields}
        components={components}
      >
        <RouterProvider router={router} />
      </Authenticator>
    </I18nProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
