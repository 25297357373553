import React from 'react';
import { useNavigate } from 'react-router-dom';
import { InstanceType } from '../../../types/instance';

interface ClickableNameLinkProps {
  item: InstanceType;
}

export const ClickableNameLink: React.FC<ClickableNameLinkProps> = ({ item }) => {
  const navigate = useNavigate();
  const navigateToUpdatePage = () => {
    const { instanceId } = item;

    if (instanceId) {
      navigate(`/instances/edit/${encodeURIComponent(instanceId)}`);
    }
  };

  return (
    <span
      style={{ cursor: 'pointer', textDecoration: 'underline' }}
      onClick={navigateToUpdatePage}
    >
      {item?.name || item?.instanceName || 'Default Name'}
    </span>
  );
};
