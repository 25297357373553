// import useSWR from 'swr';
import useIntuitionSWR from './hooks/useIntuitionSWR';
// import fetcher from './fetcher';
import { formatDateTimePickerApi } from './timeUtils';
import { queueArnsToQueueIds } from '../routes/visualizations/view/configurable-dashboard/components/widgets/utils/widget.helpers';

export default function GetWidgetData({ widget, period }: { widget: any; period: any }) {
  const { templateType } = widget;

  // Use optional chaining and nullish coalescing to provide default values
  const channels = widget.filters?.channels ?? null;
  const queues = widget.filters?.queues ?? null;
  const agents = widget.filters?.agents ?? null;
  const routingProfiles = widget.filters?.routingProfiles ?? null;

  const { startDate, endDate } = formatDateTimePickerApi(period);
  const interval = period.type === 'relative' ? 60000 : 0;

  // Create URLSearchParams object
  const params = new URLSearchParams();
  params.append('start', startDate);
  params.append('end', endDate);
  params.append('templateType', templateType);

  // Append channels, queues, agents, and routingProfiles to the params if they exist
  if (agents) params.append('agents', JSON.stringify(agents));
  if (queues) params.append('queues', queueArnsToQueueIds(queues));
  if (channels) params.append('channels', JSON.stringify(channels));
  if (routingProfiles) params.append('routingProfiles', JSON.stringify(routingProfiles));

  // Construct the URL with the query string
  const url = `/queries?${params.toString()}`;
  const { data, isLoading, error } = useIntuitionSWR<any>(url, {
    shouldRetryOnError: false,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: interval,
  });

  return { data, isLoading, error };
}
