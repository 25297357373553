import { useNavigate } from 'react-router-dom';
import { useApiWithFlash } from '../../../utils/hooks/useApiWithFlashInstance';
import { EditVisualizationForm, PartialDashboardData } from '../form';

export default function VisualizationsCreate() {

  // Hooks
  const navigate = useNavigate();
  const { handleApiWithFlash } = useApiWithFlash();

  // Handle form submission
  async function handleSubmitForm(dashboard: PartialDashboardData) {

    const {feature, ...data } = dashboard;

    const response = await handleApiWithFlash(`/intuition/dashboards/${feature}`, 'POST', {
      successMessage: `Successfully created dashboard  ${feature}`,
      errorMessage: `Error creating dashboard ${feature}`,
      mutateKey: `/intuition/dashboards/${feature}`,
      data,
    });

    if (response?.success) {
      navigate(`/visualizations/view/${feature}`);
    } else {
      navigate(`/visualizations`);
    }
  }

  const formProps = {
    handleSubmitForm,
  }

  return (
    <EditVisualizationForm {...{...formProps}} />
  );
}
