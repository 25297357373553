import { PropertyFilterProperty, useCollection } from '@cloudscape-design/collection-hooks';
import { TableEmptyState, TableEmptyStateProps, TableNoMatchState } from '../../components/table/commons';

export interface UseCatoCollectionProps {
  tableEmptyStateProps?: TableEmptyStateProps;
  pageSize?: number;
  filteringProperties: readonly PropertyFilterProperty[];
}

export const useCatoCollection = <T,>(allItems: ReadonlyArray<T>, { tableEmptyStateProps, filteringProperties, pageSize }: UseCatoCollectionProps) => {
  const hasEmpty = !!tableEmptyStateProps;
  const { actions, ...others } = useCollection(allItems, {
    propertyFiltering: {
      filteringProperties,
      empty: hasEmpty && <TableEmptyState {...{ ...tableEmptyStateProps }} />,
      noMatch: (
        <TableNoMatchState
          onClearFilter={() => {
            actions.setPropertyFiltering({ tokens: [], operation: 'and' });
          }}
        />
      ),
    },
    filtering: {
      empty: hasEmpty && <TableEmptyState {...{ ...tableEmptyStateProps }} />,
      noMatch: (
        <TableNoMatchState
          onClearFilter={() => {
            actions.setPropertyFiltering({ tokens: [], operation: 'and' });
          }}
        />
      ),
    },
    pagination: { pageSize },
    sorting: {},
    selection: {},
  });

  return { ...others, actions };
};
