import GetWidgetData from '../../../../../../../utils/getWidgetData';
import { useItemContext } from '../../../../dashboardContext';
import WidgetTable from './table';

export default function AgentSummaryStatsContent({ widgetId }: { widgetId: string }) {
  const { item, getWidget } = useItemContext();
  const widget = getWidget(widgetId);
  const period = item.data.period;

  const { data, isLoading, error } = GetWidgetData({ widget, period });

  const widgetTableProps = {
    widgetId,
    data: data?.data,
    isLoading,
    error,
  };

  return <WidgetTable {...widgetTableProps} />;
}
