import { useAppSession } from '../../utils/hooks/sessionContext';
import { disabledResources } from '../disabledResources';
import { ProtectedRouteProps } from './types';

/**
 * utility function can be used without ProtectedRoute component to protectd a list item for example
 * @param props
 * @returns boolean
 */
export const useProtectRoute = () => {
  const appSession = useAppSession();

  return (props: Omit<ProtectedRouteProps, 'children'>): boolean => {

    const disabledResource = disabledResources.includes(props.resourceType);

    const isAdmin = appSession?.userProfile?.['custom:securityProfile'] === 'Administrator';

    if(disabledResource) {
      return false;
    }

    if(isAdmin) {
      return true;
    }

    const routePermissions =
      (!!appSession?.instancePermissions && appSession?.instancePermissions[props.resourceType]) || [];
    return !props.resourceAction
      ? !!routePermissions.length
      : routePermissions.includes(props.resourceAction);
  };
};
