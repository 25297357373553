import React, { useEffect, useState } from 'react';
import {
  ColumnLayout,
  Container,
  SpaceBetween,
  SplitPanel,
} from '@cloudscape-design/components';
import { ValueWithLabel } from '../../../components/valueWithLabel';
import { RolesArray, User } from '../types';
import { getAttributeValue } from './helpers';

interface FormattedUser {
  username: string;
  fullName: string;
  createDate: string;
  modifiedDate: string;
  securityProfile: string;
  userStatus: string;
  roles: string[];
}

interface AuditSplitPanelDetailsProps {
  selectedItem: User;
  rolesData: RolesArray;
}

const UserSplitPanelDetails: React.FC<AuditSplitPanelDetailsProps> = ({
  selectedItem,
  rolesData,
}) => {
  const [formattedUser, setFormattedUser] = useState<FormattedUser | null>(
    null
  );
  useEffect(() => {
    const createDate = parseDate(selectedItem.UserCreateDate);
    const modifiedDate = parseDate(selectedItem.UserLastModifiedDate);
    const fullName = `${parseAttributes(
      selectedItem,
      'given_name'
    )} ${parseAttributes(selectedItem, 'family_name')}`;

    const securityProfile = parseAttributes(
      selectedItem,
      'custom:securityProfile'
    );
    const roles = parseRoles(selectedItem);

    setFormattedUser({
      username: selectedItem.Username,
      fullName,
      createDate,
      modifiedDate,
      securityProfile,
      userStatus: selectedItem.UserStatus,
      roles,
    });
  }, [selectedItem, rolesData]);

  function parseAttributes(selectedItem: User, toFetch: string) {
    const value = getAttributeValue(selectedItem.Attributes, toFetch) || '';
    return value;
  }

  function parseDate(date: string) {
    if (!date) return '';
    return new Date(date).toLocaleString('en-GB', {
      hour12: false,
      minute: 'numeric',
      hour: 'numeric',
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  }

  function parseRoles(selectedItem: User): string[] {
    const roleValue = parseAttributes(selectedItem, 'custom:roles');
    const roleIds = roleValue.split('|');
    return roleIds
      .map((roleId) => {
        const foundRole = rolesData.find((role) => role.feature === roleId);
        return foundRole?.name;
      })
      .filter((role): role is string => !!role);
  }

  return (
    formattedUser && (
      <SplitPanel
        header={`User: ${formattedUser.username}`}
        closeBehavior='collapse'
      >
        <Container>
          <ColumnLayout columns={2} variant='text-grid'>
            <SpaceBetween size='l'>
              <ValueWithLabel label='Username'>
                {formattedUser.username}
              </ValueWithLabel>
              <ValueWithLabel label='Full Name'>
                {formattedUser.fullName}
              </ValueWithLabel>
              <ValueWithLabel label='Created Date'>
                {formattedUser.createDate}
              </ValueWithLabel>
              <ValueWithLabel label='Modified Date'>
                {formattedUser.modifiedDate}
              </ValueWithLabel>
            </SpaceBetween>
            <SpaceBetween size='l'>
              <ValueWithLabel label='Security Profile'>
                {formattedUser.securityProfile}
              </ValueWithLabel>
              <ValueWithLabel label='User status'>
                {formattedUser.userStatus}
              </ValueWithLabel>
              {formattedUser.roles.length > 0 && (
                <ValueWithLabel label='Roles'>
                  <ul>
                    {formattedUser.roles.map(
                      (roleName: string, index: number) => (
                        <li key={index}>{roleName}</li>
                      )
                    )}
                  </ul>
                </ValueWithLabel>
              )}
            </SpaceBetween>
          </ColumnLayout>
        </Container>
      </SplitPanel>
    )
  );
};

export default UserSplitPanelDetails;
