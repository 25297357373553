import React, { useState, useEffect } from 'react';
import Header from '@cloudscape-design/components/header';
import SpaceBetween from '@cloudscape-design/components/space-between';
import { ContentLayout } from '@cloudscape-design/components';
import ContactSearchTable from './components/table';
import { ContactSearchArray, DateRange } from './types';
import useIntuitionSWR from '../../utils/hooks/useIntuitionSWR';

export default function ContactSearch() {
  const [submitSearch, setSubmitSearch] = useState<boolean>(false);
  const [changesMade, setChangesMade] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: '6hour',
    endDate: 'now',
  });
  const [searchFilters, setSearchFilters] = useState({});
  const [currentFetchUrl, setCurrentFetchUrl] = useState<string | null>(null);

  useEffect(() => {
    const constructFetchUrl = () => {
      const newQueryParams = {
        start: dateRange.startDate,
        end: dateRange.endDate,
        templateType: 'getContactSearch',
        searchFilters: JSON.stringify(searchFilters),
      };

      const queryString = Object.entries(newQueryParams)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');

      const url = `/queries?${queryString}`;
      return url;
    };

    if (!currentFetchUrl || submitSearch) {
      setSubmitSearch(false);
      setCurrentFetchUrl(constructFetchUrl());
    }
  }, [submitSearch, currentFetchUrl, dateRange.startDate, dateRange.endDate, searchFilters]);

  useEffect(() => {
    setChangesMade(true);
  }, [dateRange, searchFilters]);

  const { data, isLoading, error } = useIntuitionSWR<ContactSearchArray>(currentFetchUrl, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 0,
    shouldRetryOnError: false,
  });

  useEffect(() => {
    setChangesMade(false);
  }, [data]);

  return (
    <ContentLayout
      header={
        <Header
          description='Search for all contacts within the system'
          variant='h1'
        >
          Contact Search
        </Header>
      }
    >
      <SpaceBetween size='m'>
        <ContactSearchTable
          changesMade={changesMade}
          contactSearchResults={!data ? [] : data.data}
          error={error}
          isLoading={isLoading}
          setChangesMade={setChangesMade}
          setDateRange={setDateRange}
          setSearchFilters={setSearchFilters}
          setSubmitSearch={setSubmitSearch}
        />
      </SpaceBetween>
    </ContentLayout>
  );
}
