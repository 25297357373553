import React, { useState } from 'react';
import fetcher from '../../../utils/fetcher';
import {
  ColumnLayout,
  Container,
  Header,
  ContentLayout,
  SpaceBetween,
  Textarea,
  Form,
  Input,
  Button,
  FormField,
  Select,
  Toggle,
} from '@cloudscape-design/components';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useFlash } from '../../../utils/hooks/useFlash';
import { formConfig } from '../config';

type FormData = Record<string, string | number | boolean | null>;
type FieldErrors = Record<string, string | null>;

export default function PromptEngineeringCreate() {
  // Get the default values for state on first load
  const getDefaultFormData = (): FormData => {
    const defaultData: FormData = {};
    formConfig.forEach((field) => {
      if (field.defaultValue !== undefined) {
        defaultData[field.name] = field.defaultValue;
      } else if (field.inputType === 'text' || field.inputType === 'dropdown') {
        defaultData[field.name] = '';
      } else if (field.inputType === 'number') {
        defaultData[field.name] = 0;
      } else if (field.inputType === 'textarea') {
        defaultData[field.name] = '';
      }
    });
    return defaultData;
  };

  // state
  const [formSubmited, setFormSubmited] = useState<boolean>(false);
  const [fieldErrors, setFieldErrors] = useState<FieldErrors>({});
  const [formData, setFormData] = useState<FormData>(getDefaultFormData());
  const [textareaCharCount, setTextareaCharCount] = useState<number>(0);

  // Hooks
  const navigate = useNavigate();
  const { addFlash, removeFlash } = useFlash();

  const validateForm = (dataToValidate: FormData) => {
    const errors: FieldErrors = {};

    formConfig.forEach((field) => {
      if (field.validation) {
        const error = field.validation(dataToValidate[field.name]);
        if (error) {
          errors[field.name] = error;
        }
      }
    });

    setFieldErrors(errors);
    return !Object.keys(errors).length;
  };
  
  const handleInputChange = (fieldName: string, value: string | boolean) => {
    const newFormData = { ...formData, [fieldName]: value };
    setFormData(newFormData);

    if (fieldName === 'prompt' && typeof value === 'string') {
      setTextareaCharCount(value.length);
    }

    validateForm(newFormData);
  };

  // Handle form submission
  async function handleSubmitForm() {
    setFormSubmited(true);

    try {
      await fetcher(`/prompt-dictionary/${formData.promptName}`, 'POST', formData);
      const messageId = uuidv4();
      addFlash({
        type: 'success',
        content: `Successfully created ${formData.promptName}`,
        dismissible: true,
        dismissLabel: 'Dismiss',
        onDismiss: () => removeFlash(messageId),
        id: messageId,
      });
      navigate('/prompt-engineering');
    } catch (error) {
      const messageId = uuidv4();
      addFlash({
        type: 'error',
        content: `Error creating ${formData.promptName}`,
        dismissible: true,
        dismissLabel: 'Dismiss',
        onDismiss: () => removeFlash(messageId),
        id: messageId,
      });
    } finally {
      setFormSubmited(false);
    }
  }

  return (
    <ContentLayout header={<Header variant="h1">{formData?.promptName}</Header>}>
      <Container>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            // const value = e.target.elements.prompt.value; // Accessing value of the input/textarea with name="prompt"
            handleSubmitForm();
          }}
        >
          <Form
            variant="embedded"
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button formAction="none" variant="link" onClick={() => navigate('/prompt-engineering')}>
                  Cancel
                </Button>
                <Button variant="primary" loading={formSubmited} disabled={Object.keys(fieldErrors).length > 0}>
                  Submit
                </Button>
              </SpaceBetween>
            }
          >
            <SpaceBetween direction="vertical" size="xl">
              {/* Render fields with fullWidth true */}
              {formConfig
                .filter((field) => field.fullWidth)
                .map((field) => (
                  <ColumnLayout columns={1} key={field.name}>
                    <FormField
                      label={field.label}
                      errorText={fieldErrors[field.name]}
                      constraintText={
                        field.inputType === 'textarea'
                          ? `Characters remaining: ${1000 - textareaCharCount}`
                          : field.constraintText
                      }
                    >
                      {field.inputType === 'text' && (
                        <Input
                          onChange={({ detail }) => handleInputChange(field.name, detail.value)}
                          value={String(formData[field.name])}
                          inputMode="text"
                          placeholder={field.placeholder}
                        />
                      )}
                      {field.inputType === 'textarea' && (
                        <Textarea
                          onChange={({ detail }) => handleInputChange(field.name, detail.value)}
                          value={String(formData[field.name]) || ''}
                          placeholder={field.placeholder}
                          rows={15}
                        />
                      )}
                    </FormField>
                  </ColumnLayout>
                ))}

              {/* Render regular fields */}
              <ColumnLayout columns={3}>
                {formConfig
                  .filter((field) => !field.fullWidth)
                  .map((field) => (
                    <FormField
                      label={field.label}
                      errorText={fieldErrors[field.name]}
                      constraintText={field.constraintText}
                      key={field.name}
                    >
                      {(field.inputType === 'text' || field.inputType === 'number') && (
                        <Input
                          onChange={({ detail }) => handleInputChange(field.name, detail.value)}
                          value={String(formData[field.name])}
                          inputMode={field.inputType === 'number' ? 'numeric' : 'text'}
                          placeholder={field.placeholder}
                        />
                      )}
                      {field.inputType === 'dropdown' && (
                        <Select
                          selectedOption={{ label: String(formData[field.name]), value: String(formData[field.name]) }}
                          onChange={({ detail }) => {
                            if (detail.selectedOption.value) {
                              handleInputChange(field.name, detail.selectedOption.value);
                            }
                          }}
                          options={
                            field.options
                              ? field.options.map((option) => ({
                                  label: option.label,
                                  value: option.id,
                                }))
                              : []
                          }
                        />
                      )}
                      {field.inputType === 'toggle' && (
                        <Toggle
                          onChange={({ detail }) => handleInputChange(field.name, detail.checked)}
                          checked={Boolean(formData[field.name])}
                        />
                      )}
                    </FormField>
                  ))}
              </ColumnLayout>
            </SpaceBetween>
          </Form>
        </form>
      </Container>
    </ContentLayout>
  );
}
