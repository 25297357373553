import React from 'react';
import moment from 'moment';
import { userIcon } from '../../../icons';
import styles from './agentStatus.module.scss';
import { AgentStatusObject } from './types';

export const AgentStatus: React.FC<{ agent: AgentStatusObject }> = ({ agent }) => {
  const { firstName, lastName } = agent;
  const status = agent.currentStatus.name;

  const eventTime = agent.currentStatus.startTimeStamp ? moment(agent.currentStatus.startTimeStamp) : null;
  const currentTime = moment();
  const timeDiff = moment.duration(currentTime.diff(eventTime));

  const totalHours = timeDiff.days() * 24 + timeDiff.hours();
  const formattedTimeDiff = `${totalHours.toString().padStart(2, '0')}:${timeDiff.minutes().toString().padStart(2, '0')}:${timeDiff
    .seconds()
    .toString()
    .padStart(2, '0')}`;

  return (
    <>
      <div className={styles.agentUsername}>
          {firstName} {lastName}
      </div>
      <img
        className={styles.userIcon}
        src={userIcon.light}
        alt='User Icon'
      />
      <div className={styles.agentStatus}>
        <div>{status}</div>
        <div>{formattedTimeDiff}</div>
      </div>
    </>
  );
};
