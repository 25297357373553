import { useCallback, useEffect } from 'react';
import { BlockerFunction, useBlocker, useNavigate } from 'react-router-dom';

interface BlockerLogicParams {
  changesDetected: boolean;
  path: string;
  formSubmitted: boolean;
}

export function useBlockerLogic({ changesDetected, path, formSubmitted }: BlockerLogicParams) {
  const navigate = useNavigate();
  const shouldBlock = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) => changesDetected && currentLocation.pathname !== nextLocation.pathname,
    [changesDetected],
  );

  const blocker = useBlocker(shouldBlock);

  useEffect(() => {
    if (blocker.state === 'blocked' && !changesDetected) {
      blocker.reset();
    }
  }, [blocker, changesDetected]);

  useEffect(() => {
    if (blocker.state === 'blocked' && formSubmitted) {
      blocker.proceed();
    }
  }, [blocker, formSubmitted]);

  function handleCancel() {
    navigate(`/${path}`);
  }

  function handleCloseCancelModal() {
    blocker?.reset?.();
  }

  function handleConfirmCancel() {
    blocker?.proceed?.();
  }

  return { blocker, handleCancel, handleCloseCancelModal, handleConfirmCancel };
}
