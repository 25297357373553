import { Header } from '@cloudscape-design/components';
import { WidgetConfig } from '../interfaces';
import ContactTraceRecord from './contactTraceRecord';
import { Provider } from './provider';

export const getContactTraceRecord: WidgetConfig = {
  definition: { defaultRowSpan: 1, defaultColumnSpan: 2, minRowSpan: 1 },
  data: {
    icon: 'table',
    title: 'Agent metrics',
    description: 'Realtime agent data. Table for all agents',
    header: ChartHeader,
    content: ContactTraceRecord,
    provider: Provider,
    staticMinHeight: 560,
    multipleAllowed: true,
  },
};

function ChartHeader() {
  return (
    <Header variant="h2" description="Realtime agent data">
      Realtime agents
    </Header>
  );
}
