import React from 'react';
import {
  ColumnLayout,
  Container,
  SpaceBetween,
  SplitPanel,
} from '@cloudscape-design/components';
import { ValueWithLabel } from '../../../components/valueWithLabel';
import { RoleRecord } from '../../../types/rolePermissions';

interface AuditSplitPanelDetailsProps {
  selectedItem: RoleRecord
}

const RoleSplitPanelDetails: React.FC<AuditSplitPanelDetailsProps> = ({
  selectedItem,
}) => {

  const filteredPolicies = selectedItem.policies.filter(
    (policy: any) => policy.actions && policy.actions.length > 0
  );

  return (
    <SplitPanel header={`Role: ${selectedItem.name}`} closeBehavior='collapse'>
      <Container>
        <ColumnLayout columns={2} variant='text-grid'>
          <SpaceBetween size='l'>
            <ValueWithLabel label='Name'>{selectedItem.name}</ValueWithLabel>
            <ValueWithLabel label='Description'>
              {selectedItem.description}
            </ValueWithLabel>
            <ValueWithLabel label='Instance'>
              {selectedItem.policies[0].instance}
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size='l'>
            <ValueWithLabel label='Permissions'>
              <ul>
                {filteredPolicies.map((policy: any, index: number) => (
                  <li key={index}>
                    {policy.resourceType} - {policy.actions.join(', ')}
                  </li>
                ))}
              </ul>
            </ValueWithLabel>
          </SpaceBetween>
        </ColumnLayout>
      </Container>
    </SplitPanel>
  );
};

export default RoleSplitPanelDetails;
