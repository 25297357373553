import { useState, useEffect } from 'react';
import useIntuitionSWR from '../../../../../utils/hooks/useIntuitionSWR';

const base64ToBlob = (base64: string) => {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i += 1) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray]);
};

export interface GetAudioFromS3ChildProps {
  blobURL: string | null;
  isLoading: boolean;
  error: string | null;
}

export interface GetAudioFromS3Props {
  url: string;
  children: (props: GetAudioFromS3ChildProps) => React.ReactElement | null;
}

const GetAudioFromS3: React.FC<GetAudioFromS3Props> = (props: GetAudioFromS3Props) => {
  const { url, children } = props;
  const [blobURL, setBlobURL] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  // Define the SWR URL conditionally
  const swrUrl = url === 'demo' ? null : `/recording-playback?path=${url}`;

  const {
    data,
    isLoading,
    error: fetchError,
  } = useIntuitionSWR(swrUrl, {
    shouldRetryOnError: false,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  useEffect(() => {
    if (fetchError) {
      setError('Failed to fetch audio');
      return;
    }

    if (data) {
      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const blob = base64ToBlob(data.body);
        const audioSrc = URL.createObjectURL(blob);
        setBlobURL(audioSrc);
      } catch (e) {
        setError('Invalid data received');
      }
    }

    if (url === 'demo') {
      // Load the local sound file
      fetch('/sampleCallRecording.wav')
        .then((response) => response.blob())
        .then((blob) => {
          const audioSrc = URL.createObjectURL(blob);
          setBlobURL(audioSrc);
        })
        .catch((err: unknown) => {
          console.error('error', { err });
          setError('Failed to load local audio');
        });
    }
  }, [data, fetchError, url]);

  // Pass the blobURL and error to children as a function
  return children({ blobURL, isLoading, error });
};

export default GetAudioFromS3;
