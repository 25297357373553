import React, { useState, useContext, useEffect } from 'react';
import Input from '@cloudscape-design/components/input';
import FormField from '@cloudscape-design/components/form-field';
import Alert from '@cloudscape-design/components/alert';

import {
  ChatWebSocketContext,
  ChatWebSocketContextType,
  IMessageInterface,
} from '../contexts/chatWebSocketContext';

export default function QuestionBar() {
  const [question, setQuestion] = useState('');
  const { conversationId = "", promptIntro, promptConfig, ready, messages: value, socket, updateMessages } =
    useContext<ChatWebSocketContextType>(ChatWebSocketContext);

  const sendMessage = () => {
    if (question.trim() === '') {
      return;
    }
    // return back to ready when websocket available
    console.log('Send message to WebSocket', ready);
    console.log(JSON.stringify({ action: 'sendQuestion', data: question }));
    console.log('conversationId', conversationId);
    console.log('promptIntro', promptIntro);
    console.log('promptConfig', promptConfig);
    if (socket) {
      const messageObject: IMessageInterface = {
        messageType: 'Question',
        message: question,
        state: 'Sent',
        createdAt: new Date(),
        conversationId,
      };
      console.log('messageObject', messageObject);
      socket.send(
        JSON.stringify({
          sessionId: '',
          userId: '',
          action: 'sendQuestion',
          questionText: messageObject.message,
          promptIntro: promptIntro || '',
          promptConfig,
          messageSource: 'PORTAL',
          userType: '',
          state: 'IN_PROGRESS',
          conversationId: messageObject.conversationId,
        })
      );
      updateMessages(messageObject);
      setQuestion('');
    } else {
      console.log('no connection');
    }
  };

  const sendKeyboardMessage = (event: any) => {
    // console.log("sendKeyboardMessage", event)
    if (event.detail.key === 'Enter') {
      console.log('Enter key was pressed');
      sendMessage();
    }
  };
  //
  useEffect(() => {
    console.log('value', value);
    console.log('ready', ready);
  }, [ready, value]);

  if (!ready) {
    return (
      <Alert statusIconAriaLabel="Info" header="Connectivity">
        No connection
      </Alert>
    );
  }

  return (
    <FormField
      stretch
      errorText={question.length > 200 && 'The name has too many characters.'}
      constraintText={<>Character count: {question.length}/200</>}
    >
      <Input
        name="cato-question"
        onChange={(e: any) => setQuestion(e.detail.value)}
        value={question}
        placeholder="Enter your message"
        type="search"
        onKeyDown={sendKeyboardMessage}
      />
    </FormField>
  );
}
