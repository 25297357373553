import { Button, ColumnLayout, Container, ContentLayout, Form, FormField, Header, Input, SpaceBetween, Textarea } from '@cloudscape-design/components';
import { useState } from 'react';
import { SurveyData } from '../types';
import { TagIcon } from '../../utilities/tagIcon';
import { isValidTag } from '../../../utils/validations';
import { handleFieldChange } from '../../../utils/fieldChangeUtils';
import { useBlockerLogic } from '../../../utils/hooks/useBlocker';
import useChangeDetector from '../../../utils/useChangeDetector';
import { ConfirmCancelModal } from '../../../components/confirmCancelModal';
import { v4 as uuidv4 } from 'uuid';
interface Question {
  uuid: string;
  question: string;
}

type Questions = Array<Question>;
interface EditSurveyFormProps {
  survey?: SurveyData;
  onSubmitForm: (surveyUpdate: SurveyData) => Promise<void> | void;
}

export default function EditSurveyForm({ onSubmitForm, survey: initialSurvey }: Readonly<EditSurveyFormProps>) {
  const { title, description, introPrompt, outroPrompt, min, max, questions, flags, tag } = initialSurvey || {
    title: '',
    description: '',
    introPrompt: '',
    outroPrompt: '',
    questions: [],
    flags: [],
    tag: '',
    min: 0,
    max: 9,
  };

  const {
    item: survey,
    setItem: setSurvey,
    changesDetected,
  } = useChangeDetector<Omit<SurveyData, 'questions'>>({ title, description, introPrompt, outroPrompt, min, max, flags, tag });

  const {
    item: surveyQuestions,
    setItem: setSurveyQuestions,
    changesDetected: questionsChanged,
  } = useChangeDetector<Questions>(
    questions.reduce((response: Questions, question) => {
      return [...response, { uuid: uuidv4(), question }];
    }, []),
  );

  const [tagError, setTagError] = useState<boolean>(false);
  const [tagErrorMessage, setTagErrorMessage] = useState<string>();
  const [submitted, setSubmitted] = useState(false);

  const setField = handleFieldChange(setSurvey);
  const { blocker, handleCancel, handleCloseCancelModal, handleConfirmCancel } = useBlockerLogic({
    changesDetected: changesDetected || questionsChanged,
    path: 'surveys',
    formSubmitted: submitted,
  });

  const updateQuestion = (index: number, text: string) => {
    const updatedQuestions = [...surveyQuestions];
    updatedQuestions[index].question = text;

    setSurveyQuestions(updatedQuestions);
  };

  const addQuestion = () => {
    setSurveyQuestions([...surveyQuestions, { uuid: uuidv4(), question: '' }]);
  };

  const removeQuestion = (index: number) => {
    const updatedQuestions = [...surveyQuestions];
    updatedQuestions.splice(index, 1);

    setSurveyQuestions(updatedQuestions);
  };
  const validateTag = (value: string) => {
    // Define patterns
    const validTag = isValidTag(value);

    if (!validTag) {
      setTagError(true);
      setTagErrorMessage('Only lowercase alphanumeric, - _ and + supported');
    } else {
      setTagError(false);
      setTagErrorMessage('');
    }
  };

  const handleTagChange = (tag: string) => {
    setField('tag', tag);
    validateTag(tag);
  };

  const saveSurvey = async () => {
    setSubmitted(true);
    const completeSurvey: SurveyData = {
      ...survey,
      questions: surveyQuestions.map(({ question }) => question),
    };

    onSubmitForm(completeSurvey);
  };

  const validateRange = () => {
    const minimum = Number(survey.min);
    const maximum = Number(survey.max);

    if (maximum <= minimum || minimum >= maximum) {
      return false;
    }

    return true;
  };

  const validateForm = () => {
    if (survey.title === '') {
      return false;
    }

    if (surveyQuestions.length === 0 || !!surveyQuestions.filter(({ question }) => question.length === 0).length) {
      return false;
    }

    if (!validateRange()) {
      return false;
    }

    return true;
  };

  const createEditLabel = (!!survey?.title && `Edit ${survey.title}`) || 'Create a new';
  const canSubmit = (changesDetected || questionsChanged) && !tagError && validateForm();

  return (
    <>
      <ContentLayout header={<Header variant='h1'></Header>}>
        <Container
          header={
            <Header
              variant='h2'
              description={`Use this form to ${createEditLabel} survey`}
            >
              {createEditLabel} survey
            </Header>
          }
        >
          <form onSubmit={(event) => event.preventDefault()}>
            <Form
              actions={
                <SpaceBetween
                  direction='horizontal'
                  size='xs'
                >
                  <Button
                    formAction='none'
                    variant='link'
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant='primary'
                    onClick={saveSurvey}
                    disabled={!canSubmit}
                  >
                    Save
                  </Button>
                </SpaceBetween>
              }
              errorText=''
            >
              <SpaceBetween size='m'>
                <Container header={<Header variant='h2'>Title and Description</Header>}>
                  <SpaceBetween
                    direction='vertical'
                    size='xl'
                  >
                    <ColumnLayout
                      columns={2}
                      variant='text-grid'
                    >
                      <FormField label={'Provide a survey title'}>
                        <Input
                          onChange={({ detail }) => setField('title', detail.value)}
                          value={survey.title}
                        />
                      </FormField>
                      <FormField
                        constraintText={'Only alphanumeric, - _ and + supported'}
                        errorText={tagErrorMessage}
                        label={
                          <>
                            Tag your message group <TagIcon />
                          </>
                        }
                      >
                        <Input
                          onChange={({ detail }) => handleTagChange(detail.value)}
                          value={survey.tag}
                        />
                      </FormField>
                    </ColumnLayout>
                    <FormField
                      label='Description'
                      stretch
                    >
                      <Textarea
                        onChange={({ detail }) => setField('description', detail.value)}
                        value={survey.description}
                      />
                    </FormField>
                  </SpaceBetween>
                </Container>
                <Container
                  header={
                    <Header
                      variant='h2'
                      description='Messaging associated to the survey'
                    >
                      Messaging
                    </Header>
                  }
                >
                  <SpaceBetween size='s'>
                    <FormField
                      stretch={true}
                      label='Introduction message (optional)'
                      description='The message to play at the start of the survey'
                    >
                      <Input
                        disabled={submitted}
                        type='text'
                        value={survey.introPrompt}
                        onChange={(event) => setField('introPrompt', event.detail.value)}
                      ></Input>
                    </FormField>
                    <FormField
                      stretch={true}
                      label='Conclusion message (optional)'
                      description='The message to play at the end of the survey'
                    >
                      <Input
                        disabled={submitted}
                        type='text'
                        value={survey.outroPrompt}
                        onChange={(event) => setField('outroPrompt', event.detail.value)}
                      ></Input>
                    </FormField>
                  </SpaceBetween>
                </Container>
                <Container
                  header={
                    <Header
                      variant='h2'
                      description='The range for valid input'
                    >
                      Input range
                    </Header>
                  }
                >
                  <SpaceBetween
                    direction='horizontal'
                    size='s'
                  >
                    <FormField
                      stretch={false}
                      label='From'
                    >
                      <Input
                        disabled={submitted}
                        type='number'
                        value={survey.min.toString()}
                        invalid={!validateRange()}
                        onChange={(event) => setField('min', parseInt(event.detail.value))}
                      ></Input>
                    </FormField>
                    <FormField
                      stretch={false}
                      label='To'
                    >
                      <Input
                        disabled={submitted}
                        type='number'
                        value={survey.max.toString()}
                        invalid={!validateRange()}
                        onChange={(event) => setField('max', parseInt(event.detail.value))}
                      ></Input>
                    </FormField>
                  </SpaceBetween>
                </Container>
                <Container
                  header={
                    <Header
                      variant='h2'
                      description='The content of the survey'
                      actions={
                        <SpaceBetween
                          direction='horizontal'
                          size='xs'
                        >
                          <Button
                            disabled={submitted}
                            onClick={addQuestion}
                          >
                            Add question
                          </Button>
                        </SpaceBetween>
                      }
                    >
                      Questions
                    </Header>
                  }
                >
                  <SpaceBetween size='l'>
                    {surveyQuestions.map((item, index) => (
                      <SpaceBetween
                        size='xs'
                        key={item.uuid}
                      >
                        <FormField
                          label={`Question ${index + 1}`}
                          // stretch={true}
                          secondaryControl={
                            <Button
                              variant='inline-icon'
                              onClick={() => removeQuestion(index)}
                              iconName='remove'
                            />
                          }
                        >
                          <Input
                            disabled={submitted}
                            type='text'
                            value={item.question}
                            onChange={(event) => updateQuestion(index, event.detail.value)}
                          ></Input>
                        </FormField>
                      </SpaceBetween>
                    ))}
                  </SpaceBetween>
                </Container>
              </SpaceBetween>
            </Form>
          </form>
        </Container>
      </ContentLayout>
      {blocker.state === 'blocked' && (
        <ConfirmCancelModal
          {...{
            onCancel: handleCloseCancelModal,
            onConfirm: handleConfirmCancel,
            cancelMessage: 'Are you sure you want to close? Any unsaved changes will be lost.',
          }}
        />
      )}
    </>
  );
}
