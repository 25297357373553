import { useState } from 'react';
import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@cloudscape-design/components/button';
import Header from '@cloudscape-design/components/header';
import ButtonDropdown, { ButtonDropdownProps } from '@cloudscape-design/components/button-dropdown';
import { Pagination, Spinner, Table, TableProps } from '@cloudscape-design/components';
import PropertyFilter from '@cloudscape-design/components/property-filter';
import Alert from '@cloudscape-design/components/alert';
import { getTextFilterCounterText } from '../../../utils/text-filter';
import { useLocalStorage } from '../../../components/use-local-storage';
import { useColumnWidths } from '../../../components/table/use-column-width';
import { filteringProperties } from './filtering_poperties';
import { mutate } from 'swr';
import { useSplitPanelControl } from '../../../utils/hooks/splitPanelContext';
import { useNavigate } from 'react-router-dom';

import { useTableConfig } from './table.config';

import { TableEmptyStateProps } from '../../../components/table/commons';
import RoleSplitPanelDetails from './RoleSplitPanelDetails';
import { useApiWithFlash } from '../../../utils/hooks/useApiWithFlash';
import { Actions, ResourceTypes, RoleRecord } from '../../../types/rolePermissions';
import { ProtectedUtilityCreate, useProtectUtility } from '../../../components/protectedUtility';
import { RolesTableProps } from '../types';
import { useAppSession } from '../../../utils/hooks/sessionContext';
import { useCatoCollection } from '../../../utils/hooks/useCatoCollection';

export type DecoratedRoleRecord = RoleRecord & {
  instance: string;
};

export default function RolesTable({ isLoading, roles = [], error }: RolesTableProps) {
  const { defaultPreferences, columns, Preferences } = useTableConfig();
  const appSession = useAppSession();

  const [refreshLoading, setRefreshLoading] = useState(false);
  const [selectedItem, setSelectedItems] = useState<DecoratedRoleRecord>();
  const [columnDefinitions, saveWidths] = useColumnWidths('Cato-Roles-TableEditable-Widths', columns);
  const [preferences, setPreferences] = useLocalStorage('Cato-Roles-TableEditable-Preferences', defaultPreferences);
  const protectUtility = useProtectUtility();
  const { setSplitPanelOpen, setSplitPanelContent } = useSplitPanelControl();
  const navigate = useNavigate();
  const { handleApiWithFlash } = useApiWithFlash();

  const tableEmptyStateProps: TableEmptyStateProps = {
    resourceName: 'Roles',
    resourceType: ResourceTypes.Roles,
    urlPath: 'roles',
  };

  const decoratedRoles = roles.map((role) => ({
    ...role,
    instance: appSession?.instances.find((instance) => instance.instanceId === role?.policies[0].instance)?.instanceName ?? 'unknown',
  }));

  const { items, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCatoCollection(decoratedRoles, {
    tableEmptyStateProps,
    pageSize: preferences.pageSize,
    filteringProperties,
  });

  const onRefresh = async () => {
    setRefreshLoading(true);
    const endpoint = '/roles';
    await mutate(endpoint);
    setRefreshLoading(false);
  };

  const deleteSelectedRole = async () => {
    await handleApiWithFlash(`/roles/${selectedItem?.feature}`, 'DELETE', {
      successMessage: `Successfully deleted role ${selectedItem?.feature}`,
      errorMessage: `Error deleting role ${selectedItem?.feature}`,
      deleteMessage: `Are you sure you want to delete role "${selectedItem?.name}"`,
    });
    setSelectedItems(undefined);
    setSplitPanelOpen(false);
    setSplitPanelContent(null);
    await onRefresh();
  };

  const handleUtilityItemClick = async (event: CustomEvent<ButtonDropdownProps.ItemClickDetails>) => {
    const { id } = event.detail;

    switch (id) {
      case 'edit':
        navigate(`/roles/edit/${selectedItem?.feature}`);
        break;
      case 'delete':
        await deleteSelectedRole();
        break;
      case 'edit-users':
        navigate(`/roles/edit/${selectedItem?.feature}/users`);
        break;
      default:
        break;
    }
  };

  const refreshButtonProps = { onClick: onRefresh };

  function onSelectionChange(selectedRoleItem: RoleRecord) {
    setSplitPanelContent(<RoleSplitPanelDetails selectedItem={selectedRoleItem} />);
    setSplitPanelOpen(true);
  }

  const functions = [
    { text: 'Edit', action: Actions.Update },
    { text: 'Delete', action: Actions.Delete },
    { text: 'Edit Users', action: Actions.Update },
  ];

  const dropDownButtonMenuProps: ButtonDropdownProps = {
    disabled: !selectedItem,
    onItemClick: handleUtilityItemClick,
    loadingText: 'loading',
    items: functions
      .filter((f) =>
        protectUtility({
          resourceType: ResourceTypes.Roles,
          resourceAction: f.action,
        }),
      )
      .map((f) => ({
        text: f.text,
        id: f.text.toLowerCase().replace(' ', '-'),
        disabled: false,
      })),
  };

  const header = (
    <Header
      variant='awsui-h1-sticky'
      actions={
        <SpaceBetween
          direction='horizontal'
          size='xs'
        >
          <Button
            iconName='refresh'
            ariaLabel='Refresh'
            {...refreshButtonProps}
          />
          {!!dropDownButtonMenuProps.items.length && <ButtonDropdown {...{ ...dropDownButtonMenuProps }}>Actions</ButtonDropdown>}
          <ProtectedUtilityCreate resourceType={ResourceTypes.Roles}>
            <Button
              variant='primary'
              onClick={() => navigate('/roles/create')}
            >
              Create Role
            </Button>
          </ProtectedUtilityCreate>
        </SpaceBetween>
      }
    >
      Roles {roles && !isLoading ? `(${roles.length})` : <Spinner />}
    </Header>
  );

  const filter = (
    <PropertyFilter
      {...propertyFilterProps}
      countText={getTextFilterCounterText(filteredItemsCount)}
      expandToViewport={true}
      filteringAriaLabel={'Filter roles'}
      filteringPlaceholder={'Filter roles'}
    />
  );

  const rolesTableProps: TableProps<DecoratedRoleRecord> = {
    ...collectionProps,
    stickyHeader: true,
    resizableColumns: true,
    onColumnWidthsChange: saveWidths,
    onSelectionChange: ({ detail }) => {
      const [selectedRoleItem] = detail.selectedItems;
      onSelectionChange(selectedRoleItem);
      if (selectedRoleItem) {
        setSelectedItems(selectedRoleItem);
      }
    },
    selectedItems: selectedItem ? [selectedItem] : [],
    columnDefinitions,
    items,
    trackBy: 'feature',
    loadingText: 'Loading Roles',
    columnDisplay: preferences.contentDisplay,
    wrapLines: preferences.wrapLines,
    stripedRows: preferences.stripedRows,
    stickyColumns: preferences.stickyColumns,
    contentDensity: preferences.contentDensity as 'compact' | 'comfortable',
    selectionType: 'single',
    loading: isLoading || refreshLoading,
    header,
    filter,
    pagination: <Pagination {...paginationProps} />,
    preferences: <Preferences {...{ preferences, setPreferences }} />,
  };

  return (
    <>
      <Table {...{ ...rolesTableProps }} />
      {error && (
        <Alert
          statusIconAriaLabel='Error'
          type='error'
          header='Error getting data'
        >
          Refresh the page to try again.
        </Alert>
      )}
    </>
  );
}
