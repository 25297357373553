import { useAppSession } from '../../utils/hooks/sessionContext';
import { disabledResources } from '../disabledResources';
import { ProtectedUtilityProps } from './types';

/**
 * utility function can be used without ProtectedUtility component to protectd a list item for example
 * @param props
 * @returns boolean
 */
export const useProtectUtility = () => {
  const appSession = useAppSession();

  return (props: Omit<ProtectedUtilityProps, 'children'>): boolean => {
    const disabledResource = disabledResources.includes(props.resourceType);

    const isAdmin = appSession?.userProfile?.['custom:securityProfile'] === 'Administrator';

    const routePermissions = (!!appSession?.instancePermissions && appSession?.instancePermissions[props.resourceType]) || [];

    return !disabledResource && (isAdmin || routePermissions.includes(props.resourceAction));
  };
};
