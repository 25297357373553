import GetWidgetData from '../../../../../../../utils/getWidgetData';
import { useItemContext } from '../../../../dashboardContext';
import { WidgetTableProps } from '../interfaces';
import WidgetTable from './table';

export default function QueueSummaryStatsContent({ widgetId }: { widgetId: string }) {
  const { item } = useItemContext();
  const widget = item.data.widgets.find((widget: { id: string }) => widget.id === widgetId);
  const period = item.data.period;

  const { data, isLoading, error } = GetWidgetData({ widget, period });

  const widgetTableProps: WidgetTableProps = {
    widgetId,
    isLoading,
    data: data?.data,
    error,
  };

  return <WidgetTable {...widgetTableProps} />;
}
