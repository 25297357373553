import React, { useEffect, useState } from 'react';
import {
  ColumnLayout,
  Container,
  Header,
  ContentLayout,
  SpaceBetween,
  Textarea,
  Form,
  Input,
  Toggle,
  Button,
  FormField,
  Table,
  Spinner,
} from '@cloudscape-design/components';
import { useNavigate, useParams } from 'react-router-dom';
import useChangeDetector from '../../../utils/useChangeDetector';
import { MessageGroupItem } from '../types';
import useInstanceSWR from '../../../utils/hooks/useInstanceSWR';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLanguage, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useInlineEdit } from '../lib/useInlineEdit';
import { handleFieldChange } from '../../../utils/fieldChangeUtils';
import TranslationModal from './components/translationModal';
import { useApiWithFlash } from '../../../utils/hooks/useApiWithFlashInstance';
import Alert from '@cloudscape-design/components/alert';
import { ConfirmCancelModal } from '../../../components/confirmCancelModal';
import { TagIcon } from '../../utilities/tagIcon';
import { isValidTag } from '../../../utils/validations';
import { useBlockerLogic } from '../../../utils/hooks/useBlocker';

export default function MessagesEdit() {
  // state to control changes made
  const { item, setItem, setItemShadow, changesDetected } = useChangeDetector<MessageGroupItem>({} as MessageGroupItem);

  // state
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMessageKey, setSelectedMessageKey] = useState<string | null>(null);
  const [tagError, setTagError] = useState<boolean>(false);
  const [tagErrorMessage, setTagErrorMessage] = useState<string>();
  // Hooks
  const navigate = useNavigate();
  const { featureId } = useParams();
  const { handleApiWithFlash } = useApiWithFlash();
  const { handleEdit, handleAttributeEdit, addRow, removeRow } = useInlineEdit(setItem);
  const { blocker, handleCancel, handleCloseCancelModal, handleConfirmCancel } = useBlockerLogic({
    changesDetected,
    path: 'messages',
    formSubmitted,
  });
  const setItemFieldChange = handleFieldChange(setItem);

  const validateTag = (value: string) => {
    // Define patterns
    const validTag = isValidTag(value);

    if (!validTag) {
      setTagError(true);
      setTagErrorMessage('Only lowercase alphanumeric, - _ and + supported');
    } else {
      setTagError(false);
      setTagErrorMessage('');
    }
  };

  const handleTagChange = (tag: string) => {
    setItemFieldChange('tag', tag);
    validateTag(tag);
  };

  const openModalWithMessageKey = (key: string) => {
    setSelectedMessageKey(key);
    setIsModalOpen(true);
  };

  const { data, isLoading, error } = useInstanceSWR<MessageGroupItem>(`/messages/${featureId}`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 0,
  });

  useEffect(() => {
    if (data?.data) {
      setItem(data.data);
      setItemShadow(data.data);
    }
  }, [data]);

  if (error) {
    return (
      <Alert
        statusIconAriaLabel='Error'
        type='error'
        header='Unable to load component'
      >
        {error.status}
      </Alert>
    );
  }

  if (isLoading || !item.feature) {
    return (
      <Container
        header={
          <Header
            variant='h2'
            description='Loading your message editor'
          >
            <Spinner />
          </Header>
        }
      />
    );
  }

  // Handle form submission
  async function handleFormSubmit() {
    setFormSubmitted(true);
    await handleApiWithFlash(`/messages/${item.feature}`, 'PUT', {
      successMessage: `Successfully updated message group ${item.feature}`,
      errorMessage: `Error updating message group ${item.feature}`,
      mutateKey: `/messages/${item.feature}`,
      data: item,
    });
    navigate('/messages');
  }

  const messages = item.data.messages ?? [];
  const attributes = item.data.attributes ?? [];

  const canSubmit = changesDetected && !tagError;

  return (
    <ContentLayout header={<Header variant='h1'></Header>}>
      <Form
        variant='embedded'
        actions={
          <SpaceBetween
            direction='horizontal'
            size='xs'
          >
            <Button
              formAction='none'
              variant='link'
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              variant='primary'
              loading={formSubmitted}
              onClick={handleFormSubmit}
              disabled={!canSubmit}
            >
              Submit
            </Button>
          </SpaceBetween>
        }
      >
        <SpaceBetween
          direction='vertical'
          size='xl'
        >
          <Container header={<Header variant='h2'>{isLoading ? <Spinner /> : item?.feature}</Header>}>
            <SpaceBetween
              direction='vertical'
              size='xl'
            >
              <ColumnLayout
                columns={2}
                variant='text-grid'
              >
                <FormField label={'Message group name'}>
                  <Input
                    disabled
                    value={item?.feature}
                  />
                </FormField>
                <FormField
                  constraintText={'Only alphanumeric, - _ and + supported'}
                  errorText={tagErrorMessage}
                  label={
                    <>
                      Tag your message group <TagIcon />
                    </>
                  }
                >
                  <Input
                    onChange={({ detail }) => handleTagChange(detail.value)}
                    value={item?.tag ?? ''}
                  />
                </FormField>
              </ColumnLayout>
              <FormField
                label='Description'
                stretch
              >
                <Textarea
                  onChange={({ detail }) => setItemFieldChange('description', detail.value)}
                  value={item?.description}
                />
              </FormField>
            </SpaceBetween>
          </Container>

          <Table
            wrapLines
            header={
              <Header
                variant='h2'
                actions={
                  <SpaceBetween
                    direction='horizontal'
                    size='xs'
                  >
                    <Button
                      variant='normal'
                      onClick={() => {
                        addRow('situational');
                      }}
                    >
                      Add situational message
                    </Button>
                    <Button
                      variant='primary'
                      onClick={() => {
                        addRow('normal');
                      }}
                    >
                      Add message
                    </Button>
                  </SpaceBetween>
                }
              >
                Messages
              </Header>
            }
            columnDefinitions={[
              {
                id: 'key',
                header: 'Message Key',
                cell: (message) => message.key ?? '-',
                width: 150,
                minWidth: 150,
                editConfig: {
                  ariaLabel: 'Message Key',
                  editIconAriaLabel: 'editable',
                  errorIconAriaLabel: 'Key Error',
                  editingCell: (message, cellContext) => {
                    const defaultMessagePath = message.key;
                    return (
                      <Input
                        autoFocus={true}
                        value={cellContext.currentValue ?? defaultMessagePath}
                        onChange={(event) => {
                          cellContext.setValue(event.detail.value);
                        }}
                      />
                    );
                  },
                },
              },
              {
                id: 'default',
                header: 'Default Message',
                cell: (message) => message.message.find((m) => m.lang === 'default')?.message[0]?.children[0]?.text ?? '-',
                minWidth: 300,
                editConfig: {
                  ariaLabel: 'Name',
                  editIconAriaLabel: 'editable',
                  errorIconAriaLabel: 'Name Error',
                  editingCell: (message, cellContext) => {
                    const defaultMessagePath = message.message.find((m) => m.lang === 'default')?.message[0]?.children[0]?.text;
                    return (
                      <Input
                        autoFocus={true}
                        value={cellContext.currentValue ?? defaultMessagePath}
                        onChange={(event) => {
                          cellContext.setValue(event.detail.value);
                        }}
                      />
                    );
                  },
                },
              },
              {
                id: 'status',
                header: 'Active Status',
                cell: (message) => {
                  if (message.active === null) {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100%',
                        }}
                      >
                        <Toggle
                          checked
                          disabled
                        ></Toggle>
                      </div>
                    );
                  } else if (message.active === false || message.active === true) {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100%',
                        }}
                      >
                        <Toggle
                          checked={message.active}
                          onChange={() => handleEdit('active', message, null, !message.active)}
                        ></Toggle>
                      </div>
                    );
                  } else {
                    return '-';
                  }
                },
                width: 176,
                minWidth: 176,
              },
              {
                id: 'translations',
                header: 'Translations',
                cell: (message) => (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faLanguage}
                      style={{ cursor: 'pointer' }}
                      onClick={() => openModalWithMessageKey(message.key)}
                      title='View Translations'
                    />
                  </div>
                ),
                width: 30,
                minWidth: 30,
              },
              {
                id: 'remove',
                header: 'Remove',
                cell: (item) => (
                  <FontAwesomeIcon
                    icon={faTrash}
                    style={{ color: '#ff0000', cursor: 'pointer' }}
                    onClick={(event) => removeRow(event, item.key)}
                  />
                ),
                width: 30,
                minWidth: 30,
              },
            ]}
            submitEdit={(item, column, newValue) => {
              handleEdit(column.id as string, item, column, newValue);
            }}
            items={messages}
            loadingText='Loading messages...'
          />

          <Table
            header={
              <Header
                variant='h2'
                actions={
                  <SpaceBetween
                    direction='horizontal'
                    size='xs'
                  >
                    <Button
                      variant='primary'
                      onClick={() => {
                        addRow('attribute');
                      }}
                    >
                      Add attribute
                    </Button>
                  </SpaceBetween>
                }
              >
                Attributes
              </Header>
            }
            columnDefinitions={[
              {
                id: 'key',
                header: 'Key',
                cell: (attribute) => attribute.key ?? '-',
                width: 200,
                minWidth: 176,
                isRowHeader: true,
                editConfig: {
                  ariaLabel: 'key',
                  editIconAriaLabel: 'editable',
                  errorIconAriaLabel: 'Key Error',
                  editingCell: (attribute, cellContext) => {
                    return (
                      <Input
                        autoFocus={true}
                        value={cellContext.currentValue ?? attribute.key}
                        onChange={(event) => {
                          cellContext.setValue(event.detail.value);
                        }}
                      />
                    );
                  },
                },
              },
              {
                id: 'value',
                header: 'Value',
                cell: (attribute) => attribute.value ?? '-',

                minWidth: 176,
                isRowHeader: true,
                editConfig: {
                  ariaLabel: 'key',
                  editIconAriaLabel: 'editable',
                  errorIconAriaLabel: 'Key Error',
                  editingCell: (attribute, cellContext) => {
                    return (
                      <Input
                        autoFocus={true}
                        value={cellContext.currentValue ?? attribute.value}
                        onChange={(event) => {
                          cellContext.setValue(event.detail.value);
                        }}
                      />
                    );
                  },
                },
              },
              {
                id: 'remove',
                header: 'Remove',
                cell: (item) => (
                  <FontAwesomeIcon
                    icon={faTrash}
                    style={{ color: '#ff0000', cursor: 'pointer' }}
                    onClick={(event) => removeRow(event, item.key, 'attribute')}
                  />
                ),
                width: 30,
                minWidth: 30,
              },
            ]}
            submitEdit={(item, column, newValue) => {
              handleAttributeEdit(column.id as string, item, column, newValue);
            }}
            items={attributes}
            loadingText='Loading attributes...'
          />
        </SpaceBetween>
      </Form>
      {blocker.state === 'blocked' && (
        <ConfirmCancelModal
          {...{
            onCancel: handleCloseCancelModal,
            onConfirm: handleConfirmCancel,
            cancelMessage: 'Are you sure you want to close? Any unsaved changes will be lost.',
          }}
        />
      )}
      {selectedMessageKey && (
        <TranslationModal
          visible={isModalOpen}
          setVisible={setIsModalOpen}
          messageKey={selectedMessageKey}
          item={item}
          setItem={setItem}
        />
      )}
    </ContentLayout>
  );
}
