import { Modal, SpaceBetween, ColumnLayout, Box, Button, Input } from '@cloudscape-design/components';
import { useState } from 'react';
import { useItemContext } from '../../../../dashboardContext';
import styles from './agentStatus.module.scss';
import { SortableList } from '../../../../../components/sortableList';
import { AgentStatusPreferenceType, AgentStatusTypes, StatusOrderableItem, StatusOrderableItems } from './types';
import { useInitialise } from '../../../../../../../utils/hooks/useInitialise';
import QueuePicker from "../../../../../../../components/amzn-connect-components/queue-picker";
import FormField from "@cloudscape-design/components/form-field";

export type AgentStatusPrefencesProps = {
  widgetId: string;
  onDismiss: () => void;
};

const orderableItems: StatusOrderableItems = {
  [AgentStatusTypes.Routable]: {id: AgentStatusTypes.Routable, title: "Routable", statuses: ["Available", "Incoming", "On A Call", "After Call Work"]},
  [AgentStatusTypes.Custom]: {id: AgentStatusTypes.Custom, title: "Custom", statuses: ["Custom Statuses"]},
  [AgentStatusTypes.Error]: {id: AgentStatusTypes.Error, title: "Error", statuses: ["Missed Call"]},
}

const AgentPreferenceSortableList = SortableList<StatusOrderableItem>;
export const AgentStatusPreferences = ({ widgetId, onDismiss }: AgentStatusPrefencesProps) => {
  const { getWidget, setWidgetDetails } = useItemContext();
  const [statusOrder, setStatusOrder] = useState<StatusOrderableItem[]>([])
  const widget = getWidget<AgentStatusPreferenceType>(widgetId);
  
  useInitialise(() => {
      const initialOrder = widget.preferences.order ? widget.preferences.order.map(order => orderableItems[order]) : Object.values(orderableItems);

      setStatusOrder(initialOrder);
  });

  const setTheOrderItems = (orderedStatuses: StatusOrderableItem[]) => {
    const newOrder = orderedStatuses.map(item => item.id);
    setStatusOrder(orderedStatuses);
    setWidgetDetails(widgetId, { preferences: { order: newOrder }})
  }

  const handleTitleChange = (title: string) => {
    setWidgetDetails(widgetId, { title });
  };

  return (
    <Modal
      visible={true}
      header='Preferences'
      footer={
        <Box float='right'>
          <SpaceBetween
            direction='horizontal'
            size='xs'
          >
            <Button
              variant='link'
              formAction='none'
              onClick={onDismiss}
            >
              Close
            </Button>
          </SpaceBetween>
        </Box>
      }
      onDismiss={onDismiss}
    >
      <ColumnLayout
        columns={1}
        borders='horizontal'
      >

        <FormField 
            description="Select a queue from the dropdown below to view its realtime data" 
            label="Queue">
            <QueuePicker widgetId={widgetId} />

        </FormField>

        <FormField description='Set the name of the widget'>
              <Input
                value={widget.data.title}
                onChange={({ detail }) => handleTitleChange(detail.value)}
              />
            </FormField>
        <div>
          <h2>Set the order</h2>
        <AgentPreferenceSortableList
          items={statusOrder}
          onChange={setTheOrderItems}
          renderItem={(item) => (
            <SortableList.Item id={item.id}>
              <SortableList.DragHandle />
              <div className={styles.sortableListItem}>
                <h4>{item.title}</h4>
                <p>{item.statuses.join(', ')}</p>
              </div>
              
            </SortableList.Item>
          )}
        />
        </div>
      </ColumnLayout>
    </Modal>
  );
};
