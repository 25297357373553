/*
  This function is to convert the Date Range Picker values into EPOCH as required by DynamoDB APIs
*/

import { DateRangePickerProps } from '@cloudscape-design/components/date-range-picker';

type RelativeUnit = 'minute' | 'hour' | 'day' | 'week' | 'month' | 'year';

interface RelativeDateRange {
  type: 'relative';
  key: string;
  amount: number;
  unit: RelativeUnit;
}

interface AbsoluteDateRange {
  type: 'absolute';
  startDate: string;
  endDate: string;
}

export type DateRange = RelativeDateRange | AbsoluteDateRange;

export interface EpochDateRange {
  startEpoch: number;
  endEpoch: number;
}

// Helper function to get milliseconds for a relative unit
function getMilliseconds(amount: number, unit: RelativeUnit): number {
  const milliseconds: { [key in RelativeUnit]: number } = {
    minute: 60 * 1000,
    hour: 60 * 60 * 1000,
    day: 24 * 60 * 60 * 1000,
    week: 7 * 24 * 60 * 60 * 1000,
    month: 30 * 24 * 60 * 60 * 1000,
    year: 365 * 24 * 60 * 60 * 1000,
  };
  return amount * milliseconds[unit];
}

// Main conversion function
export function dateRangeToEpoch(dateRange: DateRangePickerProps.Value): { epochRange: EpochDateRange; dateRange: DateRange } {
  const now = Date.now();
  let startEpoch: number;
  let endEpoch: number;
  let convertedDateRange: DateRange;

  if (dateRange.type === 'relative') {
    endEpoch = now;
    startEpoch = now - getMilliseconds(dateRange.amount, dateRange.unit as RelativeUnit);

    convertedDateRange = {
      type: 'relative',
      key: dateRange.key ?? `last-${dateRange.amount}-${dateRange.unit}`,
      amount: dateRange.amount,
      unit: dateRange.unit as RelativeUnit,
    };
  } else {
    // Absolute date range
    startEpoch = new Date(dateRange.startDate).getTime();
    endEpoch = new Date(dateRange.endDate).getTime();

    convertedDateRange = {
      type: 'absolute',
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    };
  }

  return {
    epochRange: { startEpoch, endEpoch },
    dateRange: convertedDateRange,
  };
}
