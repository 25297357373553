import React, { useState } from 'react';
import Header from '@cloudscape-design/components/header';
import SpaceBetween from '@cloudscape-design/components/space-between';
import { Alert, ContentLayout , Container, Button } from '@cloudscape-design/components';
import ChatBlock from './components/containers/chatBlock';
import QuestionBar from './components/containers/questionBar';
import { ChatWebSocketProvider } from './components/contexts/chatWebSocketContext';
import SettingsModal from './settingsModal';
import PoweredBy from '../../components/poweredBy';
import useSWR from 'swr';
import fetcher from '../../utils/fetcher';
import { useLocation, useNavigate } from 'react-router-dom';

export default function ChatbotTesting() {
  const [visible, setVisible] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const { data: promptData, isLoading: promptLoading } = useSWR('/prompt-dictionary', fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 0,
  });

  const displayWarning = !promptLoading && (!promptData || promptData?.data.length === 0);

  // implement a basic iframe support
  const queryParams = new URLSearchParams(location.search);
  const iframe = queryParams.get('iframe') === 'true';

  return (
    <ContentLayout
      header={
        !iframe && (
          <Header variant="h1" description="Test out your GenAI Assistant configuration">
            Chatbot testing
          </Header>
        )
      }
    >
      <SpaceBetween size="m">
        <div className="flex items-center justify-center">
          <div className="mt-12 h-5/6 max-w-2xl rounded-2xl border border-accent p-2 shadow-2xl">
            {displayWarning ? (
              <Container header={<Header variant="h2">Documents</Header>}>
                <Alert
                  statusIconAriaLabel="Warning"
                  type="warning"
                  action={<Button onClick={() => navigate('/prompt-engineering/create')}>Create a prompt</Button>}
                >
                  Please create a prompt before using chatbot testing tool
                </Alert>
              </Container>
            ) : (
              <ChatWebSocketProvider>
                <Container
                  header={
                    <Header
                      variant="h2"
                      description="I am here to help you take the guess work out of parenting."
                      actions={
                        <SpaceBetween direction="horizontal" size="xs">
                          <Button iconName="settings" variant="icon" onClick={() => setVisible(true)} />
                        </SpaceBetween>
                      }
                    >
                      Jeni
                    </Header>
                  }
                  footer={<PoweredBy />}
                >
                  <ChatBlock />
                  <QuestionBar />
                  <SettingsModal visible={visible} setVisible={setVisible} />
                </Container>
              </ChatWebSocketProvider>
            )}
          </div>
        </div>
      </SpaceBetween>
    </ContentLayout>
  );
}
