import { ButtonDropdown, ButtonDropdownProps, Icon, Link, LinkProps, NonCancelableCustomEvent, Select, SelectProps } from '@cloudscape-design/components';
import './top-navigation.scss';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import { UserProfile, fetchUserProfile } from '../../../components/auth/userProfile';
import { signOut } from '../signout';
import { extractSelectGroupItems } from '../../../components/navigation/instanceSelector';
import { useAppSession } from '../../../utils/hooks/sessionContext';
import { InstanceSelected, InstanceType } from '../../../types/instance';
import { useInitialise } from '../../../utils/hooks/useInitialise';

export function TopBarNavigation() {
  const appSession = useAppSession();
  const [selectedOption, setSelectedOption] = useState<OptionDefinition | null>(null);
  const [items, setItems] = useState<Array<SelectProps.OptionGroup>>([]);
  const navigate = useNavigate();
  const [profile, setProfile] = useState<UserProfile | null>(null);

  useInitialise(() => {
    async function getUserProfile() {
      const profileData = await fetchUserProfile();
      setProfile(profileData);
    }

    getUserProfile();
  });

  useEffect(() => {
    const instanceItems = appSession?.instances ? extractSelectGroupItems(appSession.instances) : [];

    setItems(instanceItems);
  }, [appSession?.instances]);

  useEffect(() => {
    setSelectedOption({
      label: appSession?.instanceSelected?.instanceAlias,
      value: appSession?.instanceSelected?.instanceArn,
    });
  }, [appSession?.instanceSelected]);

  const handleUtilityItemClick = (event: CustomEvent<ButtonDropdownProps.ItemClickDetails>) => {
    const details = event.detail;

    if (details.id === 'sign-out') {
      signOut();
    }
  };

  function handleIdentityClick(event: CustomEvent & { preventDefault: () => void }) {
    event.preventDefault();
    navigate('/');
  }

  async function handleInstanceSelectorClick(event: NonCancelableCustomEvent<SelectProps.ChangeDetail>) {
    try {
      const storedInstanceSelected = appSession?.instanceSelected;
      const currentInstanceArn = storedInstanceSelected?.instanceArn;

      const instances = appSession?.instances || [];

      if (instances.length > 0) {
        const instanceArn = event.detail.selectedOption.value;
        const selectedInstance = instances.find((instance: InstanceType) => instance.instanceArn === instanceArn);

        if (selectedInstance) {
          const selectedData: InstanceSelected = {
            instanceArn: selectedInstance.instanceArn,
            instanceAlias: selectedInstance.instanceName,
            instanceRegion: selectedInstance.instanceArn,
            instanceIntuitionUrl: selectedInstance.intuitionUrl,
            instanceTimezone: selectedInstance.timezone,
          };

          await appSession?.setInstanceSelected(selectedData);

          if (instanceArn !== currentInstanceArn) {
            navigate('/home');
          }
        } else {
          console.error('Selected instance ARN not found in data');
        }
      }
    } catch (error) {
      console.error('Failed to fetch instance data:', error);
    }
  }

  const linkProps: LinkProps = {
    onFollow: handleIdentityClick,
  };

  const selectProps: SelectProps = {
    selectedOption,
    onChange: handleInstanceSelectorClick,
    options: items,
  };

  const buttonDropdownProps = {
    description: profile?.email || 'Menu',
    iconName: 'user-profile',
    items: [{ text: profile?.email, items: [{ id: 'sign-out', text: 'Sign out' }] }],
    onItemClick: handleUtilityItemClick,
  };

  return (
    <nav
      id='header-navigation'
      className='header'
    >
      <div>
        <Link {...linkProps}>Cato</Link>
      </div>
      <span className='filler'>&nbsp;</span>
      {selectedOption && selectedOption?.value && (
        <div>
          <Select {...selectProps} />
        </div>
      )}
      <div>
        <ButtonDropdown {...buttonDropdownProps}>
          <Icon name='user-profile' />
          {profile ? <span className='profile-name'>{`${profile?.given_name} ${profile?.family_name}`}</span> : 'Menu'}
        </ButtonDropdown>
      </div>
    </nav>
  );
}
