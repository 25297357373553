export const FILTERING_PROPERTIES = [
  {
    propertyLabel: 'Ticket Id',
    key: 'ticketId',
    groupValuesLabel: 'Ticket Id values',
    operators: [':', '!:', '=', '!='] as const,
  },
  {
    propertyLabel: 'Subject',
    key: 'subject',
    groupValuesLabel: 'Subject',
    operators: [':', '!:', '=', '!='] as const,
  },
  {
    propertyLabel: 'Description',
    key: 'description',
    groupValuesLabel: 'Description',
    operators: [':', '!:', '=', '!='] as const,
  },
] as const;
