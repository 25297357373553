import React, { createContext, useState } from "react";
import { WidgetPreferences } from "./preferences";


export interface InstanceQueueMetricsWidgetContextType {
  openPreferences: () => void;
  widgetId: any;
}

export const InstanceQueueMetricsWidgetContext = createContext<InstanceQueueMetricsWidgetContextType>({
  openPreferences: () => null,
  widgetId: {},
});

export function InstanceQueueMetricsProvider({ children, widgetId }: { children: React.ReactElement; widgetId: string }) {
  const [preferencesVisible, setPreferencesVisible] = useState(false);
  return (
    <InstanceQueueMetricsWidgetContext.Provider
      value={{
        widgetId,
        openPreferences: () => setPreferencesVisible(true),
      }}
    >
      {React.cloneElement(React.Children.only(children), {
        removeConfirmationText: 'Instance Queue Metrics',
        actions: [{ text: 'Preferences', onClick: () => setPreferencesVisible(true) }],
      })}
      {preferencesVisible && (
        <WidgetPreferences
          widgetId={widgetId}
          onDismiss={() => setPreferencesVisible(false)}
        />
      )}
    </InstanceQueueMetricsWidgetContext.Provider>
  );
}
