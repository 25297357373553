export enum AgentStatusTypes {
  Routable = 'ROUTABLE',
  Custom = 'CUSTOM',
  Error = 'ERROR',
}
export type AgentStatusType = `${AgentStatusTypes}`

export type AgentStatusObject = {
  firstName: string | null;
  lastName: string | null;
  currentStatus: {
    startTimeStamp: string;
    name: string;
    type: AgentStatusType
  };
};

export type AgentStatusesObjects = AgentStatusObject[];

export type StatusOrderableItem = {
  id: string;
  title: string;
  statuses: string[];
}

export type StatusOrderableItems = {
  [key in AgentStatusType]: StatusOrderableItem;
};

export type AgentStatusPreferenceType = {
  order: AgentStatusType[];
  header: string;
}
