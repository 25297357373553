import React from 'react';
import { Link } from 'react-router-dom';
import { CollectionPreferencesProps, TableProps } from '@cloudscape-design/components';
import { useProtectUtility } from '../../../components/protectedUtility';
import { Actions, ResourceTypes } from '../../../types/rolePermissions';
import { DecoratedRoleRecord } from './table';
import { PreferenceProps } from '../../../types/table';
import { TablePreferences } from '../../../utils/tablePreferences';

export const useTableConfig = () => {
  const protectUtility = useProtectUtility();
  const columns: TableProps.ColumnDefinition<DecoratedRoleRecord>[] = [
    {
      id: 'name',
      header: 'Name',
      cell: (item) => protectUtility({resourceType: ResourceTypes.Roles, resourceAction: Actions.Create}) ? <Link to={`/roles/edit/${item?.feature}`}>{item?.name}</Link> : item.name,
      sortingField: 'name',
    },
    {
      id: 'description',
      header: 'Description',
      cell: (item) => item.description,
      sortingField: 'description',
    },
    {
      id: 'instance',
      header: 'Instance',
      cell: (item) => item.instance,
      sortingField: 'instance',
    },
  ];

  const defaultContentDisplayOptions: CollectionPreferencesProps.ContentDisplayOption[] = [
    { id: 'name', label: 'Resource Name', alwaysVisible: true },
    { id: 'description', label: 'Description', alwaysVisible: false },
    { id: 'instance', label: 'Instance', alwaysVisible: false },
  ];

  const defaultPageSizeOptions: CollectionPreferencesProps.PageSizeOption[] = [
    { value: 10, label: '10 roles' },
    { value: 30, label: '30 roles' },
    { value: 50, label: '50 roles' },
  ];

  const defaultPreferences: CollectionPreferencesProps.Preferences = {
    pageSize: 10,
    contentDisplay: [
      { id: 'name', visible: true },
      { id: 'description', visible: true },
      { id: 'instance', visible: true },
    ],
    wrapLines: false,
    stripedRows: false,
    contentDensity: 'comfortable',
    stickyColumns: { first: 1, last: 0 },
  };

  const Preferences = ({
    preferences,
    setPreferences,
    disabled = false,
    pageSizeOptions = defaultPageSizeOptions,
    contentDisplayOptions = defaultContentDisplayOptions,
  }: PreferenceProps) => <TablePreferences {...{ preferences, setPreferences, disabled, pageSizeOptions, contentDisplayOptions }} />;


  return { columns, defaultPreferences, Preferences };
};
