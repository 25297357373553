import React from 'react';
import { CollectionPreferences } from '@cloudscape-design/components';
import { epochToUTCString } from '../../../utils/timeUtils';

const rawColumns = [
  {
    id: 'datetime',
    header: 'Datetime (UTC)',
    cell: (item) => {
      const epoch = item.dateTime ? item.dateTime.split('#')[0] : null;
      return epoch ? epochToUTCString(Number(epoch)) : '-';
    },
    sortingField: 'dateTime',
  },
  {
    id: 'requester',
    header: 'Performed by',
    cell: (item) => item.requester || '-',
    sortingField: 'requester',
  },
  {
    id: 'action',
    header: 'Action',
    cell: (item) => item.action || '-',
    sortingField: 'action',
  },
  {
    id: 'type',
    header: 'Resource Type',
    cell: (item) => item.type || '-',
    sortingField: 'type',
  },
  {
    id: 'resource',
    header: 'Resource Name',
    cell: (item) => item.resource || '-',
    sortingField: 'resource',
  },
  {
    id: 'instanceId',
    header: 'Instance Id',
    cell: (item) => item.instanceId || '-',
    sortingField: 'instanceId',
  },
];

export const COLUMN_DEFINITIONS = rawColumns.map((column) => ({ ...column }));

const editableColumns = {};

export const EDITABLE_COLUMN_DEFINITIONS = COLUMN_DEFINITIONS.map((column) => {
  if (editableColumns[column.id]) {
    return {
      ...column,
      minWidth: Math.max(column.minWidth || 0, 176),
      ...editableColumns[column.id],
    };
  }
  return column;
});

const CONTENT_DISPLAY_OPTIONS = [
  { id: 'datetime', label: 'Datetime', alwaysVisible: true },
  { id: 'requester', label: 'Performed by', alwaysVisible: true },
  { id: 'action', label: 'Action', alwaysVisible: true },
  { id: 'type', label: 'Resource Type', alwaysVisible: false },
  { id: 'resource', label: 'Resource Name', alwaysVisible: false },
  { id: 'instanceId', label: 'Instance ID', alwaysVisible: false },
];

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: '10 Logs' },
  { value: 30, label: '30 Logs' },
  { value: 50, label: '50 Logs' },
];

export const DEFAULT_PREFERENCES = {
  pageSize: 10,
  contentDisplay: [
    { id: 'datetime', visible: true },
    { id: 'requester', visible: true },
    { id: 'action', visible: true },
    { id: 'type', visible: true },
    { id: 'resource', visible: true },
    { id: 'instanceId', visible: true },
  ],
  wrapLines: false,
  stripedRows: false,
  contentDensity: 'comfortable',
  stickyColumns: { first: 1, last: 0 },
};

export const Preferences = ({
  preferences,
  setPreferences,
  disabled = false,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  contentDisplayOptions = CONTENT_DISPLAY_OPTIONS,
}) => (
  <CollectionPreferences
    title={'Preferences'}
    cancelLabel={'Cancel'}
    confirmLabel={'Confirm'}
    disabled={disabled}
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    pageSizePreference={{ title: 'Page size', options: pageSizeOptions }}
    wrapLinesPreference={{
      label: 'Wrap lines',
      description: 'Select to see all the text and wrap the lines',
    }}
    stripedRowsPreference={{
      label: 'Striped rows',
      description: 'Select to add alternating shaded rows',
    }}
    contentDensityPreference={{
      label: 'Compact mode',
      description: 'Select to display content in a denser, more compact mode',
    }}
    contentDisplayPreference={{
      title: 'Select visible content / order',
      options: contentDisplayOptions,
    }}
    stickyColumnsPreference={{
      firstColumns: {
        title: 'Stick first column(s)',
        description: 'Keep the first column(s) visible while horizontally scrolling the table content.',
        options: [
          { label: 'None', value: 0 },
          { label: 'First column', value: 1 },
          { label: 'First two columns', value: 2 },
        ],
      },
      lastColumns: {
        title: 'Stick last column',
        description: 'Keep the last column visible while horizontally scrolling the table content.',
        options: [
          { label: 'None', value: 0 },
          { label: 'Last column', value: 1 },
        ],
      },
    }}
  />
);
