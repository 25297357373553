import { useNavigate } from 'react-router-dom';
import { useApiWithFlash } from '../../../utils/hooks/useApiWithFlashInstance';
import EditDialPlanForm from '../form';
import { DialPlanItem } from '../types';

export default function DialPlanCreate() {

  // Hooks
  const navigate = useNavigate();
  const { handleApiWithFlash } = useApiWithFlash();

  // Handle form submission
  async function onSubmitForm(dialPlan: DialPlanItem) {

    const response = await handleApiWithFlash(`/dial-plans/${dialPlan.feature}`, 'POST', {
      successMessage: `Successfully created dial plan ${dialPlan.feature}`,
      errorMessage: `Error creating dial plan ${dialPlan.feature}`,
      data: dialPlan,
    });

    if (response) {
      navigate('/dial-plan');
    }
  }

  return (
    <EditDialPlanForm { ...{ onSubmitForm }} />
  );
}
