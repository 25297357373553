/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions.js';
import styled from 'styled-components';
import { FaPlayCircle, FaPauseCircle } from 'react-icons/fa';
import _ from 'lodash';
import SentimentBar from './sentimentBar';

const WaveSurferWrap = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr;
  align-items: center;

  button {
    width: 40px;
    height: 40px;
    border: none;
    background-color: transparent;
    padding-top: ${(props) => (props.contactObject?.conversationCharacteristics ? '45px' : '0px')};
  }
`;
function Waveform({ audio, contactObject, setAudioPosition }) {
  const containerRef = useRef();
  const timeRef = useRef();
  const cursorTimeRef = useRef();
  const waveSurferRef = useRef({
    isPlaying: () => false,
  });
  const [isPlaying, toggleIsPlaying] = useState(false);

  // Function to get the ParticipantId based on BeginOffsetMillis
  function getParticipantId(beginOffsetMillis) {
    const transcriptPiece = _.find(
      contactObject.transcript,
      (piece) => piece.BeginOffsetMillis <= beginOffsetMillis && piece.EndOffsetMillis >= beginOffsetMillis
    );
    return transcriptPiece ? transcriptPiece.ParticipantId : null;
  }

  useEffect(() => {
    const waveSurfer = WaveSurfer.create({
      container: containerRef.current,
      /** The height of the waveform in pixels */
      height: 150,
      /** Render each audio channel as a separate waveform */
      splitChannels: false,
      /** Stretch the waveform to the full height */
      normalize: false,
      /** The color of the waveform */
      waveColor: '#07bc0c',
      /** The color of the progress mask */
      progressColor: '#5ac8fa',
      /** The color of the playpack cursor */
      cursorColor: '#5ac8fa',
      /** The cursor width */
      cursorWidth: 4,
      /** Render the waveform with bars like this: ▁ ▂ ▇ ▃ ▅ ▂ */
      barWidth: NaN,
      /** Spacing between bars in pixels */
      barGap: NaN,
      /** Rounded borders for bars */
      barRadius: NaN,
      /** A vertical scaling factor for the waveform */
      barHeight: NaN,
      /** Vertical bar alignment * */
      barAlign: '',
      /** Minimum pixels per second of audio (i.e. zoom level) */
      minPxPerSec: 1,
      /** Stretch the waveform to fill the container, true by default */
      fillParent: true,
      /** Whether to show default audio element controls */
      mediaControls: false,
      /** Play the audio on load */
      autoplay: false,
      /** Pass false to disable clicks on the waveform */
      interact: true,
      /** Hide the scrollbar */
      hideScrollbar: false,
      /** Audio rate */
      audioRate: 1,
      /** Automatically scroll the container to keep the current position in viewport */
      autoScroll: true,
      /** If autoScroll is enabled, keep the cursor in the center of the waveform during playback */
      autoCenter: true,
    });
    const wsRegions = waveSurfer.registerPlugin(RegionsPlugin.create());

    waveSurfer.load(audio);

    waveSurfer.on('ready', () => {
      const duration = waveSurfer.getDuration();
      const minutes = Math.floor(duration / 60);
      const seconds = Math.floor(duration % 60);
      const formattedTime = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;

      // update time element
      timeRef.current.textContent = formattedTime;
    });

    const handleTooltipShow = (event) => {
      event.target.setAttribute('title', event.target.dataset.tip);
    };

    const handleTooltipHide = (event) => {
      event.target.removeAttribute('title');
    };

    function updateCurrentTime() {
      const currentTime = waveSurfer.getCurrentTime();
      const minutes = Math.floor(currentTime / 60);
      const seconds = Math.floor(currentTime % 60);
      const formattedTime = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;

      // update time element
      cursorTimeRef.current.textContent = formattedTime;
    }

    // Update time during playback
    waveSurfer.on('audioprocess', updateCurrentTime);

    // Update time when user seeks
    waveSurfer.on('seeking', updateCurrentTime);

    waveSurfer.on('audioprocess', () => {
      const audioPosition = waveSurfer.getCurrentTime() * 1000; // Convert to milliseconds
      setAudioPosition(audioPosition);
    });

    waveSurfer.on('error', (err) => {
      console.error('WaveSurfer Error:', err);
    });

    waveSurfer.on('decode', () => {
      // Regions for IssuesDetected
      contactObject?.transcript?.forEach((transcriptPiece) => {
        if (transcriptPiece.IssuesDetected) {
          transcriptPiece.IssuesDetected.forEach((issue) => {
            const region = wsRegions.addRegion({
              id: issue.id,
              start: transcriptPiece.BeginOffsetMillis / 1000, // convert to seconds
              end: transcriptPiece.EndOffsetMillis / 1000,
              color: 'red',
              drag: false,
              resize: false,
            });

            // Store the issue text in the region element (accessible later for tooltip)
            region.element.dataset.tip = issue.Text;
            region.element.style.backgroundColor = 'rgba(255, 0, 0, 0.5)';

            // Attach event listeners to show/hide tooltip on mouse interactions
            region.element.addEventListener('mouseover', handleTooltipShow);
            region.element.addEventListener('mouseout', handleTooltipHide);
          });
        }
      });

      // Regions for MatchedDetails
      if (contactObject?.categories?.MatchedDetails) {
        Object.keys(contactObject.categories.MatchedDetails).forEach((category) => {
          const categoryData = contactObject.categories.MatchedDetails[category];
          const issueText = category;
          // Change 'Payment' to the desired category
          categoryData?.PointsOfInterest?.forEach((poi) => {
            const region = wsRegions.addRegion({
              id: `${category}_${poi.BeginOffsetMillis}_${poi.EndOffsetMillis}`,
              start: poi.BeginOffsetMillis / 1000, // convert to seconds
              end: poi.EndOffsetMillis / 1000, // convert to seconds
              color: 'blue',
              drag: false,
              resize: false,
              content: issueText,
            });

            // Using inline styles
            region.element.style.backgroundColor = 'rgba(0, 0, 255, 0.9)';
            region.element.style.height = '3px';
            region.element.style.position = 'absolute';

            // Adjust the tooltip text size and position above the marker
            region.element.style.fontSize = '10px';
            region.element.style.zIndex = '50';

            region.element.style.top = getParticipantId(poi.BeginOffsetMillis) === 'AGENT' ? '120px' : '20px';

            // Store the issue text in the region element (accessible later for tooltip)
            region.element.dataset.tip = issueText;
          });
        });
      }
    });

    waveSurfer.on('ready', () => {
      // Update isPlaying state based on the actual playback state once the audio is fully loaded
      toggleIsPlaying(waveSurfer.isPlaying());
    });

    waveSurferRef.current = waveSurfer;

    return () => {
      waveSurfer.un('ready');
      waveSurfer.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audio]);

  return (
    <WaveSurferWrap contactObject={contactObject}>
      <button
        onClick={() => {
          waveSurferRef.current.playPause();
          toggleIsPlaying(!isPlaying);
        }}
        type="button"
      >
        {isPlaying ? <FaPauseCircle size="3em" /> : <FaPlayCircle size="3em" />}
      </button>

      <div ref={containerRef}>
        {contactObject?.transcript &&
          contactObject.transcript.map((transcriptPiece) => {
            if (transcriptPiece.IssuesDetected) {
              return transcriptPiece.IssuesDetected.map((issue) => (
                <div
                  key={issue.id}
                  className="tooltip"
                  data-tip={issue.Text} // Use issue.Text as tooltip content
                >
                  {/* Your region marker JSX */}
                </div>
              ));
            }
            return null;
          })}
        {contactObject?.transcript && (
          <SentimentBar
            transcript={contactObject.transcript}
            totalDurationMillis={contactObject.conversationCharacteristics.TotalConversationDurationMillis}
          />
        )}
        <div ref={timeRef} style={{ textAlign: 'right' }} />
        <div ref={cursorTimeRef} style={{ position: 'absolute' }} />
      </div>
    </WaveSurferWrap>
  );
}

Waveform.propTypes = {
  audio: PropTypes.string.isRequired,
};



export default Waveform;
