import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Flashbar from '@cloudscape-design/components/flashbar';
import Shell from '../layouts/shell';
import BreadcrumbGroup, { BreadcrumbGroupProps } from '@cloudscape-design/components/breadcrumb-group';
import Navigation from '../components/navigation';
import React, { useEffect, useState } from 'react';
import { FlashProvider, useFlash } from '../utils/hooks/useFlash';
import { SplitPanelProvider, useSplitPanelControl } from '../utils/hooks/splitPanelContext';
import { ModalProvider } from '../utils/hooks/modalContext';
import { getBreadcrumbsForPath } from '../components/navigation/navigationMapping';
import { AppSessionProvider, useAppSession } from '../utils/hooks/sessionContext';
import { Spinner } from '@cloudscape-design/components';
import styles from './root.module.scss';

// Help pages
const helpPageConfig = {
  // memberType include 'simplify'
  '/messages': () => import('./messages/help'),
  '/messages/create': () => import('./messages/help'),
  '/messages/edit/:featureId': () => import('./messages/help'),
  '/closures': () => import('./closures/help'),
  '/closures/create': () => import('./closures/help'),
  '/closures/edit/:featureId': () => import('./closures/help'),
  '/dial-plan': () => import('./dial-plan/help'),
  '/dial-plan/create': () => import('./dial-plan/help'),
  '/dial-plan/edit/:featureId': () => import('./dial-plan/help'),

  // memberType include 'intuition'
  '/visualizations': () => import('./visualizations/help'),
  '/visualizations/create': () => import('./visualizations/help'),
  '/visualizations/edit/:featureId': () => import('./visualizations/help'),
  '/contact-search': () => import('./contact-search/help'),
  '/contact-search/view/:contactId': () => import('./contact-search/help'),

  // memberType include 'xq'
  '/chatbot-testing': () => import('./chatbot-testing/help'),
  '/documents': () => import('./documents/help'),
  '/documents/create': () => import('./documents/help'),
  '/prompt-engineering': () => import('./prompt-engineering/help'),
  '/prompt-engineering/edit/:itemId': () => import('./prompt-engineering/help'),

  // All memberTypes
  '/settings': () => import('./settings/help'),
  '/utilities': () => import('./utilities/help'),
  '/user-admin': () => import('./user-admin/help'),
  '/user-admin/create': () => import('./user-admin/help'),
  '/audit-log': () => import('./audit-log/help'),
  '/home': () => import('./home/help'),
  '/instances': () => import('./instances/help'),
  '/instances/create': () => import('./instances/help'),
  '/instances/edit/:instanceId': () => import('./instances/help'),
  '/tickets': () => import('./tickets/help'),
  '/tickets/create': () => import('./tickets/help'),
  '/roles': () => import('./roles/help'),
  '/roles/create': () => import('./roles/help'),
  '/roles/edit': () => import('./roles/help'),
};
type HelpRoutes = keyof typeof helpPageConfig;

function findHelpForPath(path: string): HelpRoutes | null {
  for (const routeTemplate in helpPageConfig) {
    const regexPath = '^' + routeTemplate.replace(/:[a-zA-Z]+/, '.*') + '$'; // convert route templates into regular expressions
    const regex = new RegExp(regexPath);
    if (regex.test(path)) {
      return routeTemplate as HelpRoutes;
    }
  }
  return null;
}

export default function Root() {
  return (
    <AppSessionProvider>
      <ModalProvider>
        <SplitPanelProvider>
          <FlashProvider>
            <RootContent />
          </FlashProvider>
        </SplitPanelProvider>
      </ModalProvider>
    </AppSessionProvider>
  );

  function RootContent() {
    const { flash } = useFlash();
    const { setSplitPanelOpen, setSplitPanelContent } = useSplitPanelControl();
    const location = useLocation();
    const navigate = useNavigate();
    const [helpContent, setHelpContent] = useState<React.ReactNode | null>(null);
    const dynamicBreadcrumbs = getBreadcrumbsForPath(location.pathname);
    const appSession = useAppSession();

    useEffect(() => {
      setSplitPanelOpen(false); // close the split panel on navigation
      setSplitPanelContent(null); // Clear the split panel contents on navigation
      const loadHelpPage = async () => {
        const matchingRoute = findHelpForPath(location.pathname);
        if (matchingRoute) {
          // Check that matchingRoute is not null
          const fetchHelp = helpPageConfig[matchingRoute];
          if (fetchHelp) {
            const HelpComponent = (await fetchHelp()).default;
            setHelpContent(<HelpComponent />);
          } else {
            setHelpContent(null);
          }
        } else {
          setHelpContent(null);
        }
      };
      loadHelpPage();
    }, [location.pathname, setSplitPanelContent, setSplitPanelOpen]);

    // use '/home' as the default page
    useEffect(() => {
      if (location.pathname === '/') {
        navigate('/home');
      }
      // ... (rest of your useEffect code)
    }, [location.pathname, navigate]);

    const handleBreadcrumbFollow = (event: CustomEvent<BreadcrumbGroupProps.ClickDetail>) => {
      event.preventDefault(); // Prevent the default navigation
      navigate(event.detail.href);
    };

    // If we cannot get to the tenant item then display the loading page
    if (!appSession || appSession.memberType === null) {
      return (
        <div className='mainLoader'>
          <Spinner
            variant='inverted'
            size='large'
          />
        </div>
      );
    }

    // implement a basic iframe support
    const queryParams = new URLSearchParams(location.search);
    const iframe = queryParams.get('iframe') === 'true';

    // if iframe or fullscreen set, then display the page without Shell
    if (iframe) {
      return <Outlet />;
    }

    if (appSession.fullScreen) {
      return (
        <div
          id='detail'
          className={styles.fullscreenContainer}
        >
          <Outlet />
        </div>
      );
    }

    return (
      <Shell
        breadcrumbs={
          <BreadcrumbGroup
            items={dynamicBreadcrumbs}
            onFollow={handleBreadcrumbFollow}
          />
        }
        navigation={<Navigation />}
        tools={helpContent}
        notifications={
          <Flashbar
            items={flash}
            stackItems
          />
        }
      >
        <div id='detail'>
          <Outlet />
        </div>
      </Shell>
    );
  }
}
