import React, { useState } from 'react';
import { Button, SpaceBetween } from '@cloudscape-design/components';
import { DateRangePickerProps } from '@cloudscape-design/components/date-range-picker';
import { formatDateTimePickerApi } from '../../../utils/timeUtils';
import PropertyFilters from './PropertyFilters';
import CxDateRangePicker from '../../../components/cxDataRangePicker';

type SearchBarProps = {
  readonly isLoading: boolean;
  readonly changesMade: boolean;
  readonly setChangesMade: React.Dispatch<React.SetStateAction<boolean>>;
  readonly setDateRange: React.Dispatch<
    React.SetStateAction<{
      startDate: any;
      endDate: any;
    }>
  >;
  readonly setSearchFilters: React.Dispatch<React.SetStateAction<boolean>>;
  readonly setSubmitSearch: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function SearchBar({ isLoading, changesMade, setChangesMade, setDateRange, setSearchFilters, setSubmitSearch }: SearchBarProps) {
  const [value, setValue] = useState<DateRangePickerProps.Value>({
    key: 'previous-6-hours',
    amount: 6,
    unit: 'hour',
    type: 'relative',
  });

  const handleDateRangeChange = (rangeValue: DateRangePickerProps.Value | null) => {
    if (rangeValue) {
      setValue(rangeValue);
      setChangesMade(true);
      setDateRange(formatDateTimePickerApi(rangeValue));
    }
  };

  return (
    <SpaceBetween size='xs'>
      <CxDateRangePicker
        setDateRangeValue={handleDateRangeChange}
        defaults={value}
      />

      <PropertyFilters
        setSearchFilters={setSearchFilters}
        setChangesMade={setChangesMade}
      />
      <Button
        onClick={() => setSubmitSearch(true)}
        loading={isLoading}
        disabled={!changesMade}
      >
        Search
      </Button>
    </SpaceBetween>
  );
}
