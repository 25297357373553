import { Link } from 'react-router-dom';
import { CollectionPreferencesProps } from '@cloudscape-design/components';
import { TagIcon } from '../../utilities/tagIcon';
import { PartialDashboardData } from '../form';
import { PreferenceProps } from '../../../types/table';
import { TablePreferences } from '../../../utils/tablePreferences';

const rawColumns = [
  {
    id: 'feature',
    header: 'Visualization Dashboard',
    cell: (item: PartialDashboardData) => <Link to={`/visualizations/view/${item?.feature}`}>{item?.feature}</Link> || '-',
    sortingField: 'feature',
  },
  {
    id: 'description',
    header: 'Description',
    cell: (item: PartialDashboardData) => item?.description || '-',
    sortingField: 'description',
  },
  {
    id: 'tag',
    header: (
      <>
        Tag
        <TagIcon />
      </>
    ),
    cell: (item: PartialDashboardData) => item?.tag,
    sortingField: 'description',
  },
];

export const COLUMN_DEFINITIONS = rawColumns.map((column) => ({ ...column }));

const CONTENT_DISPLAY_OPTIONS = [
  { id: 'feature', label: 'Visualization', alwaysVisible: true },
  { id: 'description', label: 'Description', alwaysVisible: false },
  { id: 'tag', label: 'Tag', alwaysVisible: false },
];

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: '10 visualizations' },
  { value: 30, label: '30 visualizations' },
  { value: 50, label: '50 visualizations' },
];

export const DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences = {
  pageSize: 10,
  contentDisplay: [
    { id: 'feature', visible: true },
    { id: 'description', visible: true },
    { id: 'tag', visible: true },
  ],
  wrapLines: false,
  stripedRows: false,
  contentDensity: 'comfortable',
  stickyColumns: { first: 1, last: 0 },
};

export const Preferences = ({
  preferences,
  setPreferences,
  disabled = false,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  contentDisplayOptions = CONTENT_DISPLAY_OPTIONS,
}: PreferenceProps) => <TablePreferences {...{ preferences, setPreferences, disabled, pageSizeOptions, contentDisplayOptions }} />;
