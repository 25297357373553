import * as React from 'react';
import LineChart from '@cloudscape-design/components/line-chart';
import { MixedLineBarChartProps } from '@cloudscape-design/components/mixed-line-bar-chart';

import * as awsui from '@cloudscape-design/design-tokens/index';
import { SentimentByPeriod } from '../../../../types/contactRecord';

interface SentimentPoint {
  Score: number;
}

export interface SentimentLineChartProps {
  SentimentByPeriod?: SentimentByPeriod;
}

/* eslint-disable react/prop-types */
export default function SentimentLineChart(props: SentimentLineChartProps) {
  const { SentimentByPeriod } = props;
  const yValueFormatter = (e: number): string => {
    if (e >= 1) {
      return `${e} Positive`;
    }
    if (e <= -1) {
      return `${Math.abs(e)} Negative`;
    }
    return 'Neutral';
  };

  const getDatumPoints = (data: SentimentPoint[]): MixedLineBarChartProps.Datum<number>[] =>
    data.map((point: SentimentPoint, index: number) => ({
      x: index + 1, // 1-based index for quarters
      y: point.Score,
    }));

  const getLineBarChart = (title: string, color: string, data: MixedLineBarChartProps.Datum<number>[]): MixedLineBarChartProps.LineDataSeries<number> => ({
    title,
    type: 'line',
    color,
    data,
    valueFormatter: yValueFormatter,
  });

  const series: MixedLineBarChartProps.LineDataSeries<number>[] = [];
  if (SentimentByPeriod?.QUARTER?.AGENT) {
    series.push(getLineBarChart('AGENT', awsui.colorChartsPurple300, getDatumPoints(SentimentByPeriod.QUARTER.AGENT)));
  }
  if (SentimentByPeriod?.QUARTER?.CUSTOMER) {
    series.push(getLineBarChart('CUSTOMER', awsui.colorChartsGreen300, getDatumPoints(SentimentByPeriod.QUARTER.CUSTOMER)));
  }

  return (
    <LineChart
      series={series}
      xDomain={[1, 4]} // Updated for quarters
      yDomain={[-5, 5]} // Updated based on score range
      ariaLabel="Single data series line chart"
      height={150}
      hideFilter
      xScaleType="linear"
      xTitle="Quarter"
      yTitle="Sentiment Score"
    />
  );
}
